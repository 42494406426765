import * as React from 'react';
import TextImgBox from '../textImgBox';
import useStyles from './styles';
import { Typography } from '@mui/material';
import image from './images/person-icon.png';
import TextLogoBox from '../textLogoBox';
import { allowOnClick } from '../../utilities/appHelpers';
import { useEffect, useState } from 'react';
import { useUserTheme } from '../../themes';

export interface ISideBarProps {
  className?: string,
  style?: Record<string, any>,
  children: any
  visibility?:boolean
  onToggle?:any
}

export default function SideBar(props: ISideBarProps) {
  const classes = useStyles().classes;
  const {isMobile,viewSize}=useUserTheme(), [mobileNav, setMobileNav] = useState(false);
  const { className = "", style = {} ,visibility=false, onToggle=()=>void(0)} = props;
  
  useEffect(()=>{
    setMobileNav((stat)=> !stat);
  },[isMobile, visibility])

  return (
    <>
    {(isMobile) && mobileNav && (<div role='button' onClick={onToggle} className={`${classes.cover} animated faster fadeIn`}></div>)}
      <div  className={`${classes.main} ${mobileNav || viewSize === "md" || viewSize === "lg" || viewSize === "xl"?classes.show:classes.hide} ${className}`} style={style}>
        {props.children}
    </div>
    </>
  );
}

export function SideBarHeader({status, presence,children}:any) {
  return (
    <TextImgBox status={status} presence={presence} imgSrc={image} imgSize="30px" style={{ padding: "25px 40px" }} >
      <Typography component="div" >{children}</Typography>
    </TextImgBox>
  )
}

export interface ISideBarItemProps {
  logo?:any,
  style?:Record<string,any>,
  className?:string,
  children?:any,
  onClick?:any,
  isClicked?:boolean
}
export function SideBarItem(props:ISideBarItemProps) {
  const classes = useStyles().classes;
  const {logo=null, style={}, className="", onClick=()=>void(0), isClicked=false} = props;
  const [clicked, setClicked] = useState(isClicked);
  useEffect(()=>{
    setClicked(isClicked);
  }, [isClicked]);

  return (
    <div className={`${classes.item} ${className} ${clicked ? " active" : ""}`} {...allowOnClick(()=>{setClicked(true); onClick()})} style={style}>
      <div className={classes.gradient}></div>
      <TextLogoBox logo={logo} className={classes.textLogoBox}>
      <Typography component="div" className={classes.text}>{props.children}</Typography>
      </TextLogoBox> 
      <div className={`${classes.indicator}`}></div> 
    </div>
  );
}