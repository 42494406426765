import { Theme } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
// import { flex } from "../../utilities/appHelpers";

const useStyles = makeStyles()((theme: Theme) => ({
  loginForm: {
    marginTop: "16px",
    padding: "0 16px 20px 16px",
    boxSizing: "border-box",
    fontSize: "15px",
    "& *": {
      boxSizing: "inherit",
    },
    '& .cta-cont':{
      display:'flex',
      justifyContent:'space-between',
      gap: '32px',
      "& #dtp-signup-login-link":{
        color:theme.palette.secondary.main
      },
      "& .dtp-signup-tc-login": {
        marginTop: '18px'
      }
    },
    "& .link":{
      backgroundColor:'transparent', border:'none', color:theme.palette.secondary.main, fontWeight:600, fontSize:'1rem', paddingLeft:0, cursor:'pointer'
   },
    "& .reset-icon":{
      width:'100%', height:180,
      "& img":{
        width:'100%', height:'100%', objectFit:'contain'
      }
    }
  },
  title: {
    textAlign: "center",
    padding: "5px",
    fontSize: "2em",
    fontWeight: "bold",
    margin: "10px 5px",
    transition: "all 1s ease",
    "&.small": {
      fontSize: "15px"
    }
  },
  imageWrapper: {
    display: 'block',
    width: 'calc(100% - 50px)',
    margin: 'auto',
    maxWidth: '400px',
    position: 'relative',
    height: 'auto'
  },
  images: {
    width: "35%",
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    zIndex: 1,
  },
  imageHeightSetter: {
    position: 'relative',
    visibility: 'hidden',
  },
  imageAnimated: {
    animation: "loginshieldimg 1.1s ease-in-out 0.5s forwards",
    width: '15%',
    transform: 'translate(-50%, 25%)',
  },
  imageCenter: {
    animation: "loginroundimg 0.7s ease-out 0.8s forwards",
    
  },
 

}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
