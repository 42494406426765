import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import { ThemeOptions } from '@mui/material/styles';
import { Theme, DeprecatedThemeOptions, ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material";
import { lightTheme, darkTheme, colors } from "./themeOptions";
import { getFromStore, removeFromStore, saveInStore } from "../utilities/helpers";



declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}



/**
 * **userTheme** - 'light' | 'dark' | 'default'
 */
export type userTheme = 'light' | 'dark' | 'default';


export interface IUserTheme{
    /**
 * **userTheme** - 'light' | 'dark' | 'default'
 */
    userTheme:userTheme,

    /**
 * **theme** - material ui Theme
 */
    theme:Theme,

     /**
 * **changeTheme** - function to change theme
 * @param {userTheme} userTheme
 */
    changeTheme :(userTheme:userTheme) => void,

     /**
 * **userColor** - the color theme selected
 */
      userColor:string,

       /**
 * **changeColor** - function to change theme
 * @param {string} userColor
 */
   changeColor :(userColor:string) => void,
}

export const ThemeContext = createContext<IUserTheme>({userTheme:'light', changeTheme:()=>void(0),theme:createTheme(lightTheme), changeColor:()=>void(0),userColor:'standard'});

export const getOSTheme = ():userTheme=>{
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches?'dark':'light'
}


/**
 * **UserThemeProvider** - Customized Theme provider, (includes material ui ThemeProvider Implementation)
 * @param props 
 * @returns 
 */
export const UserThemeProvider = (props:{children:any})=>{
    //State to manage user selected ThemeOption
    const [theme, setUserThemeOptions] = useState<ThemeOptions>((getFromStore('ACP_USER_THEME','local') || getOSTheme()) === 'dark'?darkTheme:lightTheme);
    //State to manage user select theme 
    const [userTheme, setUserTheme] = useState<userTheme>(getFromStore('ACP_USER_THEME','local') || getOSTheme() || 'light');

    //State to manage user select color 
    const [userColor, setUserColor] = useState<string>(getFromStore('ACP_USER_COLOR','local') || 'standard');

    //set default Theme
    /* useEffect(()=>{

         const colorInStore = getFromStore('ACP_USER_COLOR','local');
        if(colorInStore){
            setUserColor(colorInStore);
        //   changeColorInner(colorInStore);
        } 

        //Use saved user theme if exist
      const themeInStore = getFromStore('ACP_USER_THEME','local') || getOSTheme();
      if(themeInStore){
        changeThemeInner(themeInStore);
        return
      }


      //Get and set OSTheme if exist
      const themeFromOS =getOSTheme();
      if(themeFromOS !== 'light')changeThemeInner(themeFromOS);


    },[]); */

    /**
     * Function to change color Inner Use
     */
     const changeColorInner = (userColor:string)=>{
        setUserColor(userColor);
    }
    
    /**
     * Function to change theme Inner Use
     */
    const changeThemeInner = (userTheme:userTheme)=>{
        setUserThemeOptions(userTheme == 'dark'?darkTheme:lightTheme);
        setUserTheme(userTheme);
    }


    /**
     * **changeTheme** Change user theme globally ('light' | 'dark' | 'default')
     * @param userTheme 
     */
    const changeTheme = (userTheme:userTheme)=>{
        //check if user change to default, then get and use the os theme
        const newUserTheme:userTheme = userTheme === 'default'?getOSTheme():userTheme;
        changeThemeInner(newUserTheme);
        //if user change to default remove saved one in storage else update storage
        userTheme === 'default'?removeFromStore('ACP_USER_THEME','local'):saveInStore('ACP_USER_THEME',userTheme,'local');
    }

    /**
     * **changeColor** Change user color globally
     * @param userColor 
     */
     const changeColor = (userColor:string)=>{
        
        changeColorInner(userColor);
        //if user change to default remove saved one in storage else update storage
        saveInStore('ACP_USER_COLOR',userColor,'local');
    }

    // credits: richard maloney 2006
function getTintedColor(color:string, v:number) {
    if (color.length >6) { color= color.substring(1,color.length)}
    let rgb = parseInt(color, 16); 
    let r:any = Math.abs(((rgb >> 16) & 0xFF)+v); if (r>255) r=r-(r-255);
    let g:any = Math.abs(((rgb >> 8) & 0xFF)+v); if (g>255) g=g-(g-255);
    let b:any = Math.abs((rgb & 0xFF)+v); if (b>255) b=b-(b-255);
    r = Number(r < 0 || isNaN(r)) ? 0 : ((r > 255) ? 255 : r).toString(16); 
    if (r.length == 1) r = '0' + r;
    g = Number(g < 0 || isNaN(g)) ? 0 : ((g > 255) ? 255 : g).toString(16); 
    if (g.length == 1) g = '0' + g;
    b = Number(b < 0 || isNaN(b)) ? 0 : ((b > 255) ? 255 : b).toString(16); 
    if (b.length == 1) b = '0' + b;
    return "#" + r + g + b;
} 

    /**
     * **injectUserColors** Dynamically update the default theme option by the user selected color theme
     * @param {string} userColor the color theme name 
     * @param {DeprecatedThemeOptions} theme the theme options object
     * @returns {DeprecatedThemeOptions} updated theme option object 
     */
    const injectUserColors = (userColor:string,theme:DeprecatedThemeOptions) =>{
        if(colors[userColor]){
            const newprim = {...theme};
            const primaryColors = colors[userColor];
            if(newprim.palette){
                newprim.palette.primary = primaryColors
            }else{
                newprim.palette = {primary:primaryColors}
            }

            //use darken of primary in dark mode
            /* if(newprim.palette.mode == 'dark'){
                const darken = chroma(primaryColors?.main).darken(4).hex();
                newprim.background = {...newprim.background,main:darken};
            } */
            return newprim
        }
        return theme;
    }

    return (
        <ThemeContext.Provider value={{userTheme,theme:createTheme(injectUserColors(userColor,theme)),changeTheme,changeColor,userColor}}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={createTheme(injectUserColors(userColor,theme))}>
                {props.children}
                </ThemeProvider>
            </StyledEngineProvider>
        </ThemeContext.Provider>
    );
}

/**
 * **useUserTheme** - Custom hook to make UserThemeProvider available in child component
 * @returns {IUserTheme} -{userTheme,userThemeOptions,changeTheme}
 */
export const useUserTheme = () =>{
    const userThemeModel:IUserTheme = useContext(ThemeContext);

    /**
     * Hold the computed screen size (breakpoint string)
     */
   const [screenSize, setScreenSize] = useState('');

   /**
    * Get the current screen breakpoint key as specified in theme
    * @returns {string} The breakpoint key
    */
    const getScreenSize = ()=>{
        const width = window.innerWidth; //current screen width
        //swap the breakpoint to have the value as key
        const swapedBreakpoint = Object.fromEntries(Object.entries(userThemeModel.theme.breakpoints.values).map((indBrkpoint:[string,number])=>[indBrkpoint[1],indBrkpoint[0]]))
        //sort the breakpoints to makesure it in ascending order
        const sortObj = Object.keys(swapedBreakpoint).sort().reduce(function (result:Record<number,string>, key:any) {
              result[key] = swapedBreakpoint[key];
              return result;
            }, {});
        
            //convert the breakpoint object to array of arrays
        const breakPointArr = Object.entries(sortObj);

        //check if no breakpoint set
        if(breakPointArr.length < 1)return '';
        //check if it is greater than the highest first, the return the largest breakpoint (string key)
        if(width >= parseInt(breakPointArr[breakPointArr.length-1][0]))return breakPointArr[breakPointArr.length-1][1]

        //get the current screen breakpoint key based on the current screen size
        const indBrkPoint = breakPointArr.findIndex((indKey:any)=>indKey[0] > width);
        return breakPointArr[indBrkPoint > 0?indBrkPoint-1:0][1];
    }

    /**
     * regetSize - get the updated screen width and compute the breakpoint, then set the state that holds the breakpoint
     * @returns 
     */
    const regetSize = ()=>setScreenSize(getScreenSize());

    /**
     * Call regetSize when screen is resized
     */
    useEffect(()=>{
        window.addEventListener('resize',regetSize)
        return () => {
            window.removeEventListener('resize',regetSize)  
        }
    },[]);

    const cSize = useMemo(()=>{
return screenSize == ''?getScreenSize():screenSize
    },[screenSize])

    
    return {...userThemeModel,viewSize:cSize, isMobile: cSize === 'xs', isTablet: cSize === 'sm' || cSize === 'md', isDesktop: cSize === 'lg' || cSize === 'xl'};
}

