import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard, faStar, faUser } from "@fortawesome/free-regular-svg-icons";
import { faBuildingUser } from "@fortawesome/free-solid-svg-icons";
import useMediaQuery from '@mui/material/useMediaQuery';
import useStyles from './styles';
import { Typography } from '@mui/material';
import SimpleButton, { ButtonVariant } from '../../../components/simpleButton';
import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import PGSHome from './home';
import PGSSetup from './setup';

export enum IECPPages {
  "home" = 0,
  "setup" = 1
}

export interface IECPProps {
}

export default function ECP(props: IECPProps) {
  const classes = useStyles().classes;
  const [page, setPage] = useState(IECPPages.home);
  return (
    <div>
      <Typography component="h2" className="fadeInUp animated faster" style={{ margin: "20px 0 10px" }}>
        <div className={classes.twoCol}>
          <div className={classes.leftCol} style={{ fontSize: 20, fontWeight: 600 }}>
            Taquatech: Payment Gateway Service
          </div>
          <div className={classes.rightCol} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            {page === IECPPages.home && (
              <SimpleButton variant={ButtonVariant.alt} onClick={() => { setPage(IECPPages.setup) }} className="fadeIn animated delay-0-8s">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ padding: "0 5px" }}><Icon icon="carbon:cloud-service-management" width="16" height="16" /></div>
                  <div>Setup</div>
                </div>
              </SimpleButton>
            )}
            {page !== IECPPages.home && (
              <SimpleButton variant={ButtonVariant.alt} onClick={() => { setPage(IECPPages.home) }} className="fadeIn animated delay-0-8s">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon icon="eva:arrow-back-fill" width="16" height="16" style={{ marginRight: 5 }} />
                  <div>Back</div>
                </div>
              </SimpleButton>
            )}
          </div>
        </div>
      </Typography>
      <div style={{ margin: "20px 0" }} className="fadeIn animated delay-0-4s">
        {page === IECPPages.home && <PGSHome />}
        {page === IECPPages.setup && <PGSSetup />}
      </div>
    </div>
  );
}
