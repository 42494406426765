
import "./plugins_styles/dtp-dashboard.css";
import "./plugins_styles/dtp-home.css";
import useStyles from "./styles"
import blue from "./congratimgs/blue.png" 
import bluesmall from "./congratimgs/bluesmall.png" 
import greenlong from "./congratimgs/greenlong.png" 
import purplelong from "./congratimgs/purplelong.png" 
import purplesmall from "./congratimgs/purplesmall.png" 
import purplesmallsmall from "./congratimgs/purplesmallsmall.png" 
import redlong from "./congratimgs/redlong.png" 
import redsmall from "./congratimgs/redsmall.png" 
import redsmallsmall from "./congratimgs/redsmallsmall.png" 
import yellowlong from "./congratimgs/yellowlong.png" 
import yellowsmall from "./congratimgs/yellowsmall.png" 
import login2 from "./congratimgs/login2.png" 
import login from "./congratimgs/login.png" 
import LoginMod from "../loginMod";
import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { getAppStorage } from "../../utilities/appHelpers";
import { resendLoginLink } from "../../services/signup";
import { messasgeType, useGlobalState } from "../../pages/dashboard";
import { Icon } from "@iconify/react";
import Hint from '../../components/hintBox';

export default function WelcomePage() {
    const classes = useStyles().classes;
    // const [showSignIn, setShowSignIn] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [hint, setHint] = useState<Record<string,any>>({errorData:null, message:'', count:0, show:false,title:""}); 
    const [hintState, setHintState] = useGlobalState("hint");

    useEffect(()=>{
        const email = getAppStorage('userEmail');
        if(email) setUserEmail(email);
    }, []);

    const resendToken = async ()=> {
        const email = getAppStorage('userEmail');
        if(email) {
            try {
                const res = await resendLoginLink({ email });
                if (res.status === 200) {
                    // show message
                    setHintState({
                        icon: messasgeType.success,
                        msg: res.data.message,
                        status: true
                    })
                } else {
                    // handle error
                    setHintState({
                        icon: messasgeType.failed,
                        msg: res.data.message,
                        status: true
                    })
                }
            } catch (error:any) {
                if(error.response) {
                    setHintState({
                        icon: messasgeType.failed,
                        msg: error.response.data.message,
                        status: true
                    })
                } else {
                    setHintState({
                        icon: messasgeType.failed,
                        msg: "Unspecified Error",
                        status: true
                    })
                }
            }
        } else {
            // handle error
            setHint({
                title: "Failed",
                message: "Unknown Email",
                show: true
            })
        }
    }
    
  return <>
  {/* <LoginMod  show={showSignIn} onPopChange={setShowSignIn} /> */}
  <Hint show={hintState.status} message={hintState.msg} onClose={(stat:boolean)=>setHintState(current=>({...current,status:stat,msg:''}))} icon={<Icon icon={hintState.icon}/>} escButton={true}/>
  <div id="DashBoardPageWel" className="fadeIn animated delay-0-7s dtp-page DashBoardPage" >
 <div className={`dtp-dashboard ${classes.bg}`}>
    <div className=" dtp-dashboard-wel">
        <div className="dtp-welc-anim-bx">
            <img src={blue} style={{width: "80px"}} className="dtp-pop-ribons ml5"/>
            <img src={bluesmall} style={{width: "8px"}} className="dtp-pop-ribons ml6"/>
            <img src={greenlong} style={{width: "30px"}} className="dtp-pop-ribons ml2"/>
            <img src={purplelong} style={{width: "60px"}} className="dtp-pop-ribons ml7"/>
            <img src={ purplesmall } style={{width: "13px"}} className="dtp-pop-ribons ml8"/>
            <img src={ purplesmallsmall } style={{width: "10px"}} className="dtp-pop-ribons ml9"/>
            <img src={ redlong } style={{width: "90px" }}className="dtp-pop-ribons ml4"/>
            <img src={ redsmall } style={{width: "8px"}} className="dtp-pop-ribons ml10"/>
            <img src={ redsmallsmall } style={{width: "7px"}} className="dtp-pop-ribons ml11"/>
            <img  src={ yellowlong } style={{width: "60px" }}className="dtp-pop-ribons ml1"/>
            <img src={ yellowsmall } style={{width: "10px"}} className="dtp-pop-ribons ml3"/>
            <img src={login2} className="dtp-dashboard-welimg1 dtp-dashboard-welimg" alt="DTECHPAY"/>
            <img src={login} className="dtp-dashboard-welimg2 dtp-dashboard-welimg scaleuser" alt="DTECHPAY"/>
        </div>
        <Typography component={'div'} className="dtp-signup-title fadeInUp animated delay-0-4s w3-xlarge">Welcome&nbsp;<b
            className={`${classes.textColor} text-color`}>On-Board</b>
        </Typography>
            <div className="dtp-line" style={{marginBottom:10}}>&nbsp;</div>
            {/* <div style={{display:'grid', placeItems:'center'}}>
                <Typography component={'div'} className={`dtp-dual-btn card-3 ${classes.btns}`}>
                    <button className={`${classes.primaryBtn} bg-color card-1`}>Documentation</button>
                    <button className={`${classes.secondaryBtn} bg-color-alt card-1`} id="dtp-proceed" onClick={()=>{setShowSignIn(true)}}>Proceed</button>
                </Typography>
            </div> */}

            <Typography component={'div'} className={` ${classes.otherTextColor} dtp-welc-copy`} style={{marginTop:50}}>
                {/* <div>By clicking the <strong>Proceed</strong> button, you have accepted our <a href="#">Terms and Conditions</a></div> */}
                <p>We have sent an email to <strong>{userEmail}</strong> to verify your email address and activate your account. The link will expire in 24 hours.</p>
                <p>Didn't receive an email? <button className={`${classes.link}`} onClick={resendToken}>Resend it now</button></p>
                <div className="w3-margin-top">&copy; {new Date().getFullYear()} <a href="./">DTECHPAY</a></div>
            </Typography>
        
    </div>
    </div>
</div>
  </>;
}
