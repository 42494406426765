import { memo, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PDFGen from "../components/pdf";

function Receipt() {

    const [URLSearchParams] = useSearchParams();
    const navigator = useNavigate()

    const recpData= useRef<Record<string,any>>({});
    useEffect(() => {
        if (URLSearchParams.has('recp')) {
            let recpt = JSON.parse(atob(URLSearchParams.get('recp') ?? ''));
            let rdata = recpt;
            const { businessName, amount, payerName, charges, date, channel, ref, currency } = rdata;
            if (!businessName || !amount || !payerName || !charges || !date || !channel || !ref || !currency) {
                // navigator('/badrequest');
                // console.log(recpt);
                
                recpData.current = recpt;
            }else{
            }
            recpData.current = recpt;
        } else {
            navigator('/badrequest');
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<PDFGen data={recpData.current} />);
}

export default memo(Receipt);