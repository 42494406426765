import React, { useEffect, useState} from "react";
import IconBtn from '../iconBtn';
import LogoBox from '../logoBox';
import useStyles from './styles';
import { useUserTheme } from '../../themes';
import { Icon } from '@iconify/react';


export default function NoMenuNav(props: any) {
  const classes = useStyles().classes;
  const {isMobile, viewSize} = useUserTheme();
  const { className="", style={}, onClickMobile=null} = props, [mob, setMob] = useState(false);
  
  useEffect(()=>{
    onClickMobile && onClickMobile(mob);
  },[mob])

  return (
    <div className={`${classes.main} ${className} card-1-1`} style={style}>
      { (isMobile || viewSize === "sm") && <IconBtn onToggle={(stat:boolean)=>setMob(stat)} style={{width:'auto',height:'auto',fontSize:'1.5rem'}} icon={<Icon icon='charm:menu-hamburger'/>} />}
      <LogoBox className={classes.logo} />
      {/* {!isMobile && <div className={classes.navUt}>
        <IconBtn icon={<FontAwesomeIcon icon={faSearch} />} />
        <SmallDropBtn text={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center", width: 15, height: "100%" }}><img src={GB} alt="" style={{ margin: 0, width: "100%", height: "100%", objectFit: "contain", objectPosition: "center" }} /></div>
            <Typography style={{ margin: "0 8px" }}>English</Typography>
          </div>
        } />
        <IconBtn icon={<FontAwesomeIcon icon={faCog} />} />
        <IconBtn icon={<FontAwesomeIcon icon={faBell} />} />
        <IconBtn icon={<img src={personIcon} />} />
      </div>}
        {isMobile && <IconBtn style={{width:'auto',height:'auto',fontSize:'1.5rem'}} icon={<Icon icon='clarity:ellipsis-vertical-line'/>} />} */}
        <span>&nbsp;</span>
    </div>
  );
}
