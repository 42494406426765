import { lighten } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
 main: {
   display: "flex",
   textAlign: "center",
   justifyContent:"center",
   alignItems: "center",
   border: "none",
   backgroundColor: "transparent",
   borderRadius: 5,
   boxSizing:"border-box",
   padding:"7px 10px",
   cursor: "pointer",
   fontSize:"0.8em",
   "& *": {
     boxSizing: "inherit"
   },
   "&:disabled": {
     cursor: "not-allowed",
    //  background:'grey',
     boxShadow:'none'

   }
 },
 mainBg: {
   backgroundColor: theme.palette.primary.main,
   color: theme.palette.secondary.contrastText
 },
 altBg: {
  backgroundColor: lighten(theme.palette.secondary.main, 0.2),
  color: theme.palette.secondary.contrastText,
  fontWeight: 600
 },
 whiteBg: {
  backgroundColor: theme.palette.secondary.contrastText,
  color: lighten(theme.palette.secondary.main, 0.0),
  border: `1px solid ${lighten(theme.palette.primary.main, 0.8)}`
 },
 redBg: {
  backgroundColor: "rgb(252, 76, 76)",
  color: theme.palette.secondary.contrastText,
  fontWeight: 600
 },
 greenBg: {
  backgroundColor: "rgb(88, 176, 63)",
  color: theme.palette.secondary.contrastText,
  fontWeight: 600
 }
 
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
