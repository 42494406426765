import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeftLong,
  faArrowRightLong,
  faBuildingUser,
  faCheck,
  faFolderTree,
  faHandshakeSimple,
  faInfo,
  faLaptopCode,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alpha } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../components/button";
import OptionCard from "../../components/optionCard";
import Popup from "../../components/popup";
import { Option } from "../../components/select";
import WizardImproved from "../../components/wizardI";
import Indicator from "../../components/wizardI/indicator";
import { SessionCheck, dispatch, messasgeType, useGlobalState } from "../../pages/dashboard";
import theme from "../../resource/theme";
import { addSetupInfo, getAccountTypes, getCountries } from "../../services/setup";
import { error, getAppStorage, setAppStorage } from "../../utilities/appHelpers";
import AccountPage from "./pages/account";
import ContactDetailsPage from "./pages/details";
import FinishPage from "./pages/finish";
import WelcomePage from "./pages/welcome";

export interface ISetupWizardProps {}

export default function SetupWizard(props: ISetupWizardProps) {
  const userRef:string = getAppStorage("DTECHPAY_USERID");
  const [page, setPage] = useState(0);
  const [dir, setDir] = useState("left");
  const [accTypes, setAccTypes] = useState<Record<string,any>[]>([]);
  const [allCountries, setAllCountries] = useState<Record<string,any>[]>([]);
  const [countries, setCountries] = useState<Record<string,any>>([]);
  const [allStates, setAllStates] = useState<Record<string,any>[]>([]);
  const [states, setStates] = useState<Record<string,any>[]>([]);
  const [alertMessage, setAlertMessage] = useState<Record<string,any>>([]);
  const [account, setAccount] = useState(-1);

  const filterCountry = (val:string)=>{
    setCountries(allCountries.filter((indval:any)=>indval.name.toLowerCase().indexOf(val.toLowerCase()) > -1))
  }
  const [selectedCountry, setSelectedCountry] = useState<any>(-1)
  const [countryvalue, setCountryValue] = useState('');

  const filterState = (val:string)=>{
    setStates(allStates.filter((indval:any)=>indval.name.toLowerCase().indexOf(val.toLowerCase()) > -1))
  }
  const [selectedState, setSelectedState] = useState<any>(-1)
  const [statevalue, setStateValue] = useState('');

  const [formData, setFormData] = useState<Record<string, any>>({
    accTypeId: "",
    address: "",
    countryCode: "",
    stateCode: "",
    postalCode: "",
    busName: "",
  });

  const [hintState,setHintState] = useGlobalState("hint");

  const requiredFields:Record<string, any> = {accTypeId:"Account type", address:"Address", countryCode:"Country code", stateCode:"State/Province code", postalCode:"Postal code"};

  const submitForm = async ()=>{
    try {
      const res = await addSetupInfo(userRef, formData);
      if (res.status === 200) {
        setPage(3);
        return;
      } else {
        setPage(2);
          setAlertMessage({
            ...alertMessage,
            message: res.data.message,
            title: "Request failed",
            show: true,
            count:alertMessage.count+1
          });
      }
    } catch (e: any) {
      SessionCheck(e);
      setAlertMessage({
        ...alertMessage,
        message: error.info(error.handle(e,"You're offline. Please check your network and try again")),
        title: "Request failed",
        show: true,count:alertMessage.count+1
      });
      return;
    }
  }

  const [wizardDisplay, setWizardDisplay] = useState(true);

  const prev = () => {
    setPage(page > 0 ? page - 1 : page);
    setDir("right");
  };
  const next = () => {
    if(page === 1 && account === -1) {setPage(1); setAlertMessage({
      ...alertMessage,
      message: "Select an account type to proceed",
      title: "Request failed",
      show: true, count:alertMessage.count+1
    }); return;}
    if(page === 2 && formData) {
      // setPage(3);
      //   return;
      for (const indData in formData) {
        if (Object.prototype.hasOwnProperty.call(formData, indData)) {
          if(formData[indData] === "" &&  indData !== "busName") {
            setAlertMessage({
              ...alertMessage,
              message: `${requiredFields[indData]} is required`,
              title: "Request failed",
              show: true, count:alertMessage.count+1
            });
            return;
          };
        }
      }
      submitForm();
      return;
    }
    if(page === 3) {
      setWizardDisplay(false);
      setAppStorage("dshStat", 4);
      return;
    }

    setPage(page < pages.length - 1 ? page + 1 : page);
    setDir("left");
  };
  const loadAccTypes = async () => {
    if(getAppStorage("AccTypes") === "" || getAppStorage("AccTypes") === undefined || getAppStorage("AccTypes") === null){
      try {
        const res = await getAccountTypes();
        if (res.status === 200) {
          setAccTypes(res.data.data);
          setAppStorage("AccTypes", res.data.data)
        } else {
          setPage(0);
          setAlertMessage({
            ...alertMessage,
            message: res.data.message,
            title: "Request failed",
            show: true, count:alertMessage.count+1
          });
        }
      } catch (e: any) {
        SessionCheck(e);

        setPage(0);
        setAlertMessage({
          ...alertMessage,
          message: error.info(error.handle(e,"You're offline. Please check your network and try again")),
          title: "Request failed",
          show: true, count:alertMessage.count+1
        });
      }
    } else {
      setAccTypes(getAppStorage("AccTypes"));
    }
  };
  const loadCountries = async () => {
    if(getAppStorage("Countries") === "" || getAppStorage("Countries") === undefined || getAppStorage("Countries") === null){
      try {
        const res = await getCountries();
        if (res.status === 200) {
          setAllCountries(res.data.data);
          setCountries(res.data.data);
          setAppStorage("Countries", res.data.data);
        }
      } catch (e: any) {
        SessionCheck(e);

        setAlertMessage({
          ...alertMessage,
          message: error.info(error.handle(e,"You're offline. Please check your network and try again")),
          title: "Request failed",
          show: true, count:alertMessage.count+1
        });
      }
    } else {
      setAllCountries(getAppStorage("Countries"));
      setCountries(getAppStorage("Countries"));
    }
  };
  const loadStates = (allStates:any)=>{
    setAllStates(allStates)
    setStates(allStates);
  }
  useEffect(() => {
    (page === 1 && accTypes.length === 0) && loadAccTypes();
    (page === 2 && allCountries.length === 0) && loadCountries(); 
  }, [page]);
  useEffect(()=>{
    if(page === 2 && selectedCountry > -1) {
      setCountryValue(countries[selectedCountry].name);
      setFormData({ ...formData, countryCode: countries[selectedCountry].code2 });
      loadStates(countries[selectedCountry].states);
      setSelectedState(-1);
      setStateValue("")
    }
  }, [countries,selectedCountry]);

  useEffect(()=>{
    page === 2 && selectedState > -1 && setStateValue(states[selectedState].name)
  }, [states,selectedState]);
  
  const accLogos = [
    <FontAwesomeIcon icon={faUser} />,
    <FontAwesomeIcon icon={faBuildingUser} />,
    <FontAwesomeIcon icon={faLaptopCode} />,
  ];
  const fAccTypes = accTypes.map(
    (accType) => {
      return (
        <OptionCard
          onClick={(e: any) => {
            setAccount(accType.id);
            setFormData({ ...formData, accTypeId: accType.id.toString() });
          }}
          content={{
            logo: accLogos[accType.id], title: accType.name, text: accType.descr,
            textStyle: {
              color: `${account === accType.id ? "#fff" : "#444" }`,
              transition: "0.5s ease-in",
            },
            logoStyle: {
              color: `${account === accType.id ? "#fff" : "#444" }`,
              transition: "0.5s ease-in",
            },
            titleStyle: {
              color: `${account === accType.id ? "#fff" : "#444" }`,
              transition: "0.5s ease-in",
            }
          }}
          style={{
            backgroundColor: `${ account == accType.id ? alpha(theme.palette.secondary.main, 0.7) : alpha(theme.palette.secondary.contrastText, 0.5) }`,
            transition: "0.5s ease-in",
          }}
          key={accType.id}
        />
      );
    }
  );

  const contactFormElems = [
    ({type:`${account > 0 ? "text" : ""}`,label:"Business Name", 
    onInput:(e: any) =>{
    setFormData({
      ...formData,
      busName: e.target.value,
    })}}),
    {type:"textarea",label:"Address",required:true,onInput:(e: any) =>
    setFormData({
      ...formData,
      address: e.target.value,
    })},
    {type:"select",label:"Country",value:countryvalue,
    required:true, options:countries.length > 0 && countries.map((country:any,index:number)=>{
      return (
        <Option key={index} selected={selectedCountry === index ? true : false} onClick={() => {
          setSelectedCountry(index);
        }}>
        {country.name}
        </Option>
      )
    }),
    onChange:(e: any) =>{
      filterCountry(e.target.value);
      setCountryValue(countries[selectedCountry] === undefined ? countries[0].name : countries[selectedCountry].name);
    },
    onKeyDown:(e: any) => {
      if (e.code === "ArrowDown" || e.code === "ArrowRight") {
        setSelectedCountry(
          selectedCountry === countries.length - 1
            ? selectedCountry
            : selectedCountry + 1
        );
      }
      if (e.code === "ArrowUp" || e.code === "ArrowLeft") {
        setSelectedCountry(
          selectedCountry === 0 ? selectedCountry : selectedCountry - 1
        );
      }
    }},
    {type:"select",label:"State/Province", onInput:()=>{
      setFormData({ ...formData, stateCode: states[selectedState].code });
    },
    value:statevalue,required:true,options:countryvalue != '' ? states.map((state:any, index:number)=>{
      return (
        <Option key={index} selected={selectedState === index ? true : false} onClick={() => {
          setSelectedState(index);
          setFormData({ ...formData, stateCode: states[index].code });
        }}>
          {state.name}
        </Option>
      )
    }) : (
      <Option></Option>
    ),
    onChange:(e: any) => {
      setStateValue(states[selectedState] === undefined ? states[0].name : states[selectedState].name);
      filterState(e.target.value);
    },
    onKeyDown:(e: any) => {
      if (e.code === "ArrowDown" || e.code === "ArrowRight") {
        setSelectedState(
          selectedState === states.length - 1
            ? selectedState
            : selectedState + 1
        );
      }
      if (e.code === "ArrowUp" || e.code === "ArrowLeft") {
        setSelectedState(
          selectedState === 0 ? selectedState : selectedState - 1
        );
      }
    }},
    {type:"text",label:"Postal Code",inputType:"number",
    inputStyle:{
      appearance: "textfield",
      MozAppearance: "textfield",
      WebkitAppearance: "textfield",
    },
    required:true,
    onInput:(e: any) =>{
    setFormData({
      ...formData,
      postalCode: e.target.value,
    })}}
  ]

  const pages = [
    {
      content: <WelcomePage />,
      logo: <FontAwesomeIcon icon={faHandshakeSimple} />,
      title: "Welcome",
    },
    {
      content: (
        <AccountPage>{fAccTypes}</AccountPage>
      ),
      logo: <FontAwesomeIcon icon={faFolderTree} />,
      title: "Account Types",
    },
    {
      content: <ContactDetailsPage formElems={contactFormElems} />,
      logo: <FontAwesomeIcon icon={faInfo} />,
      title: "Contact Details",
    },
    {
      content: <FinishPage />,
      logo: <FontAwesomeIcon icon={faCheck} />,
      title: "Finished",
    },
  ];

  const indicators = pages.map((indPage: any, index: number) => {
    return <Indicator key={index} logo={indPage.logo} title={indPage.title} indicate={page >= index ? true : false} />;
  });

  const controls = (
    <>
      {page > 0 && page < 3 && (
        <Button
          icon={<FontAwesomeIcon icon={faArrowLeftLong} />}
          style={{ transform: "scale(0.8)" }}
          text="Back"
          onClick={prev}
        />
      )}
      <Button
        icon={<FontAwesomeIcon icon={faArrowRightLong} />}
        style={{ transform: "scale(0.8)" }}
        reversed={true}
        text="Continue"
        onClick={next}
      />
    </>
  );

  useEffect(()=>{
    setHintState({...hintState,status:alertMessage.show, msg:alertMessage.message, icon:messasgeType.default})
  },[alertMessage.message, alertMessage.show, alertMessage.count])
  return (  
    <Popup show={wizardDisplay ? true : false} showLogo={false} style={{ ...{ padding: 0}}} contentBoxStyle={{overflow: "visible", maxHeight: "unset" }} allowOutsideClick={false}>
     
        <WizardImproved
          page={pages[page].content}
          indicators={indicators}
          logo={pages[page].logo}
          controls={controls}
          style={{ width: "80vw" }}
        />
    </Popup>
  );
}
