import { InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import Table, { Th, Td, Tr } from '../../../../components/designerTable';
import useStyles from './../styles';
import SimpleButton from '../../../../components/simpleButton';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Popup from '../../../../components/popup';
import TextField from '../../../../components/textField';
import Button from '../../../../components/button';
import { getAllWallets } from '../../../../services/wallet';
import { allowOnClick, error } from '../../../../utilities/appHelpers';
import { addAcount, getServiceSetups } from '../../../../services/bussiness';
import { SessionCheck, dispatch, messasgeType, useGlobalState } from '../../../../pages/dashboard';
import accImg from "../../../../assets/images/account-security.png"
import { useUserTheme } from '../../../../themes';



export default function PGSSetup({data}:Record<string,any>) {

  //STATES
  const {isMobile}=useUserTheme();
  const [copied, setCopied]=useState(false);
  const [copied2, setCopied2]=useState(false);
  const demo_SID = useRef<any>(null),demo_SK = useRef<any>(null),demo_BID = useRef<any>(null),live_SID = useRef<any>(null),live_SK = useRef<any>(null),live_BID = useRef<any>(null);
  const[showAccountForm,setShowAccountForm] = useState(false);
  const [focus,setFocus]=useState("")
  const [wallet,setWallet]=useState("")
  const [appState,setAppState]=useState({btnText:'Create',icon:'mdi:plus',loading:false})
  const [wallets,setWallets]=useState([]);
  const [accounts,setAccounts]=useState<Array<any>>([])
  const [formData,setFormData]=useState({accName:'',walletCode:''});
  const [errorHandler,setErrorHandler]=useState({status:false,msg:'',count:0});

  const [hintState,setHintState] = useGlobalState("hint");


  //FUNCTIONS
  useEffect(()=>{
    let timmer = setTimeout(()=> copied?setCopied(false):setCopied2(false),1500);
    return()=>clearTimeout(timmer);
  },[copied,copied2])
  

  useEffect(()=>{
    (async()=>{
      try {
      const resp = await getServiceSetups(data.bizCode,data.serviceCode);
      if(resp.status === 200){
        JSON.stringify(accounts)!== resp.data.data.accounts && setAccounts(resp.data.data.accounts);
      }
    } catch (e:any) {
      SessionCheck(e);

      setErrorHandler({...errorHandler,status:true, msg:error.handle(e,"You're offline. Please check your network and try again",true), count:errorHandler.count+1});
      
    }
  })()
  
  },[])


  useEffect(()=>{
    setHintState({...hintState,status:errorHandler.status, msg:errorHandler.msg, icon:messasgeType.default});
  },[errorHandler.count])

  const copyToClipboard =(value:any, primaryLocation = true)=>{
    if(document.execCommand('copy')){
      let t = document.createElement('textarea');
              t.style.position = 'absolute';
              t.style.left = '-9999px';
              t.value=value;
              document.body.appendChild(t);
              t.select();
              if(document.execCommand('copy')){
                primaryLocation ? setCopied(true) :setCopied2(true);
              document.body.removeChild(t);
              }

    }else{
      
      if(navigator.clipboard){

        navigator.clipboard.writeText(value).then(() => {
           primaryLocation ? setCopied(true) :setCopied2(true);
          })
        }
    }
  }
  
  const handleSelect = (e:any)=>{
    e.target.value !== "" ? setFocus("ff") : setFocus("");
    setWallet(e.target.value);
    setFormData({...formData,walletCode:e.target.value});
    
  }


  useEffect(()=>{
    (async()=>{
      try {
        const resp = await getAllWallets();
      if(resp.status===200){
        setWallets(resp.data.data);
      } 
    } catch (e:any) {
      SessionCheck(e);

      setErrorHandler({...errorHandler,status:true,msg:error.handle(e,"You're offline. Please check your network and try again",true), count:errorHandler.count+1});
    }
    
  })()
  setAccounts(data.accounts);
  },[])


  const handleAccountCreation = useCallback(async()=>{
    setAppState({...appState,btnText:'Creating...',icon:'mdi:progress-clock',loading:true})
    try {
      const resp = await addAcount(data.serviceCode,formData);
     if(resp.status===201){
      setAppState({...appState,btnText:'Created',icon:'mdi:plus',loading:false})
      setAccounts((a)=>a.concat(resp.data.data))
      setShowAccountForm(false);
    } 
  } catch (e) {
    setAppState({...appState,btnText:'Created',icon:'mdi:plus',loading:false});
      setErrorHandler({...errorHandler,status:true,msg:error.handle(e,"You're offline. Please check your network and try again",true), count:errorHandler.count+1});
  }
  
  },[formData.accName, formData.walletCode])

  const handleAdding= useCallback(()=>{
      setShowAccountForm(true);
  },[formData.accName, formData.walletCode])



  //OTHERS
  const{live_keys, demo_keys}=data;
  



  const classes = useStyles().classes;
  const apiCRDW = ["10%", "100%", "100%"];
  const accCRDW = ["15%", "15%", "20%", "35%", "25%"];
  const sdkCRDW = ["10%", "45%", "45%"];
  return (
    <>
    <Popup show={showAccountForm} showLogo={false} onPopChange={(stat:boolean)=>setShowAccountForm(stat)}>
      <div className={`${classes.accountForm}`}>
        <div className='imgCont'>
          <img src={accImg} alt=" " />
        </div>
        <Typography className={`fadeInUp animated delay-0-1s`} component={'h1'} style={{fontSize:'1.2rem', fontWeight:600,marginBottom:20}}>Create New Account</Typography>
        <form>
          <TextField className={`fadeInUp animated delay-0-2s`} type="text" placeholder="Account name" onChange={(e:any)=>setFormData({...formData,accName:e.target.value})} value={formData.accName}/>

            <div className={`fadeInUp animated delay-0-4s`} style={{position:'relative',width:"100%"}}>
            <InputLabel className={`${classes.inputLabel} ${focus}`} style={{zIndex: 5}}>Select Wallet</InputLabel>
                <Select labelId="business-type" className={`${classes.inputElement} card-1`} style={{backgroundColor:"#fff", marginTop:20, borderRadius: 4}} onChange={(e:any)=>handleSelect(e)} value={wallet}>
                  { wallets && wallets.map((d:any,i:number)=><MenuItem key={i} value={d.walletCode}><Typography>{d.accNo}</Typography></MenuItem>) }
                </Select>
              </div>
              
            <div className='btnCont'>

          <Button className={`fadeInUp animated delay-0-6s`} text={appState.btnText} variant="secondary" disabled={appState.loading} loading={appState.loading} onClick={handleAccountCreation} icon={<Icon icon={appState.icon} />}/> 
            </div>
        </form>

      </div>
    </Popup>

    <div className="fadeInUp animated faster">
      <div style={{ margin: "20px 0"}}>
        <Typography className={`${classes.popup} ${copied ? 'slideInUp_i':''}`} component={'div'} style={{fontWeight:600}}>
          Code Copied to clipboard
        </Typography>
      <Typography component="h2" className={`${classes.headerMd}`} style={{ marginBottom: "5px" }}>
            <div style={{display: "flex"}}>
              <div style={{width: "50%", fontSize: 20, fontWeight: 600}}>
                API Keys
              </div>
              {/* <div style={{ display: "flex", justifyContent: "flex-end", width: "50%" }}>
                <SimpleButton variant={ButtonVariant.alt} onClick={() => { }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ padding: "0 5px" }}><Icon icon="fluent:key-reset-20-regular" width="16" height="16" /></div>
                    <div>Reset All</div>
                  </div>
                </SimpleButton>
              </div> */}
            </div>
          </Typography>
        <div className={`${classes.desCard} card-2`}>

          <div>
            {!isMobile &&(<Th className={classes.compactRow} style={{ backgroundColor: "transparent" }}>
              <Td className={classes.smPad} style={{ width: apiCRDW[0] }}></Td>
              <Td className={classes.smPad} style={{ width: apiCRDW[1] }}>Demo</Td>
              <Td className={classes.smPad} style={{ width: apiCRDW[2] }}>Live</Td>
            </Th>)}
            
            <Tr className={`${classes.compactRow} ${classes.mobrow}`}>
              {!isMobile && (<Td className={`${classes.smPad} ${classes.rowTitle}`} style={{ width: apiCRDW[0] }}>Business ID</Td>)}
              <Td className={`${classes.smPad} ${classes.copyable}`} style={{ width: apiCRDW[1] }}>
                {/* Demo */}
                <div className={classes.wrppa}>
                 {isMobile && ( <Typography component="span">DEMO - Business ID</Typography>)}
                  <input title={`Click to copy`} ref={demo_BID} onClick={(e:any)=>copyToClipboard(demo_BID?.current && demo_BID?.current?.value)} type="password" className={classes.desItem} value={demo_keys?.businessCode} readOnly/>
                </div>

               {!isMobile && ( <div  className={classes.copyIcons}>
                  <span title='Copy code' onClick={()=>copyToClipboard(demo_BID?.current && demo_BID?.current?.value)}>
                    <Icon icon="fluent:copy-select-20-regular" width="16" height="16"/>
                  </span>

                  <span title='Reset code' >
                    <Icon icon="fluent:key-reset-20-regular" width="16" height="16"/>
                  </span>
                </div>)}
                
              </Td>
              <Td className={`${classes.smPad} ${classes.copyable}`} style={{width: apiCRDW[2]}}>
                {/* Live */}
                <div className={classes.wrppa}>
                {isMobile && ( <Typography component="span">LIVE - Business ID</Typography>)}
                   <input onClick={(e:any)=>copyToClipboard(live_BID?.current && live_BID?.current?.valuee.target.value)} title="Click to copy" type="password" className={classes.desItem} value={live_keys?.businessCode} readOnly />
                </div>

                {!isMobile && (<div className={classes.copyIcons}>
                  <span title='Copy code' onClick={()=>copyToClipboard(live_BID?.current && live_BID?.current?.value)}>
                    <Icon icon="fluent:copy-select-20-regular" width="16" height="16" />
                  </span>

                  <span title='Reset code'>
                    <Icon icon="fluent:key-reset-20-regular" width="16" height="16" />
                  </span>
                </div>)}

              </Td>
            </Tr>

            <Tr className={`${classes.compactRow} ${classes.mobrow}`}>
             {!isMobile && ( <Td className={`${classes.smPad} ${classes.rowTitle}`} style={{ width: apiCRDW[0] }}>Secret Key</Td>)}
              <Td className={`${classes.smPad} ${classes.copyable}`} style={{ width: apiCRDW[1] }}>
                {/* Demo */}
                <div className={classes.wrppa}>
                  {isMobile && ( <Typography component="span">DEMO - Secret key</Typography>)}
                  <input ref={demo_SK} onClick={(e:any)=>copyToClipboard(e.target.value)} title="Click to copy" type="password" className={classes.desItem} value={demo_keys?.secretKey} readOnly />
                </div>
                
                {!isMobile && (<div className={classes.copyIcons}>
                  <span title='Copy code' onClick={()=>copyToClipboard(demo_SK?.current && demo_SK?.current?.value)}>
                    <Icon icon="fluent:copy-select-20-regular" width="16" height="16" />
                  </span>

                  <span title='Reset code'>
                    <Icon icon="fluent:key-reset-20-regular" width="16" height="16" />
                  </span>
                </div>)}

              </Td>
              <Td className={`${classes.smPad} ${classes.copyable}`} style={{ width: apiCRDW[2] }}>
                {/* Live */}
                <div className={classes.wrppa}>
                {isMobile && ( <Typography component="span">LIVE - Secret key</Typography>)}
                   <input ref={live_SK} onClick={(e:any)=>copyToClipboard(e.target.value)} title="Click to copy" type="password" className={classes.desItem} value={live_keys?.secretKey} readOnly />
                </div>

                {!isMobile && (<div  className={classes.copyIcons}>
                  <span title='Copy code' onClick={()=>copyToClipboard(live_SK?.current && live_SK?.current?.value)}>
                    <Icon icon="fluent:copy-select-20-regular" width="16" height="16"/>
                  </span>

                  <span title='Reset code'>
                    <Icon icon="fluent:key-reset-20-regular" width="16" height="16"/>
                  </span>
                </div>)}

              </Td>
            </Tr>

            <Tr className={`${classes.compactRow} ${classes.mobrow}`}>
             {!isMobile && ( <Td className={`${classes.smPad} ${classes.rowTitle}`} style={{ width: apiCRDW[0] }}>Service ID</Td>)}
              <Td className={`${classes.smPad} ${classes.copyable}`} style={{ width: apiCRDW[1] }}>
                {/* Demo */}
                <div className={classes.wrppa}>
                {isMobile && ( <Typography component="span">DEMO - Service code</Typography>)}
                  <input ref={demo_SID} onClick={(e:any)=>copyToClipboard(e.target.value)}  title="Click to copy" type="password" className={classes.desItem} value={demo_keys?.serviceCode} readOnly />
                </div>

                {!isMobile &&(<div  className={classes.copyIcons}>
                  <span title='Copy code' onClick={()=>copyToClipboard(demo_SID?.current && demo_SID?.current?.value)}>
                    <Icon icon="fluent:copy-select-20-regular" width="16" height="16" />
                  </span>
                  <span title='Reset code'>
                    <Icon icon="fluent:key-reset-20-regular" width="16" height="16" />
                  </span>

                </div>)}

              </Td>
              <Td className={`${classes.smPad} ${classes.copyable}`} style={{ width: apiCRDW[2]}}>
                {/* Live */}
                <div className={classes.wrppa}>
                {isMobile && ( <Typography component="span">LIVE - Service code</Typography>)}
                    <input ref={live_SID} onClick={(e:any)=>copyToClipboard(e.target.value)} title="Click to copy" type="password" className={classes.desItem} value={live_keys?.serviceCode} readOnly />
                </div>

                {!isMobile && (<div  className={classes.copyIcons}>
                  <span title='Copy code' onClick={()=>copyToClipboard(live_SID?.current && live_SID?.current?.value)}>
                    <Icon icon="fluent:copy-select-20-regular" width="16" height="16" />
                  </span>
                  
                  <span title='Reset code'>
                    <Icon icon="fluent:key-reset-20-regular" width="16" height="16" />
                  </span>

                </div>)}
              </Td>
            </Tr>
          </div>
        </div>

        
        {/* section headings */}
        <Typography component="h2" className={`${classes.headerMd}`} style={{ marginBottom: "5px", position:'relative'}}>
        <div className={`${classes.popup2} ${copied2 ? 'slideInUp_i':''}`}>Copied to clipboard</div>
            <div style={{display: "flex"}}>
              <div style={{width: "50%", fontSize: 20, fontWeight: 600}}>
                Accounts
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", width: "50%"}}> 
                {/* <SimpleButton variant={ButtonVariant.alt} onClick={() => { }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ padding: "0 5px" }}><Icon icon="fluent:key-reset-20-regular" width="16" height="16" /></div>
                    <div>Reset All</div>
                  </div>
                </SimpleButton> */}
                <SimpleButton onClick={handleAdding}>Add New</SimpleButton>
              </div>
            </div>
          </Typography>

        

        {/* new table implementation */}
        <Table
              fields={[
                { name: "Account name", size: 25 },
                { name: "Account Code", size: 20 },
                { name: "Account Number", size: 25 },
                // { name: "Description", size: 30 },
                { name: "Payment Link", size: 30 },
              ]}
              records={ accounts.map((a:any,i:number)=>{
                  return{
                  "Account Name": <div  className={classes.plainArea} suppressContentEditableWarning={true} contentEditable={true}>{a.accName}</div>,

                  "AccountCode": <div title='Click to copy' {...allowOnClick((e:any)=>copyToClipboard(e.target.innerText,false))} className={classes.desItem } suppressContentEditableWarning={true}  contentEditable={true}>{a.accCode}</div>,

                  "AccountNum":  <div title='Click to copy' {...allowOnClick((e:any)=>copyToClipboard(e.target.innerText,false))} className={classes.desItem}>{a.accNo}</div>, 

                  // "Customer": <div suppressContentEditableWarning={true} className={classes.plainArea} contentEditable={true} >{a.descr || 'nil'} </div>, 

                  "Paymentlink": <div title='Click to copy' {...allowOnClick((e:any)=>copyToClipboard(e.target.innerText,false))} suppressContentEditableWarning={true} className={classes.desItem}>{a.paymentLink}</div>, 

                  controls: { isDropdown: false, incoming: true },
                }
              })

                
              }
            />


        {/* add some descr abt the apis and sdks */}
{/*         
        <div className={`${classes.desCard}`}>
        <Typography component="h2" className={classes.headerMd} style={{ marginBottom: "10px" }}>APIs and SDKs</Typography>
          <div>
            <Th className={classes.compactRow} style={{ backgroundColor: "transparent" }}>
              <Td className={classes.smPad} style={{ width: sdkCRDW[0] }}></Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[1] }}>APIs</Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[2] }}>SDKs</Td>
            </Th>
            <Tr className={classes.compactRow} style={{alignItems: "flex-start"}}>
              <Td className={`${classes.smPad}`} style={{ width: sdkCRDW[0] }}><div className={classes.plainArea}>PHP</div></Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[1] }}>
                <div className={classes.plainArea}> */}
                  {/* <div>blablajdp</div> */}
                  {/* <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Documentation &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Learn More &gt; </div>
                </div>
              </Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[2] }}>
                <div className={classes.plainArea}>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Install &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Documentation &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Learn More &gt; </div>
                </div>
              </Td>
            </Tr>
            <Tr className={classes.compactRow} style={{alignItems: "flex-start"}}>
              <Td className={`${classes.smPad}`} style={{ width: sdkCRDW[0] }}><div className={classes.plainArea}>NodeJS</div></Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[1] }}>
                <div className={classes.plainArea}>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Documentation &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Learn More &gt; </div>
                </div>
              </Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[2] }}>
                <div className={classes.plainArea}>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Install &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Documentation &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Learn More &gt; </div>
                </div>
              </Td>
            </Tr>
            <Tr className={classes.compactRow} style={{alignItems: "flex-start"}}>
              <Td className={`${classes.smPad}`} style={{ width: sdkCRDW[0] }}><div className={classes.plainArea}>Java</div></Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[1] }}>
                <div className={classes.plainArea}>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Documentation &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Learn More &gt; </div>
                </div>
              </Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[2] }}>
                <div className={classes.plainArea}>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Install &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Documentation &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Learn More &gt; </div>
                </div>
              </Td>
            </Tr>
            <Tr className={classes.compactRow} style={{alignItems: "flex-start"}}>
              <Td className={`${classes.smPad}`} style={{ width: sdkCRDW[0] }}><div className={classes.plainArea}>C++</div></Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[1] }}>
                <div className={classes.plainArea}>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Documentation &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Learn More &gt; </div>
                </div>
              </Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[2] }}>

                <div className={classes.plainArea}>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Install &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Documentation &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Learn More &gt; </div>
                </div>
              </Td>
              
            </Tr>
          </div>
        </div> */}

      </div>
    </div>
    </>
  );
}
