
import { TRANSACTIONS, GATEWAY, PROCESS,OTP, RESENDOTP } from './../../utilities/constant';
import axiosInstance from '../httpsGateway';

//Post requests
export const getTransactionDetails =  (ref:string,checkSum:string)=>{
    return axiosInstance.get(`${GATEWAY}/${TRANSACTIONS}/${ref}`);   
}

export const getTransactionDetail =  (ref:string)=>{
    return axiosInstance.get(`${GATEWAY}/${TRANSACTIONS}/${ref}`);   
}

export const processCardDetails =  (payload:Record<string,any>)=>{
    return axiosInstance.post(`${GATEWAY}/${PROCESS}`,payload);   
}

export const processOTP =  (payload:Record<string,any>,ref:string)=>{
    return axiosInstance.post(`${GATEWAY}/${OTP}/${ref}`,payload);   
}
export const sendPin =  (payload:Record<string,any>,ref:string)=>{
    return axiosInstance.post(`${GATEWAY}/pin/${ref}`,payload);   
}

export const ResendOTP =  (ref:Record<string, any>)=>{
    return axiosInstance.post(`${GATEWAY}/${RESENDOTP}`,ref);   
}
