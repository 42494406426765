import { Select as S ,Typography } from '@mui/material';
import React, { useEffect, useState, useRef, createRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import OptionChecker, { CheckerBtn } from '../../../components/optionChecker';
import useStyles from './../styles';
import TextField from '../../../../../components/textField';
import { commarize2, currency, error, sanitize, getAppStorage, setAppStorage } from '../../../../../utilities/appHelpers';
import { getBillables, getBillerCat, getCablePlans, makePayment, purchaseCable } from '../../../../../services/billler';
import { SChecker } from '../../../../../components/skeletonLoader';
import Button from '../../../../../components/button';
import { ROLES, SessionCheck, messasgeType, useGlobalState } from '../../../../../pages/dashboard';
import CustomCheckbox from '../../../../../components/customCheckbox';
import Alert, { IType } from '../../../../../components/alertBox';
import { createPortal } from 'react-dom';
import Select from "react-select";
import { getAllWallets } from '../../../../../services/wallet';

export interface ICableTVProps {
  className?: string,
  style?: Record<string, any>,
}

export default function CableTV(props: ICableTVProps) {
  const classes = useStyles().classes;
  const { className = "", style = {} } = props;
  const navigate = useNavigate();
  const [hintState,setHintState] = useGlobalState("hint");
  const [switchNum, setSwitchNum] = useState(0);
  const [paymentCode, setPaymentCode] = useState({code:'',amount:''});
  const [customer, setCustomer] = useState('');
  const [amount, setAmount] = useState({disabled:true, value:''});

  const [selectedBiller, setSelectedBiller] = useState<any>(null), billers = useRef<Record<string, any>>({}),
  [appState,setAppState]=useState({ text:'Recharge', icon:<Icon icon="eva:arrow-forward-fill"/>, hintIcon:messasgeType.default, status:false, count:1, loading:false,msg:'',}),
  [alertState,setAlertState]=useState({ type:IType.warning, title:'', status:false, count:1,message:'',})
  ,[focus,setFocus]=useState(""),[finishing, setFinishing]=useState({ text:'Recharge', icon:<Icon icon="eva:arrow-forward-fill"/>, loading:false, disabled:true});

const selectInputRef = useRef<any>();
const resetForm= ()=>{
  setCustomer('');
  setAmount({...amount,value:'', disabled:false});
  selectInputRef?.current?.select.clearValue();
}

  const [loadingStatus, setLoadingStatus] = useState({utils:false, subs:false,bill:false});
  useEffect(()=>{
    setHintState({...hintState,status:appState.status, msg:appState.msg, icon:appState.hintIcon});
  },[appState.count])

  const [paymentParam, setPaymentParam] = useState({
    amount: '100',
    cardNumber: "02110144711",
    billerId: "dstv",
    planId: "PRWFRNSE36",
    validity: "1 month",
    walletCode: "W380XX"
  })
  const wrapper= useRef<any>(null);
  const [currentWallet, setcurrentWallet] = useState(''); 

  const fetchUpdatedWallet = async()=>{
    try {
      const resp = await getAllWallets();
      if(resp.status===200){
        setUserUpdatedWallet(resp.data.data);
      }
    } catch (e) {
      setAppState({...appState, status:true, msg:error.handle(e,"You've lost Internet connection",true), hintIcon:messasgeType.failed,count:appState.count+1});
      SessionCheck(e);
    }
    
  }
  
    useEffect(() => {
       const elm =  wrapper.current && (wrapper.current as HTMLElement).querySelectorAll('input[type=radio]')[0];
       const l = wrapper.current && (wrapper.current as HTMLElement).querySelectorAll('label')[0];
       l && l.classList.add('selectedwallet101');
       if(elm){
         elm.checked = true;
        }
        const w = getAppStorage('WALLETS')[0];
        w && setcurrentWallet(w.walletCode);
       
    }, [switchNum]);
  
  useEffect(()=>{
    setPaymentParam({...paymentParam,walletCode:currentWallet});
  },[currentWallet])


  const [subBillers, setSubBillers] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>([]),[selectedOption,setSelectedOption] = useState<any>();
  
  // call utility sub categories when ever there are selected
  useEffect(()=>{
      setLoadingStatus({...loadingStatus,subs:true});
      (async()=>{
        try {
          const resp = await getBillerCat('cable');
          if(resp.status===200){
            setLoadingStatus({...loadingStatus,subs:false});            
             setSubBillers(resp.data?.data);
          }
          
        } catch (e) {
          setAppState({...appState, status:true, msg:error.handle(e,"You've lost Internet connection",true).includes('/api')?error.handle(e,"You've lost Internet connection",true).split('/api')[0]+'...':error.handle(e,"You've lost Internet connection",true), hintIcon:messasgeType.failed,count:appState.count+1});
          SessionCheck(e);
        }
      })()

  },[])

  // call utility sub categories when ever there are selected
  useEffect(()=>{
      
      setOptions([]);
      
      (async()=>{
        try {
          if(selectedBiller){
            setLoadingStatus({...loadingStatus,bill:true});
            setPaymentParam({...paymentParam,billerId:selectedBiller.billerId});

            const resp = await getCablePlans({billerId:selectedBiller.billerId});
            if(resp.status===200){
              const data = resp.data.data;
                data.map((obj:Record<string,any>)=>{
                    Object.keys(obj).forEach(oldkey => {
                      let value = obj[oldkey];
                      let mergedValues = obj?.options[0].price + "~"+ obj?.planId+'~'+ obj?.options[0].validity;

                      let newKey='others';
                      if(oldkey === 'planId'){
                        newKey='value';
                      }else if(oldkey === 'name'){
                        newKey='label';
                      }else if(oldkey==='options'){
                        delete obj[oldkey];
                      }

                      delete obj[oldkey];
                      obj[newKey] = newKey==='value'?mergedValues: value;

                      });
                })
                
                setOptions(data);
              
              setLoadingStatus({...loadingStatus,bill:false});
            }
          }

          
          
        } catch (e) {
          setLoadingStatus({...loadingStatus,bill:false});
          setSelectedBiller(null);
          setAppState({...appState, status:true, msg:error.handle(e,"You've lost Internet connection",true).includes('/api')?error.handle(e,"You've lost Internet connection",true).split('/api')[0]+'...':error.handle(e,"You've lost Internet connection",true), hintIcon:messasgeType.failed,count:appState.count+1});
          SessionCheck(e);
  
        }
      })()

  },[selectedBiller])



useEffect(()=>{
if(customer.trim().length>0 &&amount.value.trim().length>0){
  setFinishing({...finishing,disabled:false});
}else{
  setFinishing({...finishing,disabled:true});
}

setPaymentParam({...paymentParam,cardNumber:customer,amount:amount.value});

},[customer, amount.value])

const [userUpdatedWallet, setUserUpdatedWallet]=useState(null);

useEffect(()=>{
  if(userUpdatedWallet)setAppStorage('WALLETS',userUpdatedWallet);
},[userUpdatedWallet])

useEffect(()=>{
    if(selectedOption){
        const [price, planId,validity] = selectedOption.value && selectedOption.value.split('~');
        setPaymentParam({...paymentParam,planId:planId, amount:price, validity:validity});
          setAmount({...amount,value:price});

    }
},[selectedOption])



const handleChange = (e:any, wallet:string, btn:HTMLLabelElement)=>{
  const el = wrapper.current && (wrapper.current as HTMLElement).querySelector('.selectedwallet101');
  if(el){
    if(el !== btn){
      el.classList.remove('selectedwallet101');
      btn.classList.add('selectedwallet101');
    }
  }else{btn.classList.add('selectedwallet101');}
  setPaymentParam({...paymentParam,walletCode:wallet});

}

const sendRecharge = useCallback(async()=>{
  setFinishing({...finishing, text:'Please wait...', icon:<Icon icon="humbleicons:dots-horizontal"/>, loading:true, disabled:true});
  try {
    const resp = await purchaseCable(paymentParam);
    if(resp.status===200 || resp.status===201){
      setAlertState({...alertState,type:IType.success, status:true, title:'Success', message:resp.data.message})
      setSelectedBiller(null);
      fetchUpdatedWallet();

      setFinishing({...finishing, text:'Recharge', icon:<Icon icon="eva:arrow-forward-fill"/>, loading:false});
      setCustomer(''); setAmount({disabled:false,value:''});
    }
  } catch (er) {
    setAlertState({...alertState, type:IType.error,status:true, title:'Error', message:error.handle(er,"You've lost Internet connection",true)});
    setFinishing({...finishing, text:'Recharge', icon:<Icon icon="eva:arrow-forward-fill"/>, loading:false, disabled:false});
    SessionCheck(er);
  }

},[paymentParam.planId, paymentParam.amount, paymentParam.walletCode,paymentParam.billerId, paymentParam.cardNumber])

  return (
    <>
    {createPortal(
      <Alert type={alertState.type} title={alertState.title} show={alertState.status} onClose={(stat: boolean) => setAlertState({...alertState,status:stat})} message={alertState.message} />,
      document.body
    )}
    <div className={`${className} ${classes.main}`} style={style}>

      <div>
        {switchNum === 0 && (
          <>
          <Typography component="h3" className={`${classes.smallTitle}`}>Your Wallets</Typography>
          <div ref={wrapper} className={`${classes.walletchecker} scrollbar-transparent`}>
                  {getAppStorage('WALLETS') && getAppStorage('WALLETS').map((w: Record<string, any>, i: number) => {
                    let wRef = createRef<HTMLLabelElement>();
                    //@ts-ignore
                    return <CustomCheckbox title={w.walletName} className={`slideInRight animated delay-0-${i}s`} ref={wRef} isSelected={i === 0} onChange={(e: any) => handleChange(e, w.walletCode, wRef.current)} txt1={w.accNo} txt2={currency.naira + w.bal} id={'wallet' + i} key={i} />;

                  }
                  )}
            </div>
            <div>
             {subBillers && <Typography component="h3" className={`${classes.smallTitle} fadeInUp animated faster`}>Select a biller</Typography>}
              <div>
                <OptionChecker className='fadeIn animated'>

                { loadingStatus.subs? <SChecker cards={6}/>:
                
                !selectedBiller ?
                subBillers && subBillers.map((b:any,i:number)=>{
                  billers.current['biller'+i] = {};

                   return <CheckerBtn key={i} title={b.biller} className={`fadeInUp animated delay-0-${i}s`} onClick={() => {billers.current['biller'+i].selected? billers.current['biller'+i]={selected:false,name:'biller'+i}: billers.current['biller'+i]={selected:true,name:'biller'+i};setSelectedBiller(b);
                 }} checked={billers.current['biller'+i].selected}/>
                }):<CheckerBtn title={ selectedBiller && selectedBiller?.biller} className={`fadeIn animated`} onClick={() =>{setSelectedBiller(null);resetForm()}} checked={true}/>
              }
          
                </OptionChecker>
              </div>
              
            </div>

            { loadingStatus.bill ?<SChecker cards={4}/> :
            subBillers&&selectedBiller&& (
            <div>
              { options.length>0 &&
                <div>
                 <Typography component="h3" className={`${classes.smallTitle}`}>
                What are you paying for?
              </Typography>
              <div className='card-1' style={{borderRadius:5, margin:'10px 0',zIndex:10,position:'relative'}}>
                <Select defaultValue={selectedOption} onChange={setSelectedOption} options={options} isClearable={true} isSearchable={true} />
                </div>
              </div>}


              <div style={{ zIndex:-100}} className="fadeInUp animated delay-0-1s">
                <TextField className={`animated faster slideInDown`} placeholder={'Decoder Number'} onChange={(e: any) => setCustomer(e.target.value)} value={customer} />
              </div>

              <div style={{zIndex:-100}} className="fadeInUp animated delay-0-2s">
                <TextField currency={currency.naira} disabled={amount.disabled} className={`animated faster slideInDown`} placeholder={"Amount"} onChange={(e: any) => setAmount({...amount,value:e.target.value})} value={commarize2(sanitize(amount.value))} />
              </div>
            
            <div className={`${classes.cta}`}>
              {(ROLES?.UTILITIES_MODULE?.privileges?.PURCHASE_CABLE??false)&&<Button className={`fadeInUp animated delay-0-3s`} style={{ fontWeight: 800, margin: "30px 0" }} text={finishing.text} icon={finishing.icon} loading={finishing.loading} disabled={finishing.disabled} onClick={sendRecharge} />}
              </div>

            </div>)

            }

            
          </>)}

      </div>
    </div>
    </>
  );
}
