import { alpha, lighten } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'data' | 'row' | 'dropdown' | 'status'>()((theme, _params, classes) => ({
  table: {
    width: "100%",
    [theme.breakpoints.down('md')]:{
      overflow: "scroll"
    },
    boxSizing: "border-box",
    fontSize: 11,
    "& *": {
      boxSizing: "inherit",
    },
  },
  head: {
    width: "100%",
    minWidth: '600px',
    display: "flex",
    backgroundColor: theme.palette.background.default,
    alignItems: "center",
    marginBottom: 6,
    borderRadius: 4,
    padding: "0px 20px",
    [`& .${classes.data}`]: {
      fontWeight:600
    }
  },
  row: {
    width: "100%",
    display: "flex",
    backgroundColor: theme.palette.secondary.contrastText,
    alignItems: "center",
    marginBottom: 6,
    borderRadius: 6,
    padding: "4px 20px",
    position: "relative", 
  },
  
  data: {
    padding: 8
  },
  dropdown: {
    position: "absolute",
    width: "100%",
    top:"100%",
    left:0,right:0,
    padding: "8px 20px",
    backgroundColor: theme.palette.secondary.contrastText,
  },
  rowdrop: {
    width: "100%",
    minWidth: '600px',
    marginBottom: 6,
    borderRadius: 6,
    position: "relative",
    overflow:"hidden",
    [`& .${classes.row}`]: {
      marginBottom: 0,
      borderRadius: "6px 6px 0 0",
    },
    [`&.clicked .${classes.dropdown}`]: {
      position: "relative",
    },
    "&.clicked":{
      [`& .${classes.row}`]: {
        backgroundColor: lighten(theme.palette.primary.main, 0.2),
        color: theme.palette.secondary.contrastText,
        transition: "all 0.2s ease-in"
      },
      [`& .${classes.status}`]: {
        color: "#fff",
        backgroundColor: "transparent"
      }
    }, 
   

  '&.hoverEffect':{
      cursor: "pointer",
       "& :hover":{
         [`& .${classes.status}`]: {
           color: "#fff",
           backgroundColor: "transparent"
          },
          [`&.${classes.row}`]: {
            backgroundColor: 'rgba(96, 117, 166, 1)',
            color: theme.palette.secondary.contrastText,
            transition: "all 0.2s ease-in",
          },
    } 
  }
    
  },
    
    

  status: {
    borderRadius: 6,
    padding: "2px 6px",
    width: "fit-content",
    fontWeight: 600,
    fontSize: "0.8em",
    letterSpacing: 1,
    "&.pending":{
      backgroundColor: `rgba(254, 114, 179, 0.2)`,
      color: "#fc4a4a"
    },
    "&.completed":{
      backgroundColor: `${alpha("#0022ff",0.2)}`,
      color: "#0022ff"
    }
  },
  descrBoxHeader: {
    color:alpha(lighten(theme.palette.primary.main, 0.2),0.5),
    fontWeight: 500,
  },
  descrBoxDescr: {
    fontSize: "1.2em",
    fontWeight: 600,
    wordWrap: "break-word"
  },
  info: {
    backgroundColor: theme.palette.background.default,
    padding: 8,
    borderRadius: 6,
    fontSize: "0.8em",
  },
  dropArrow: {
    borderRadius: 6,
    backgroundColor: alpha(theme.palette.background.default,0.6),
    width: "2em",
    height: "2em",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "0",
    bottom: "0",
    margin: "auto",
    right: "2em"
  },
  rowInd: {
    position: "absolute",
    top: 0,
    bottom: 0,
    width: 3,
    left: 0,
    zIndex: 10,
    "&.incoming":{
      backgroundColor: "#48db40"
    },
    "&.outgoing":{
      backgroundColor: "#db4040"
    }
  },
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
