import { NEWPIN, VPIN, PASSWORD,UPDATE,RESET,RESENDCODE } from './../../utilities/constant';
import { BOARDING } from '../../utilities/constant';
import axiosInstance from '../https';

export const verifyPin =  (data:Record<string,any>,uid:any)=>{
    return axiosInstance.post(`${BOARDING}/${VPIN}/${uid}`,data);
}
export const verifyPasswordResetCode =  (data:Record<string,any>,uid:any)=>{
    return axiosInstance.post(`${BOARDING}/${VPIN}/${PASSWORD}/${uid}`,data);
}
export const createNewPassword =  (data:Record<string,any>,uid:any)=>{
    return axiosInstance.post(`${BOARDING}/${PASSWORD}/${UPDATE}/${uid}`,data);
}
export const resendPasswordResetCode =  (uid:any)=>{
    return axiosInstance.get(`${BOARDING}/${PASSWORD}/${RESET}/${RESENDCODE}/${uid}`);
}

export const resendPin =  (uid:any)=>{
    return axiosInstance.post(`${BOARDING}/${NEWPIN}/${uid}`);
}
export const RequestResetPassword =  (data:Record<string, any>)=>{
    return axiosInstance.post(`${BOARDING}/${PASSWORD}/${RESET}`,data);
}