import ReactPDF, { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, usePDF, Image } from '@react-pdf/renderer';
import logo from "../../resource/images/logo2.png";
import theme from "../../resource/theme";
import { useEffect } from 'react';

export const pdfstyles = StyleSheet.create({
    page: {
      backgroundColor: '#fff'
    },

    section: {

      padding: 10,
      backgroundColor:'#f2f2f2',
      borderRadius:2,
      width:"100%",
      minHeight:100
    },
    first: {
      margin: 10,
      padding: "0px 40px",
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
    },
    row: {
      padding: "10px 5px",
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
      flexDirection:'row'
    },
    thinkLine:{
      width:"100%", height:'3px', backgroundColor:theme.palette.primary.main,
    },
    line:{
      width:"80%", height:'1px', backgroundColor:'#f2f2f5', margin:'10px 0 '

    },
    txt: {  fontSize: 9, fontWeight: 800, color: 'grey' }
    
  });
export default function PDFGen(props: any) {
  const {businessName,amount, payerName, charges, date, channel, ref,currency='NGN'} = props.data;
  useEffect(()=>{console.log(props);
  },[])


return(

      <PDFViewer style={{width:'100%', height:'100vh'}}>

<Document pageLayout="twoPageRight">
      <Page size="A5" style={pdfstyles.page}>
      <View style={{width:"100%", height:50, display:'flex',justifyContent:'center',alignItems:'center',marginTop:10}}>
        <View style={{width:100, height:50, display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Image src={logo} style={{width:'120px', height:"50px", display:'flex',justifyContent:'center',alignItems:'center'}}/>
        </View>

    </View>

        <View style={pdfstyles.thinkLine}></View>
        <View style={pdfstyles.first}>
           <Text>Receipt from {businessName}</Text>
           <Text style={{textAlign:'center', fontSize:'8px', margin:10,color:'grey'}}>Your payment was successful and has been received by {businessName}</Text>
           <Text style={{margin:'12px', fontSize:'25px', fontWeight:800}}>{currency} {amount}</Text>

        <View style={pdfstyles.line}></View>

          
           <Text style={{margin:'15px 0 10px 0', fontSize:10, fontWeight:800, color:'grey'}}>Payment Details</Text>

          <View style={pdfstyles.section}>
            <View style={pdfstyles.row}>
              <Text style={pdfstyles.txt}>Amount Paid</Text>
              <Text style={pdfstyles.txt}>{currency} {amount}</Text>
            </View>

            <View style={pdfstyles.row}>
              <Text style={pdfstyles.txt}>Transaction Fee</Text>
              <Text style={pdfstyles.txt}>{currency} {charges}</Text>
            </View>

            <View style={pdfstyles.row}>
              <Text style={pdfstyles.txt}>Payer Name</Text>
              <Text style={pdfstyles.txt}>{payerName}</Text>
            </View>

            <View style={pdfstyles.row}>
              <Text style={pdfstyles.txt}>Payment Method</Text>
              <Text style={pdfstyles.txt}>{channel}</Text>
            </View>

            <View style={pdfstyles.row}>
              <Text style={pdfstyles.txt}>Transaction Reference</Text>
              <Text style={pdfstyles.txt}>{ref}</Text>
            </View>

          </View>

           <Text style={{margin:'15px 0 5px 0', fontSize:10, fontWeight:800, color:'grey'}}>{date}</Text>

           <View style={pdfstyles.line}></View>
           
           <View style={{flexDirection:'column',alignSelf:'flex-start',margin:'10px 0'}}>
              <Text style={[pdfstyles.txt,{fontSize:8}]}>If you questions or issues with this payment, kindly contact Dtech Support Services Limited at invoice@dtechpay.com</Text>
            </View>


           <View style={pdfstyles.line}></View>
           
           <View style={{flexDirection:'column',alignSelf:'flex-start',margin:0}}>
              <Text style={{color:'#000', fontSize:'9px'}}>Please Note:</Text>
              <Text style={[pdfstyles.txt,{fontSize:8}]}>This debit will appear as <Text style={{fontWeight:600, color:'#000'}}>Dtechpay or Dtechpay payment</Text> in your Bank Statement</Text>
            </View>

          </View>
        

      </Page>
    </Document>
</PDFViewer>
  );



}
