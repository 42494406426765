import { IDENTITY, AUTH, USER } from '../../utilities/constant';
import axiosInstance from '../https'

// POST REQUESTS
export const Login =  (data:Record<string,any>)=>{
  return axiosInstance.post(`${IDENTITY}/${AUTH}/`,data);
}

export const GetUserDetails = ()=>{
  return axiosInstance.get(`${IDENTITY}/${USER}`);
}