import { Icon } from "@iconify/react";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table, { DescBox, InfoBox, Status } from "../../components/designerTable";
import SimpleButton, { ButtonVariant } from "../../components/simpleButton";
import TextImgBox from "../../components/textImgBox";
import { formatDateWithMonthName } from "../../utilities/appHelpers";
import companyImg from "../dashboard/pages/business/images/company-building.png";
import useStyles from "./styles";
import { ROLES } from "../../pages/dashboard";


const ServiceDetails=(props:any)=>{
const [appState, setAppState]=useState <Record<string,any>>({res:[], loading:true});
const navigate = useNavigate();
const classes = useStyles().classes;


  useEffect(()=>{
    props.data && setAppState({...appState,res:props.data});
  },[props.data]);

  
    return (
         <div>
              <Typography component="h2" className="fadeInUp animated faster" style={{ margin: "20px 0 10px"}}>
                <div className={classes.ser}>
                  <div className={classes.title}>
                    <TextImgBox imgSrc={companyImg} imgSize={"50px"} style={{alignItems: "flex-start"}}>
                      <div style={{ fontSize: 20, fontWeight: 600 }}>{appState.res?.name}</div>  
                      <div>{appState.res?.type}</div>
                      <div>{appState.res.address}</div>
                    </TextImgBox>
                    {/* <div>
                      <SimpleButton variant={ButtonVariant.white} onClick={() => {}} className="fadeIn animated delay-0-8s" style={{ borderRadius: "50%", display: "flex", alignItems: "center"}}>
                      <Icon icon="simple-line-icons:options" width="16" height="16" />
                      </SimpleButton>
                    </div> */}
                  </div>
                  <div className={classes.addSerBtn}>
                    {(ROLES?.BUSINESS_MODULE?.privileges?.ADD_SERVICE ?? false) && <SimpleButton variant={ButtonVariant.white} onClick={props.addServiceAction} className="fadeIn animated delay-0-8s" style={{ margin: "20px 5px" }}>
                      <div className={classes.addSer}>
                        <Icon icon="ci:table-add" width="16" height="16" style={{ marginRight: 5 }} />
                        <div>Add a Service</div>
                      </div>
                    </SimpleButton>}
                  </div>
                </div>
              </Typography>

              <div style={{ margin: "20px 0" }} className="fadeIn animated delay-0-4s">
                <div>
                  <Typography component="h3" className="fadeInUp animated faster" style={{ margin: "20px 0 10px", fontSize: 16, fontWeight: 600 }}>List of Services</Typography>
                  <Table
                    fields={[
                      {name: "", size: 5},
                      { name: "Service", size: 25 },
                      { name: "Status", size: 20 },
                      { name: "Subscription Duration", size: 25 },
                      // { name: "Renewal Date", size: 25 }
                    ]}
                    records={appState.res.services ? appState.res.services.map((data:any,i:number,arr:Array<any>)=>{ 
                      const all = appState.res;
                      return{
                        icon: <Icon icon={data.icon} width="24" height="24" />, name: data.serviceName, status:<Status status={true}>Active</Status>, duration: "Unlimited", date: formatDateWithMonthName(data.subDate), controls: { isDropdown: true },
                       details: (
                        <div key={i} style={{ display: "flex", padding: "5px 0" }}>
                          <div style={{ width: "60%", paddingRight: 20 }}>
                            <DescBox title='Service Name' descr='Payment Gateway' style={{ margin: "10px 0" }} descrStyle={{ fontSize: "1.5em" }} />
                            <InfoBox>{data.serviceDescr}</InfoBox>
                          </div>
                          <div style={{ width: "40%", textAlign: "right"}}>
                            <DescBox title='Subscription Duration' descr='Unlimited' style={{ margin: "10px 0" }} />
                            {/* <DescBox title='Renewal Date' descr={formatDateWithMonthName(data.renewalDate)} style={{ margin: "10px 0" }} /> */}
                            <DescBox title='Status' descr='Active' style={{ margin: "10px 0" }} />
                            <DescBox title='' descr={
                              (
                                <div style={{display: "flex", justifyContent: "flex-end", alignItems:"center" }}>
                                  {(ROLES?.BUSINESS_MODULE?.privileges?.VIEW_SERVICE_DASHBOARD ?? false) && <SimpleButton onClick={() => navigate(data.buildUrl,{state:[all,data.serviceCode]})} style={{ margin: "15px 5px 15px 0" }}>
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ padding: "0 5px" }}><Icon icon="eos-icons:cluster-management" width="16" height="16" /></div>
                                        <div>Manage</div>
                                      </div>
                                    </SimpleButton>}
                                    {/* <SimpleButton variant={ButtonVariant.danger} onClick={() => {}} style={{ margin: "15px 0 15px 5px" }}>
                                      <div style={{ display: "flex", alignItems:"center"  }}>
                                          <div style={{ padding: "0 5px" }}><Icon icon="ep:remove" width="16" height="16" /></div>
                                          <div>Remove</div>
                                      </div>
                                    </SimpleButton> */}
                                </div>
                              )
                            } style={{ margin: "10px 0" }} descrStyle={{}} />
                          </div>
                        </div>
                      ) 
                      }
                    }) :[]
                  }
                  />
                </div>
    
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>

                  {(ROLES?.BUSINESS_MODULE?.privileges?.ADD_SERVICE ?? false) && <SimpleButton onClick={props.addServiceAction} className="fadeIn animated delay-0-8s" style={{ margin: "20px 5px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Icon icon="ci:table-add" width="16" height="16" style={{ marginRight: 5 }} />
                      <div>Add a Service</div>
                    </div>
                  </SimpleButton>}
                </div>
              </div>
            </div>
          
    );
          
}

export default ServiceDetails;