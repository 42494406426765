import * as React from 'react';
import useStyles from './styles';

export interface ITextImgBoxProps {
  imgSrc: string,
  imgSize: string,
  children: any,
  className?: string,
  style?: Record<string, any>
  status?:boolean
  presence?:string
}

export enum userStatus{
  offline='offline',
  online='online',
  away = 'away'
}

export default function TextImgBox(props: ITextImgBoxProps) {
  const classes = useStyles().classes;
  const { imgSrc = "", className = "", style = {}, imgSize="20%",status=false, presence=userStatus.away} = props;

  return (
    <div className={`${classes.main} ${className}`} style={style}>
      <div className={`${classes.imgWrapper} card-1-1`} style={{width:imgSize}}>
        <img src={imgSrc} alt="" />
        {status && 
          <div className={`status ${presence}`}></div>
        }
      </div>
      <div className={classes.textWrapper}>
        {props.children}
      </div>
    </div>
  );
}
