import { makeStyles } from 'tss-react/mui';



// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((theme)=>({
        cont:{
            "&":{display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column",}, padding:30,
            width:450,
            "& #bgtime":{
                position:'absolute', top:"-15%", right:"-25%", width:300, filter:'opacity(7%)'
            },
            "& .illusCont":{
                width:180, height:180,overflow:'hidden', marginBottom:20,
                "& img":{width:'100%'}
            },
            "& .textCont":{
                display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", textAlign:'center', color:'grey',
                "& h3":{
                    fontWeight:600, lineHeight:'1.2em', color:'#565555de',

                } 
            },
            display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column",
            "& .inputArea":{
                width:'100%',padding:'0 0px', marginTop:'-20px',
                "& .btn":{
                    width:'100%', marginTop:10,
                    "& button":{width:'100%'}
                }
            },
            "@media screen and (max-width: 600px)": {
                width:'100%', marginTop:'10%',
                "& .inputArea":{
                    width:'100%',padding:'0 0', 
                    "& .btn":{
                        width:'100%', marginTop:20,
                   
                }
                }
            }

        }
}));