import * as React from "react";
import TextField from "../textField";
import useStyles from "./styles";

export interface ISelectBoxProps {
  className?: string;
  style?: Record<string, any>;
  label?: string;
  value?: string;
  onChange?: any;
  onKeyDown?: any;
  children?: any
}

export default function Select(props: ISelectBoxProps) {
  const {
    label = " ",
    value = "",
    onChange = ()=>void(0),
    onKeyDown = ()=>void(0)
  } = props;

  const [optionDisplay, setOptionDisplay] = React.useState(false);

  const classes = useStyles().classes;
  const genRandInt = (min:number, max:number)=>{
    return Math.floor(Math.random() * (max - min + 1) ) + min;
  }
  const noRemoveClass = "dontclosedd-" + genRandInt(1000, 9999);

  const toggleDisplay = (e:any)=>{
    e.stopPropagation();
    if(!e.target.classList.contains(noRemoveClass) && optionDisplay) setOptionDisplay(false)
  }
  
  React.useEffect(()=>{
    optionDisplay ? window.addEventListener("click", toggleDisplay) : window.removeEventListener("click", toggleDisplay);
  },[optionDisplay])
  

  return (
    <div className={`${classes.select} ${noRemoveClass}`}>

      <TextField mainClass={noRemoveClass} inputClass={noRemoveClass} label={label} onFocus={()=>setOptionDisplay(true)} value={value} onChange={onChange} onKeyDown={onKeyDown} />

      <div className={`${classes.optionsWrapper} card-1-1 ${optionDisplay === false ? classes.hide : ""}`}>{props.children}</div>

    </div>
  );
}

export const Option = (props:any) => {
  const classes = useStyles().classes;
  const {selected=false, onClick=()=>void(0)} = props;
return <button type="button" onClick={(e:any)=>onClick(e)} onKeyDown={(e:any)=>{
  // e.code === "Enter" && setOptionDisplay(false)
  }} className={`${classes.option} ${selected ? classes.selected : ""}`}>
{props.children}
</button>
}
