import useStyles from './styles';
import React from "react";
export interface ITextLogoBoxProps {
  logo: any,
  children: any,
  className?: string,
  style?: Record<string, any>
}

export default function TextLogoBox(props: ITextLogoBoxProps) {
  const classes = useStyles().classes;
  const { className = "", style = {}, logo = <></> } = props;
  return (
    <div className={`${classes.main} ${className}`} style={style}>
      {/* <div> */}
        {logo}
      {/* </div> */}
      <div className={classes.textWrapper}>
        {props.children}
      </div>
    </div>
  );
}
