import { Theme } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  detailsPage: {
    display: "flex",
    width: "100%",
    padding: 20
  },
  detailsPageIllus: {
    height: "50vh",
    padding: 20,
    width: "50%",
    "& img":{
      width: "100%",
      height: "100%",
      objectFit: "contain",
      objectPosition: "center",
    }
  },
  detailsPageForm: {
    padding: "10px",
    width: "50%"
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
