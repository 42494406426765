import { GENESIS, VEMAIL, VUSERNAME} from './../../utilities/constant';
import { BOARDING } from '../../utilities/constant';
import axiosInstance from '../https'

export const addUser =  (data:Record<string,any>)=>{
    return axiosInstance.post(`${BOARDING}/${GENESIS}`,data);
}
export const verifyEmail =  (data:Record<string,any>)=>{
    return axiosInstance.post(`${BOARDING}/${VEMAIL}`,data);
}
export const verifyUsername =  (data:Record<string,any>)=>{
    return axiosInstance.post(`${BOARDING}/${VUSERNAME}`,data);
}
export const loginWithLink = (data:Record<string,any>)=>{
    return axiosInstance.post(`${BOARDING}/verify/email`,data);
}
export const resendLoginLink = (data:Record<string,any>)=>{
    return axiosInstance.post(`${BOARDING}/verify/email/request-link`,data);
}