import Axios from 'axios';
import * as appHelpers from '../../utilities/appHelpers';
import { cloneDeep } from 'lodash';

const axiosInstance = Axios.create({
   baseURL: process.env.REACT_APP_GATEWAY_API,
   // baseURL: "http://100.26.242.237/dea/v1/gateway/initiate",
   headers: {
      'Content-Type': 'application/json',
      'X-Correlation-ID': appHelpers.uuidv4(),
      
   },
});


axiosInstance.interceptors.request.use(async (config) => {
   const clonedConfig:any = cloneDeep(config);
   const token = appHelpers.getAppStorage('DTECHPAY_TOKEN');
  if(token){
     clonedConfig.headers.common = {
      Authorization: `Bearer ${token}`,
      // 'Client-ID':process.env.REACT_APP_CLIENTID
   };
  }
   

   return clonedConfig; 
});

const indicateActive = (res: any) => {

   return res;
};

axiosInstance.interceptors.response.use(indicateActive, (err:any) => {
   //const errdata = err.response ? err.response : err;

  // indicateActive(errdata);
   throw err;
});

export default axiosInstance;
