// 
import { makeStyles } from 'tss-react/mui';


// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((theme) => ({
    gatewayBody: {
        '& *': {
            fontFamily: theme.typography.fontFamily + ' !important'
        },
        width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f6f6f6"

    },
    cont: {
        display: "flex", justifyContent: "space-between", maxWidth:520,
        //extra
        backgroundColor: "#fff", borderRadius: 10, boxShadow: "1px 1px 20px #00000005", padding: 30, minWidth: "850px", transition: "all 0.3s linear", width: "auto", position: "relative",

        "& .dtpLogo": {
            position: "absolute", top: -30, left: "50%", transform: "translateX(-50%)", 
        },
        "& .mob-nav": {
            display:'flex',
            justifyContent:'space-between', width: 'calc(100% - 60px)', position: "absolute", top: 30, fontSize: "1.8rem", left: "50%", transform: "translateX(-50%)",
            '& button':{
                backgroundColor:'transparent', padding:'5px 10px', border:'none', cursor:'pointer', borderRadius:4,
                "&:hover":{
                    backgroundColor:'#f2f2f2',
                }
            }
        },
        "& footer": {
            position: "absolute", bottom: -40, left: "50%", transform: "translateX(-50%)", color: "grey",
            "@media screen and (max-width: 950px)": {
                bottom: 10, fontSize:'0.7rem',textAlign:"center",position: "static",transform: "translateX(0%)", marginTop:'30px',
            }
        },
        "& .paymentapproved": {
            width: "calc(100% - 20px)", height: "540px", display:'flex', justifyContent:'space-evenly', flexDirection:'column', padding: "5% 12%",

            "& > p": {
                marginBottom: -20, width: "80%", textAlign: "center",
            },
            "& > div:last-child": { marginTop: "-70px", zIndex: 1 },
            "& > div": { textAlign: "center" },
            "& .logo": {
                width: 300, height: 200, display:'flex', justifyContent:'center',alignItems:'center', overflow: "hidden", position: "relative",
                "& img": {
                    width: "100%", height: "100%", objectFit: "cover", margin: 0,
                }
            },
            "& h3": { marginTop: -20 },
            "& p": { color: "grey" },

            "@media screen and (max-width: 512px)": {
                "& .finishInfo":{
                    lineHeight:" 2.5rem", marginBottom: 10 }
            }

        },


        "@media screen and (max-width: 950px)": {
            width: "100% !important", height: "100%", minWidth: 0, flexDirection:"column",

            "& .dtpLogo": {
                position: "absolute", top: 10, left: "50%", transform: "translateX(-50%)",
            },

        }


    },


    menu: {
        width: "100%", display: "flex",
        "& > div": {
            display: "flex", flexDirection: "column", height: 50, width: 100, textAlign: 'left', justifyContent: " space-evenly",
            alignItems: "flex-start",
            "& .active ~ div": {
                width: "calc(100% - 70px)", height: 2, backgroundColor: "green",
            }
        },
        "& .active": { fontWeight: 800 },
        "& button": {
            backgroundColor: "transparent", border: "none", marginRight: "0", cursor: "pointer"
        },

    },

    detailsArea: {
        width: 350,
        "& .inline": {
            display: "flex", justifyContent: "space-between",
            "& > div": {
                width: "calc(50% - 10px)"
            }
        },
        "& .actionBtn": {
            display:'flex',
            justifyContent:'space-between',
            "& label, input": {
                cursor: "pointer"
            },
            "& label": {
                userSelect: "none", marginLeft: 10
            }
        }
    },

    cardDetails: {
        "&": {
            display:'flex',
            justifyContent:'center',
            alignItems: 'flex-start',
            "& .cardlogos": {
                display:'flex',
                justifyContent:'flex-start', margin: "-10px 0 30px 0", border: "solid red",
                "& > div": {
                    width: 25, marginRight: 20,
                    "& img": {
                        width: "100%", height: "auto"
                    }
                }

            },

        }
    },

    cards: {
        width: 200, margin: "20px 30px 0 0",
        "& > div": {
            width: "100%", height: 120, borderRadius: 20, backgroundColor: "#f2f2f2", marginBottom: 10, position: "relative", overflow: "hidden",
            "& .top": { transform: "translate(43%,-66%)" },
            "& .btm": { transform: "translate(-42%,41%)" },
            "& .bgarts": {
                position: "absolute", width: 200, height: 150, borderRadius: "50%", backgroundColor: "#ffffff1c",
            },

            "& .cardcont": {
                display:'flex',
                justifyContent:'space-between',
                alignItems: 'center',
                flexDirection: 'column', padding: "5px 5px", color: "#ffffffb5", height: "100%",
                "& > div": {

                    width: "100%", padding: 5

                },
                "& .fs": {
                    display:'flex',
                    justifyContent:'flex-end',
                    alignItems: 'center',
                    "& div": {
                        width: 40, justifyContent: "center", alignItems: "center", flexDirection: "column",display: 'flex',
                        "& img": {
                            width: 20
                        },
                        "& small": {
                            fontSize: "0.4rem",
                        }
                    }
                },
                "& .num": {
                    display:'flex',
                    "& p": { margin: 0 }
                },
                "& .names": {
                    display:'flex',
                    justifyContent:'space-between',
                    "& p": {
                        margin: 0,
                        fontSize: "0.7rem",
                    }
                }
            }
        },
        "& > div:nth-child(1)": {
            background: "linear-gradient(145deg, #f13dc375, 1%, #6730cade 76%)"
        },
        "& > div:nth-child(2)": {
            background: "linear-gradient(145deg, #c42d6d9e, 18%, #ee5a6fde, #e8b249 119%)"
        },
        "& > div:nth-child(3)": {
            background: "linear-gradient(145deg, #2db6c49e, 18%, #665aeede, #d449e8 119%)"
        },
    },

    paymentSummary: {
        "& > p": {
            marginLeft: 30
        },
        "& > div": {
            width: 250, backgroundColor: "#f0f0f073", boxShadow: "0 0 10px #00000002", marginLeft: 30, borderRadius: 5, padding: 20,

            "& .logodet": {
                justifyContent: "center", alignItems: "center", flexDirection: "column", display: 'flex', width: "100%", padding: 20,
                "& .logo": {
                    width: 70, height: 70, display:'flex', padding: 5, backgroundColor: "#fff", borderRadius: 10, boxShadow: "0 15px 25px #00000030", overflow: "hidden", marginBottom: 10,
                    "& img": {
                        width: "100%", height: '100%', objectFit: 'contain',
                    }
                },

                "& p": {
                    marginTop: 0, fontWeight: "bolder",
                }
            },

            "& .summ": {
                "& > div": {
                    display:'flex',
                    justifyContent:'space-between', width: "100%", marginBottom: 20,
                    fontSize: "0.8rem",
                    "& :first-child": {
                        color: "grey"
                    }
                },

            }
        },

        "@media screen and (max-width: 950px)": {
            display: "none",width: "100%", height: "calc(100% - 80px)", backgroundColor: "#ffffffad", backdropFilter: "blur(0.2rem)", position:"absolute", right:0, zIndex:1, top:80, paddingRight:20,
            "& > p": {
                marginLeft: 'calc(100% - 250px)', textShadow:'1px 1px 1px #00000050', fontSize:'1rem', fontWeight:500,
            },
            "& .summ-wrapper":{
                backgroundColor:'#f0f0f0', marginLeft:"auto", width:"100%",maxWidth:250,
            },

        }

    },

    "showSummary":{
        display: "block !important",
    },

    pin: {
        "&": {

            width: "100%", height: "540px", display:'flex',
            justifyContent:'space-evenly', alignItems:'center', flexDirection:"column", padding: "5% 0",
            "& .selectCardInfo": {
                display: 'flex', justifyContent:'center',alignItems:'center', backgroundColor: "#fff", borderRadius: 10, padding: "10px 20px", boxShadow: " 0 0 15px #00000025",
                "& > div": {
                    marginRight: 10, display: 'flex', overflow: 'hidden',
                    "& img": {
                        width: "100%",
                    },

                }
            },

            "& p": {
                // marginBottom:-10,
            },

            "& .actionBtn": {
                display: 'flex', width: "100%",
                [theme.breakpoints.down("xs")]:{
                    flexDirection:"column-reverse", gap:15
                },
                "& button:first-child": { 
                    marginRight: 30,
                    [theme.breakpoints.down("xs")]: {marginRight: '0 !important' } 
                },
                "& button": { 
                    [theme.breakpoints.down("xs")]: {width:"100% "}
                }
            },

            [theme.breakpoints.down("xs")]:{
                marginTop:30
            }
        },


    },
    otp: {
        "&": {

            width: "calc(100% - 20px)", height: "350px", justifyContent: "space-between", alignItems: "center", flexDirection: "column",display: 'flex', padding: "5% 12%", marginTop:100,

            "& > p": {
                marginBottom: -20, width: "80%", textAlign: "center",
            },

            "& input": {
                letterSpacing: "10px",
            },

            "& .actionBtn": {
                display:'flex',
                justifyContent:'space-between', width: "100%", marginTop: -50,
            },
            "& > div:nth-child(4)": {
                display:'flex',
                justifyContent:'flex-start', width: "100%",
                "& button": {
                    background: "transparent", border: "none", color: theme.palette.secondary.main, cursor: "pointer"
                }
            }


        },
        [theme.breakpoints.down("xs")]: {
            marginTop:50, width:'100%',
            "& .actionBtn": {
                flexDirection:"column-reverse", gap:15,marginTop:'0',
                "& button": { width:"100%"},
            },
            "& > div:nth-child(4)": {
                 flexDirection:"column",marginTop:20
            }
        }

    }

}));