import { darken, lighten, Typography } from '@mui/material';
import React, { useEffect, useState,useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import OptionChecker, { CheckerBtn } from '../../components/optionChecker';
import ReturnBar from '../../components/returnBar';
import useStyles from './styles';
import TextField from '../../../../components/textField';
import SimpleButton from '../../../../components/simpleButton';
import { commarize, commarize2, currency, error, getAppStorage, sanitize, setAppStorage,truncate } from '../../../../utilities/appHelpers';
import { GetBanks, GetTransferTypes, TransferFund, VerifyDetails } from '../../../../services/transfer';
import { messasgeType, useGlobalState } from '../../../../pages/dashboard';
import Popup from '../../../../components/popup';
import Select  from "react-select";
import Button from '../../../../components/button';
import Alert from '../../../../components/alertBox';
import OptionSwitch, { SwitchBtn } from '../../components/optionSwitch';

export interface IFundWalletModProps {
  className?: string,
  style?: Record<string, any>,
}

export default function FundWalletMod(props: IFundWalletModProps) {
  //CONTS & STATES
  const classes = useStyles().classes;
  const { className = "", style = {} } = props;
  const navigate = useNavigate();
  const toDashboard = () => {
    navigate("/dashboard");
  }
  const [appState,setAppState]=useState({ text:'Proceed', icon:<Icon icon="eva:arrow-forward-fill"/>, hintIcon:messasgeType.default, status:false, count:1, loading:false,msg:'',})
  const [hintState,setHintState] = useGlobalState("hint");
  const userDetails = getAppStorage("USER_DETAILS");
  const [switchNum, setSwitchNum] = useState(0);
  const [types, setTypes] = useState([]);
  const [options, setOptions] = useState([]);
  const [walletOptions, setWalletOptions] = useState([]);
  const [typeID, setTypeID] = useState(''),
  [paymentParam,setPaymentParam] = useState({bank_code: '', account_number: "", account_name: "", amount: '', wallet_code: "", narration: "" }),
  [page2, setPage2] = useState(false),
  [verifyingAccount, setVerifyingAccount] = useState(false),
  [bankCode, setBankCode] = useState(''),
  [amount, setAmount] = useState(''),
  [showAccountForm,setShowAccountForm]=useState(false),
  [formData, setFormData]=useState({password:'',wallet:''}),
  [focus,setFocus]=useState(""),
  [selectedOption, setSelectedOption] = useState<any>(),
  [selectedWallet, setSelectedWallet] = useState<any>(),
  [awaiting, setAwaiting] = useState({button:true, amtInput:true,bankSelect:true}),
  [showAlert, setShowAlert] = useState(false),
  [customAlert, setcustomAlert] = useState({title:'Warning', message:''});
  // customAlert setShowAlert




  //USE EFFECTS
  useEffect(()=>{
    setHintState({...hintState,status:appState.status, msg:appState.msg, icon:appState.hintIcon});
  },[appState.count])

  useEffect(() => {
    (async()=>{
      try {
        const resp = await GetTransferTypes();
        if(resp.status===200){
          setTypes(resp.data.data);
        } 

      } catch (e) {
        setAppState({...appState, status:true, msg:error.handle(e,"You've lost Internet connection",true).includes('/api')?error.handle(e,"You've lost Internet connection",true).split('/api')[0]+'...':error.handle(e,"You've lost Internet connection",true), hintIcon:messasgeType.failed,count:appState.count+1})

      }
      
    })()
  },[]);

  useEffect(()=>{
    if(selectedOption){
      setBankCode(selectedOption.value);
      setAwaiting({...awaiting,amtInput:false});
    }else{
      setBankCode('');
      setAwaiting({...awaiting,amtInput:true});
    }
    
  },[selectedOption])

  useEffect(()=>{
    if(typeID.trim().length > 0){
      setPage2(true);
      getDestination(typeID);
    }

  },[typeID])

  useEffect(()=>{
    selectedWallet && setPaymentParam({...paymentParam,wallet_code:selectedWallet?.value});
  },[selectedWallet])

  useEffect(()=>{
    setPaymentParam({...paymentParam,bank_code:bankCode,amount:sanitize(amount)});
    if(paymentParam.account_name !== '' && paymentParam.account_number !== '' && amount !== '' && bankCode!=='' ){
      setAwaiting({...awaiting, button:false});
    }else{
      setAwaiting({...awaiting, button:true});
    }
    
  },[paymentParam.account_name, paymentParam.account_number,amount, bankCode])



  //FUNCTIONS
  const getDestination = async (tid:string)=>{
    try {
      const resp = await GetBanks(tid);
      if(resp.status === 200){
        // setAppStorage('bank&wallet',resp.data.data?.banks);
        let resObj = resp.data.data?.banks;
       resObj && resObj.map((obj:Record<string,any>)=>{
          Object.keys(obj).forEach(oldkey => {
              let value = obj[oldkey];
              let newKey = oldkey == 'cbn_code'?'value': oldkey == 'bank_name' ?'label' : oldkey;
              delete obj[oldkey];
              obj[newKey] = value;
          });
        })
        setOptions(resObj);
        setAwaiting({...awaiting, bankSelect:false});
      }
    } catch (e) {
      
    }
      
  }
  const inputState = useRef({complete:'',incomplete:''});
 useEffect(()=>{
  const verifyAccount = async ()=>{
    try {
      const resp  = await VerifyDetails(typeID, {bank_code:bankCode, account_number:paymentParam.account_number});
      if(resp.status ===200){
        if(resp.data.data?.success){
          if(inputState.current.incomplete === inputState.current.complete){
            setPaymentParam({...paymentParam, account_name:resp.data.data?.account_name});
          }
        }
      }
    } catch (e:any) {
      //error.handle(e,"Network Error",true)
      setShowAlert(true); setcustomAlert({...customAlert,title: e?.response?.data?.data?.message,message:e?.response?.data?.data?.message ==='Invalid Account'?'The Bank you selected does not match the account number you supplieid':'Try again'})
    }finally{
      setVerifyingAccount(false);
    }
  }
  if(paymentParam.account_number.trim().length>0){
    if(paymentParam.account_number.length ===10){
      if(bankCode !== ''){
        setVerifyingAccount(true);
      }else{
        setVerifyingAccount(false);setPaymentParam({...paymentParam, account_name:''});
      }

      inputState.current = inputState.current = {...inputState.current, complete:paymentParam.account_number,incomplete:paymentParam.account_number};
    }else if(paymentParam.account_number.length < 10){
      setVerifyingAccount(false);
      setPaymentParam({...paymentParam, account_name:''});
      inputState.current = inputState.current = {...inputState.current, incomplete:paymentParam.account_number};
      }

    if(inputState.current.incomplete === inputState.current.complete){
      bankCode !== '' && verifyAccount()
    }
    
  }else{
    setPaymentParam({...paymentParam, account_name:''});
  }
  
 },[paymentParam.account_number,bankCode])


  const handleAmountInput = useCallback((e:any)=>{
    setPaymentParam({...paymentParam, account_number:truncate(sanitize(e.target.value),10)});
  },[paymentParam.account_number])

  
  const Submit = ()=>{
    fetchWallet()
    setShowAccountForm(true);

    }

    const resetForm= ()=>{
      setPaymentParam({...paymentParam,wallet_code:''});
      setFormData({...formData,password:''});
    }

    const handleSelect = (e:any)=>{
      e.target.value !== "" ? setFocus("ff") : setFocus("");
      setPaymentParam({...paymentParam,wallet_code:e.target.value});
    }

    const TransferNow = useCallback(async()=>{
      setAppState({...appState, text:'Please wait...', icon:<Icon icon="humbleicons:dots-horizontal"/>, loading:true});
    
      try {
        const resp = await TransferFund(typeID,paymentParam);
        if(resp.status===200 || resp.status===201){
          setAppState({...appState, status:true, msg:resp.data.message, hintIcon:messasgeType.success,icon:<Icon icon="eva:arrow-forward-fill"/>, count:appState.count+1})
        }
      } catch (e) {
        setAppState({...appState,text:'Recharge', icon:<Icon icon="eva:arrow-forward-fill"/>, loading:false, status:true, msg:error.handle(e,"You've lost Internet connection",true).includes('/api')?error.handle(e,"You've lost Internet connection",true).split('/api')[0]+'...':error.handle(e,"You've lost Internet connection",true), hintIcon:messasgeType.failed,count:appState.count+1});
    
      }
    },[paymentParam.wallet_code])

   const fetchWallet = ()=>{
      if(getAppStorage('WALLETS')){
          const resObj = getAppStorage('WALLETS').map((w:any)=>w);
          resObj && resObj.map((obj:Record<string,any>)=>{
            Object.keys(obj).forEach(oldkey => {
                let value = obj[oldkey];
                let newKey = oldkey == 'accNo'?'label': oldkey == 'walletCode' ?'value' : oldkey;
                delete obj[oldkey];
                obj[newKey] = value;
            });
          })
        setWalletOptions(resObj);
      } 
    }

  return (
    <div className={`${className} ${classes.main}`} style={style}>
      <ReturnBar title='DashBoard' onClick={() => { toDashboard() }}></ReturnBar>
        <Typography component="h2" style={{ margin: "20px 0 10px", fontSize: 20, fontWeight: 600 }}>Bank/Wallet Transfer</Typography>
      <OptionSwitch>
          <SwitchBtn
            title="Bank"
            className={`${switchNum === 0 ? "active" : ""}`}
            style={{ width: "50%" }}
            onClick={() => {
              switchNum !== 0 && setSwitchNum(0);
            } } />
          {/* <SwitchBtn
            title="Wallet"
            className={`${switchNum === 1 ? "active" : ""}`}
            style={{ width: "50%" }}
            onClick={() => {
              switchNum !== 1 && setSwitchNum(1);
            } } /> */}
        </OptionSwitch>
     
      <div>
        {switchNum === 0 && (
          <>
            {!page2 && (<div>
              <Typography component="h3" className={`${classes.smallTitle}`}>
                What type of transfer do you want to do?<sup>*</sup>
              </Typography>
              <div>
                <OptionChecker>
                  {
                    types.map((b:any,i:number)=> {
                    if(+b.type_state===1){ 
                    return <CheckerBtn key={i} title={b.type_name} className={`fadeInUp animated delay-0-${i}s`} onClick={() => setTypeID(b.type_code)} checked={true}/>
                    }
                  }
                    )
                  }
                </OptionChecker>
              </div>
            </div>)}

            {page2 && (
              <>
              <div>
                 <Typography component="h3" className={`${classes.smallTitle}`}>
                Select your bank or wallet<sup>*</sup>
              </Typography>
              <div className='card-1' style={{borderRadius:5, margin:'10px 0',zIndex:10,position:'relative'}}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  isClearable={true}
                  isDisabled={awaiting.bankSelect}
                  isLoading={awaiting.bankSelect}
                  />
                </div>
              </div>


                <div>
                  <TextField placeholder={'Account Number'} disabled={awaiting.amtInput} onChange={(e: any) => handleAmountInput(e)} value={paymentParam.account_number} />
                  
                    {
                      verifyingAccount ? <Icon style={{fontSize:'2.5rem', color:'#2c53b1',margin:'-5px 0 -20px 0'}} icon="eos-icons:three-dots-loading" />:<small style={{textTransform:'uppercase', marginLeft:5}}> {paymentParam.account_name}</small>
                    }
                  
              </div>


                <div>
                  <TextField placeholder={currency.naira+' Amount '}  onChange={(e: any) => setAmount(commarize2( e.target.value))} value={amount} />
                </div>

                <div>
                  <TextField placeholder={'Naration'}  onChange={(e: any) => setPaymentParam({...paymentParam,narration:e.target.value})} value={paymentParam.narration}/>
                </div>
                
                <SimpleButton disabled={awaiting.button} style={{ padding: "10px 40px", fontWeight: 600, margin: "10px 0" }}
                  onClick={Submit}>Submit</SimpleButton>

              </>
              )}



<Popup show={showAccountForm} showLogo={false} onPopChange={(stat:boolean)=>setShowAccountForm(stat)} onReset={resetForm}>
      <div className={`${classes.accountForm}`}>
        
        <Typography className={`fadeInUp animated delay-0-1s`} component={'h1'} style={{fontSize:'1.2rem', fontWeight:600,marginBottom:20,textAlign:'center',lineHeight:'1.4rem'}}>
          <p>You're about to send <span style={{color:'rgb(0 155 178)'}}> {currency.naira+commarize(paymentParam.amount)}</span> to <span style={{color:'rgb(0 155 178)'}}>{paymentParam.account_name} </span> </p>  <small style={{ color:'grey'}}>Select a wallet and enter your password to Proceed</small> </Typography>
        <form>

            <div className={`fadeInUp animated delay-0-2s`} style={{position:'relative',width:"100%",zIndex:2}}>
            <Select
                  defaultValue={selectedWallet}
                  onChange={setSelectedWallet}
                  options={walletOptions}
                  isClearable={true}
                  />
              </div>

          <TextField className={`fadeInUp animated delay-0-4s`} type="password" placeholder="Password" onChange={(e:any)=>setFormData({...formData,password:e.target.value})} value={formData.password}/>


            <div className='btnCont' style={{marginTop:20}}>
          <Button className={`fadeInUp animated delay-0-6s`} text={appState.text} variant="secondary" disabled={appState.loading} loading={appState.loading} onClick={TransferNow} icon={appState.icon}/> 
            </div>
        </form>

      </div>
    </Popup>
    <Alert title={customAlert.title} onClose={(stat:boolean)=>setShowAlert(stat)} message={customAlert.message} show={showAlert}/>

</>)}










        
      </div>
    </div>
  );
  
}
