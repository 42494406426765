import { makeStyles } from 'tss-react/mui';
import { flex } from "../../../../utilities/appHelpers";

const useStyles = makeStyles<void, 'inputLabel'>()((theme, _params, classes) => ({
  main: {
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    "& *": {
      boxSizing:"inherit"
    }
  },
  smallTitle:{
    fontSize:"0.7em",
    margin: "15px 0 5px"
  },
  dropItem:{
    "& :hover":{
      background:'#f2f2f2',
    },
    cursor:'pointer',
    "& .items":{
      display:'flex',justifyContent:'flex-start',alignItems:'center',gap:8,padding:'10px 10px 10px 5px',borderRadius:5,
      "& span":{fontWeight:500}
    }

  },
  ellipseBtn:{
    "&":{
      cursor:'pointer',
      padding:'5px 8px',
      borderRadius:5,
      background:'transparent',
      border:'none',
      display:'flex',justifyContent:'center',alignItems:'center',
      "&:hover":{
        background:'#f2f2f2'
      }
    }
  },
  accountForm:{
    width:400, padding:10, height:300,
    '&':{display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column",},
    "& .form":{
      display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", width:'100%',gap:15,
      "& .btnCont":{
        width:'100%', marginTop:50, display:'flex',
        justifyContent:'space-between', 
        "& button":{padding: 0, width:'calc(50% - 20px)'}
      }
    },
    "& .imgCont":{
      width:"100%", height:150,display: 'flex',
      "& img":{
        width:"100%", height:"100%", objectFit:'contain'
      }
    },
    [theme.breakpoints.down('sm')]: {
      width:'100%', marginTop:'25%'
    }
  },


inputLabel: {
  position: "absolute",
  zIndex: 1,
  top: "36%",
  transform: "translateY(-50%)",
  cursor: "text",
  display: "block",
  border: "none",
  // color: '#222',
  // backgroundColor: "transparent",
 
  fontWeight: "bold",
  outline: "none",
  borderRadius: "4px",
  padding: "2px 5px",
  // fontSize: "0.8em",
  transition: "all 0.4s ease-in",
  // make label up temporary
  fontSize: "0.7em",
  backgroundColor: "rgba(0, 155, 178, 0.7)",
  left:8,color:'#fff'
},
".ff": {
  top: "36%",
  color: "#fff",
  transform: "translate(10%,100%)",
  backgroundColor: "rgba(0, 155, 178, 0.7)",
  borderRadius: "4px",
  fontSize: "0.8em",
  padding: "3px 5px",
  transition: "all 0.4s ease-in"
},
inputElement: {
  display: "block",
  width: "100%",
  color: "#000",
  border: "none",
  fontSize: "1.1em",
  padding: "2px 12px",
  fontWeight: "bold",
  outline: "none",
  position: "relative",
  fontFamily: 'Raleway, Montserrat, Source Sans Pro, sans-serif',
  zIndex: 2,
  lineHeight: '1.5',
  backgroundColor: "transparent",
  transition: "all 0.2s ease-in",
  textAlign: "left",
  [`&.focused ~ .${classes.inputLabel}, &.focused.textarea ~ .${classes.inputLabel}`]: {
    top: "0",
    color: "#fff",
    transform: "translateY(-100%)",
    backgroundColor: "rgba(0, 155, 178, 0.7)",
    borderRadius: "4px",
    fontSize: "0.8em",
    padding: "0px 4px",
    transition: "all 0.4s ease-in",
  },
  "&.input-error ~ $errorDisplay": {
    animation: 'errortbblinking 2s ease-in-out infinite',
    content: "''",
    display: 'block',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    boxSizing: 'border-box',
    top: '5px',
    right: '5px',
    position: 'absolute',
  },
  [`&.textarea ~ .${classes.inputLabel}`]: {
    top: "20%"
  },
  "& *, & *::before, & *::after, &:hover *, &:hover *::before, &:hover *::after, &::before, &::after, &:hover, &:hover::before, &:hover::after":{
    border: "none !important"
  }
  
}
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;