import { faDownload, faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SessionCheck, dispatch, messasgeType, useGlobalState } from '../../../../pages/dashboard';
import { getLinkAccessById } from '../../../../services/paymentLink';
import { useUserTheme } from '../../../../themes';
import { error } from '../../../../utilities/appHelpers';
import ReturnBar from '../../components/returnBar';



export default function IndLinkAccess() {
    const navigate = useNavigate();
    const {isMobile, isTablet} = useUserTheme();
    const [indLinkAcc, setIndLinkAcc] = useState({
        customerName: '',
        email: '',
        phone: '',
        dateAccessed: '',
        amount: '',
        status: '',
        channel: '',
        currency: '',
    });
    const [appState,setAppState]=useState({loading:false, res:[], msg:'',isMsg:false,status:false,count:0, msgType:messasgeType.default});
    const [,setHintState] = useGlobalState("hint");
    const params = useParams();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const dateAccessed = indLinkAcc?.dateAccessed.split(' ');
    const date = dateAccessed[0]?.split('-');
    const time = dateAccessed[1]?.split(':');
    useEffect(() => {
        (async () => {
            try {
                setAppState(prev => ({ ...prev, loading: true }));
                const resp = await getLinkAccessById(params.id, params.aid);
                if (resp.status === 200) {
                    const linkDets = resp?.data?.data || {};
                    setIndLinkAcc(linkDets);
                }
                setAppState(prev => ({ ...prev, loading: false }));
            } catch (e: any) {
                SessionCheck(e);
                setAppState({ ...appState, isMsg: true, msg: error.handle(e, "Failed to fetch required Data, please check your network and try again", true), count: appState.count + 1, msgType: messasgeType.network, loading: false });
            }
        })();
    }, []);

    useEffect(() => {
        setHintState(prev => ({ ...prev, status: appState.isMsg, msg: appState.msg, icon: appState.msgType }));
    }, [appState.count]);
console.log(dateAccessed[1], 'dateAccessed[1]')
console.log(time, 'time')
    
    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <ReturnBar title="Back to Payment Links Overview" onClick={() => { navigate("/dashboard/payment"); }} style={{ width: "fit-content" }} />
            <ReturnBar title="Back to Links Access" onClick={() => { navigate(`/dashboard/payment/link/${params.id}`); }} style={{ width: "fit-content" }} />
            </div>
            <Typography component="h2" style={{ fontSize: 25, fontWeight: 600, marginBlock: '24px' }}>Link Access Details</Typography>
            <div className="fadeIn animated delay-0-1s" style={{ backgroundColor: 'white', padding: isMobile ? '16px' : '40px', display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', alignItems: 'center', gap: isMobile ? '16px' : '24px',borderRadius: '10px' }}>
                <div style={{display: isMobile? 'flex' : 'unset', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <Typography style={{ fontSize: 14, }}>Reference </Typography>
                    <Typography component="h2" style={{ fontSize: 20, fontWeight: 600, }}>HFgm00pKDe02vUHy77af</Typography>
                </div>
                <div style={{ width: isMobile ? '100%' : '1px', backgroundColor: 'rgba(0, 0, 0, .1)', height: isMobile ? '1px' : '50px' }}></div>
                <div style={{display: isMobile? 'flex' : 'unset', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <Typography style={{ fontSize: 14, }}>Amount</Typography>
                    <Typography component="h2" style={{ fontSize: 20, fontWeight: 600, }}>₦{indLinkAcc.amount}</Typography>
                    <Typography style={{ fontSize: 10, }}>{indLinkAcc.status}</Typography>
                </div>
                <div style={{ width: isMobile ? '100%' : '1px', backgroundColor: 'rgba(0, 0, 0, .1)', height: isMobile ? '1px' : '50px' }}></div>
                <div style={{display: isMobile? 'flex' : 'unset', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <Typography style={{ fontSize: 14, }}>Dtechpay Fees</Typography>
                    <Typography component="h2" style={{ fontSize: 20, fontWeight: 600, }}>₦300.00</Typography>
                    <Typography style={{ fontSize: 10, }}>Charged to Customer</Typography>
                </div>
                <div style={{ width: isMobile ? '100%' : '1px', backgroundColor: 'rgba(0, 0, 0, .1)', height: isMobile ? '1px' : '50px' }}></div>
                {/* <div>
                    <Typography style={{ fontSize: 14, }}>Payout Due </Typography>
                    <Typography component="h2" style={{ fontSize: 20, fontWeight: 600, }}>₦0.00</Typography>
                </div>
                <div style={{ width: '1px', backgroundColor: 'rgba(0, 0, 0, .1)', height: '50px' }}></div>
                <div>
                    <Typography style={{ fontSize: 14, }}>Fraud Score </Typography>
                    <Typography component="h2" style={{ fontSize: 20, fontWeight: 600, }}>0</Typography>
                    <Typography style={{ fontSize: 10, }}>Low </Typography>
                </div>
                <div style={{ width: '1px', backgroundColor: 'rgba(0, 0, 0, .1)', height: '50px' }}></div> */}
                <div><FontAwesomeIcon icon={faDownload} /> {isMobile ? 'Download' : ''} </div>
                {/* <div style={{ width: '1px', backgroundColor: 'rgba(0, 0, 0, .1)', height: isMobile ? '0px' : '50px' }}></div>
                <div><FontAwesomeIcon icon={faDownload} /></div> */}

            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin:  isTablet || isMobile ? "8px" : '8px 0 24px 0', backgroundColor: 'white', border: `1px solid rgba(0, 0, 0, .1)`, padding: isTablet || isMobile ? '0px 16px' : '0px 24px', borderRadius: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <div style={{borderBottom: `4px solid blue`, padding: '10px 8px 2px 0px', fontSize: '18px', flexGrow: '1'}}>Summary</div>
                    {/* <div style={{borderBottom: `4px solid blue`, padding: '8px', fontSize: '18px', color: 'grey'}}>Analytics</div> */ }
                </div>
                <div style={{color: `grey`, margin: '0px 0px 0px 8px'}}>
                    Payment made: <span style={{color: `black`, fontWeight: 'bold'}}>{months[Number(date[1])-1]} {date[2]}, {date[0]} | {time[0]}:{time[1]} </span>{Number(time[0]) < 12 ? 'AM' : 'PM' }
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'space-between', gap: '16px' }}>
                <div style={{ width: !isMobile ? '50%' : '100%', backgroundColor: 'white', padding: isTablet || isMobile ? '16px' : '24px 32px', borderRadius: '10px', border: `1px solid rgba(0, 0, 0, .1)` }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid rgba(0, 0, 0, 0.2)',paddingBottom: '8px', marginBottom: '24px' }}>
                        <div style={{color: `grey`, fontSize: '18px'}}>Payment Information</div>
                        <div style={{color: `grey`, fontSize: '18px'}}>Product: <span style={{color: `blue`}}>Gateway</span></div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '24px' }}>
                        <div>
                            <div style={{color: `grey`,}}>Payment Method</div>
                            <div>Transfer</div>
                        </div>
                        <div>
                            <div style={{color: `grey`,}}>Bank</div>
                            <div>WEMA BANK</div>
                        </div>
                    </div>
                    <div>
                        <div style={{color: `grey`,}}>Account Number</div>
                        <div>081xxxx1675</div>
                    </div>
                </div>
                <div style={{ width: !isMobile ? '50%' : '100%', backgroundColor: 'white', padding: isTablet || isMobile ? '16px' : '24px 32px', borderRadius: '10px', border: `1px solid rgba(0, 0, 0, .1)` }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid rgba(0, 0, 0, 0.2)',paddingBottom: '8px', marginBottom: '24px' }}>
                        <div style={{color: `grey`, fontSize: '18px'}}>Customer Details</div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '8px',color: `grey`, fontSize: '18px' }}>
                            <div><FontAwesomeIcon icon={faExpand} /></div>
                            <div>Blacklist</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '24px' }}>
                        <div>
                            <div style={{color: `grey`,}}> Customer Name</div>
                            <div>{indLinkAcc.customerName}</div>
                        </div>
                        <div>
                            <div style={{color: `grey`,}}>Email Address</div>
                            <div>{indLinkAcc.email}</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {/* <div>
                            <div style={{color: `grey`,}}>Customer Risk Rating</div>
                            <div>--</div>
                        </div> */}
                        <div>
                            <div style={{color: `grey`,}}>Phone Number</div>
                            <div>{indLinkAcc.phone}</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}