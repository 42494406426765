import React, { createRef, useCallback, useEffect, useRef, useState } from 'react'
import Table from '../../../../components/designerTable';
import { error } from '../../../../utilities/appHelpers';
import { alpha, Box, Checkbox, darken, FormControlLabel, Typography } from '@mui/material';
import { defaultPageData, PaginationControl } from '../../../../components/PaginationControl';
import { paginationData } from '../../../../utilities/types';
import usePagination, { usePaginationInterface } from '../../../../customHooks/pagination';
import { STable } from '../../../../components/skeletonLoader';
import { Icon } from '@iconify/react';
import { useNavigate,useLocation } from 'react-router-dom';
import SimpleButton, { ButtonVariant } from '../../../../components/simpleButton';
import Dropdown from '../../../../components/Dropdown';
import { ROLES, SessionCheck, messasgeType, useGlobalState } from '../../../../pages/dashboard';
import { NetworkError } from '../../../../utilities/constant';
import useStyles from './styles';
import Popup from '../../../../components/popup';
import { useUserTheme } from '../../../../themes';
import { DeleteRoles, createNewRole, fetchPermissions, fetchRoles, getOneRole } from '../../../../services/userManagement';
import moment from 'moment';
import { uniqueId } from 'lodash';
import BrokenPage from '../../../../components/errorPage/brokenPage';
import img1 from './images/NoResult.png';
import Button from '../../../../components/button';
import Select from 'react-select';
import TextField from '../../../../components/textField';
import makeAnimated from 'react-select/animated';
import { ContentLoader } from '../..';
import { Aside } from '../../../../components/aside';


export default function Roles(props:any) {
    const classes = useStyles().classes;
    const {isMobile} = useUserTheme();
    const [RoleData, setRoleData] = useState<any>([]);
    const [loadRoles, setLoadRoles] = useState<number>(0);
    const [pageData, setPageData] = useState<paginationData>(defaultPageData);
    const pagination: usePaginationInterface = usePagination(pageData);
    const [controlPageData, setControlPageData] = useState<paginationData>(defaultPageData);
    const navigate = useNavigate();
    const [test,setTest] = useState<any>('nothing');
    const loc = useLocation();
    const [appState, setAppState] = useState({ loading: true,  msg: '', isMsg: false, status: false, count: 0, msgType: messasgeType.default });
    const [, setHintState] = useGlobalState("hint");

    const animatedComponents = makeAnimated();
    const {ADD_USER=false,EDIT_ROLE=false,EDIT_USER=false,DELETE_ROLE=false,DELETE_USER=false,ADD_ROLE=false,VIEW_ROLE=false,VIEW_USER=false} = ROLES.USER_MANAGEMENT_MODULE.privileges;

    useEffect(()=>{
        (async()=>{
            setAppState({...appState,loading:true});
            try {
                const resp = await fetchRoles();
                if(resp.status===200){
                    setRoleData(resp.data.data);
                    console.log(resp.data.data);
                    
                }
            } catch (e) {
                SessionCheck(e);
                setAppState({ ...appState, isMsg: true, msg: error.handle(e, NetworkError, true), count: appState.count + 1, msgType: messasgeType.network, loading: false });

            }finally{
                setAppState({...appState,loading:false});

            }
            
        })()
    },[loadRoles])


    useEffect(() => {
        setHintState(prev => ({ ...prev, status: appState.isMsg, msg: appState.msg, icon: appState.msgType }))
    }, [appState.count]);
    const [showRoleForm,setShowRoleForm] = useState(false);
    const [formData,setFormData] = useState<Record<string,any>>({roleName:'',permissions:[]});
    const resetForm=()=>setFormData({roleName:'',permissions:[]});
    const [selectedRole,setSelectedRole] = useState<any>();
    const [roleOptions,setRoleOptions] = useState<any>([]);
    const [loadingSideContent,setLoadingSideContent]=useState(true);
    const [contentLoader,setContentLoader]=useState(false);
    const [openSideContent,setOpenSideContent]=useState(false);
    const [requestState,setRequestState]=useState({loading:false,buttonText:'Create'});

const mainObj= useRef<Record<string,any>>({});
const fetchRole = useCallback(async(rid:string)=>{
    setOpenSideContent(true);
    setLoadingSideContent(true);
    try {
        const resp = await getOneRole(rid);
         if(resp.status===200){
            const resObj = resp.data.data?.permissions;
            // let mainObj: = ;
            // console.log(resObj);
            
            // resObj && resObj.forEach(({parentFeatureID}:Record<string,any>)=>{
            //     // Object.keys(obj).forEach(oldkey => {
            //     // });
            //         // let key = obj.parentFeatureID;
            //         // mainObj[key] = {};
            //         setMainObj({...mainObj,[parentFeatureID]:[]});
            //     //   if(!Object.hasOwn(mainObj.current,key)){
            //     //   }else{

            //     //   }
            //   })
              setSelectedRole(resObj);
              
              fetchAllRoles(true);
            }
    } catch (e) {
        SessionCheck(e);
        setAppState({ ...appState, isMsg: true, msg: error.handle(e, NetworkError, true), count: appState.count + 1, msgType: messasgeType.network, loading: false });
    }finally{
        setLoadingSideContent(false);
    }
},[])

const deleteRole = useCallback(async(rid:string)=>{
    setContentLoader(true)
    try {
        const resp = await DeleteRoles(rid);
         if(resp.status===200){
            setLoadRoles((p)=>p+1);
            setAppState({ ...appState, isMsg: true, msg: 'Succesfully deleted role', count: appState.count + 1, msgType: messasgeType.success, loading: false });

            }
    } catch (e) {
        SessionCheck(e);
        setAppState({ ...appState, isMsg: true, msg: error.handle(e, NetworkError, true), count: appState.count + 1, msgType: messasgeType.network, loading: false });
    }finally{
        setContentLoader(false);
    }
},[])


const fetchAllRoles = useCallback(async(forSideContent:boolean=false)=>{
   (!forSideContent) && setContentLoader(true);
    try {
        const resp = await fetchPermissions();
         if(resp.status===200){
           (forSideContent !== true)&& setShowRoleForm(true);
           
            const resObj = resp.data.data;
            if((forSideContent !== true && resObj)){
                resObj.forEach((obj:Record<string,any>)=>{
                    Object.keys(obj).forEach(oldkey => {
                    let value = obj[oldkey];
                    let newKey = (oldkey === 'featureID')?'value': (oldkey === 'featureName') ?'label' : oldkey;
                    
                    delete obj[oldkey];
                    obj[newKey] = value;
                    });
                })
                setRoleOptions(resObj);
            }else{
                resObj && resObj.forEach(({parentFeatureID}:Record<string,any>)=>{
                        if((!Object.hasOwn(mainObj.current,parentFeatureID)) && parentFeatureID!==null){
                          mainObj.current[parentFeatureID]=[];
                        }
                  })
                  let ar:Array<Record<string,any>> = [];
                  resObj.forEach((obj:Record<string,any>,i:number)=>{
                    let parent = obj.parentFeatureID;
                    if(parent!==null){
                        ar = mainObj.current[parent];
                        ar.push(obj);
                    }

                  })

                  let finalArray:Array<any> = [];
                  Object.keys(mainObj.current).forEach(oldkey => {
                    let value = mainObj.current[oldkey];
                    finalArray.push(value);
                    });

                    mainObj.current = finalArray;
                  setRoleOptions(mainObj.current);
            }
            
                
            }
    } catch (e) {
        console.log(e);
        
        SessionCheck(e);
        setAppState({ ...appState, isMsg: true, msg: error.handle(e, NetworkError, true), count: appState.count + 1, msgType: messasgeType.network, loading: false });
    }finally{
           (forSideContent) ? setLoadingSideContent(false):setContentLoader(false);
    }
},[])

useEffect(() => {
    if(loc.state?.showRoleForm){
        fetchAllRoles();
        
    }
},[]);

useEffect(() => {
    const selection = (Array.isArray(selectedRole))? selectedRole.map((obj:any)=> obj.value):[];
    (selection.length > 0 ) && setFormData({...formData,permissions:selection});    
}, [selectedRole]);

const checkObj = useRef<Record<string,any>>({});
const[checkMonitor,setCheckMonitor]=useState<Record<string,any>>({});

useEffect(() => {
    setCheckMonitor(checkObj.current);    
}, [roleOptions]);


    const createRole= useCallback(async()=>{
        setRequestState({ ...requestState,loading:true,buttonText:'Processing...'});
        try {
            const resp = await createNewRole(formData);
             if(resp.status===200){
                setLoadRoles((p)=>p+1);
                 setAppState({ ...appState, isMsg: true, msg:'Role Created Successfully', count: appState.count + 1, msgType: messasgeType.success, loading: false });
                 setShowRoleForm(false);
             }
            
        } catch (e) {
            SessionCheck(e);
            setAppState({ ...appState, isMsg: true, msg: error.handle(e, NetworkError, true), count: appState.count + 1, msgType: messasgeType.network, loading: false });
        }finally{
            setRequestState({...requestState,loading:false,buttonText:'Invite'});
        }

    },[formData.roleName,formData.permissions]);


    //CHECKBOX FUNCTIONS-------------------|
  

    const indeterminate = (parent:any)=>{
        parent=checkObj.current[parent];
        let ar = [];
       Object.keys(parent.children).forEach((item:any)=>{
            if(parent.children[item]===false){
                ar.push(item.name);
            }
       })
       return ar.length>0?true:false;
    }

    const checkParentItems = (parent:string)=>{
        checkObj.current[parent]['status'] = !checkObj.current[parent]['status'];
        setCheckMonitor(checkObj.current);
    }

    const checkItem = ({parent,item}:Record<string,any>)=>{
        checkObj.current[parent]['children'][item] = !checkObj.current[parent]['children'][item];
        checkObj.current[parent]['indeterminate'] = indeterminate(parent);
        setCheckMonitor(checkObj.current);
        setTest(checkMonitor['WALLET_MODULE']['children']['DELETE_WALLET']);
        

    }



    //CHECKBOX FUNCTIONS-------------------|











    return (
<div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-start', height:'100%',gap:'50px'}}>
<ContentLoader show={contentLoader}/>

        <div className="fadeIn animated faster" style={{width:'100%'}}>
            <div style={{ margin: "20px 0 10px", display: 'flex', justifyContent: 'space-between'}}>
                <div>

                <Typography component="h2" style={{ fontSize: 20, fontWeight: 600 }}>Roles</Typography>
                <Typography component="p" style={{}}>Track and manage your roles</Typography>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div></div>
                        <div style={{ width: '1px', height: '100%', backgroundColor: 'red' }}></div>
                        <div></div>
                        <div style={{ width: '1px', height: '100%', backgroundColor: 'red' }}></div>
                        {(!appState.loading && RoleData.length >0 &&  ADD_ROLE )&& <SimpleButton  onClick={fetchAllRoles}>
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center',gap:5}}>
                                <Icon icon="mingcute:add-fill" fontSize={15} /> <span>Create Role</span>
                            </div>
                        </SimpleButton> 
                        }
                    </div>

                </div>
            </div>
            {appState.loading && <STable style={{ marginTop: 20 }} />}

            {test}
           
                {!appState.loading && RoleData.length >0 && (<div style={{ margin: "20px 0" }}>
                    <Table fields={[{ name: "Roles", size: 20 }, { name: "Role ID", size: 20 }, { name: "Privileges", size: 40 },{ name: "Date Created", size: 15 },
                    (( EDIT_ROLE || DELETE_ROLE)&&{ name: "", size: 1 })]}
                        records={RoleData.length > 0 && RoleData.map((role: any, index: number) => {
                            return {
                                role: <Typography>{role.roleName}</Typography>,
                                roleId: role?.roleId??'R009'+uniqueId().toUpperCase(),
                                privilege: <div style={{display:'flex',gap:5, flexWrap:'wrap'}}>
                                    {
                                        role.permissions.map((r: { featureName: string; })=> <span style={{whiteSpace:'nowrap', backgroundColor:'#f2f2f2',borderRadius:3,padding:'4px 7px', fontWeight:500,color:'#000'}}>
                                            {r.featureName}</span>)
                                    }
                                    </div> ,
                                date: moment().format("DD-MM-YY"),
                                more: (EDIT_ROLE || DELETE_ROLE)?(<Dropdown style={{minWidth:120,borderRadius:10, backgroundColor:'#fff',color:'#000'}}
                                dropHandle={'button'}
                                dropHandleChildren={<button className={classes.ellipseBtn}> <Icon icon="pepicons-pop:dots-y" fontSize={18} /></button>}
                            >
                                {({ hideDrop }: any) => {
                                    return (
                                        <div onClick={() => { hideDrop() }} style={{ display: 'flex', flexDirection: 'column', gap: 2, fontSize: '12px', cursor: 'pointer', padding: '14px' }}>
                                            {EDIT_ROLE&&<div onClick={() => {
                                                //fetchRole(role?.roleId??'1'); uncomment this line to complete the 'Aside' Edit implementation
                                                return null
                                                }} className={classes.dropItem}> 
                                                <div className='items'>
                                                    <Icon icon="iconamoon:edit" fontSize={16} />
                                                    <span>Edit</span>
                                                </div> 
                                            </div>}
                                            {DELETE_ROLE&&<div onClick={() => deleteRole(role?.roleId??'1')} className={classes.dropItem}>
                                                <div className='items'>
                                                    <Icon icon="tabler:trash" fontSize={16} />
                                                    <span>Archive</span>
                                                </div>
                                            </div>}
                                        </div>
                                    );
                                }}
                            </Dropdown>):null
                            }
                        })} />
                </div>)}
                                
            {!appState.loading && RoleData.length < 1 &&
            <BrokenPage illustration={img1} onClick={fetchAllRoles} message={`No role created yet, Click the button bellow to start creating Roles`} buttonText='Create Role' showButton={ADD_ROLE}/>
                }
        </div>
        <Popup show={showRoleForm} showLogo={false} onPopChange={(stat:boolean)=>setShowRoleForm(stat)} onReset={resetForm}>
        <Typography  component={'h1'} style={{fontSize:'1.2rem', color:'rgb(91, 91, 91)', fontWeight:700,marginBottom:20}}>Create New Role</Typography>
  <div className={`${classes.accountForm}`}>
    <div className="form">

    <div className={`fadeInUp animated delay-0-1s`} style={{width:'100%'}}>
        <Typography  component={'div'} style={{fontSize:'1rem',color:'grey', fontWeight:600,marginBottom:-18}}>Name</Typography>
        <TextField type="text" placeholder='Role Name' style={{width:'100%'}} onChange={(e:any)=>setFormData({...formData,roleName:e.target.value})} value={formData.roleName}/>
    </div>

        <div className={`fadeInUp animated delay-0-2s`} style={{position:'relative',width:"100%",zIndex:2}}>
        <Typography  component={'div'} style={{fontSize:'1rem',color:'grey', fontWeight:600,marginBottom:0}}>Privileges</Typography>
        <Select
            //   closeMenuOnSelect={false}
              defaultValue={selectedRole}
              closeMenuOnSelect={false}
              onChange={setSelectedRole}
              options={roleOptions}
              isClearable={false}
              isSearchable={false}
              isMulti
              components={animatedComponents}
              />
          </div>

        <div className='btnCont' style={{marginTop:20}}>
            <Button className={`fadeInUp animated delay-0-3s`} text={'Cancel'} variant="secondary" onClick={()=>{setShowRoleForm(false)}} icon={ <Icon icon="iconamoon:sign-times-bold" fontSize={15} />}/> 

            <Button className={`fadeInUp animated delay-0-4s`} text={requestState.buttonText} variant="primary" disabled={requestState.loading} loading={requestState.loading} onClick={createRole} icon={<Icon icon='mingcute:send-fill'/>}/> 
            
        </div>
</div>

  </div>
</Popup>

{openSideContent&& (<Aside loading={loadingSideContent} onClose={(stat:boolean)=>setOpenSideContent(stat)}>
    

<div>
    {
        (Array.isArray(roleOptions) && roleOptions.length > 0) && roleOptions.map((r:Array<any>, i:number)=>{
            if(r[0].parentFeatureID !== 'ALL_MODULES' && r[0].parentFeatureName !== null){

          
          checkObj.current[r[0].parentFeatureID]={status:false,indeterminate:false,children:{}}
            return (
                <>

                <FormControlLabel
                label={r[0].parentFeatureName.toUpperCase()}
                control={
                    <Checkbox
                    checked={checkMonitor[r[0].parentFeatureID]['status']}
                    indeterminate={checkMonitor[r[0].parentFeatureID]['indeterminate']}
                    onChange={()=>checkParentItems(r[0].parentFeatureID)}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                    />
                }
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                    {
                        
                        r.map(({featureID,parentFeatureName,parentFeatureID,featureName}:Record<string,any>)=>{
                            checkObj.current[parentFeatureID]['children'][featureID]=true;
                            let ref = false;
                        return (<FormControlLabel
                        label={featureName}
                        control={<Checkbox checked={ref} onChange={(event)=>ref = !event.target.checked}
                         sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}/>}
                      />)
                    })
                    }
                </Box>
                </>
            )
          }
        })
    }
     
</div>

</Aside>)}
</div>
    );
}