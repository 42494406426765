import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import useStyles from './styles';

export interface IIconBtnProps {
  icon?: any,
  onToggle?:any,
  className?:string,
  style?:Record<string, any>
}

export default function IconBtn (props:any) {
  const {icon=<FontAwesomeIcon icon={faCog} />, onToggle= ()=>void(0), className="", style={}} = props
  const classes = useStyles().classes,[mob, setMob] = React.useState(false);
  
  React.useEffect(()=>{
   onToggle && onToggle(mob);   
  },[mob])
  
  return (
    <button className={`${classes.main} ${className}`} onClick={()=>setMob(!mob)} style={style}>
      {icon}
    </button>
  );
}
