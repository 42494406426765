import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme)=>({
    alertStyle:{
        display:'flex', justifyContent:'center', alignItems:'flex-start',
        width: '100%', height: '100%',
        boxSizing: 'border-box', position: 'fixed',
        zIndex: 1000, top: 0, left: 0, paddingTop:80,

        '& .alert_body':{
            // backgroundColor:theme.palette.background.paper,
            width:'calc(100% - 50px)', minHeight: 100, height:'auto', maxWidth:450,
            boxSizing: 'border-box', padding: '15px 18px', borderRadius:'5px', transition:' all 1s ease', position:'relative',

            '& .header':{
                width:'100%',
                '& .icon':{
                    margin:'auto', marginTop: "-50px",marginBottom:'10px', borderRadius:'50%',width:80,height:80, color:'#ffffff',
                    display:'flex',justifyContent:'center',alignItems:'center', fontSize:'2rem'
                },
                '& button:hover':{backgroundColor:'light'},
                '& button':{
                    padding:'5px 10px', background:'transparent', border:'none', borderRadius:4, color: 'rgb(206, 50, 45)', fontWeight:'600', fontSize:'1.1rem', position:'absolute',top:0, right:0, cursor:'pointer',
                }
            }
            // '& .content-box':{
            //     maxHeight:'512px', overflowY:'scroll',boxSizing:'border-box',
            // },
            
            
        },
        

    },

    shell:{
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    error:{
        backgroundColor: 'rgb(206, 50, 45)',
    },
    err_light:{
        backgroundColor: 'rgb(253, 245, 245)',
    },

    success:{
        backgroundColor: 'rgb(38, 200, 139)',
    },
    succ_light:{
        backgroundColor: 'rgb(239, 255, 249)',
    },

    warning:{
        backgroundColor: 'rgb(38, 132, 200)',
    },
    warn_light:{
        backgroundColor: 'rgb(241, 249, 255)',
    }

   
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;