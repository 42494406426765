import { useStyles } from "./styles";
import loader from "../../modules/dashboard/pages/business/images/loader.gif";
import React from "react";
export const Loader = (props:any) => {
    const classes = useStyles().classes;
  return (
    <div style={props.show?{display:"flex"}:{display:"none"}} className={classes.wrapper}>
      <div className={classes.loader}>
        <img src={loader} alt="" />
      </div>
    </div>
  );
};
