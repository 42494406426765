import {
  Typography} from "@mui/material";
import { useCallback, useEffect, useRef, useState, createRef} from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import OptionSwitch, { SwitchBtn } from "../../components/optionSwitch";
import ReturnBar from "../../components/returnBar";
import useStyles from "./styles";
import TextField from "../../../../components/textField";
import OptionCard from "../../../../components/optionCard";
import { currency, getAppStorage ,error, commarize2, sanitize, setAppStorage, commarize} from "../../../../utilities/appHelpers";
import mtn from "../../../../assets/images/mtn.svg.png";
import mobile9 from "../../../../assets/images/9mobile.png";
import glo from "../../../../assets/images/glo.png";
import airtel from "../../../../assets/images/airtel.png";
import Button from "../../../../components/button";
import { purchaseAirtime } from "../../../../services/billler";
import { ROLES, SessionCheck, messasgeType, useGlobalState } from "../../../../pages/dashboard";
import CustomCheckbox from "../../../../components/customCheckbox";
import Alert, { IType } from "../../../../components/alertBox";
import Data from "../data";
import React from "react";
import { getAllWallets } from "../../../../services/wallet";

export interface IAirtimeModProps {
  className?: string;
  style?: Record<string, any>;
}

export default function AirtimeMod(props: IAirtimeModProps) {
  const classes = useStyles().classes;
  const { className = "", style = {} } = props;
  const navigate = useNavigate();
  const toDashboard = () => {
    navigate("/dashboard");
  };

  const [phoneNumber,setPhoneNumber] = useState('');
  const [switchNum, setSwitchNum] = useState(0),
  [paymentParam, setPaymentParam]=useState({
      amount: '500',
      phone: "08130158069",
      billerId: "mtn",
      walletCode: "W380XX"
  });

  const billerID={
    AIRTEL: 'airtel',
    GLO:'glo', 
    MTN:'mtn',
    MOBILE9:'9mobile'
  }


  const [amount, setAmount] = useState("");
  const [appState, setAppState] = useState({status:false,msg:'', alertType:IType.warning, title:'Message', icon:messasgeType.default, count:1});
  const [selectedTelcom,setSelectedTelcom] = useState <Record <string, any>>({status:false, id:''}),[finishing, setFinishing]=useState({ text:'Recharge', icon:<Icon icon="eva:arrow-forward-fill"/>, loading:false});

    //new useEffects from here...


    useEffect(()=>{
      if(selectedTelcom.id.trim().length>0){

        setPaymentParam({...paymentParam,billerId:selectedTelcom.id});
      }
        
    },[selectedTelcom.id])

    
    const telNetwks = [
      {name:'MTN',logo:mtn,code:'',id:billerID.MTN},
      {name:'AIRTEL',logo:airtel,code:'',id:billerID.AIRTEL},
      {name:'9MOBILE',logo:mobile9,code:'',id:billerID.MOBILE9},
      {name:'GLO',logo:glo,code:'',id:billerID.GLO}
    ]

    const wrapper= useRef<any>(null);
    const numWrapper= useRef<any>(null);
    const c= useRef<any>(0);



  useEffect(() => {
    c.current+=1
    const l = wrapper.current && (wrapper.current as HTMLElement).querySelectorAll('label')[0];
    const elm = l&& l.querySelector('input[type=radio]');
    l&&l.classList.add('selectedwallet101');
     if(elm){
       elm.checked = true;
      }
      const w = getAppStorage('WALLETS')[0];
     w && setPaymentParam({...paymentParam,walletCode:w.walletCode})
     
     
  },[switchNum,wrapper.current]);

  //proceed to making payments
  const fetchUpdatedWallet = async()=>{
    try {
      const resp = await getAllWallets();
      if(resp.status===200){
        setUserUpdatedWallet(resp.data.data);
      }
    } catch (e) {
      let info =  error.handle(e,"You've lost Internet connection",true);
        setAppState({...appState, alertType:IType.error, title:'Error', status:true, msg:info.toLowerCase().includes('internal error')?'...oops! something went wrong, please try again later':info, icon:messasgeType.failed,count:appState.count+1});
        SessionCheck(e);
    }
    
  }
 
  const sendRecharge = useCallback(async()=>{
   
    setFinishing({...finishing, text:'Please wait...', icon:<Icon icon="humbleicons:dots-horizontal"/>, loading:true});
    try {
      const resp = await purchaseAirtime(paymentParam);
      if(resp.status===200 || resp.status===201){
        fetchUpdatedWallet();
        setAppState({...appState, status:true, title:'Success', alertType:IType.success, msg:resp.data.message, icon:messasgeType.success, count:appState.count+1})
        setFinishing({...finishing, text:'Recharge', icon:<Icon icon="eva:arrow-forward-fill"/>, loading:false});
        setPhoneNumber(''); setAmount('');
      }
    } catch (er) {
      setFinishing({...finishing, text:'Recharge', icon:<Icon icon="eva:arrow-forward-fill"/>, loading:false});
      setAppState({...appState, alertType:IType.error, title:'Error', status:true, msg:error.handle(er,"You've lost Internet connection",true), icon:messasgeType.failed,count:appState.count+1});
      SessionCheck(er);

    }
    

  },[paymentParam.amount, paymentParam.walletCode, paymentParam.phone])

  const handleChange = (e:any, wallet:string, btn:HTMLLabelElement)=>{
    const el = wrapper.current && (wrapper.current as HTMLElement).querySelector('.selectedwallet101');
    if(el){

      if(el !== btn){
        el.classList.remove('selectedwallet101');
        btn.classList.add('selectedwallet101');
      }
    }else{btn.classList.add('selectedwallet101');}
    setPaymentParam({...paymentParam,walletCode:wallet});

  }
   
    useEffect(()=>{
      if(amount.trim().length > 1){
       return setPaymentParam({...paymentParam,amount:amount});
      }
      if(phoneNumber.trim()!.length >= 11){
      return setPaymentParam({...paymentParam,phone:phoneNumber});
      }
  },[amount, phoneNumber])


  
  
  function handleNetworkChange(e: MouseEvent, id: string, ref:any):void {
      setSelectedTelcom({...selectedTelcom,status:true,id:id});
      const btn = numWrapper.current && (numWrapper.current as HTMLElement).querySelector('.telcom-selected101');
      if(btn){
        if(ref && btn !== ref){
          btn.classList.remove('telcom-selected101');
          ref.classList.add('telcom-selected101');
        }
        
      }else{        
        ref && ref.classList.add('telcom-selected101');
      }
         
    }

    const [userUpdatedWallet, setUserUpdatedWallet]=useState(null);
    const [userWallet, setUserWallet]=useState<any>(null);

    useEffect(()=>{
      if(userUpdatedWallet)setAppStorage('WALLETS',userUpdatedWallet);
      setUserWallet(getAppStorage('WALLETS'));
    },[userUpdatedWallet])

    
    

  return (
    <div>
    <Alert type={appState.alertType} title={appState.title} show={appState.status} onClose={(stat: boolean) => setAppState({...appState,status:stat})} message={appState.msg} />

    <div>
      <div className={`${className} ${classes.main}`} style={{ ...style }}>
        <ReturnBar
          title="DashBoard"
          onClick={() => {
            toDashboard();
          } }
        >
          {/* <button>filter</button> */}
        </ReturnBar>
        <Typography
          component="h2"
          style={{ margin: "20px 0 10px", fontSize: 20, fontWeight: 600 }}
        >
          Buy Airtime/Data
        </Typography>
        <OptionSwitch>
          <SwitchBtn
            title="Airtime"
            className={`${switchNum === 0 ? "active" : ""}`}
            style={{ width: "50%" }}
            onClick={() => {
              switchNum !== 0 && setSwitchNum(0);
            } } />
          <SwitchBtn
            title="Data"
            className={`${switchNum === 1 ? "active" : ""}`}
            style={{ width: "50%" }}
            onClick={() => {
              switchNum !== 1 && setSwitchNum(1);
            } } />
        </OptionSwitch>

        <div>
          {switchNum === 0 && (
            <>


              <div>
                <Typography component="h3" className={`${classes.smallTitle}`}>
                  Which wallet would you like to use for the purchase?
                </Typography>
                <div ref={wrapper} className={`${classes.walletchecker} scrollbar-transparent`}>
                  {userWallet&&userWallet.map((w: Record<string, any>, i: number) => {
                    let wRef = createRef<HTMLLabelElement>();
                    //@ts-ignore
                    return <CustomCheckbox title={w.walletName} className={`slideInRight animated delay-0-${i}s`} ref={wRef} isSelected={i === 0} onChange={(e: any) => handleChange(e, w.walletCode, wRef.current)} txt1={w.accNo} txt2={currency.naira + commarize(w.bal)} id={'wallet' + i} key={i} />;
                    
                  }
                  )}
                </div>
                <Typography component="h3" className={`${classes.smallTitle} fadeInUp animated delay-0-4s`}>
                  Please select a network
                </Typography>
                <div className={classes.telnets} ref={numWrapper}>
                  {telNetwks && telNetwks.map((t: Record<string, any>, i: number) => {
                    let ref = createRef<HTMLButtonElement>();
                    return <OptionCard ref={ref} key={i} style={{ padding: '10px 20px', gap: 5, flexDirection: 'column' }}
                      className={`fadeInUp animated delay-0-${i}s`}
                      content={{
                        logo: <img src={t.logo} alt="telco" />,
                        title: (
                          <div style={{ fontSize: "0.8em" }}>
                            <div>{t.name}</div>
                          </div>
                          //@ts-ignore
                        )
                      }} onClick={(e: MouseEvent) => handleNetworkChange(e, t.id, ref!.current)} />;
                  }
                  )}
                </div>
              </div>

              {selectedTelcom.status && (
                <>
                  <Typography component="h3" style={{ marginBottom: -20 }} className={`${classes.smallTitle}`}>
                    Enter Phone Number
                  </Typography>
                  <div style={{ padding: '10px', marginBottom: '10px' }}>

                    <TextField
                      className={`animated faster slideInDown`}
                      placeholder={"Enter Mobile number"}
                      onChange={(e: any) => setPhoneNumber(sanitize(e.target.value))}
                      value={phoneNumber} />


                  </div>
                </>
              )}

              {phoneNumber.trim().length > 10 ?
                (<>
                  <div className={classes.gridCont}>
                    <div className="custom-amt card-1" onClick={() => setAmount('100')}>{currency.naira} 100.00</div>
                    <div className="custom-amt card-1" onClick={() => setAmount('500')}>{currency.naira} 500.00</div>
                    <div className="custom-amt card-1" onClick={() => setAmount('1000')}>{currency.naira} 1,000.00</div>
                    <div className="custom-amt card-1" onClick={() => setAmount('2000')}>{currency.naira} 2,000.00</div>
                  </div>
                  <div style={{ padding: '0 10px' }}>
                    <TextField currency={currency.naira} className={`animated faster slideInDown`} placeholder={"Amount"} onChange={(e: any) => setAmount(e.target.value)} value={commarize2(sanitize(amount))} />
                  </div>

                </>



                ) : []}

              {amount !== "" && (

                <div className={classes.cta}>
                  {(ROLES?.UTILITIES_MODULE?.privileges?.PURCHASE_AIRTIME)&&<Button style={{ fontWeight: 800, margin: "30px 0" }} text={finishing.text} icon={finishing.icon} loading={finishing.loading} disabled={finishing.loading} onClick={sendRecharge} />}
                </div>
              )}

            </>

          )}

          {
            // Other pages begins
            switchNum === 1 && (
             <Data/>
            )}


        </div>
      </div>
    </div>
    </div>
    // </>
  );
}
