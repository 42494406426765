import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme)=>({
        line:{
            borderTop: 'rgba(255, 255, 255, 0.35) thin solid',
            borderBottom: 'rgba(0, 0, 0, 0.15) thin solid',
            width:'100%', height:1, marginTop:10,
        },
        lineV:{
            borderLeft: 'rgba(255, 255, 255, 0.35) thin solid',
            borderRight: 'rgba(0, 0, 0, 0.15) thin solid',
            width: 1,boxSizing: 'border-box', height:'100%'
        }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;