import * as React from "react";
import useStyles from "./styles";

export const SWallet=()=> {
  const classes = useStyles().classes;
  return (
    <div className={`${classes.main} slideInLeft animated faster`}>
      <div className={`${classes.first}`}>
        <div className={`${classes.info}`}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={`${classes.iconCont}`}></div>
      </div>

      <div className={`${classes.last}`}>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export const STable = ({style={}})=>{
  const classes = useStyles().classes;
  return(
      <div style={style} className={`${classes.tableCont}`}>
        <div className="th">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div className="tr">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
      </div>
  );
}

export const SPaymentCard = ({cards=1})=>{
  const style = useStyles().classes;
  return(
    <div className={`${style.paymentCardCont}`}>
   {cards && [...Array(cards).keys()].map((c:any,i:number)=><div key={i} >
      <div className={`first`}>
        <div className="circle"></div>
        <div className="det">
          <div></div>
          <div></div>
        </div>
      </div>

      <div className="second">
        <div></div>
        <div></div>
      </div>
    </div>)}
   </div> 
  );
}

export const SChecker = ({cards=1})=>{
  const style = useStyles().classes;
  return(
    <div className={`${style.checker}`}>
   {cards && [...Array(cards).keys()].map((c:any,i:number)=>
   <div key={i} className="ch border">
      <div className={`text blink`}></div>
      <div className={`line`}></div>
    </div>
    
    )}
   </div> 
  );
}
