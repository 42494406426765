import React, { useEffect, useState } from 'react';
import SimpleButton, { ButtonVariant } from '../../../../components/simpleButton';
import { useNavigate, useParams } from 'react-router-dom';
import ReturnBar from '../../components/returnBar';
import { InputLabel, Select, MenuItem, Typography } from "@mui/material";
import useStyles from "../business/styles";
import TextField from '../../../../components/textField';
import CheckBox from '../../../../components/checkBox';
import { error, getAppStorage } from '../../../../utilities/appHelpers';
import { SessionCheck, dispatch, messasgeType, useGlobalState } from '../../../../pages/dashboard';
import { createLink, editLink, getLinkById } from '../../../../services/paymentLink';
import { trim } from 'lodash';
import { NetworkError } from '../../../../utilities/constant';
import { css } from '@emotion/css';
import { useUserTheme } from '../../../../themes';

export interface newLinkI {
    name?: string;
    descr?: string;
    amount: string;
    callbackUrl?: string;
    walletId?: number;
    chargeFrom: string;
    accountCode?: string;
    currency: string;
    dateCreated?: string;
}


export default function CreateLink() {
    const navigate = useNavigate();
    const classes = useStyles().classes;
    const {isDesktop} = useUserTheme();
    const params = useParams();
    const [focusOn, setFocusOn] = useState({
        amount: false,
        wallet: false,
        // chargeFrom: false
    });

    const [isWallet, setIsWallet] = useState(true);
    const wallets = getAppStorage('WALLETS');

    const [appState, setAppState] = useState({ loading: false, res: [], msg: '', isMsg: false, status: false, count: 0, msgType: messasgeType.default });
    const [, setHintState] = useGlobalState("hint");

    const [linkData, setLinkData] = useState<newLinkI>({
        name: '',
        descr: '',
        amount: '',
        callbackUrl: window.location.origin+'/finish',
        walletId: 0,
        chargeFrom: 'Customer',
        accountCode: '269qyt',
        currency: 'NGN'
    });
    // const chargeFrom =[
    //     {name: 'Business', id: 1},
    //     {name: 'Customer', id: 2}
    // ];


    const CreateNEditLink = async () => {
        try {
            setAppState(prev => ({ ...prev, loading: true }));
            if (trim(linkData.name) === '' && !params.id) {
                setAppState(prev => ({ ...prev, loading: false, msg: 'Please provide a link name', isMsg: true, count: prev.count + 1 }));
                return;
            }
            // if (trim(linkData.callbackUrl) === '') {
            //     setAppState(prev => ({ ...prev, loading: false, msg: 'Please provide a callback URL', isMsg: true, count: prev.count + 1 }));
            //     return;
            // }
            if (trim(linkData.amount) === '') {
                setAppState(prev => ({ ...prev, loading: false, msg: 'Please set the amount for this link', isMsg: true, count: prev.count + 1 }));
                return;
            }
            if (trim(linkData.currency) === '') {
                setAppState(prev => ({ ...prev, loading: false, msg: 'Please choose a currency', isMsg: true, count: prev.count + 1 }));
                return;
            }
            if (!linkData.walletId) {
                setAppState(prev => ({ ...prev, loading: false, msg: 'Please select a wallet', isMsg: true, count: prev.count + 1 }));
                return;
            }

            let resp: any;
            if (params.id) {
                const editData: newLinkI = {
                    descr: linkData.descr,
                    amount: linkData.amount,
                    callbackUrl: linkData.callbackUrl,
                    walletId: linkData.walletId,
                    chargeFrom: linkData.chargeFrom,
                    accountCode: linkData.accountCode,
                    currency: linkData.currency
                }
                resp = await editLink(editData, params.id);
            } else {
                resp = await createLink(linkData);
            }

            if (resp.status === 201 || resp.status === 200) {
                setAppState(prev => ({ ...prev, loading: false, msg: resp.data.message, status: resp.data.status, isMsg: true, count: prev.count + 1 }));
                navigate('/dashboard/payment');
            }
        } catch (e: any) {
            SessionCheck(e);
            setAppState(prev => ({ ...prev, msg: error.handle(e, NetworkError, true), isMsg: true, count: prev.count + 1, loading: false }));
        }
    }

    useEffect(() => {
        (async () => {
            try {
                if (params.id) {
                    setAppState(prev => ({ ...prev, loading: true }));
                    const id = params.id ? params.id : '';
                    const resp = await getLinkById(id);
                    if (resp.status === 200) {
                        const linkDets = resp?.data?.data;                        
                        setLinkData(prev => ({...prev, 
                            amount: linkDets.amount,
                            descr: linkDets.descr,
                            chargeFrom: linkDets.chargeFrom,
                            name: linkDets.name,
                            currency: linkDets.currency,
                            callbackUrl: linkDets.callbackUrl
                        }));
                        setFocusOn(prev => ({ ...prev, amount: true }));
                    }
                }
                setAppState(prev => ({ ...prev, loading: false }));
            } catch (e: any) {
                SessionCheck(e);
                setAppState({ ...appState, isMsg: true, msg: error.handle(e, "Failed to fetch required Data, please check your network and try again", true), count: appState.count + 1, msgType: messasgeType.network, loading: false });
            }
        })();
    }, []);

    useEffect(() => {
        setHintState(prev => ({ ...prev, status: appState.isMsg, msg: appState.msg, icon: appState.msgType }))
    }, [appState.count]);

    const removeHoverShadow = css({
        "&:hover": {
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)'
        }
    })

    return (
        <div>
            <div className="fadeInUp animated faster">
                <ReturnBar title="Back to Payment Links Overview" onClick={() => { navigate("/dashboard/payment"); }} style={{ width: "fit-content" }} />
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: "20px 0 20px" }}>
                    <Typography component="h2" style={{ fontSize: 20, fontWeight: 600, }}>{!params.id ? "Create New Link" : "Edit Link"}</Typography>
                    <div style={{ display: 'flex', gap: '8px' }}>
                        <SimpleButton variant={ButtonVariant.white} onClick={() => { navigate("/dashboard/payment"); }
                        }>
                            <Typography component="span">Cancel</Typography>
                        </SimpleButton>
                        <SimpleButton disabled={appState.loading} variant={ButtonVariant.alt} onClick={() => { CreateNEditLink(); }
                        }>
                            <Typography component="span">Save {params.id ? '' : 'Link'}</Typography>
                        </SimpleButton>
                    </div>
                </div>
            </div>

            <div className="fadeIn animated faster" style={{ display: 'flex', width: isDesktop ? '50%' : '100%'}}>
                <div style={{ width: "100%" }}>
                    {/* <div style={{ width: "100%", marginBottom: '16px', backgroundColor: 'white', padding: '24px' }}>
                        <Typography style={{ fontSize: 14, fontWeight: 550, marginBottom: '5px' }}>Charge from</Typography>
                        <div style={{ display: 'flex', alignItems: 'center', position: 'relative', width: "100%", boxSizing: "border-box", }}>
                            <InputLabel className={`${classes.inputLabel} ${focusOn.chargeFrom ? 'ff' : ''}`} style={{ zIndex: 5, paddingLeft: '8px', top: '50%', boxSizing: "border-box", }}>Charge from...</InputLabel>
                            <Select labelId="business-type" className={`${classes.inputElement} card-1`} style={{ backgroundColor: "#fff", borderRadius: 4, }} onChange={(e: any) => { setLinkData(prev => {
                                return { ...prev, chargeFrom: e.target.value }
                            }); setFocusOn(prev => ({ ...prev, chargeFrom: e.target.value !== "" ? true : false })); }} value={linkData.chargeFrom} >
                                {
                                    chargeFrom.map((option: any, index: number) => <MenuItem key={index} value={option.name}><Typography>{option.name}</Typography></MenuItem>)
                                }
                            </Select>
                        </div>
                    </div>  */}
                    <div className="fadeIn animated delay-0-5s" style={{ display: 'flex', flexDirection: 'column', gap: '16px', backgroundColor: 'white', padding: '24px' }}>
                        {!params.id && <div>
                            <Typography style={{ fontSize: 14, fontWeight: 550, marginBottom: '5px' }}>Payment Link Name</Typography>
                            <TextField onInput={(e: any) => setLinkData(prev => {
                                return { ...prev, name: e.target.value }
                            })} placeholder="Link Name" value={linkData.name} style={{ marginTop: '0px' }} />
                        </div>}
                        {/* <div>
                            <Typography style={{ fontSize: 14, fontWeight: 550, marginBottom: '5px' }}>Callback URL</Typography>
                            <TextField onInput={(e: any) => setLinkData(prev => {
                                return { ...prev, callbackUrl: e.target.value }
                            })} placeholder="Callback URL" value={linkData.callbackUrl} style={{ marginTop: '0px' }} />
                        </div> */}
                        <div style={{ /* borderBottom: '1px solid rgba(0, 0, 0, 0.2)', */ paddingBottom: '16px', }}>
                            <Typography style={{ fontSize: 14, fontWeight: 550, marginBottom: '5px' }}>Amount</Typography>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                {/* <div style={{ position: 'relative', boxSizing: "border-box", }}>
                                    <InputLabel className={`${classes.inputLabel} ${focusOn.amount ? 'ff' : ''}`} style={{ zIndex: 5, paddingLeft: '8px', top: '50%', boxSizing: "border-box", }}>NGN</InputLabel>
                                    <Select labelId="amount" className={`${classes.inputElement} card-1`} style={{ backgroundColor: "#fff", borderRadius: 4, }} onChange={(e: any) => {
                                        setFocusOn(prev => ({ ...prev, amount: e.target.value !== "" ? true : false }));
                                        setLinkData(prev => {
                                            return { ...prev, currency: e.target.value }
                                        })
                                    }} value={linkData.currency} >
                                        {
                                            [{ type: 'NGN', id: 1 }].map((option: any, index: number) => <MenuItem key={index} value={option.type}><Typography>{option.type}</Typography></MenuItem>)
                                        }
                                    </Select>
                                </div> */}
                                {/* <div>NGN</div> */}
                                <TextField className={removeHoverShadow} style={{ width: '56px', marginTop: '0px', cursor: 'pointer' }} value={linkData.currency} />
                                <TextField style={{ flexGrow: '1', marginTop: '0px' }} onInput={(e: any) => {
                                    setLinkData(prev => {
                                        return { ...prev, amount: e.target.value }
                                    })
                                }} placeholder="Amount" value={linkData.amount} />
                            </div>

                        </div>
                        <div style={{ /* borderBottom: '1px solid rgba(0, 0, 0, 0.2)', */ paddingBottom: '16px', }}>
                            <Typography style={{ fontSize: 14, fontWeight: 600 }}>Wallet</Typography>
                            {/* {isWallet && */}
                                <div style={{ width: "100%", backgroundColor: 'white', margin: '8px 0px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', position: 'relative', width: "100%", boxSizing: "border-box", }}>
                                        <InputLabel className={`${classes.inputLabel} ${focusOn.wallet ? 'ff' : ''}`} style={{ zIndex: 5, paddingLeft: '8px', top: '50%', boxSizing: "border-box", }}>Select wallet...</InputLabel>
                                        <Select labelId="business-type" className={`${classes.inputElement} card-1`} style={{ backgroundColor: "#fff", borderRadius: 4, }} onChange={(e: any) => {
                                            setFocusOn(prev => ({ ...prev, wallet: e.target.value !== "" ? true : false }));
                                            setLinkData(prev => ({ ...prev, walletId: e.target.value }));
                                        }} value={linkData.walletId} >
                                            {
                                                wallets.map((option: any, index: number) => <MenuItem key={index} value={option.wRecId}><Typography>{option.accNo}</Typography></MenuItem>)
                                            }
                                        </Select>
                                    </div>
                                </div>
                            {/* } */}
                            {/* <label style={{ display: 'flex', alignItems: 'center', gap: '24px', marginTop: '16px' }}>
                                <CheckBox onChange={(e: any) => {
                                    setIsWallet(e.target.checked);
                                }} style={{ width: 'unset', marginTop: '0px', }} isChecked={isWallet} />
                                <div>Attach service code to payment link</div>
                            </label> */}
                        </div>
                        <div>
                            <Typography style={{ fontSize: 14, fontWeight: 550, marginBottom: '5px' }}>Description</Typography>
                            <TextField  type="textarea" onInput={(e: any) => setLinkData(prev => {
                                return { ...prev, descr: e.target.value }
                            })} placeholder="Describe this link" value={linkData.descr} style={{ marginTop: '0px', height: '80px' }} />
                        </div>
                    </div>

                </div>
                <div></div>
            </div>

        </div>
    );
}