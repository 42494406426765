import { alpha, Theme, lighten } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  optionCard: {
    display: "flex",
    width: "auto",
    height: "auto",
    gap: "20px",
    backgroundColor: alpha(theme.palette.secondary.contrastText, 0.5),
    color: "#444",
    borderRadius: "4px",
    padding: "15px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "left",
    cursor: "pointer",
    outline: "none",
    border: "none",
    margin: "15px",
    flexWrap: "wrap",
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main,0.2),
      color: "#fff",
      transition: "background 0.2s ease-in",
    },
    [theme.breakpoints.down('sm')]:{
     padding:'5px !important', margin:10
    }
  },
  optionCardIcon: {
    fontSize: "2em",
    padding: "25px",
    borderRadius: "50%",
    border: `1px solid ${theme.palette.background.paper}`,
    color: theme.palette.secondary.main,
    position: "relative", width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& svg": {
      width: "35px",
      height: '35px',
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)"
    },
    overflow: 'hidden',
    " & img": {
      width: "60px",
      height: 'auto', objectFit: 'cover',
    }
  },
  optionCardHeader: {
    fontSize: "1.2em",
    margin: 0,
    marginBottom: "5px",
    fontWeight: 600, 
    [theme.breakpoints.down('sm')]:{
      display:'none'
    }
  },
  optionCardText: {
    fontSize: "0.8em"
  },
  "selected": {
    backgroundColor: lighten(theme.palette.primary.main,0.2),
    color: "#fff",
    transition: "background 0.2s ease-in",
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;