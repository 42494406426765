import { Theme } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  main: {
    display: "flex", alignItems: "center",
    boxSizing: "border-box", 
    "& *": {
      boxSizing: "inherit"
    },
  },
  imgWrapper: {
    display: "flex", alignItems: "center", width: 15, height: "100%", borderRadius:8,backgroundColor:"#fff",position:'relative',
    "& img": {
      margin: 0, width: "100%", height: "100%", objectFit: "cover", objectPosition: "center"
    },
    "& .status": {
      width:12,height:12, borderRadius:50, position:'absolute', bottom:-1,right:-1,barckground:'#ffad53', border:'2px solid #fff',
    },
    "& .offline": {
      background:'darkgrey',
    },
    "& .online": {
       background:'#32b632',
    }
  },
  textWrapper: {
    margin: "0 8px"
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;