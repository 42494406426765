import { lighten } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'inputLabel' | 'errorDisplay'>()((theme, _params, classes) => ({
  main: {
    width: "100%",
    borderRadius: "4px",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  icon: {
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.background.paper,
    margin: "0 10px",
    padding: "5px",
    "& svg": {
      width: "100%",
      height: "100%",
      color: lighten(theme.palette.primary.main, 0.2),
    }
  },
  textFieldBox: {
    boxSizing: "border-box",
    padding: "8px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    position: "relative",
    marginTop: "18px",
    width: "100%",
    "& *": {
      boxSizing: "border-box",
    },
  },
  inputLabel: {
    position: "absolute",
    zIndex: 1,
    top: "60%",
    transform: "translateY(-50%)",
    cursor: "text",
    display: "block",
    border: "none",
    color: '#222',
    backgroundColor: "transparent",
    fontWeight: "bold",
    outline: "none",
    borderRadius: "4px",
    fontSize: "0.8em",
    padding: "0px 12px",
    transition: "all 0.4s ease-in",
    "&.ff": {
      top: "0",
      color: "#fff",
      transform: "translate(10%,100%)",
      backgroundColor: "rgba(0, 155, 178, 0.7)",
      borderRadius: "4px",
      fontSize: "0.8em",
      padding: "0px 4px",
      transition: "all 0.4s ease-in"
    }
  },
  innerWrapper: {
    position: "relative",
    width: "100%",
    margin: 0,
    padding: 0,
    backgroundColor: 'inherit'
  },
  inputElement: {
    display: "block",
    width: "100%",
    color: "#000",
    border: "none",
    fontSize: "1.1em",
    padding: "2px 12px",
    fontWeight: "bold",
    outline: "none",
    position: "relative",
    fontFamily: 'Raleway, Montserrat, Source Sans Pro, sans-serif',
    zIndex: 2,
    lineHeight: '1.5',
    backgroundColor: "transparent",
    transition: "all 0.2s ease-in",
    textAlign: "left",
    [`&.focused ~ .${classes.inputLabel}, &.focused.textarea ~ .${classes.inputLabel}`]: {
      top: "0",
      color: "#fff",
      transform: "translateY(-100%)",
      backgroundColor: "rgba(0, 155, 178, 0.7)",
      borderRadius: "4px",
      fontSize: "0.8em",
      padding: "0px 4px",
      transition: "all 0.4s ease-in",
    },
    [`&.input-error ~ .${classes.errorDisplay}`]: {
      animation: 'errortbblinking 2s ease-in-out infinite',
      content: "''",
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      boxSizing: 'border-box',
      top: '5px',
      right: '5px',
      position: 'absolute',
    },
    [`&.textarea ~ .${classes.inputLabel}`]: {
      top: "20%"
    },
    "& *, & *::before, & *::after, &:hover *, &:hover *::before, &:hover *::after, &::before, &::after, &:hover, &:hover::before, &:hover::after":{
      border: "none !important"
    }
  },
  errorDisplay: {
    display: "none",
  },
  visibilityControlIcon: {
    position: "relative",
    display: 'block',
    width: '30px',
    cursor: "pointer",
    backgroundColor: "#fff",
    margin: "2px",
    zIndex: 3
  },
  logoWrapper: {
    position: "absolute",
    display: "flex",
    width: "auto",
    padding: "0 5px",
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto 0px',
    backgroundColor: "transparent"
  },
  verifyLogo: {
    position: "relative",
    display: 'block',
    width: '30px',
    cursor: "pointer",
    backgroundColor: "#fff",
    margin: "2px",
    zIndex: 3
  },

  imgCont:{
    width:250,height:250,overflow:"hidden", marginLeft:"50% !important",  transform:"translateX(-50%)",
    "& img":{
      width:"100%"
    }
  },
  navWrapper:{"&":{
    display: 'flex'

    },

    "& > div:last-child button:last-of-type span":{
      fontWeight:"bolder", fontSize:"0.9rem",
    },
     
    "& > div:last-child button svg":{
      visibility:"hidden",
     
    }
  },

}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;