import * as React from 'react';
import DashboardMod from '../../modules/dashboard';
import {createGlobalState, createStore} from 'react-hooks-global-state';
import Popup from '../../components/popup';
import TextField from '../../components/textField';
import Button from '../../components/button';
import time1 from "../../assets/images/time.png"
import time2 from "../../assets/images/time-nobg.png"
import { useStyles } from './styles';
import { Icon } from '@iconify/react';
import { error, getAppStorage, setAppStorage } from '../../utilities/appHelpers';
import { Login } from '../../services/login';
import Hint from '../../components/hintBox';
import useSessionTimeout from '../../customHooks/useSessionTimeout';
import { NetworkError } from '../../utilities/constant';
import PasswordField from '../../components/passwordField';
import { useLocation } from 'react-router-dom';

export enum messasgeType{
    default = 'clarity:warning-solid',
    network = 'fluent:cellular-warning-24-filled', //fluent:wifi-warning-24-regular
    session = 'ic:outline-desktop-access-disabled', //ic:outline-desktop-access-disabled
    notFound = 'ooui:article-not-found-rtl',
    success = 'clarity:success-line', //clarity:success-standard-solid
    failed = 'ep:failed'
}
export const SessionCheck = (e:any)=>{
  if(!e)return ;
  
  if(e?.response?.status===401){
    dispatch({type:'show'});
  }
}
export let ROLES:any = {};

export enum icon{
  success='akar-icons:check',
  info="clarity:info-circle-solid",
  error='humbleicons:times',
  redirect="ooui:article-redirect-rtl"
}
//global useState pattern - anables dynamic values
//initaialState
const hint = {
    hint:{ 
      status:false,
      msg:'',
      icon:messasgeType.default
    }
}
export const {setGlobalState, useGlobalState,getGlobalState} = createGlobalState(hint)

//global state using Reducer function pattern - static value
const reducer = (state:any, action:any) => {
  switch (action.type) {
    case 'show': return { ...state, status: state.status = true };
    case 'hide': return { ...state, status: state.status = false };
    default: return state;
  }
};
export const { dispatch, useStoreState } = createStore(reducer,{status:false});



export default function Dashboard () {
  const style = useStyles().classes;
  const [pwdValue, setPwdValue]=React.useState('');
  const [, setState]=React.useState<any>();
  const [appState, setAppState]=React.useState({btnText:'Continue',count:0,msg:'',showHint:false,loading:false,icon:'mdi:lock', hintIcon:messasgeType.default});
  const [userDet, setUserDet]=React.useState<Record<string,any>>({})

  const {isIdle,setIdle} = useSessionTimeout();
  const [lock,setLock] = React.useState(false);
  const loc = useLocation();

  const [{status,msg,icon},setHint] = useGlobalState('hint');
  const t = React.useRef<any>();
  React.useEffect(()=>{
    t.current = setInterval(()=>{
      let st= getAppStorage('sessionExp');
    if(st===true){
        setLock(true);
    }else{
      setLock(false);
    }
    
  },300);
  
    if(pwdValue.length>0){
      setPwdValue('');
    }
        
    
    return ()=>clearInterval(t.current);
  },[isIdle])

    const updateState=()=>setState({});

    const handleSubmittion = React.useCallback(async()=>{
      if(pwdValue.trim().length<1)return;
      setAppState({...appState,loading:true,showHint:false, msg:'',btnText:'Authenticating...', icon:'prime:unlock'});
      try {
        const resp = await Login({password:pwdValue, username:userDet.email});
        if(resp.status===200){
          if(resp.data.data?.roles){
            setAppStorage('ROLES',resp.data.data.roles);
          }
          setAppState({...appState,loading:false,btnText:'Continue', icon:'mdi:lock'});  
          //localstorage
          setAppStorage("DTECHPAY_TOKEN",resp.data.data.token);
          setAppStorage('DTECHPAY_USERID',resp.data.data.uniqueId);
          updateState();
          setAppStorage('USER_DETAILS',resp.data.data);
          setIdle(false);
          setAppStorage('sessionExp',false);
          
          dispatch({type:'hide'});
        }
        
      } catch (e) {
        let text = error.handle(e,NetworkError,true) || '';
        text = text.toLowerCase().includes('invalid username/email and password')?'Incorrect Password':text;

        setAppState({...appState,loading:false,showHint:true, count:appState.count+1, msg:error.handle(e,NetworkError,true), btnText:'Continue', icon:'mdi:lock', hintIcon:messasgeType.failed});
      }

  },[pwdValue,userDet])
  const screentime = React.useRef<any>(null); 
  screentime.current = useStoreState('status'); 

  React.useEffect(()=>{
    const user = getAppStorage('USER_DETAILS');
    user && setUserDet(user);
    if(screentime.current==true){
      setIdle(true);
      setAppStorage('sessionExp',true);
    }
    
  },[screentime.current])

  React.useEffect(()=>{
    setHint({...hint,status:appState.showHint, msg:appState.msg, icon:appState.hintIcon});
  },[appState.count])

  React.useEffect(()=>{
    if(loc.state?.roles){
      setAppStorage('ROLES',loc.state.roles);
    }
    ROLES = getAppStorage('ROLES');
  },[])
  
 

  return (
    <>
    <Hint show={status} message={msg} onClose={(stat:boolean)=>setHint(current=>({...current,status:stat,msg:''}))} icon={<Icon icon={icon}/>} escButton={true}/>
     <Popup show={lock} exitButton={false} allowOutsideClick={false} onPopChange={undefined} showLogo={false} scrollbar={false} style={{overflow:'hidden'}}>
      <div className={`${style.cont}`}>
      <img id='bgtime' src={time2} alt=" " />
        <div className="illusCont"><img src={time1} alt="session timeout" /></div>

        <div className='textCont'>
          <h3 className='fadeInUp anmated delay-0-1s'>Hey {userDet?.firstname?.trimEnd()}, your session has expired due to Inactivity!</h3>
          <p className='fadeInUp anmated delay-0-2s'>Please re-enter your password to continue</p>
        </div>


        <div className='inputArea'>
            <PasswordField onKeyUp={(e:any)=>e.keyCode===13 && handleSubmittion()} className='fadeInUp anmated delay-0-4s' placeholder="Password" style={{padding:'15px'}} onInput={(e:any)=>setPwdValue(e.target.value)} value={pwdValue}/>
            <div className="btn fadeInUp anmated delay-0-6s">
              <Button type={'button'} variant="secondary" onClick={handleSubmittion} disabled={appState.loading} loading={appState.loading} text={appState.btnText} icon={<Icon icon={appState.icon}/>}/>
            </div>
        </div>

      </div>
    </Popup>

    <DashboardMod />
    </>
   
  );
}

// export const ROLES = {
//   "ALL_MODULES": {
//       "status": true,
//       "privileges": {
//           "WALLET_MODULE": true,
//           "TRANSACTION_MODULE": true,
//           "BUSINESS_MODULE": true,
//           "PAYMENT_LINK_MODULE": true,
//           "UTILITIES_MODULE": true,
//           "USER_MANAGEMENT_MODULE": true,
//           "SETTINGS_MODULE": true
//       }
//   },
//   "WALLET_MODULE": {
//       "status": true,
//       "privileges": {
//           "ADD_WALLET": false,
//           "VIEW_WALLET": false,
//           "EDIT_WALLET": true,
//           "DELETE_WALLET": true,
//           "FUND_WALLET": false
//       }
//   },
//   "TRANSACTION_MODULE": {
//       "status": true,
//       "privileges": {
//           "VIEW_TRANSACTION": false
//       }
//   },
//   "BUSINESS_MODULE": {
//       "status": true,
//       "privileges": {
//           "ADD_BUSINESS": true,
//           "VIEW_BUSINESS": false,
//           "EDIT_BUSINESS": true,
//           "DELETE_BUSINESS": true,
//           "ADD_SERVICE": true,
//           "VIEW_SERVICE": true,
//           "VIEW_SERVICE_DASHBOARD": true,
//           "DELETE_SERVICE": true
//       }
//   },
//   "PAYMENT_LINK_MODULE": {
//       "status": true,
//       "privileges": {
//           "ADD_PAYMENT_LINK": true,
//           "VIEW_PAYMENT_LINK": true,
//           "VIEW_PAYMENT_LINK_ACCESS": true,
//           "EDIT_PAYMENT_LINK": false,
//           "CHANGE_PAYMENT_LINK_STATUS": true,
//           "DELETE_PAYMENT_LINK": false
//       }
//   },
//   "UTILITIES_MODULE": {
//       "status": true,
//       "privileges": {
//           "PURCHASE_AIRTIME": true,
//           "PURCHASE_DATA": true,
//           "PURCHASE_ELECTRICITY": true,
//           "PURCHASE_CABLE": true
//       }
//   },
//   "USER_MANAGEMENT_MODULE": {
//       "status": true,
//       "privileges": {
//           "ADD_USER": true,
//           "VIEW_USER": true,
//           "EDIT_USER": false,
//           "DELETE_USER": false,
//           "ADD_ROLE": false,
//           "VIEW_ROLE": true,
//           "EDIT_ROLE": true,
//           "DELETE_ROLE": true
//       }
//   },
//   "SETTINGS_MODULE": {
//       "status": true,
//       "privileges": {
//           "VIEW_APP_FEATURES": true
//       }
//   }
// }
