import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme)=>({
    bg:{
        backgroundColor:theme.palette.background.paper,
    },
    textColor:{
        color:theme.palette.primary.main,
    },

    primaryBtn:{
        backgroundColor:theme.palette.primary.main,
        color:'#fff',
    },
    secondaryBtn:{
        backgroundColor:theme.palette.secondary.main,
        color:'#fff',
    },
    otherTextColor:{
        "& a":{
            color:theme.palette.secondary.main,
        }
    },

    btns:{
        "& button":{
            cursor:'pointer',
        }
    },
    link:{
        backgroundColor:'transparent', border:'none', color:theme.palette.secondary.main, fontWeight:600, paddingLeft:0, cursor:'pointer'
     },



}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;