import useStyles from "./styles";
import illus from "./images/bg2e.png"
import LogoBox from "../../components/logoBox";
import Button from "../../components/button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight,faUserShield } from "@fortawesome/free-solid-svg-icons";
import Slider from "../../components/slider";
import SignUpMod from "../signupMod";
import LoginMod from "../loginMod";
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import HintBox from "../../components/hintBox";
import { useGlobalState } from "../../pages/dashboard";
import { useUserTheme } from "../../themes";
import { useLocation,useParams,useSearchParams } from "react-router-dom";


export default function HeroMod(props:any) {
  const classes = useStyles().classes;
  const [showSignup, setShowSignup] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const location = useLocation();
  const [URLSearchParams] = useSearchParams();

  const [{status, msg, icon},setHintState] = useGlobalState("hint");
  const {isMobile} = useUserTheme();
  const urlParam = useRef<any>(null);


  useEffect(()=>{
    if(location.pathname==='/invite'){
      setShowSignup(true);
      if(URLSearchParams.has('email') && URLSearchParams.has('merchantId') && URLSearchParams.has('role')){
        urlParam.current ={
          email:URLSearchParams.get('email'),
          role:URLSearchParams.get('role'),
          merchantId:URLSearchParams.get('merchantId'),
        }
      }
    }

  },[])

 
  return ( 
    <>
     <HintBox show={status} message={msg} onClose={(stat:boolean)=>setHintState(current=>({...current,status:stat,msg:''}))} icon={<Icon icon={icon}/>}  escButton={true}/>
    <SignUpMod show={showSignup} data={urlParam.current} SigInForm={()=>{setShowSignup(false);setShowSignIn(true)}} onPopChange={setShowSignup}/>
    
      <LoginMod  show={showSignIn} SignupForm={()=>{setShowSignup(true);setShowSignIn(false)}} onPopChange={setShowSignIn} />
    
    <main className={`${classes.homeStyles} body-wrapper`}>
      <div className="bg-waves">
        {/* <Switch style={{position: 'fixed',zIndex: 9,top: '30px',right: '30px'}} onIcon={<FontAwesomeIcon icon={faMoon} />} offIcon={<FontAwesomeIcon icon={faSun}/>}/> */}
        <svg
          className="dtp-home-curve"
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
        >
          <path
            d="M330.98,-1.47 C289.78,48.84 368.79,113.97 194.41,152.45 L500.00,150.00 L500.00,0.00 Z"
            className="bg-color w3-hide-small"
          ></path>
          <path
            d="M504.22,33.05 C382.34,29.11 578.72,128.78 0.28,153.45 L500.00,150.00 L503.10,34.03 Z"
            className="bg-color w3-hide-large w3-hide-medium"
          ></path>
        </svg>
      </div>
      <div className="hero-box">
        <div className="text-cont"> 
          <LogoBox style={!isMobile? {marginTop:'30px', marginLeft:'20px'} : { marginTop:'20px', marginLeft:'0', transform:'scale(0.7)'}}/>
          <div className="animatedTextCont">
          <Slider style={!isMobile? { marginTop:'50px', minHeight:'250px'} : { minHeight:'250px'}} interval={5000} slides={[
      {
        title: "Payment Like ABC",
        content:
          "Payment can't be this Easy. Enjoy easy and secure payment flow",
      },
      {
        title: "All Cards 1 Platform",
        content: "We accept all cards Master-Card, VISA ..."
      },
      {
        title: "Easy Integration",
        content: "Our System works with all platforms"
      },
      {
        title: "Welcome",
        content: "DTECHPAY, Payment Gateway at its Best"
      }

    ]} />
          </div>

          {isMobile && (
            <div className="illustion-cont">
            <img data-src={illus} src={illus}
              className="dtp-image dtp-home-side-img w3-display-middle"
              alt="DTECHPAY"/>
        </div>
          )}

          <div className="cta-cont"  style={{display:'flex'}}>
            <Button icon={<FontAwesomeIcon icon={faArrowRight} />} reversed={isMobile?true:false} text='Get started' variant={isMobile?'secondary':'primary'} loading={false} style={!isMobile?{marginLeft:'20px', marginTop:'-50px', fontSize:'1.5rem'}: {marginLeft:0,marginTop:'-50px', fontSize:'1.5rem'}} onClick={()=>!isMobile?setShowSignup(true):setShowSignIn(true)}/>
            
                  {!isMobile &&  
                    <Button icon={<FontAwesomeIcon icon={faUserShield} />} text='SignIn' variant="secondary" loading={false} style=          {{marginLeft:'20px', marginTop:'-50px', fontSize:'1.5rem'}} onClick={()=>setShowSignIn(true)}/>
                  }  
               </div>
            
          </div>
     
        { !isMobile && (<div className="illustion-cont">
            <img data-src={illus} src={illus}
              className="dtp-image dtp-home-side-img w3-display-middle"
              alt="DTECHPAY"/>
        </div>)}

      </div>
    </main>
   
    
    </>
  );

}
