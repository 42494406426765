import { Typography } from '@mui/material';
import * as React from 'react';
import useStyles from './styles';

export interface IWalletAdderProps {
  className?: string,
  style?: Record<string, any>,
  icon?:any
  text?:string,
  title?:string,
  action:()=>void
,
}

export default function WalletAdder(props: IWalletAdderProps) {
  const classes = useStyles().classes;
  const {style = {}, title, className = "", text="", icon=null, action} = props;
  return (
    <button title={title} onClick={action} className={`${classes.main} card-1 ${className}`} style={style}>
        {/* <div> */}

  <div className={`${classes.iconCont}`}>{icon}</div>
      <Typography component="div" className={''}>
        <p>{text}</p>
      </Typography>
        {/* </div> */}

    </button>
  );
}
