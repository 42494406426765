import { makeStyles } from 'tss-react/mui';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((themes)=>({
    gatewayBody: {
        '& *': {
            fontFamily: themes.typography.fontFamily + ' !important'
        }
        ,
        width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f6f6f6"

    },
    cont: {
        display: "flex", justifyContent: "space-between", maxWidth:520,
        //extra
        backgroundColor: "#fff", borderRadius: 10, boxShadow: "1px 1px 20px #00000005", padding: 30, minWidth: "100px", transition: "all 0.3s linear", width: "auto", position: "relative",

        "& .dtpLogo": {
            position: "absolute", top: -30, left: "50%", transform: "translateX(-50%)", 
        },
        "& .mob-nav": {
            display: 'flex', justifyContent: "space-between", width: 'calc(100% - 60px)', position: "absolute", top: 30, fontSize: "1.8rem", left: "50%", transform: "translateX(-50%)",
            '& button':{
                backgroundColor:'transparent', padding:'5px 10px', border:'none', cursor:'pointer', borderRadius:4,
                "&:hover":{
                    backgroundColor:'#f2f2f2',
                }
            }
        },
        "& footer": {
            position: "absolute", bottom: -40, left: "50%", transform: "translateX(-50%)", color: "grey",
            "@media screen and (max-width: 950px)": {
                bottom: 10, fontSize:'0.7rem',textAlign:"center",position: "static",transform: "translateX(0%)", marginTop:'30px',
            }
        },
        "& .paymentapproved": {
            width: "calc(100% - 20px)", height: "400px", display: 'flex', justifyContent: "space-evenly", alignItems:'center', flexDirection:'column',

            "& > p": {
                marginBottom: -20, width: "80%", textAlign: "center",
            },

            "& > div": { textAlign: "center",
                  
        },
        "& .finishInfo":{
            "& .title":{
                fontSize: "clamp(1.5rem, 4vw, 2rem)", fontWeight:600,lineHeight:1, color:'#6c6767'
            },
            ".descr":{
                lineHeight:1, marginTop:-3
            }
        },
            "& .logoicon": {
                width: 500, height: 200, display:'flex', justifyContent:'center',alignItems:'center', overflow: "hidden", position: "relative",marginTop:-50,
                "& .succ":{
                    width: 500, height: 'auto', display:'flex', justifyContent:'center',alignItems:'center',
                    "& img": {
                        width: "100%", height: "100%", objectFit: "cover", margin: 0,
                    },
                },
                "& .failed":{
                    width: 300, height: 120, display:'flex', justifyContent:'center',alignItems:'center',
                    "& #small-img": {
                        width: "100%", height: "100%",objectFit: "contain", margin: 0,filter: 'opacity(80%)'
                    }
                }
            },
            "& h3": { marginTop: -20 },
            "& p": { color: "grey" },

            "@media screen and (max-width: 512px)": {
                "& .finishInfo":{
                    // lineHeight:" 2.5rem", marginBottom: 10 
                },
          
            }

        },


        "@media screen and (max-width: 950px)": {
            // width: "100% !important", height: "100%", minWidth: 0, flexDirection:"column",

            "& .dtpLogo": {
                position: "absolute", top: 10, left: "50%", transform: "translateX(-50%)",
            },

        },
        "@media screen and (max-width: 512px)": {
            width: "100%", height:'100vh',paddingTop:"25%"

        }


    },
    cta: {
          width: 'calc(100% - 20px)',display:'flex',justifyContent:'center', alignItems:'center',  marginTop:-5, gap:20,
          "& button": {
            width: 'calc(50%)', color:'#fff !important'
          }
       ,
        [themes.breakpoints.down('sm')]: {
          width: '100%',display:'flex',justifyContent:'center', alignItems:'center', 
          "& button": {
            width: 'calc(100% - 20px)', color:'#fff !important'
          }
        }
      }
}));