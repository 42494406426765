import { Icon } from '@iconify/react';
import useStyles from './styles';
import React from "react";
export interface IReturnBarProps {
  className?:string,
  style?:Record<string, any>,
  title:string,
  onClick:any,
  children?:any
}

export default function ReturnBar (props: IReturnBarProps) {
  const classes = useStyles().classes;
  const { className = "", style = {}, title = "", onClick=()=>void(0), children=null} = props;
  return (
    <div className={`${className} ${classes.main}`} style={style}>
      <button className={classes.button} onClick={onClick}>
        <span>{title}</span>
        <Icon icon="eva:arrow-ios-forward-fill" width="15px" height="20px" />
      </button>
      <div>{children}</div>
    </div>
  );
}
