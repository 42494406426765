import { Theme } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  main: {
    padding: 20,
    textAlign: "center"
  },
  welcomeHeader: {
    fontSize: "4em",
    marginBottom: "10px",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down(800)]:{
      fontSize: "3em",
    },
    [theme.breakpoints.down(600)]:{
      fontSize: "2em",
    },
    [theme.breakpoints.down(450)]:{
      fontSize: "1.5em",
    },
    [theme.breakpoints.down(350)]:{
      fontSize: "1.2em",
    }
  },
  welcomeText: {
    fontSize: "1.5em",
    [theme.breakpoints.down(600)]:{
      fontSize: "1.2em",
    },
    [theme.breakpoints.down(450)]:{
      fontSize: "0.9em",
    },
  },
  smallText: {
    fontSize: "1em",
    [theme.breakpoints.down(600)]:{
      fontSize: "0.8em",
    },
    [theme.breakpoints.down(450)]:{
      fontSize: "0.6em",
    },
  },
  illus: {
    height: "30vh",
    margin: 20,
    "& img":{
      width: "100%",
      height: "100%",
      objectFit: "contain",
      objectPosition: "center",
    }
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;