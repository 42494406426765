import { css, keyframes } from "@emotion/css";
import React from "react";
import { useUserTheme } from "../../themes";
// import { ISize } from "../Button";
// import { FiCheck } from "react-icons/fi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
// import chroma from "chroma-js";
import theme from "../../resource/theme";
export interface ICheckbox
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "size" | "type"
  > {
  /**
   * **size** - 'large' | 'medium' | 'small'
   */
  size?: 'large' | 'medium' | 'small';
  /**
   * **variant** - 'square' | 'circle'
   */
  variant?: "square" | "circle" | "outlined";
  /**
   * **type** - 'checkbox' | 'radio' | 'switch'
   */
  type?: "checkbox" | "radio" | "switch";

  skelenton?:boolean;
}

/**
 * ## Checkbox
 *
 * A simple checkbox component
 *
 * works like conventional checkbox element, including variant props (square or circle).
 *
 * accept 3 type (type='checkbox' | 'radio' | 'switch'). Though not recommended use the **RadioButton** component for radio and Switch component fro switch
 *
 * By:
 *
 * - Academa Team
 *
 * For:
 *
 * - Academa UI
 */
const Checkbox = ({
  type,
  onChange,
  size = "medium",
  style,
  color = "primary",
  variant = "square",
  skelenton = false,
  ...props
}: ICheckbox) => {
  const { theme: UTheme } = useUserTheme();
  
  const [checked, setChecked] = React.useState<boolean>(props.checked || false);

  const getAnim = (size: string, reversed = false) => {
    return keyframes`
  ${reversed ? "to" : "from"} {
    width: ${size === "large" ? "16px" : size === "medium" ? "12px" : "8px"};
    margin-left: 3px;
  }

  ${reversed ? "75%" : "25%"}{
    width: ${
      size === "large" ? "20px" : size === "medium" ? "14.4px" : "9.6px"
    };
    margin-left: ${
      size === "large" ? "9.5px" : size === "medium" ? "7.5px" : "6.5px"
    };
  }

  50%{
    width: ${
      size === "large" ? "24px" : size === "medium" ? "16.6px" : "11.2px"
    };
    margin-left: ${
      size === "large" ? "16px" : size === "medium" ? "12px" : "10px"
    };
  }

  ${reversed ? "25%" : "75%"}{
    width: ${
      size === "large" ? "20px" : size === "medium" ? "14.4px" : "9.6px"
    };
    margin-left: ${
      size === "large" ? "22.5px" : size === "medium" ? "16.5px" : "13.5px"
    };
  }

  ${reversed ? "from" : "to"} {
    width: ${size === "large" ? "16px" : size === "medium" ? "12px" : "8px"};
    margin-left: ${
      size === "large" ? "29px" : size === "medium" ? "21px" : "17px"
    };
  }
`;
  };

  React.useEffect(() => {
    setChecked(props.checked || false);
  }, [props.checked]);
  const sizeDeclear = {
    large: {
      width: type === "switch" ? "48px" : "22px",
      height: "22px",
      borderRadius:
        variant ==
        "circle" /*  && type !== 'switch'?'50%':variant=='circle' && type === 'switch' */
          ? "11px"
          : "5px",
      "& .icon": {
        opacity: 0.18,
        height: "",
        width: "",
      },
      "& .nod": {
        opacity: 0.2,
        width: "10px",
        height: "10px",
        background: theme.palette.text.primary,
        borderRadius: variant == "circle" ? "5px" : "3px",
        transition: "all 0.3s ease-in",
      },
      "& .nod.switch": {
        opacity: 0.2,
        width: "16px",
        height: "16px",
        borderRadius: variant == "circle" ? "8px" : "4px",
      },
      "& input:checked + .nod.switch": {
        animation: `${getAnim("large")} 0.3s ease-in-out both`,
      },
      "& input + .nod.switch": {
        animation: `${getAnim("large", true)} 0.3s ease-in-out both`,
      },
    },
    medium: {
      width: type === "switch" ? "36px" : "18px",
      height: "18px",
      borderRadius:
        variant ==
        "circle" 
          ? "9px"
          : "5px",
      "& .icon": {
        opacity: 0.18,
        height: "0.8em",
        width: "0.8em",
      },
      "& .nod": {
        opacity: 0.2,
        width: "8px",
        height: "8px",
        background: theme.palette.text.primary,
        borderRadius: variant == "circle" ? "4px" : "2px",
        transition: "all 0.3s ease-in",
      },
      "& .nod.switch": {
        opacity: 0.2,
        width: "12px",
        height: "12px",
        borderRadius: variant == "circle" ? "6px" : "3px",
      },
      "& input:checked + .nod.switch": {
        animation: `${getAnim("medium")} 0.3s ease-in-out both`,
      },
      "& input + .nod.switch": {
        animation: `${getAnim("medium", true)} 0.3s ease-in-out both`,
      },
    },
    small: {
      width: type === "switch" ? "28px" : "14px",
      height: "14px",
      borderRadius:
        variant ==
        "circle" 
          ? "7px"
          : "5px",
      "& .icon": {
        opacity: 0.2,
        height: "0.6em",
        width: "0.6em",
      },
      "& .nod": {
        opacity: 0.2,
        width: "5px",
        height: "5px",
        background: UTheme.palette.text.primary,
        borderRadius: variant == "circle" ? "3px" : "1px",
        transition: "all 0.3s ease-in",
      },
      "& .nod.switch": {
        opacity: 0.2,
        width: "8px",
        height: "8px",
        borderRadius: variant == "circle" ? "4px" : "2px",
        marginLeft:'3px',
      },
      "& input:checked + .nod.switch": {
        animation: `${getAnim("small")} 0.3s ease-in-out both`,
      },
      "& input + .nod.switch": {
        animation: `${getAnim("small", true)} 0.3s ease-in-out both`,
      },
    },
  };
  const classes = css({
    position: "relative",
    display: "inline-flex",
    justifyContent: type === "switch" ? "unset" : "center",
    alignItems: "center",
    border: variant ==='outlined'?`1px solid ${UTheme.background.element}`: "none",
    backgroundColor: variant==='outlined' && !skelenton?'transparent': UTheme.background.element,
    "&:hover": {
      transition: "all 0.3s ease-in",
    },
    ...sizeDeclear[size],
    transition: "all 0.2s ease-in",
    "&.checked": {
      border: variant==='outlined'?`1px solid ${theme.palette.primary.main}` :'none',
      backgroundColor:!skelenton?variant==='outlined'?'transparent': theme.palette.primary.main:UTheme.background.element,
      transition: "all 0.3s ease-out",
    },
    "&.checked .icon, &.checked .nod": {
      opacity: 1,
      stroke:variant==='outlined'? theme.palette.primary.main: theme.palette.primary.contrastText,

      transition: "all 0.3s ease-out",
    },
    "&.checked .nod": {
      backgroundColor: UTheme.palette.primary.contrastText,
    },
    "& input": {
      position: "absolute",
      top: "0px",
      width: "inherit",
      height: "inherit",
      boxSizing: "border-box",
      margin: "0px",
      opacity: 0,
      display: "block",
      zIndex: 2,
    },
  });
  type = type !== "radio" && type !== "switch" ? "checkbox" : type;
  return (
    <div className={`${classes} ${checked ? "checked" : ""}`} style={style}>
      {!skelenton && <>
      <input
        type={type !== "radio" ? "checkbox" : type}
        {...props}
        checked={checked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setChecked(e.target.checked);
          onChange && onChange(e);
        }}
      />
      {type === "checkbox" ? (
        <FontAwesomeIcon icon={faCheck} className="icon" />
      ) : (
        <div className={`nod ${type}`}>&nbsp;</div>
      )}
      </>}
    </div>
  );
};

export default Checkbox;
