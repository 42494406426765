import { trim } from 'lodash';
import react from 'react';

export const randomString = (min:number=1, max:number=1, domain:string|Array<string>=[], exclude='')=>{
    domain = domain.length < 1?':1}(23*45?6,78`90q&w{erty)ui^opasd[fghjklz/xc!vbn;m~MN@BVCXZA-SDF]GHJ.KL#POI_U$Y+T|RE%WQ=':domain;
    max = max < min?min:max;
    let ac = "";
	let s=1;
    const chars =min + Math.round(Math.random() * (max - min));
	while(s <= chars){
	  const rand = Math.ceil(Math.random() * (domain.length-1));
      const char = typeof domain == 'string'?domain?.charAt(rand):domain[rand];
      if(exclude.indexOf(char) > -1)continue;
	  ac += char;
	   s++;	
	}
	return ac;
}

const useCrypto = (props?:any)=>{
    return {
        encrypt:(txt:string)=>{
            let posstr = "", encr = "";
            const posSepr: any = [];
        if(trim(txt) != ""){
            const totchar = txt.length;
            let mainSeperator = "";
            do{
             mainSeperator += randomString(1,1,'`~!$%^*()-+=','0123456789');
            }while(txt.indexOf(mainSeperator) > -1)
            
            const posSeperator = randomString(1,1,'',mainSeperator+'0123456789');
            for(let d=0;d<totchar;d++){
                    const txtpic = txt.charAt(d);
                    const GC = randomString(1,2,[],mainSeperator);
                    const GCLen = encr.length + GC.length;
                    encr += GC+txtpic;
                    const possp = randomString(1,5,[],mainSeperator+posSeperator+'0123456789');
                    posstr += GCLen+possp;
                    posSepr.push(possp);
            }
            posstr = trim(posstr);
            encr = mainSeperator+randomString(1,3,[],mainSeperator)+mainSeperator+posstr+mainSeperator+encr+mainSeperator+posSepr.join(posSeperator)+posSeperator;
            return encr;
            }
    }
    }
}

export default useCrypto;