import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useState } from 'react';
import { allowOnClick } from '../../utilities/appHelpers';
import TextField from '../textField';
import useStyles from './styles';

export interface IPasswordFieldProps  extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLInputElement>, HTMLInputElement>{
  className?:string
  style?:Record<string, any>
  onChange?:any
  value?:string
  placeholder?:string
  disabled?:boolean
}
function PasswordField (props: IPasswordFieldProps) {
  const classes = useStyles().classes;
  const { className="", style={},onChange=()=>void(0), ...rest } = props;
  const [show, setShow] = useState(false);

  return (
    <div className={`${classes.main} ${className}`}>
      <TextField type={show ? "text" : "password"} onChange={onChange} {...rest} />
      {<button type='button' style={{background:'transparent', border:'none', color:'grey'}} className={classes.displayToggle} onClick={(e:any)=>{show ? setShow(false): setShow(true)}}>
        { show ? (<FontAwesomeIcon icon={faEyeSlash} />) : (<FontAwesomeIcon icon={faEye} />) }
      </button>}
    </div>
  );
}

export default React.memo(PasswordField);