import { Icon } from '@iconify/react';
import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import { SessionCheck, dispatch, messasgeType, useGlobalState } from '../../pages/dashboard';
import { payByTransfer } from '../../services/payment';
import { currency, error } from '../../utilities/appHelpers';
import { NetworkError } from '../../utilities/constant';
import { getTransactionDetail } from '../../services/gateway';
import Spinner from '../spinner';

export default function Transfer(props: any) {
    const styles = useStyles().classes;
    const navigate = useNavigate();
    const [copied, setCopied] = React.useState(false);
    const negateCopiedState = () => {
        setTimeout(() => { setCopied(false); }, 1000)
    }
    const [tId, setTId] = useState(undefined);
    const [transferDet, setTransferDet] = useState({
        accountNumber: "",
        amt: "",
        bankName: "",
        channel: "",
        email: "",
        expiryTime: 0,
        reference: ""
    });
    const [transactionDet, setTransactionDet] = useState<any>({});
    const [waitTime, setWaitTime] = useState(1200);
    const mins = Math.floor(waitTime / 60);
    const secs = Math.floor(waitTime % 60);
    const [appState, setAppState] = useState({ loading: false, res: [], msg: '', isMsg: false, status: false, count: 0, msgType: messasgeType.default });
    const [, setHintState] = useGlobalState("hint");
    const poolTimeRef = useRef<any>(0);

    useEffect(() => {
        const timer: any = setInterval(() => {
            setWaitTime(prev => prev < 1 ? prev : prev - 1);
        }, 1000);

        if (waitTime <= 0) {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, []);


    useEffect( () => {
        (async () => {
            try {
                setAppState(prev => ({ ...prev, loading: true }));
                const resp = await payByTransfer({ reference: props.reference, amount: props.amount });

                if (resp.status === 201 || resp.status === 200) {
                    setAppState(prev => ({ ...prev, loading: false, msg: resp.data.message, status: resp.data.status, isMsg: true, count: prev.count + 1 }));

                    setTransferDet(resp.data.data);
                }
            } catch (e: any) {
                SessionCheck(e);
                setAppState(prev => ({ ...prev, msg: error.handle(e, NetworkError, true), isMsg: true, count: prev.count + 1, loading: false }));
            }
        })()
    }, [props.isTransfer]);


    const pool = async () => {
        try {
            const resp = await getTransactionDetail(props.reference);
            if (resp.status === 200) {
                const transDets = resp?.data?.data;
                setTransactionDet(transDets);
                if (transactionDet.statusCode == 4) {
                    navigate('/confirm');
                }
            }
        } catch (e: any) {
            SessionCheck(e);
        } finally {
            if(waitTime > 0) {
                const pt: any = setTimeout(() => {
                poolTimeRef.current = poolTimeRef.current + 1
                setTId(pt);
            }, 240000); 
            } else {
                return;
            }
        }
    }

    useEffect(() => {
        if (waitTime > 0) {
            pool();
        } else {
            clearTimeout(tId);
            return;
        }
        return () => clearTimeout(tId);
    }, [poolTimeRef.current]);

    useEffect(()=>{
        setHintState(prev => ({...prev,status:appState.status, msg:appState.msg, icon:appState.msgType}));
      },[appState.count]);

    return (
        appState.loading ? <Spinner size={100} style={{ position: "absolute" }} show={true} /> : <div className={`${styles.transfer} animated fadeInUp faster`}>
            <div style={{ fontSize: '12px' }}>
                Make a <b>single transfer</b> of exactly <b style={{ fontSize: 20 }}>{currency.naira}{transactionDet.netAmt} </b> from your bank to <b>DTechpay checkout</b>
            </div>
            <div className="account-number">
                <div title="Copy account number" style={{ display: 'flex', alignItems: 'center', gap: '8px' }} onClick={(e) => {
                    navigator.clipboard.writeText(`${transferDet.accountNumber}`).then(() => {
                        setCopied(true);
                        negateCopiedState();
                    }).catch((error) => {

                    });
                }}>
                    <h2 className={'acc-num'} style={{ margin: '0', fontWeight: 'bolder', }}>{transferDet.accountNumber}</h2>
                    <Icon icon={!copied ? "solar:copy-linear" : "mdi:success-circle-outline"} />
                </div>
                <div style={{ fontWeight: '550', marginBottom: '16px' }}>{transferDet.bankName}</div>
                <div style={{ fontSize: '12px' }}>This account will expire in <b>{mins}:{secs < 10 ? `0${secs}` : secs} time</b></div>
                <div></div>
            </div>
            <div style={{ fontSize: '10px' }}>You will get a notification once we <br /> receive your payment</div>
            <button onClick={() => {
                setTransactionDet((prev: any) => ({ ...prev, statusCode: 4 })); clearTimeout(tId);
                navigate('/finish');
            }} className={'btn-bg'} style={{ outline: 'none', padding: '10px 16px', border: 'none', borderRadius: 5, width: '100%', color: 'white' }}>I've made this payment</button>
            <div>
            </div>
        </div>
    );
}