import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'headerMd' | 'headerLg'>()((theme, _params, classes) => ({
  main:{
    width: "100%",
    minHeight: "calc(100vh - 60px)",
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    padding: 10,
    display: "flex",
    [theme.breakpoints.up('sm')]: {
      width: "calc(100% - 40px)"
    },
    [theme.breakpoints.up('md')]: {
      width: "calc(100% - 250px)"
    }
  },
  mainDisplay:{
    width: "75%",
    padding: 10,
    
  },
  secondDisplay: {
    width: "25%",
    padding: 10,
  },
  balSec: {
    display: "flex",
    "&>*~*":{
      marginLeft: 10
    }
  },
  headerLg: {
    fontSize: 20,
    fontWeight: 600,
  },
  headerMd: {
    fontSize: 17,
    fontWeight: 600,
  },

  section: {
    margin: "20px 0",
    [`& .${classes.headerMd}, & .${classes.headerLg}`]: {
      marginBottom: 10
    }
  },

  sectionHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  form:{
    "& input":{
      padding:5, fontSize:'1rem',
    },

    "& button":{
      padding:5, fontSize:'1rem', color:'#fff', backgroundColor:'#000'
    },

     
  }

}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;