import { update } from "lodash";
import React, { useState } from "react";
import { useStyles } from "./styles";


interface IGatewayInputfield{
    id:string;
    type:string;
    className?:string;
    name?:string;
    icon?:any;
    style?:Record<string, any>
    label:string;
    autoFocus?:boolean
    animate?:string,
    logoIsImage?:boolean,
    onChange?:any,
}

const GatewayInputfield = (props:any)=>{
    const {id, className='', name, type, icon, label,contStyle, style, autoFocus, animate,logoIsImage=false, errorMsg='',isError=false,forwardedRef,...rest} = props;
    const [isFocus, setIsFocus] = useState(false);
    
    const styles = useStyles().classes;
    return <div style={contStyle} className={`${styles.cont} ${isFocus?styles.active:""} ${animate} ${isError?styles.error:styles.noError}`} >
        <input ref={forwardedRef} onFocus={()=>setIsFocus(!isFocus)} onBlur={()=>setIsFocus(!isFocus)} type={type} id={id} {...rest} style={style}required />
        <label htmlFor={id}>{label}</label>
        <div className={`${className} ${styles.icon}`}>{logoIsImage ?<img src={icon} alt="cardLogo"/>:icon}</div>
        <p className={`err-msg`}>{errorMsg}</p>
    </div>

}

export default GatewayInputfield;

/* export default React.forwardRef((props,ref)=>{

    return   <GatewayInputfield ref={}>
    
}) */