import { alpha, Theme } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  select: {
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    "& *":{
      boxSizing: "border-box",
      color: "#555"
    }
  },
  optionsWrapper: {
    margin: 0,
    padding: 0,
    position: "absolute",
    top: "50px",
    width: "100%",
    maxHeight: "150px",
    overflowY: "auto",
    zIndex: 100,
    transform: "scale3d(1,1,1)",
    transition: "all 0.5s ease-in",
    backgroundColor: theme.palette.background.default,
  },
  hide: {
    maxHeight: 0,
    transform: "scale3d(1.1,1.1,1.1)",
    transition: "all 0.2s ease-in"
  },
  option: {
    border: "none",
    display: "block",
    padding: "5px 15px",
    width: "100%",
    backgroundColor: "transparent",
    transition: "all 0.1s ease-in",
    cursor: "pointer",
    fontFamily: 'Raleway, Montserrat, Source Sans Pro, sans-serif',
    textAlign: "left",
    "&:hover":{
      backgroundColor: alpha(theme.palette.secondary.light, 0.5),
      transition: "all 0.3s ease-in"
    }
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
    transition: "all 0.3s ease-in"
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;