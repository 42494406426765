import { Typography } from '@mui/material';
import useStyles from './styles';
import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';

export interface IOptionCheckerProps {
  className?:string,
  style?:Record<string, any>,
  children?:any
}

export default function OptionChecker (props: IOptionCheckerProps) {
  const classes = useStyles().classes;
  const { className = "", style = {}} = props;
  return (
    <div className={`${className} ${classes.main} card-2`} style={style}>
      {props.children}
    </div>
  );
}

export interface ICheckerBtnProps {
  className?:string,
  style?:Record<string, any>,
  title:any,
  descr?:any,
  onClick?:any
  icon?:any
  checked?:boolean
  children?:HTMLElement
}

export function CheckerBtn (props: ICheckerBtnProps | any) {
  const classes = useStyles().classes;
  const { className = "", style = {}, title="", icon="", onClick=null, checked=false} = props;
  const [isChecked, setIsChecked] = useState(checked);
  useEffect(()=>{
    setIsChecked(checked);
  }, [checked]);
  return (
    <button className={`${className} ${classes.btn}`} style={style} onClick={onClick}>
      {icon !== "" && <div className={`${classes.icon}`}>{icon}</div>}
      <div className={`${classes.btnBody}`} style={icon === "" ? {width: "100%",padding: "10px 0 10px 10px"} : {}}>
        <div style={{display:'flex',flexDirection:"column", border:'none',padding:0,alignItems:'flex-start'}}>  
          <Typography>{title}</Typography>
          <Typography component={'div'}>{props.children}</Typography>
        </div>

          <div className={`${classes.checkbox}`}>
            {isChecked && <Icon icon="ant-design:check-outlined" />}
          </div>

      </div>
    </button>
  );
}