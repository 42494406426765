import { darken } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles= makeStyles()((theme)=>({
    cont:{"&":{
        display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column",
        backroundColor:'#ffffff', width:'100%',height:'100vh'
    
    }

    },

    imageCont:{"&":{
        display: 'flex', width:'calc(100% - 200px)', overflow:'hidden',height: 'calc(100% - 60%)',
        "& img":{
            width:'100%',height:'100%', objectFit:'contain',
        }
    }

    },

    infoCont:{
        "&":{
            display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", textAlign:'center',
            '& h1':{fontWeight:'800',color:darken(theme.palette.primary.main,0.1),marginBottom:-5},
            '& p':{fontWeight:'400',color:'grey',fontSize:'1.2rem'}

        }
    },

    // brokenPage:{
        wrapper:{ "&":{
            padding:'10px 20px',
            width:'calc(100% - 50%)',
            "@media screen and (max-width:600px)":{
                width:'100%'
            }
        },
        ".text":{
            marginTop:-30,
        },
        "& .btn":{
            padding:'10px 25px'
        }
    },
    imgCont:{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            width:'100%',
            height:200,
            '& img':{
                width:'100%',height:'100%',objectFit:'contain'
            }
        }
        


})); 