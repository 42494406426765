import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  main: {"&":{display: 'flex', justifyContent: "space-between", alignItems:'flex-start', flexDirection: 'column'},
    width: 250,
    height: 175,
    borderRadius: 10,
    boxSizing: "border-box",
    position:"relative",
    padding: 16,
    overflow: "hidden",
    fontSize: "1.2rem", backgroundColor:"#fff", cursor:"pointer", flexBasis:'700px', border:"thin solid #f2f2f2"
   
  },
  iconCont:{
    "&":{display: 'flex'},
    width:40,height:40,borderRadius:"50%", backgroundColor:"#c4d0ee85",
    
  },

  first:{
    "&":{display: 'flex', justifyContent: "space-between",width:"100%"},
  },

  info:{
    "&":{display: 'flex', justifyContent: "space-between", alignItems:'flex-start', flexDirection: 'column'},
    "& div:nth-child(even)":{
      width:90,height:30,borderRadius:50, backgroundColor:"#c4d0ee85", margin:"5px 0"
    },
    "& div:nth-child(1)":{
      width:100,height:10,borderRadius:50, backgroundColor:"#c4d0ee85",
    },
    "& div:nth-child(3)":{
      width:80,height:10,borderRadius:50, backgroundColor:"#c4d0ee85",
    }
      
  },
  
  last:{"&":{display: 'flex', justifyContent: "space-between",width:"100%"},
  "& div":{
    width:100,height:35,borderRadius:4, backgroundColor:"#c4d0ee85", 

  }
    
  },

  tableCont:{
    width:'100%',
      '& > div':{
        width:'100%',
        borderRadius:5, backgroundColor:'white', marginTop:5
      },
      '& .th':{
        padding:10,display: 'flex', justifyContent: "space-between",
        '& div':{
          width: 50,height:15, backgroundColor:'#f2f2f2', borderRadius: 50,
        },
        '& div:nth-child(1)':{width: 70,},
        '& div:nth-child(3)':{width: 90,},
        '& div:nth-child(5)':{width: 130,},
          

      },
      '& .tr':{
        padding:' 20px',display: 'flex', justifyContent: "space-between",
        '& div':{
          width: 70,height:25, backgroundColor:'#f2f2f2', borderRadius: 50,
        },
        '& div:nth-child(1)':{width: 50,},
        '& div:nth-child(3)':{width: 90,},
        '& div:nth-child(5)':{width: 150,},
        '& div:nth-child(6)':{width: 25,height:25, borderRadius: 5,backgroundColor:'#f2f2f2',},
        
      }
  },


  paymentCardCont:{
    "&":{display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column",width:'100%', backgroundColor:'#ffffff', borderRadius:5, padding:'10px 50px 10px 10px',
    "& > div":{
      display: 'flex', justifyContent: "space-between", width:'100%',
    },
    "& > div:not(div:first-child)":{
      marginTop:10,
    },
    '& .first':{
      display: 'flex',gap:5,
      '& .circle':{
        width:40, height:40, borderRadius:50, backgroundColor:'#f2f2f2',
      },
      '& .det':{
        display: 'flex', justifyContent: "flex-start", alignItems:'flex-start', flexDirection: 'column', gap:3,
        "& div:nth-child(1)":{
           width:70, height:10, borderRadius:10, backgroundColor:'#f2f2f2', 
        },
        "& div:nth-child(2)":{
          width:55, height:10, borderRadius:10, backgroundColor:'#f2f2f2', 
        }
      }
    },
    "& .second":{
        display: 'flex', justifyContent: "center", alignItems:'flex-start', flexDirection: 'column', gap:3,
        "& div:first-child":{
          width:55, height:10, borderRadius:10, backgroundColor:'#f2f2f2', 
        },
        "& div:last-child":{
          width:70, height:10, borderRadius:10, backgroundColor:'#f2f2f2', 
        }
    }
    
    }
  },
  checker:{
    "&":{display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column",width:'100%', backgroundColor:'#ffffff', borderRadius:5, padding:'10px', marginTop:'20px',
    '& .border:not(:last-child)':{
    borderBottom:'thin solid #f2f2f2',
  },
    "& .ch":{
      display: 'flex', justifyContent: "flex-start", alignItems:'center', flexDirection: 'column', width:'100%',
    },
    "& .ch:nth-child(even) .text":{
      width: 'calc(100% - 150px)', height:'20px', borderRadius:5,backgroundColor:'#f2f2f2', alignSelf:'flex-start', margin:10,
    },
    "& .ch:nth-child(odd) .text":{
      width: 'calc(100% - 300px)', height:'20px', borderRadius:5,backgroundColor:'#f2f2f2', alignSelf:'flex-start', margin:10,
    },

    // '& .text':{
    //   width: '35%', height:'20px', borderRadius:5,backgroundColor:'#f2f2f2', alignSelf:'flex-start', margin:10,
    // },
    '& .line':{
      // width: 'calc(100% - 10px)', height:2, backgroundColor:'#f2f2f2' 
    }
    
    
    }
  },

  border:{
    borderBottom:'thin solid #f2f2f2',
  }


  
}));



// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
