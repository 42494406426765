import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "../../components/button";
import GatewayInputfield from "../../components/GatewayInputfield";
import { useStyles } from "./styles";
import logo from "./images/signup1.png";
import approved1 from "./images/approved1.gif";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import Line from "../../components/line";
import PVI from "../../components/pvi";
import { CardDetail } from "../../components/cardDetail";
import { faArrowLeft, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoBox from "../../components/logoBox";
import Spinner from "../../components/spinner";
import { isValidCard, isValidCardExpiry, isValidCVV, sanitize,  error as E, currency, commarize, IsJsonString } from "../../utilities/appHelpers";
import { useNavigate, useParams } from "react-router-dom";
import { getTransactionDetails, processCardDetails, processOTP, ResendOTP, sendPin} from "../../services/gateway";
import md5 from "md5";
import useCrypto from "../../utilities/useCrypto";
import { dispatch, messasgeType, useGlobalState } from "../../pages/dashboard";
import Hint from '../../components/hintBox';
import { Icon } from "@iconify/react";
import { useCounter } from "../../components/counter";

const Gateway = ()=>{

    const isTabletOrMobile = useMediaQuery('(max-width:950px)');

    const styles = useStyles().classes;
    const [pageId, setPageId] = useState(1);
    const [response, setResponse] = useState<any>({});
    const [startShowing, setStartShowing] = useState(false);
    const [startShowing2, setStartShowing2] = useState(false); // set back tro false
    const [data, setData] = useState({
        cardNum: '',
        expDate: '',
        cvv: '',
        logo:''
      });
    const theme = useTheme();
    const params = useParams();
    const {encrypt} = useCrypto();
    const [loadingTrans, setLoadingTrans] = useState(true);//set loading to true
    const [transDet, setTransDet] = useState<Record<string,any>>({});
    const [cardDet, setCardDet] = useState<Record<string,any>>({
        cnum:'',
        cname:'',
        cexp:'',
        cvv:'',
        pin:''
    });
    const [processing, setProcessing] = useState(false);
    const [pinPageLaoding, setPinPageLaoding] = useState(false);
    const [optProcessing, setOptProcessing] = useState({status:false,btnText:'Pay now'});

    const [errorHandler, setErrorHandler] = useState({status:false, msg:"",count:0,icon:messasgeType.network});
    const trnsRef = useRef(''), [pinError,setPinError] = useState({msg:'',status:true});
    const navigator = useNavigate();


    const SendCardPin = async ()=>{
        if(cardDet.pin.trim().length<4) return setPinError({...pinError,msg:'Pin is required',status:true});
        setPinPageLaoding(true);
        const payload= encrypt(JSON.stringify({
            transactionId:response?.transactionId,
            pin:cardDet.pin,
        }))

        try {
            setProcessing(true);
            const res = await sendPin({enc:payload}, trnsRef.current);
            if(res.status == 200){
                // const {code} = res.data.data;
                setPageId(pageId+1);

            }
            setProcessing(false);
        } catch (e:any) {
            setErrorHandler({...errorHandler, status:true,msg:E.handle(e,"You're offline. Please check your network and try again",true), count:errorHandler.count+1});
            setProcessing(false);
            setPageId(pageId-1);
        }
        finally{
            setPinPageLaoding(false);
        }

    }

    const processCDetails = async ()=>{
        setButtonLoading(true);
        const cdet = {cnum:data.cardNum,cexp:data.expDate,cvv:data.cvv}
        
        const cd= encrypt(JSON.stringify(cdet))
        
        const payload = {
            ecd:cd,
            accountCode:transDet?.accountCode,
            ref:transDet?.ref,
        }
        try {
            setProcessing(true);
            const res = await processCardDetails(payload);
            if(res.status == 200){
                setResponse(res.data.data);
                setPageId(pageId+1);
            }
            setProcessing(false);
        } catch (e:any) {
            setErrorHandler({...errorHandler, status:true,msg:E.handle(e,"You're offline. Please check your network and try again",true), count:errorHandler.count+1});
            setProcessing(false);
        }
        finally{
            setButtonLoading(false);
        }
    }
 
    const updateCardDet = (key:string,value:string)=>{
        setCardDet({...cardDet,[key]:value});
        if(cardDet.pin.trim().length>0){
            setPinError({...pinError,msg:'',status:false});
        }
    }
    
    //TODO- fix redirecting issues
    // const redirect = (url: any) => url.split('://')[0] !== 'https' ? window.location.href = url.replace('http','https') : window.location.href = url;
    const redirect = (url: any) => window.location.href = url;
    

    const [icon1, setIcon1] = useState(<FontAwesomeIcon icon={faArrowLeft} />);
    const [icon2, setIcon2] = useState(<FontAwesomeIcon icon={faCheck} />);
    const [icon3, setIcon3] = useState(<FontAwesomeIcon icon={faTimes} />);
    const [otpValue, setOtpValue] = useState({otp:''}), [OtpError, setOtpError] = useState({msg:'',status:false});
    
   const [mins, secs,setTime,status ]= useCounter([2,60]); 
 
   const [showSummary, setShowSummary] = useState(false);
   const [showCards, setShowCards]= useState(false);

   const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const start = async()=>{
        if(otpValue.otp.trim().length<1)return setOtpError({msg:'OTP is required',status:true});
        setOptProcessing({...optProcessing,status:true, btnText:'Processing...'});
        // const Tref = params?.ref?params?.ref:'nil';

        const payload= encrypt(JSON.stringify({
            otp:otpValue.otp,
            transactionId:response?.transactionId
        }));
        
        try {
            const resp = await processOTP({enc:payload},trnsRef.current);
            if (resp.status === 200) {

                const data = resp.data.data;
                let recieptdata = {
                    channel:data?.channel,
                    currency:data?.currency,
                    amount:data?.amount,
                    ref:data?.ref,
                    charges:data?.charges,
                    businessName:data?.businessName,
                    date:data?.date,
                    payerName:data?.payerName
                };
                const recp = btoa(JSON.stringify(recieptdata));
                
                if (data.authorizationModel !== '3DS') {
                    let walletFunding = false;
                    if(IsJsonString(data?.metadata)){ 
                        let metadata = JSON.parse(data.metadata);                      
                        walletFunding= metadata?.walletFunding;
                    }
                    // if(data.metadata !== undefined && data.metadata!==null){
                    //     if(data.metadata.length > 0){
                    //         let md = JSON.parse(data.metadata);
                    //         console.log('2: ',md?.walletFunding);
                    //         walletFunding = md?.walletFunding;
                    //     }
                    // }
                    
                    let newurl = `${data.callbackUrl}?sc=${data.code}&ref=${data.ref}&cs=${data.checksum}&wf=${walletFunding}&recp=${recp}`;

                    setTimeout(() => redirect(newurl), 3000);
                    setOptProcessing({ ...optProcessing, status: false, btnText: 'Paid' })
                    setPageId(pageId + 1);
                    let toshow = setTimeout(() => { setStartShowing(true) }, 0);
                    return () => clearTimeout(toshow);

                } else {
                    redirect(data.redirect);
                }


            }

        } catch (e:any) {
            setErrorHandler({...errorHandler, status:true,msg:E.handle(e,"You're offline. Please check your network and try again",true), count:errorHandler.count+1});
            setPageId(1);
            
        }
        finally{
            setOptProcessing({...optProcessing,status:false, btnText:'Proceed'});
            setOtpValue({...otpValue,otp:''});
        }
        
    }

    useEffect(()=>{
        (async ()=>{
            try {
                const ref:string = params?.ref?params?.ref:'';
                trnsRef.current = ref;
                const cstr:string =ref+process.env.REACT_APP_SECRET;
                const checksum = md5(cstr);
                if(ref) {
                    const res = await getTransactionDetails(ref,checksum);
                    if(res.status === 200){
                        setTransDet(res.data.data);
                        setLoadingTrans(false);
                    }

                }
        } catch (e:any) {
                // setAppSate({...appState,msg:E.handle(e,'',true),isError:true});
                navigator('/badrequest');//uncomment
            }
            

        })()
        let toshow1 = setTimeout(()=>{setStartShowing2(true)},0);
        return ()=> clearTimeout(toshow1);

    },[])

    useEffect(()=>{
            if(isValidCard(data.cardNum)&&isValidCardExpiry(data.expDate)&&isValidCVV(data.cvv)){
                processCDetails();
            }
        
    },[data])


    const handleOTPInput = useCallback((value:string)=>{
        let san = sanitize(value);

        if(san.length>0){
            setOtpError({...OtpError,msg:'',status:false});
        }else{
            setOtpError({...OtpError,msg:'This field is required',status:true});

        }

        if(san.length<=7){
            setOtpValue({...otpValue,otp:san})
        }else{
            return;
        }
        

    },[otpValue.otp])

const [hintState,setHintState] = useGlobalState("hint");

  useEffect(()=>{
    setHintState({...hintState,status:errorHandler.status, msg:errorHandler.msg, icon:errorHandler.icon});
  },[errorHandler.count])

    const [isReading,setIsResending]= useState(false);
    

  const resendOTP = useCallback(async()=>{
    setIsResending(true);
    try {
        const resp = await ResendOTP({ref:trnsRef.current});
        if(resp.status===200 || resp.status===201){
            
            setIsResending(false);
            setErrorHandler({...errorHandler, status:true,msg:E.handle(resp.data,'',true), count:errorHandler.count+1,icon:messasgeType.success});
            setTime([2,60]);
        }
        
    } catch (e:any) {
        setIsResending(false);
        setErrorHandler({...errorHandler, status:true,msg:E.handle(e,"You're offline. Please check your network and try again",true), count:errorHandler.count+1});
        
    }finally{
        setIsResending(false);
    }
  },[])


    return (
        <>
        <Hint show={errorHandler.status} message={errorHandler.msg} onClose={(stat:boolean)=>{setHintState(current=>({...current,status:stat,msg:''}));setErrorHandler( prev =>{return{...prev, status:false,msg:''}}); }} icon={<Icon icon={hintState.icon}/>} escButton={true}/>
        <div style={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center",backgroundColor:"#f6f6f6"}}> 

        <div className={styles.gatewayBody}> 
{loadingTrans?<Spinner size={100} style={{position:"absolute"}} show={true}/>:

    <div className={`${styles.cont} gateCont`}>
        
        {/* <LogoBox className="dtpLogo"/> */}
        {
            isTabletOrMobile && (<div className="mob-nav">
            {/* <button style={{visibility:pageId===1?"visible":"hidden"}} title="Show Cards" onClick={()=>{setShowCards(!showCards);setShowSummary(false)}}>
                <Icon icon="charm:menu-kebab"/>
            </button> */}
            &nbsp;
            <button onClick={()=>{setShowSummary(!showSummary);setShowCards(false)}} title="Show payment summary">
               {showSummary ?<Icon icon="humbleicons:times" /> : <Icon icon="heroicons-solid:menu-alt-3" />}
            </button>
        </div>)
        }
       

        {
            pageId===1?
            <CardDetail cardVisibility={showCards} data={setData} cardDet={cardDet} updateCardDet={updateCardDet} processing={buttonLoading} amount={transDet?.amt} reference={params.ref ? params.ref : ''} />

            :

            pageId===2?

            <div className={`${styles.pin}`}>
                <div className="selectCardInfo fadeInUp delay-0-1s animated">
                    <div>
                        <img src={data.logo} alt=" "/>
                    </div>
                    <Typography component='div'><strong>xxxx-xxxx-{data.cardNum.slice(data.cardNum.length-4)}</strong></Typography>
                </div>
                        
        <p className=" fadeInUp delay-0-1s animated">Please enter your card pin</p>
        <PVI getValue={updateCardDet}/>

        <p style={{color:'red',marginTop:-20}}>{pinError.msg}</p>
<div className={styles.detailsArea}>
    <div className="actionBtn">
                <Button text="Back" onClick={()=>setPageId(pageId-1)} icon={icon1} variant="secondary" className=" fadeInUp delay-0-3s animated"/>
                <Button text="Proceed" onClick={()=>{SendCardPin()}} disabled={pinPageLaoding} loading={pinPageLaoding} icon={icon2} className="fadeInUp delay-0-5s animated"/>
            </div>
</div>
        
    </div> : 
    
    pageId===3

    ? <div className={styles.otp}>
        {processing? 
                <div style={{display:'flex',justifyContent:'center',flexDirection:'column', alignItems:'center'}}>
                <div style={{position:'relative',width:'100px',height:'100px'}}><Spinner size={100} style={{position:"absolute"}} show={true}/></div>
                <Typography component='div' style={{textAlign:'center',fontSize:'1.2em',margin:'10px'}}>Processing ...</Typography>
                </div>
                :<>
                <Typography component='div' style={{textAlign:'center'}} className={`fadeInUp delay-0-1s animated`}>A One-Time-Password (OTP) has been sent to your phone please enter it below.</Typography>
        <GatewayInputfield onInput={(e:any)=>handleOTPInput(e.target.value)} isError={OtpError.status} errorMsg={OtpError.msg} value={otpValue.otp} autoFocus={true} style={{fontSize:"1.5rem"}} animate={`fadeInUp delay-0-3s animated`} type="text" id="opt" label="OTP"/>
        <div className="actionBtn fadeInUp delay-0-5s animated">
            <Button text="Cancel" onClick={()=>{setPageId(1); setOtpValue({...otpValue,otp:''})}} icon={icon3} variant="secondary"/>
            <Button text={optProcessing.btnText} onClick={start} icon={icon2} loading={optProcessing.status} disabled={optProcessing.status}/>
        </div>

        <div className={`fadeInUp delay-0-6s animated`}>
            <span>Didn't get a code?</span>
            <button ><strong>{ isReading? 
            <div style={{display:'flex', gap:10}}>
                <Spinner size={20}/>
                <span>Resending OTP...</span>
            </div>
            :status?<span onClick={()=>resendOTP()}>Resend OTP</span>:<span style={{color:'grey'}}>{`Resend OTP in ${mins}:${secs}`}</span> } </strong></button>
        </div>
        </> }
        

    </div>: 
     pageId===4

    ?
    <div className="paymentapproved">
        <div className="logo">
           
             <img src={approved1} alt="payment Approved" style={startShowing?{display:"block"}:{display:"none"}} />
             {startShowing2? 
                <Spinner size={100} style={{position:"absolute"}} show={startShowing?true:false}/>
                :null }
            
        </div>

        <div className="finishInfo">
            <h1>Payment Approved!</h1>
            <p>Please wait while we redirect you...</p>
            <br/>
            <small><i>DON'T CLOSE THIS PAGE</i> </small>
        </div>

       

    </div>:null



        }
    



    {/* Order summary */}

        <div className={`${styles.paymentSummary}  ${ showSummary ? styles['showSummary']:''} animated fadeIn faster` }>
            <p>Order Summary</p>

            <div className="summ-wrapper animated slideInRight faster">
                <div className="logodet">
                     <div className="logo"><img src={logo} alt="dtechpay"/></div>
                        <small>{transDet?.businessName}</small>
                        <p>{`${currency.naira}${commarize(transDet?.netAmt)}`} </p>
                 </div>

                 <div className="summ">
                    <div>
                        <span>Reference</span>
                        <span>{transDet?.ref}</span>
                    </div>
                    <div>
                        <span>Type</span>
                        <span>{transDet?.accountName}</span>
                    </div>
                <Line/>
                    
                    <div>
                        <span>Amount</span>
                        <span>{`${currency.naira}${commarize(transDet?.amt)}`}</span>
                    </div>
                    
                    <div>
                        <span>Charges</span>
                        <span>{transDet?.charges}</span>
                    </div>

                <Line/>

                    <div>
                        <span>Total</span>
                        <strong style={{color:"green",fontWeight:600}}>{`${currency.naira}${commarize(transDet?.netAmt)}`}</strong>
                    </div>

                 </div>

            </div>

        </div>


    <footer style={{fontSize:'0.8em'}}>Secured payment by <strong>DTECH SUPPORT SERVICES LIMITED</strong></footer> 

                
    </div>
}
    </div>
    </div>
    </>
    )
}

export default Gateway;