import React from "react";
import useStyles from "./styles";

export interface IWizardImprovedProps {
  page: any;
  indicators: any;
  controls: any;
  logo: any;
  style:Record<string, any>
}

export default function WizardImproved(props: IWizardImprovedProps) {
  const classes = useStyles().classes;
  const {
    page = null,
    indicators = null,
    controls = null,
    logo = null,
    style = {}
  } = props;

  return (
    <div className={`${classes.wizardMain} card-2`} style={style}>
      <div className={`${classes.wizardIndicators} card-1-1`}>{indicators}</div>
      <div className={`${classes.wizardBody}`}>{page}</div>
      <div className={`${classes.wizardControls} card-2-up`}>{controls}</div>
      <div className={`${classes.contentLogo}`}>{logo}</div>
    </div>
  );
}
