import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme)=>({
    pwd:{
        width:700, height:400,display:'flex', justifyContent:'center', alignItems:'center',
        '& .inputFields':{
          '& form':{width:'100%'},
            display:'flex', justifyContent:'flex-start',alignItems:'center', flexDirection:'column', width:'50%', paddingRight:15,
            '& span':{
                color:theme.palette.primary.main,
            },
            '& h1':{
                color:theme.palette.primary.main, marginBottom:5,
            },
            '& .pwd-wrapper':{
               width:'100%',display:'flex',justifyContent:'flex-start', alignItems:'center', flexDirection:'column',
              '& .inputbx':{
                 width:'100%',
                  '& input':{
                      fontSize:'1.2rem',
                  },
              },
            },

            '& .btn':{
                width:'100%',display:'flex',justifyContent:'flex-start', marginTop:50, alignItems:'center', paddingBottom:15,
            }
        },
    },

    illus:{
       width:'50%', position:'relative',
    '& .illus-cont':{
        width: 300, height: 250,
        // phone
        '& .gwd-image-abzs':{
          position: 'absolute',
          width: '250px',
          height: '161.77px',
          left: '-125px',
          top: '125px',
          transformStyle: 'preserve-3d',
          transform: 'translate3d(0px, 0px, 0px)',
          opacity: '0',
          animation: '0.5s linear 0.496s 1 normal forwards running gwd-gen-utvegwdanimation_gwd-keyframes',

        },
        // card
        '& .gwd-image-1p6p': {
          position: 'absolute',
          width: '150px',
          height:' 100.32px',
          top: '137px',
          left: '139px',
          opacity: '0',
          transformStyle: 'preserve-3d',
          transform: 'translate3d(-94px, 47px, 0px)',
          animation:' 0.5s linear 0.696s 1 normal forwards running gwd-gen-az87gwdanimation_gwd-keyframes',
        }
        ,
          //phone-shadow
        '& .gwd-image-1c0c' :{
          position: 'absolute',
          width: '250px',
          height: '161.78px',
          left: '17px',
          top: '44px',
          transformStyle:'preserve-3d',
          transform: 'translate3d(0px, 4px, 0px)',
          opacity: '0',
          animation: '0.2s linear 1.196s 1 normal forwards running gwd-gen-er9igwdanimation_gwd-keyframes',
    
        }, 
        //card-shadow
        '& .gwd-image-j2g4' :{
          position: 'absolute',
          height: '100px',
          width: '163px',
          left: '132px',
          top: '132px',
          transformStyle: 'preserve-3d',
          transform: 'translate3d(0px, 6px, 0px)',
          opacity:' 0',
          animation: '0.2s linear 1.196s 1 normal forwards running gwd-gen-rfbagwdanimation_gwd-keyframes'
        },
        //security-sheild
        '& .gwd-image-ap5g': {
          position: 'absolute',
          width: '75px',
          height: '148.83px',
          top: '-11px',
          left: '111px',
          opacity: '0',
          transformStyle:'preserve-3d',
          transform: 'translate3d(11.0125px, 69.195px, 0px) scale3d(0.36, 0.36, 0.36)',
          animation: '0.8s linear 1.596s 1 normal forwards running gwd-gen-1isdgwdanimation_gwd-keyframes',
        },
        //light-spot
        '& .gwd-image-1rhg':{
          position: 'absolute',
          width: '60px',
          height: '39.61px',
          left: '121px',
          top: '90px',
          opacity: '0',
          transform: 'scale3d(1, 1, 1)',
          animation: '0.8s linear 1.596s 1 normal forwards running gwd-gen-1nbhgwdanimation_gwd-keyframes',
        },

        //key
        '& .gwd-image-1vd1':{
          position: 'absolute',
          width: '40px',
          height: '31.14px',
          left: '86px',
          top: '53px',
          transformOrigin: '19.999px 15.57px 0px',
          transformStyle: 'preserve-3d',
          opacity: 0,
          transform: 'translate3d(-22.5862px, 17.6147px, 0px) rotateZ(0deg) rotateX(4.58622deg) scale3d(0.98, 0.98, 0.98)',
          animation: '0.4s linear 2.396s 1 normal forwards running gwd-gen-1bdzgwdanimation_gwd-keyframes',
        }


        

    }
 },
 "hint":{transition:'height 0.5s linear',width:"100%", padding:"0 7px",overflow:'hidden'},
  "input-hint-close":{height:0},
  "input-hint-open":{height:90, paddingTop:5},

  "val-checker":{
    paddingLeft:5, margin:0, listStyle:'none',
    "& .success":{color:'green'},
    "& .error":{color:'red'},
    "& .info":{color:'grey'},
  }
 
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;