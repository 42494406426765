import { makeStyles } from 'tss-react/mui';
// 
const useStyles = makeStyles()((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor:theme.palette.background.paper,
    '.loading-spinner': {
      border: '4px solid rgba(0, 0, 0, 0.2)',
      borderTop: `4px solid ${theme.palette.secondary.main}`,
      borderRadius: '50%',
      width: '50px',
      height: '50px',
      animation: 'spin 1s linear infinite',
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },
    '.verification-status': {
      textAlign: 'center',
      fontSize: '24px',
      marginTop: '20px',
      color:theme.palette.primary.main,
    },

  }
}));

export default useStyles;