import { darken, lighten, Theme } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'logoW' | 'indsTitle'>()((theme: Theme, _params, classes) => ({
  ind: {
    display: "block",
    boxSizing: "border-box",
    outline: "none",
    border: "none",
    textAlign: "center",
    backgroundColor: "transparent",
    padding: 8,
    width: "100%",
    position: "relative",
    color: "#555",
    "& *": {
      boxSixing: "inherit"
    },
    "&::before": {
      content: "''",
      display: "block",
      width: "100%",
      height: "5px",
      position: "absolute",
      left: 0,
      top: "50%",
      transform: "translateY(-300%)",
      backgroundColor: lighten(theme.palette.background.default, 0.7),
      transition: "all 0.4s ease-in",
      cursor: "default",
    },
    "&:last-child::before": {
      left: "-50%",
      zIndex:-1
    },
    "&:first-child::before": {
      left: "50%",
    },
    [`&.indicate .${classes.logoW}`]: {
      boxShadow: "0 0 5px #00000040",
      backgroundColor: theme.palette.primary.main,
      transition: "all 0.4s ease-in",
      "& svg": {
        color: "#fff",
        transition: "all 0.4s ease-in",
      }
    },
    "&.indicate::before": {
      backgroundColor: theme.palette.primary.main,
      transition: "all 0.5s ease-in",
    },
    [`& .${classes.indsTitle}`]: {
      opacity: 1,
    }
  },

  logoW: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    position: "relative",
    cursor: "pointer",
    backgroundColor: lighten(theme.palette.background.default, 0.7),
    transition: "all 0.5s ease-in",
    margin: "auto",
    marginBottom: 10,
    "& svg": {
      color: darken(theme.palette.background.paper,0.1),
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      transition: "all 0.5s ease-in",
      zIndex: "3",
      '@media(max-width:600px)': {
        fontSize: '0.8em'
      }
    },
  },
  indsTitle: {
    fontSize: "0.8em",
    whiteSpace: "nowrap",
    fontWeight: 700,
    '@media(max-width:600px)': {
      fontSize: '0.7em'
    }
  },
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;