// import { css } from "@emotion/css";
import { css } from "@emotion/css";
import * as React from "react";
import { createPortal } from "react-dom";
import { useUserTheme } from "../../themes";

export interface IPopoverProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  /**
   * **popIntoRoot** - a boolean value indicating if popup will be port as a direct child of the document body
   */
  popIntoRoot?: boolean;
  /**
   * **owner** - a React Ref(React.MutableRefObject) to the element that owns the popover (i.e will display under it)
   */
  owner?: React.MutableRefObject<any> | null;
  /**
   * **popoverRef** - a React Ref(React.MutableRefObject) to the popover (div container)
   */
  popoverRef?: React.MutableRefObject<any> | null;
  /**
   * **popBeside** - a boolean value to determine if the popover will pop beside its owner or not)
   */
  popBeside?: boolean;
}

/**
 * ## Popover
 *
 * A floating and absolute container that automatically a direct child of the document.body,
 * using the theme configuration and a box shadow
 * can be used for dropdowns, hints or toast, popups etc
 * allow all conventional div operation and properties
 *
 * By:
 *
 * - Academa Team
 *
 * For:
 *
 * - Academa UI
 */
export default function Popover({
  className = "",
  children = null,
  popIntoRoot = true,
  owner = null,
  style,
  popoverRef = null,
  popBeside = false,
  ...props
}: IPopoverProps) {
  const { theme } = useUserTheme();
  const popover = React.useRef<any>(null);
  const { dimension, reset } = useAsDropdown({
    owner,
    popover: popoverRef || popover,
    popBeside,
  });
  const [prevOwner, setPrevOwner] =
    React.useState<React.MutableRefObject<any> | null>(null);
  const [prevChildren, setPrevChildren] = React.useState<
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
  >(null);
  if (prevOwner !== owner || prevChildren != children) {
    setPrevOwner(owner);
    setPrevChildren(children);
    reset();
  }
  /* React.useEffect(() => {
    owner && reset();
  }, [owner]); */

  /* React.useEffect(() => {
    reset();
  }, [children]); */

  const classes = css({
    width: "auto",
    display: "inline-block",
    minWidth: "200px",
    minHeight: "20px",
    borderRadius: '5x',
    backgroundColor: theme.background.container,
    color: theme.palette.text.primary,
    boxShadow: theme.shadow.z2,
    position: "absolute",
    left: "0px",
    top: "0px",
    zIndex: 100,
    "& *": {
      fontFamily: theme.typography.fontFamily,
      // color: theme.palette.text.primary,
      // fontSize:theme.typography.fontSize
    },
    // fontFamily:theme.typography.fontFamily
  });
  const popBox = (
    <div
      ref={popoverRef || popover}
      className={`${classes} ${className}`}
      style={{ ...(owner ? dimension.style : {}), ...style }}
      {...props}
    >
      {children}
    </div>
  );
  return popIntoRoot ? createPortal(popBox, document.body) : popBox;
}

export interface IUseAsDropdown {
  top: number;
  left: number;
  height: number;
  width: number;
  style: React.CSSProperties;
}

export const useAsDropdown = ({
  owner = null,
  popover = null,
  fill = true,
  popBeside = false,
}: {
  owner: React.MutableRefObject<any> | null;
  popover: React.MutableRefObject<any> | null;
  fill?: boolean;
  popBeside?: boolean;
}) => {
  const [dim, setDim] = React.useState<IUseAsDropdown>({
    top: 0,
    left: 0,
    height: 0,
    width: 200,
    style: {},
  });

  const recalculateDim = () => {
    //if no owner (i.e popup owner not set just return)
    if (!owner?.current) return;

    //get the current dimention and position of the owner
    const dd = owner?.current.getBoundingClientRect();

    let top = dd.top + (!popBeside ? dd.height + 1 : 0); //recalculate the expected popup top position (make it display under the owner with a fixed 1px gap if popBeside is false)
    let left = dd.left + (popBeside ? dd.width + 1 : 0); //the left of the popup should be the left of its owner but if popBeside is true show it beside the owner with a fixed 1px gap
    let bottom = null; //buttom not set
    let right = null; //right not set

    //if popover element is referenced
    if (popover?.current) {
      //get the dimention and position of the pover
      const dropBDim = popover?.current.getBoundingClientRect();

      //get the curren height of the window
      const winHeight =
        document.documentElement.clientHeight || window.innerHeight;
      //get the current width of the window
      const winWidth =
        document.documentElement.clientWidth || window.innerWidth;

      //calculate the space remaing downward if will accomodate the height of the popover, if position under the owner with the 1px fixed gap
      const spacedown = winHeight - top;
      if (spacedown < dropBDim.height) {
        //if the space downward will not accomodate the popover, then calculate the buttom, so that it will be imediately ontop of the owner, with the 1px fixed gap
        if(!popBeside){
          const expbottom = (winHeight - dd.bottom )+  (dd.height + 1);
        top = winHeight - (expbottom + dropBDim.height);
        }
        
        //we are not considering covering owner so we just reduse top to accomodeate popover height
        if(popBeside || top < 0){
          top = winHeight - dropBDim.height;
        }
        
        //check if the space upward is still not enought for the popover height
        /* const spaceup = winHeight - bottom;
        if (spaceup <= dropBDim.height) {
          bottom = null; //return button setting back to null
          //remove the excess from the top so as to make sure all the popover is showing
          top = top - (dropBDim.height - spacedown);
        } */
      }

      //fill props indicate, we want the with of the popover tobe same as the width of its owner
      //but if width of its owner is less than 200, use 200.
      //if fill is false, just maintain the width of the popover
      const endWidth = fill
        ? dd.width < 200
          ? 200
          : dd.width
        : dropBDim.width;

      // manage if the popover overflow horizontally
      //calculate the space after the popover
      //if negative set the right of the popover to the the right of the owner
      const spaceRemX = winWidth - (dd.left + endWidth);
      if (spaceRemX <= 0) {
        right = winWidth - (dd.right + (popBeside ? dropBDim.width + 1 : 0));
        right = right + dropBDim.width + 1 > winWidth ? 0 : right;
        // right = 0;
      }
    }
    // top = top <= 0 ? 0 : top;
    left = left <= 0 ? 0 : left;
    top = top <= 0 ? 0 : top;
    setDim({
      top: top,
      left: left,
      height: dd.height,
      width: dd.width,
      style: {
        // transform: `translate3d(${Math.floor(dim.left)}px, ${Math.floor(dim.top + dim.height)}px, 0px)`,
        left: right === null ? `${Math.floor(left)}px` : "unset",
        right: right !== null ? `${Math.floor(right)}px` : "unset",
        bottom: bottom !== null ? `${Math.floor(bottom)}px` : "unset",
        top: bottom == null ? `${Math.floor(top)}px` : "unset",
        width: `${fill ? dd.width : "auto"}px`,
        willChange: "left, top, width, right, bottom, position",
        position: "fixed",
      },
    });
  };
  React.useEffect(() => {
    setTimeout(() => {
      recalculateDim();
      // window.document.body.style.overflow = 'hidden';
      window.addEventListener("scroll", recalculateDim, true);
      window.addEventListener("resize", recalculateDim, true);

      return () => {
        //   window.document.body.style.overflow = 'inherit';
        window.removeEventListener("scroll", recalculateDim);
        window.removeEventListener("resize", recalculateDim);
      };
    }, 0);
  }, []);
  return { dimension: dim, reset: recalculateDim };
};
