// import React, { useEffect, useState, memo} from "react";
// import useStyles from "./styles";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser } from '@fortawesome/free-regular-svg-icons';
// import Spinner from "../spinner"
// import { Typography, useTheme } from "@mui/material";

// interface IButtonProps {
//     className?:string,
//     style?:Record<string,any>,
//     fontStyle?:Record<string,any>,
//     icon?:any,
//     text?:any,
//     variant?:string,
//     loading?:any,
//     onClick?:any,
//     reversed?:boolean,
//     // disabled?:boolean,
// }

// const Button = (props: any) => {
//   const theme = useTheme();
//   const classes = useStyles().classes;      
//   const {style = {}, fontStyle, reversed=false, className ='', onClick=null, loading=false, icon=<FontAwesomeIcon icon={faUser} />, text='SIGNUP', variant='primary', ...rest} = props;
//   const [showSecond, setShowSecond] = useState(false);
//   const [icon1, setIcon1] = useState(icon);
//   const [icon2, setIcon2] = useState(icon);

//   // const monClick = useCallback(onClick,[]);
//   useEffect(()=>{
//     setShowSecond(!showSecond);
//   },[icon])
  
//   useEffect(()=>{
//     showSecond?setIcon2(icon):setIcon1(icon)
//   },[showSecond])

//   const [showSecondText, setShowSecondText] = useState(false);
//   const [text1, setText1] = useState('');
//   const [text2, setText2] = useState('');
//   const [sizer, setSizer] = useState<any>('');

//   useEffect(()=>{
//     setShowSecondText(!showSecondText);
//   },[text])

//   useEffect(()=>{
//     showSecondText?setText2(text):setText1(text)
//     setSizer(<div className="ttt">{text}</div>);
//   },[showSecondText])
  
//   return (
//     <button type="button" {...rest} onClick={onClick} className={`${classes.mainBox} card-1 ${reversed?classes.reverse:''} ${showSecondText?'showsecondtext':''} ${showSecond?'showsecond':''} ${className}`} style={variant=='primary'?{backgroundColor:theme.palette.primary.main,...style}:{backgroundColor:theme.palette.secondary.main,...style}}>
//       <div className="logo">
//       <Spinner className={variant==="secondary" ?"brightenSpinner":''} show={loading} style = {{ position:'absolute'}} />
//           {icon &&
//         <div className="progress-icon">
//              <div className={`${classes.icon} first`}>{icon1}</div><div className={`${classes.icon} second`}>{icon2}</div>
//         </div>
//           }
//       </div>
//       <div className="text">
//         <Typography component={'div'} style={fontStyle}>
//         <div className={`firstText ${classes.text}`}>{text1}</div>
//         <div className={`secondText ${classes.text}`}>{text2}</div>
//         <div className="sizing">{sizer}</div>
//         </Typography>
//       </div>

//     </button>
//   );
// };

// export default memo(Button);

import React, { useState, memo } from "react";
import useStyles from "./styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import Spinner from "../spinner";
import { Typography, useTheme } from "@mui/material";

interface IButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>{
  className?: string;
  style?: Record<string, any>;
  fontStyle?: Record<string, any>;
  icon?: any;
  text?: any;
  variant?: string;
  loading?: any;
  onClick?: any;
  reversed?: boolean;
  spinnerSize?:'big' | 'small'
}

const Button = (props: IButtonProps) => {
  const theme = useTheme();
  const classes = useStyles().classes;
  const {
    style = {},
    fontStyle,
    reversed = false,
    className = '',
    onClick = null,
    loading = false,
    icon = <FontAwesomeIcon icon={faUser} />,
    text = 'SUBMIT',
    variant = 'primary',
    spinnerSize = 'big',
    ...rest
  } = props;

  const [showSecond, setShowSecond] = useState(false);
  const [showSecondText, setShowSecondText] = useState(false);

  const toggleShowSecond = () => {
    setShowSecond(prevState => !prevState);
  };

  const toggleShowSecondText = () => {
    setShowSecondText(prevState => !prevState);
  };

  const icon1 = showSecond ? null : icon;
  const icon2 = showSecond ? icon : null;

  const text1 = showSecondText ? null : text;
  const text2 = showSecondText ? text : null;


  const buttonStyle = variant === 'primary'
    ? { backgroundColor: theme.palette.primary.main, ...style }
    : { backgroundColor: theme.palette.secondary.main, ...style };

  const logoClasses = `${classes.mainBox} card-1 ${reversed ? classes.reverse : ''} ${showSecondText ? 'showsecondtext' : ''} ${showSecond ? 'showsecond' : ''} ${className}`;

  const spinnerStyle = variant === "secondary" ? "brightenSpinner" : '';

  const sizer = <div className="ttt">{text}</div>;

  return (
    <button type="button" {...rest} onClick={onClick} className={logoClasses} style={buttonStyle}>
      <div className="logo">
        <Spinner className={spinnerStyle} size={spinnerSize==='small'?30:40} show={loading} style={{ position: 'absolute' }} />
        {icon &&
          <div className="progress-icon">
            <div className={`${classes.icon} first`}>{icon1}</div>
            <div className={`${classes.icon} second`}>{icon2}</div>
          </div>
        }
      </div>
      <div className="text">
        <Typography component={'div'} style={fontStyle}>
          <div className={`firstText ${classes.text}`}>{text1}</div>
          <div className={`secondText ${classes.text}`}>{text2}</div>
          <div className="sizing">{sizer}</div>
        </Typography>
      </div>
    </button>
  );
};

export default memo(Button);

