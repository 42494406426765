import React from "react";
import useStyles from "./styles";
interface ISpinnerProps{
    style?:Record<string,any>
    size?:any,
    show?:boolean,
    className?:string
}
const Spinner = (props:ISpinnerProps) => {
    const classes = useStyles().classes;
    const {style, size='40', className, show=true} = props;
  return (
    <div className={`progress ${classes.progress} ${show?'show':'hide'} ${className}`} style={{...style,height:`${size}px`,width:`${size}px`}}>
      <div className="circle-bx" style={{height:`${size}px`,width:`${size}px`}}>
        <svg className="spinner-container tray" viewBox="0 0 44 44" style={{height:`${size}px`}}>
          <circle
            className="path"
            cx="22"
            cy="22"
            r="20"
            fill="none"
            strokeWidth="4"
          ></circle>
        </svg>
        <svg
          className="animateProgress spinner-container main"
          viewBox="0 0 44 44" style={{height:`${size}px`}}
        >
          <circle
            className="path cen-stroke-color-fade"
            cx="22"
            cy="22"
            r="20"
            fill="none"
            strokeWidth="4"
            style={{ strokeDasharray: "39,150" }}
          ></circle>
        </svg>
      </div>
    </div>
  );
};

export default React.memo(Spinner);
