import { darken, Typography } from "@mui/material";
import theme from "../../../../resource/theme";
import { Icon } from '@iconify/react';
import ReturnBar from "../../components/returnBar";
import { useNavigate } from "react-router-dom";
import SimpleButton from "../../../../components/simpleButton";
import React, { useEffect, useState, memo} from "react";
import empty from "./images/emptycart.svg";
import useStyles from "./styles";
import BusinessForm from "../../../businessFormMud";
import Businesses from "../../../businessesMod";
import Services from "../../../servicesMod";
import { error, setAppStorage, isObject, getAppStorage } from "../../../../utilities/appHelpers";
import { getAllBusiness, getBusinessByCode } from "../../../../services/bussiness/";
import { Loader } from "../../../../components/loader";
import ServiceDetails from "../../../serviceDetailsMod";
import {messasgeType, ROLES, SessionCheck, useGlobalState } from "../../../../pages/dashboard";
export enum Pages {
  "noBusiness" = 0,
  "createBusiness" = 1,
  "yourBusiness" = 2,
  "addServices" = 3,
  "businessDetails" = 4,
  "others" = 5
}

export interface IBusinessModProps {
  className?: string,
  style?: Record<string, any>,
}

 function BusinessMod(props: IBusinessModProps) {
  const classes = useStyles().classes;
  const navigate = useNavigate();

  const toDashboard = () => {
    navigate("/dashboard");
  }

  const [business, setBusiness] = useState <any>();
  const [path, setPath] = useState(<></>);
  const [appState, setAppState] = useState <Record<string, any>>({res:[], loading:true,status:'',msg:'',isMsg:false,count:0, msgType:messasgeType.default});
  const [businessDetails, setBusinessDetails]=useState<Record<string,any>>([]);
  const [isData,setIsData] = useState(1),[isData2,setIsData2] = useState(0);
  const [refreshing,setRefreshing] = useState(1);
  const [bizData,setBizData]=useState([]);

  const [hintState,setHintState] = useGlobalState("hint");

  //functions
  useEffect(()=>{
  (async()=>{
      setAppState({...appState,loading:true});
      try {
        const resp:Record<string, any> = await getAllBusiness();
        if(resp.status===201 || resp.status===200){
          setAppStorage('bizRecord',resp.data.data);
          setAppState({...appState,loading:false});
        setIsData((n)=>n+1);

      }

    
    } catch (e:any) {
      setAppState({...appState,msg:error.handle(e,"You're offline. Please check your network and try again",true), isMsg:true,loading:false, count:appState.count+1, msgType:messasgeType.network});
      bizData.length<1 && !getAppStorage('bizRecord') && setBusiness(Pages.noBusiness);
      SessionCheck(e);

    }
  })()
  
},[refreshing]);


//get already loaded data and display
useEffect(()=>{
  const savedTransRec = getAppStorage('bizRecord');
  if(savedTransRec && bizData.length>0 && JSON.stringify(savedTransRec) === JSON.stringify(bizData)){
    setAppState({...appState,loading:false});
    setBusiness(Pages.yourBusiness);

  }else if(savedTransRec && savedTransRec.length>0 && JSON.stringify(savedTransRec) !== JSON.stringify(bizData)){
    setBizData(savedTransRec);
    setAppState({...appState,loading:false});
    
  }
  if(bizData.length<1 && savedTransRec && savedTransRec.length<1){
    setBusiness(Pages.noBusiness);
  }
},[isData,bizData])

const [refreshDet, setRefreshDet]=useState(0);

  useEffect(()=>{
    handleExplore(getAppStorage('bizCode'))
  },[refreshDet])

  const handleExplore = async(c:string, data?:Array<any>)=>{
  if(c===null)return;
  setAppStorage('bizCode',c);
    setAppState({...appState,loading:true});
   try {
    const resp =  await getBusinessByCode(c);
    if(resp.status===200){
      setAppStorage('buzDet',resp.data.data);
      setIsData2(n=>n+1);
    }
    
  } catch (e:any) {
    setAppState({...appState,isMsg:true, msg:error.handle(e,"You're offline. Please check your network and try again",true), count:appState.count+1, msgType:messasgeType.network})
    SessionCheck(e);

   }
  }
  
  //compare saved data and fetch appropriately
  useEffect(()=>{
    const savedBuzDet = getAppStorage('buzDet');
    if(savedBuzDet && isObject(businessDetails) && JSON.stringify(savedBuzDet) === JSON.stringify(businessDetails)){
      setAppState({...appState,loading:false}); setBusiness(Pages.businessDetails);
      
    }else if(savedBuzDet && isObject(savedBuzDet) && JSON.stringify(savedBuzDet) !== JSON.stringify(businessDetails)){
      setBusinessDetails(savedBuzDet);setAppState({...appState,loading:false}); setBusiness(Pages.businessDetails);
    }
  },[isData2])
  
  
  useEffect(() => {
    setPath(<div className={`${classes.navWrapper}`}>
      <ReturnBar title='DashBoard' onClick={() => { toDashboard() }} style={{width: "fit-content"}}></ReturnBar>

      {business === Pages.noBusiness && (<ReturnBar title="Business" onClick={() => { setBusiness(Pages.noBusiness)}} style={{width:"fit-content"}}></ReturnBar>)}

      {business === Pages.createBusiness && (<>
        <ReturnBar title="Business" onClick={() => { setBusiness(Pages.yourBusiness);}} style={{width: "fit-content"}}></ReturnBar>
        <ReturnBar title="Add Business" onClick={() => { setBusiness(Pages.createBusiness) }} style={{width: "fit-content"}}></ReturnBar>
      </>)}

      {business === Pages.yourBusiness && (<ReturnBar title="Business" onClick={() => { setBusiness(Pages.yourBusiness) }} style={{width: "fit-content"}}></ReturnBar>)}
      {business === Pages.businessDetails && (<>
        <ReturnBar title="Business" onClick={() => { setBusiness(Pages.yourBusiness); setRefreshing((n)=>n+1)}} style={{width: "fit-content"}}></ReturnBar>
        <ReturnBar title="Services" onClick={() => { setBusiness(Pages.businessDetails) }} style={{width: "fit-content"}}></ReturnBar>
      </>)}
      
      {business === Pages.addServices && (<>
        <ReturnBar title="Business" onClick={() => { setBusiness(Pages.yourBusiness);setRefreshing((n)=>n+1) }} style={{width: "fit-content"}}></ReturnBar>
        <ReturnBar title="Services" onClick={() => { setBusiness(Pages.businessDetails);setRefreshDet(n=>n+1) }} style={{width: "fit-content"}}></ReturnBar>
        <ReturnBar title="Add Services" onClick={() => { setBusiness(Pages.addServices) }} style={{width: "fit-content"}}></ReturnBar>
      </>)}
    </div>)}, [business]);
    
    useEffect(()=>{
      setHintState({...hintState,status:appState.isMsg, msg:appState.msg, icon:appState.msgType})
    },[appState.count])

  return (
    <>
    {/* <Hint message={appState.msg} show={appState.isMsg} onClose={(stat:boolean)=>setAppState({...appState,isMsg:stat})} pauseOnMouseHover={true} escButton={true} icon={<Icon icon="mdi:access-point-network-off"/>}/> */}
    <div>
      <div style={{display: "flex", width: "100%"}}>
        {path}
      </div>

      {appState.loading && (<Loader show={appState.loading}/>)}

      {business === Pages.noBusiness &&(
        <div>
          <Typography component="h2" className="fadeInUp animated faster" style={{ margin: "20px 0 10px", fontSize: 20, fontWeight: 600 }}>Business</Typography>
          <div style={{ margin: "20px 0", textAlign: "center" }} className="fadeIn animated delay-0-4s">
            <div className={classes.imgCont} style={{ color: darken(theme.palette.background.paper, 0.2), margin: "20px 0" }}>
              {/* <Icon icon="bi:info-circle" width="100%" height="100%" /> */}
              <img src={empty} alt="empty business"/>
              </div>
            <Typography component="div" style={{ fontSize: "1.2em", margin: "20px 0" }}>You currently do not have any businesses</Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {(ROLES?.BUSINESS_MODULE?.privileges?.ADD_BUSINESS ?? false) && <SimpleButton onClick={() => { setBusiness(Pages.createBusiness) }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ padding: "0 5px" }}><Icon icon="ic:baseline-add-business" width="24" height="24" /></div>
                  <div>Add a business</div>
                </div>
              </SimpleButton>}
            </div>
          </div>
        </div>
      )}

      {business === Pages.createBusiness &&(
        <BusinessForm onSubmitSuccess={(stat:any)=>{stat && setAppState({...appState,status:stat,msg:"Successfully added Business",isMsg:stat, count:appState.count+1, msgType:messasgeType.success});setRefreshing(refreshing+1); !appState.loading && setBusiness(Pages.yourBusiness); }}/>
      )}
      
      {business === Pages.yourBusiness &&(
        <Businesses createBusinessBtn={() =>setBusiness(Pages.createBusiness)} data={bizData} 
        onClickAdd={(code:string) =>{setBusiness(Pages.addServices);setAppStorage('bizCode',code)}} onClickExplore={handleExplore}/>
        
        )
      }


      {business === Pages.addServices &&(
        <Services onAdded={(stat:boolean)=>{setBusiness(Pages.businessDetails);setRefreshDet(n=>n+1);setAppState({...appState,status:stat,msg:"Service(s) Successfully added",isMsg:stat, count:appState.count+1, msgType:messasgeType.success});}}/>
      )}

      {business === Pages.businessDetails &&(
        <ServiceDetails addServiceAction={()=>setBusiness(Pages.addServices)} data={businessDetails}/> 
      )}

      
    </div>
    </>
  );
}

export default memo(BusinessMod);