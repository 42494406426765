
import { makeStyles } from 'tss-react/mui';
// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles= makeStyles()(()=>({
    cont:{
    display:'flex',justifyContent:'center',alignItems:'center',
        width:35, height:35, position:'absolute', top:'50%', right:0, transform:"translateY(-50%)", zIndex:5,
        "& .icon":{
            animation:'zoomInShort2 0.6s 0.3s ease-out both', color:"#1ede8c", position:'absolute', 
        },
        "& .success":{
            color:"#1ede8c",
        },
        "& .error":{
            color:"#e11414d9",
        }

    }
}));