import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme)=>({
  main: {
    fontSize: "0.8em",
    width: "100%",
    height: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.secondary.main,
    
  },
  button: {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    display: "flex",
    padding: "5px 0",
    alignItems: "center",
    color: theme.palette.secondary.main,
    cursor: "pointer"
  },

  navWrapper:{"&":{
    display: 'flex'

    },
    "& span:last-of-type":{
      color:'red',
    }
  }


}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;