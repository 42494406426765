
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme)=>({
    homeStyles:{
        width:'100%',
        boxSizing:'border-box',
        overflowX:'hidden',
        '& .bg-waves, & .herobox':{
            position:'absolute', boxSizing:'border-box',
            top:'0', left:'0',width:'100%', height:'100vh',

        },
        ' & .bg-waves':{ overflow:'hidden',
            '& .dtp-home-curve':{
                filter:' drop-shadow( -4px 4px 2px rgba(0, 0, 0, .5))', height: '120%', width: '100%', fill:theme.palette.primary.main,
            },
            '& .dtp-home-curve path':{
                stroke: 'none', 
            }
        }
        ,
        '& .hero-box':{
            display:'flex', justifyContent:'space-between', alignItems:'center',padding:'0 8px', backgroundColor: theme.palette.background.paper,
            '& .text-cont':{
                width:'50%', height:'100vh',padding:'0 8px',  
                 [theme.breakpoints.down('sm')]: {
                     width:'100%', margin:0
                
                }
            },
           
            '& .illustion-cont':{
                display:'flex',justifyContent:'center',alignItems:'center', position:'relative', width:'50%', height:'100vh',padding:'0 8px',
                 [theme.breakpoints.down('sm')]: {
                    margin:'auto', width:'calc(100% - 80px)', height:'auto'
                
                },
                '& img':{
                    width:'100%', height:'auto', position:'absolute', zIndex:'5'
                }
            },
            '& .animatedTextCont':{
                minHeight:'345px',
                [theme.breakpoints.down('sm')]: {
                    minHeight:'300px',
            }
            },
            '& .cta-cont button':{
                [theme.breakpoints.down('sm')]: {
                    width:'80%', zIndex:10,
                },
                // width:'calc(100% - 500px)',
                 zIndex:10,
            },
            '& .cta-cont':{
                [theme.breakpoints.down('sm')]: {
                    position:'relative', zIndex:10,transform:'translateY(15rem)', justifyContent:'center',
                }
            },


            [theme.breakpoints.down('sm')]: {
                flexDirection:'column',
            }
        }
        
    },
  

}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;