
import { Theme } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'inputLabel' | 'errorDisplay'>()((theme: Theme, _params, classes) => ({
  dynamicInputBox: {
    boxSizing: "border-box",
    padding: "8px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    position: "relative",
    marginTop: "18px",
    "& *": {
      boxSizing: "border-box",
    },
  },
  inputLabel: {
    position: "absolute",
    zIndex: 1,
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "text",
    display: "block",
    border: "none",
    color: '#222',
    backgroundColor: "transparent",
    fontWeight: "bold",
    outline: "none",
    borderRadius: "4px",
    fontSize: "1em",
    padding: "0px 4px",
    transition: "all 0.4s ease-in",
  },
  innerWrapper: {
    position: "relative",
    width: "100%",
    margin: 0,
    padding: 0,
    backgroundColor: 'inherit'
  },
  inputElement: {
    display: "block",
    width: "100%",
    color: "#000",
    border: "none",
    fontSize: "1.1em",
    padding: "2px",
    fontWeight: "bold",
    outline: "none",
    position: "relative",
    fontFamily: 'Raleway, Montserrat, Source Sans Pro, sans-serif',
    zIndex: 2,
    lineHeight: '1.5',
    backgroundColor: "transparent",
    transition: "all 0.2s ease-in",
    [`&.focused ~ .${classes.inputLabel}`]: {
      top: "0",
      color: "#fff",
      transform: "translateY(-100%)",
      backgroundColor: "rgba(0, 155, 178, 0.7)",
      borderRadius: "4px",
      fontSize: "0.8em",
      padding: "0px 4px",
      transition: "all 0.4s ease-in",
    },
    [`&.input-error ~ .${classes.errorDisplay}`]: {
      animation: 'errortbblinking 2s ease-in-out infinite',
      content: "''",
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      boxSizing: 'border-box',
      top: '5px',
      right: '5px',
      position: 'absolute',
    },
    "&:disabled":{
      cursor:'not-allowed',
    }
  },
  errorDisplay: {
    display: "none",
  },
  visibilityControlIcon: {
    position: "relative",
    display: 'block',
    width: '30px',
    cursor: "pointer",
    backgroundColor: "transparent",
    margin: "2px",
    zIndex: 3,
    "&":{display:'flex'},
    "& svg":{fontSize:'0.9rem'}
  },
  logoWrapper: {
    position: "absolute",
    display: "flex",
    width: "auto",
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto 0px',
    backgroundColor: "transparent"
  },
  verifyLogo: {
    position: "relative",
    display: 'block',
    width: '30px',
    cursor: "pointer",
    backgroundColor: "#fff",
    margin: "2px",
    zIndex: 3
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
