import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme)=>({
    mainBox:{
        width:'180px',
        height:'auto',
        boxSizing:'border-box',
        borderRadius:'5px',
        display:'flex',
        justifyContent:'center',
        backgroundColor:theme.palette.primary.main,
        '& img':{
            width:'100%'
        }
    }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;