import { ACCTYPES, BOARDING, CONTACT, COUNTRIES, LOOKUPS } from '../../utilities/constant';
import axiosInstance from '../https'

// GET REQUESTS
export const getAccountTypes =  ()=>{
    return axiosInstance.get(`${LOOKUPS}/${ACCTYPES}/`);
}

export const getCountries =  ()=>{
    return axiosInstance.get(`${LOOKUPS}/${COUNTRIES}/`);
}

// PUT REQUESTS
export const addSetupInfo =  (userRef:string, data:Record<string,any>)=>{
    return axiosInstance.put(`${BOARDING}/${CONTACT}/${userRef}/`,data);
}
