import { makeStyles } from 'tss-react/mui';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((themes)=>({
   
    cont:{
        display:"flex",
        //extra
        backgroundColor:"#fff", borderRadius:10, boxShadow:"1px 1px 20px #00000005", padding:30, minWidth:800

    },
    sideCont:{
        width:"calc(100% - 280px)",
        "@media screen and (max-width: 950px)":{
            width:'100%', justifyContent:"center", marginTop:70, textAlign:'center',
        }
    },


    menu:{
        width:"100%", display:"flex",
        "& > div":{
            display:"flex", flexDirection:"column", height:50, width:100, textAlign:'left',justifyContent:" space-evenly",
            alignItems: "flex-start", 
            "& .active ~ div":{
                width:"calc(100% - 70px)", height:2, backgroundColor:"green",
            },

            "& :not(.active)":{
                color:'grey',
            }
        },
        "& .active":{fontWeight:800},
        "& button":{
            backgroundColor: "transparent", border:"none", marginRight: "0", cursor:"pointer", padding:0,
        },

        "@media screen and (max-width: 950px)":{
            borderRadius: "5px", background: "#f2f2f2", padding: "10px 10px", gap:30,
             "& > div":{
                height:'auto', width:'auto'
             },
             "& .active ~ div":{
                width:"100% !important", borderRadius:2, marginTop:3, height:'3px !important'
            }
        }
        
    },

    detailsArea:{
        width:400, marginRight:18,
        "& .inline":{
             display:"flex",justifyContent:"space-between",
             "& > div":{
                width:"calc(50% - 10px)"
             }
        },
        "& .actionBtn":{
            display: 'flex', justifyContent: "space-between", marginTop:20,
            "& label, input":{
                cursor:"pointer"
            },
            "& label":{
                userSelect:"none", marginLeft:10
            },
            "@media screen and (max-width: 512px)":{
                width:'100%', flexDirection:"column-reverse", gap:"20px",
                 "& button":{
               width:"100%",
            },
            }
        },

        "@media screen and (max-width: 950px)":{
            width:'100%',
            "& .inline":{
                display:"flex",flexDirection:"column",
                "& > div":{
                   width:"100% !important"
                }
           },
        }
    },
    linkData:{
        marginRight:18,
        "& .inline":{
             display:"flex",justifyContent:"space-between",
             "& > div":{
                width:"calc(50% - 10px)"
             }
        },
        "& .actionBtn":{
            display: 'flex', justifyContent: "space-between", marginTop:20,
            "& label, input":{
                cursor:"pointer"
            },
            "& label":{
                userSelect:"none", marginLeft:10
            },
            "@media screen and (max-width: 512px)":{
                width:'100%', flexDirection:"column-reverse", gap:"20px",
                 "& button":{
               width:"100%",
            },
            }
        },

        "@media screen and (max-width: 950px)":{
            width:'100%',
            "& .inline":{
                display:"flex",flexDirection:"column",
                "& > div":{
                   width:"100% !important"
                }
           },
        }
    },

    cardDetails:{
        "&":{
            display: 'flex', justifyContent: "space-between", alignItems: 'flex-start',
            "& .cardlogos":{
                display: 'flex', justifyContent: "flex-start",margin:"-10px 0 30px 0",
                "& .verve":{width:47,
                "img":{
                    height:'100%',objectFit:'contain'
                }},
                "& > div":{
                    width:25, marginRight:20,display: 'flex',
                    "& img":{
                        width:"100%", height:"auto"
                    }
                }

            }
            ,
        "@media screen and (max-width: 950px)":{
            "& .cardlogos":{
                justifyContent:"center",margin:"-10px 0 30px 0", gap:'20px',
                "& > div":{
                    marginRight:'0!important',
                    
                },

            }
           
        }
        }
    },

    cards:{
        width:200, margin:"20px 0 0 0",
        "& p":{
            fontSize:'1rem', fontWeight:500, display:'none',
        },
        "& > div":{
            width:"100%", height:120, borderRadius:20, backgroundColor:"#f2f2f2", marginBottom:10,position:"relative", overflow:"hidden", cursor:'pointer',
            "& .top":{transform:"translate(43%,-66%)"},
            "& .btm":{transform:"translate(-42%,41%)"},
            "& .bgarts":{
                position:"absolute", width:200, height:150, borderRadius:"50%", backgroundColor:"#ffffff1c",
            },

            "& .newcont":{
                display: 'flex',  height:"100%", color:"#ffffffb5", 
                "& span":{marginRight:5},
            },
            "& .cardcont":{
                display: 'flex', justifyContent: "space-between", alignItems:'center', flexDirection: 'column', padding:"5px 5px", color:"#ffffffb5", height:"100%",
                "& > div":{
                    
                    width:"100%", padding:5
                    
                },
                "& .fs":{
                        display: 'flex', justifyContent: "flex-end", alignItems:'center',
                        "& div":{
                            width:40, display: 'flex', justifyContent: "center", alignItems:'center', flexDirection: 'column',
                            "& img":{
                                width:20
                            },
                            "& small":{
                                fontSize:"0.4rem",
                            }
                        }
                    },
                    "& .num":{
                        display: 'flex', 
                        "& p":{margin:0}
                    },
                    "& .names":{
                        display: 'flex', justifyContent: "space-between", 
                        "& p":{margin:0,
                             fontSize:"0.7rem",
                        }
                    }
            }
        },
        "& > div:nth-child(2)":{
            background:"linear-gradient(145deg, #f13dc375, 1%, #6730cade 76%)"
        },
        "& > div:nth-child(3)":{
            background:"linear-gradient(145deg, #c42d6d9e, 18%, #ee5a6fde, #e8b249 119%)"
        },
        "& > div:nth-child(4)":{
            background:"linear-gradient(145deg, #2db6c49e, 18%, #665aeede, #d449e8 119%)"
        },

        "@media screen and (max-width: 950px)":{
            display:"none",width: "100%", height: "calc(100% - 100px)", backgroundColor: "#ffffffad", backdropFilter: "blur(0.2rem)", position:"absolute", left:0, zIndex:1, top:70, 
            "& > p":{display:'block'},
            "& > div":{
                width:200,margin:'auto',  marginBottom:10
            }
        },

    },
    "showCards":{
         display:"block !important",
    },

    transfer: {
        display: 'flex', flexDirection: 'column', justifyContent: 'center',alignItems: 'center', gap: '16px', textAlign: 'center', marginTop: '20px',
        "& .account-number": {
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: "#f0f0f073", padding: '16px', borderRadius: 20, width: '100%',marginBlock: '16px',
            "& .acc-num": {
                color: themes.palette.primary.main
            },
        },
        "& .btn-bg": {
            backgroundColor: themes.palette.primary.main},
    }


   

}));