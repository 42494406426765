import { CATEGORIES, BILLER, BILLABLES} from '../../utilities/constant';
import axiosInstance from '../https'

export const getBillers =  ()=>{
    return axiosInstance.get(`${BILLER}/${CATEGORIES}`);
}

export const getBillerCat =  (billId:string='electricity')=>{
    return axiosInstance.get(`${BILLER}/${billId}`);
}


export const getBillables =  (data: Record <string, any>)=>{
    return axiosInstance.post(`${BILLER}/data-bundles`,data);
}
export const getCablePlans =  (data: Record <string, any>)=>{
    return axiosInstance.post(`${BILLER}/cable-plans`,data);
}
    
export const purchaseAirtime =  (data: Record <string, any>)=>{
    return axiosInstance.post(`${BILLER}/purchase-airtime`,data);
}
export const purchaseData =  (data: Record <string, any>)=>{
    return axiosInstance.post(`${BILLER}/purchase-data`,data);
}
export const purchaseCable =  (data: Record <string, any>)=>{
    return axiosInstance.post(`${BILLER}/purchase-cable`,data);
}

export const makePayment =  (data: Record <string, any>)=>{
    return axiosInstance.post(`${BILLER}/purchase-data`,data);// TODO:: set other biller endpoint here and change name
}

export const purchaseElectricityToken =  (data: Record <string, any>)=>{
    return axiosInstance.post(`${BILLER}/purchase-electricity`,data);
}
export const MetreVerification =  (data: Record <string, any>)=>{
    return axiosInstance.post(`${BILLER}/verify-meter`,data);
}