import { darken, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReturnBar from '../../components/returnBar';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Table from '../../../../components/designerTable';
import Dropdown from '../../../../components/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { getLinkAccess, getLinkStats } from '../../../../services/paymentLink';
import { dispatch, messasgeType, SessionCheck, useGlobalState } from '../../../../pages/dashboard';
import { error } from '../../../../utilities/appHelpers';
import { STable } from '../../../../components/skeletonLoader';
import useStyles from '../business/styles';
import empty from "../business/images/emptycart.svg";
import theme from "../../../../resource/theme";
import { useUserTheme } from '../../../../themes';

export interface linkStatsI {
    accessAllTime: number;
    accessToday: number;
    collectionAllTime: number;
    collectionToday: number;
}

export default function LinkAccess() {
    const navigate = useNavigate();
    const {isMobile, isTablet} = useUserTheme();
    const classes = useStyles().classes;
    const [searchStr, setSearchStr] = useState("");
    const [linkAccess, setLinkAccess] = useState<any>([]);
    const [linkStats, setLinkStats] = useState<linkStatsI>({
        accessAllTime: 0,
        accessToday: 0,
        collectionAllTime: 0,
        collectionToday: 0,
    });
    const [appState, setAppState] = useState({ loading: false, res: [], msg: '', isMsg: false, status: false, count: 0, msgType: messasgeType.default });
    const [, setHintState] = useGlobalState("hint");
    const params = useParams();

    useEffect(() => {
        (async () => {
            try {
                setAppState(prev => ({ ...prev, loading: true }));
                const id = params.id ? params.id : '';
                const resp = await getLinkAccess(id);
                if (resp.status === 200) {
                    const linkDets = resp?.data?.data.items || [];
                    setLinkAccess(linkDets);
                }
                setAppState(prev => ({ ...prev, loading: false }));
            } catch (e: any) {
                SessionCheck(e);
                setAppState(prev => ({ ...prev, isMsg: true, msg: error.handle(e, "Failed to fetch required Data, please check your network and try again", true), count: prev.count + 1, msgType: messasgeType.network, loading: false }));
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                setAppState(prev => ({ ...prev, loading: true }));
                const id = params.id ? params.id : '';
                const resp = await getLinkStats(id);
                if (resp.status === 200) {
                    const linkDets = resp?.data?.data || {};
                    setLinkStats(linkDets);
                }
                setAppState(prev => ({ ...prev, loading: false }));
            } catch (e: any) {
                SessionCheck(e);
                setAppState(prev => ({ ...prev, isMsg: true, msg: error.handle(e, "Failed to fetch required Data, please check your network and try again", true), count: prev.count + 1, msgType: messasgeType.network, loading: false }));
            }
        })();
    }, []);

    useEffect(() => {
        setHintState(prev => ({ ...prev, status: appState.isMsg, msg: appState.msg, icon: appState.msgType }));
    }, [appState.count]);


    return (
        <div>
            <div className="fadeInUp animated faster">
                <ReturnBar title="Back to Payment Links Overview" onClick={() => { navigate("/dashboard/payment"); }} style={{ width: "fit-content" }} />
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: "20px 0 20px" }}>
                    <Typography component="h2" style={{ fontSize: 20, fontWeight: 600, }}>Link Access</Typography>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                <div className="fadeIn animated delay-0-1s" style={{ backgroundColor: 'white', padding: '40px', display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', alignItems: 'center', gap: '24px' }}>
                    <div style={{display: isMobile? 'flex' : 'unset', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <Typography style={{ fontSize: 12, }}>Collection (Today) </Typography>
                        <Typography component="h2" style={{ fontSize: 20, fontWeight: 600, }}>₦{linkStats.collectionToday}</Typography>
                    </div>
                    <div style={{ width: isMobile ? '100%' : '1px', backgroundColor: 'rgba(0, 0, 0, .1)', height: isMobile ? '1px' : '50px' }}></div>
                    <div style={{display: isMobile? 'flex' : 'unset', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <Typography style={{ fontSize: 12, }}>Link Access (Today) </Typography>
                        <Typography component="h2" style={{ fontSize: 20, fontWeight: 600, }}>{linkStats.accessToday}</Typography>
                    </div>
                    <div style={{ width: isMobile ? '100%' : '1px', backgroundColor: 'rgba(0, 0, 0, .1)', height: isMobile ? '1px' : '50px' }}></div>
                    <div style={{display: isMobile? 'flex' : 'unset', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <Typography style={{ fontSize: 12, }}>All Time Collection </Typography>
                        <Typography component="h2" style={{ fontSize: 20, fontWeight: 600, }}>₦{linkStats.collectionAllTime}</Typography>
                    </div>
                    <div style={{ width: isMobile ? '100%' : '1px', backgroundColor: 'rgba(0, 0, 0, .1)', height: isMobile ? '1px' : '50px' }}></div>
                    <div style={{display: isMobile? 'flex' : 'unset', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <Typography style={{ fontSize: 12, }}>All Time Link Access </Typography>
                        <Typography component="h2" style={{ fontSize: 20, fontWeight: 600, }}>{linkStats.accessAllTime}</Typography>
                    </div>
                </div>
                {appState.loading && <STable style={{ marginTop: 20 }} />}
                {!appState.loading && linkAccess.length > 0 &&
                    <>
                        {/* <div className="fadeIn animated delay-0-2s">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ position: 'relative' }}>
                                    <div style={{ position: 'absolute', top: '15%', left: '5px' }}><Icon icon="ep:search" fontSize={20} /></div>
                                    <input type="text" value={searchStr} placeholder='Search name or email' style={{
                                        color: "#000",
                                        border: "1px solid rgba(0, 0, 0, .1)",
                                        padding: "2px 32px",
                                        outline: "none",
                                        backgroundColor: "transparent",
                                        transition: "all 0.2s ease-in",
                                        borderRadius: '5px'
                                    }}
                                        onChange={(e) => { setSearchStr(e.target.value) }}
                                    />
                                </div>
                            </div>
                            <div></div>
                        </div> */}

                        <div className="fadeIn animated delay-0-3s">
                            <Table fields={[{ name: "Customer Email", size: 25 }, { name: "Customer Name", size: 18 }, { name: "Phone Number", size: 18 }, { name: "Accessed On", size: 15 }, { name: "Amount", size: 13 }, { name: "More", size: 11 }]}
                                records={linkAccess.map((linkAcc: any, index: number) => {
                                    const dateCreated = linkAcc.dateAccessed ? linkAcc.dateAccessed.split(' ') : '';
                                    return {
                                        customeremail: <Typography>{linkAcc.email}</Typography>,
                                        customername: linkAcc.customerName,
                                        phonenumber: linkAcc.phone,
                                        accessedon: <Typography>{dateCreated[0] ? dateCreated[0] : ''} <span style={{ color: 'grey', fontSize: '10px' }}>{dateCreated[1] ? dateCreated[1] : ''}</span></Typography>,
                                        amount: linkAcc.amount,
                                        more: <Dropdown
                                            dropHandle={'button'}
                                            dropHandleChildren={<FontAwesomeIcon icon={faEllipsis} fontSize={20} />}
                                        >
                                            {({ hideDrop }: any) => {
                                                return (
                                                    <div onClick={() => { hideDrop(); }} style={{ display: 'flex', flexDirection: 'column', gap: '8px', fontSize: '12px', cursor: 'pointer', padding: '14px' }}>
                                                        <div onClick={() => { navigate(`/dashboard/payment/link/${params.id}/access/${linkAcc.id}`) }}>Preview Access Details</div>
                                                    </div>
                                                );
                                            }}
                                        </Dropdown>
                                    }
                                })} />
                        </div>
                    </>
                }
                {!appState.loading && linkAccess.length < 1 &&
                    <div style={{ margin: "20px 0", textAlign: "center" }} className="fadeIn animated delay-0-4s">
                        <div className={classes.imgCont} style={{ color: darken(theme.palette.background.paper, 0.2), margin: "20px 0" }}>
                            <img src={empty} alt="empty business" />
                        </div>
                        <Typography component="div" style={{ fontSize: "1.2em", margin: "20px 0" }}>Oops! This link has not been accessed yet</Typography>
                    </div>}
            </div>
        </div>
    );
}