import { makeStyles } from 'tss-react/mui';
// 
const useStyles = makeStyles()((theme)=>({
      // SIGNUP STYLES
      signup:{
        width:700, height:400,display:'flex', justifyContent:'center', alignItems:'center',
        '& .inputFields':{
            display:'flex', justifyContent:'center',alignItems:'center', flexDirection:'column', width:'50%', paddingRight:15,
            '& .row1':{
                width:'100%', display:'flex', justifyContent:'space-between',
                '& .inputbx':{
                    width:'48%'
                }
            },
            '& .inputbx':{
                
                width:'100%', marginTop:10,
                '& p':{
                    fontSize:'0.9rem',
                }
            },
            '& .btn':{
                width:'100%',display:'flex',justifyContent:'flex-start', marginTop:15, alignItems:'center', 
                '& .dtp-signup-tc':{
                    boxSizing: 'border-box',
                    padding: '0px 6px',
                    
                    '& a':{
                        color:theme.palette.primary.main,
                        textDecoration:'none'
                    }
                }
            }
        },
        '& .illus-wrapper':{
            width:'50%',
        },

        "@media screen and (max-width: 600px) ":{
                flexDirection:'column', height:'100%', width:"100%",
                
         
            
        },
        "& .link":{
            backgroundColor:'transparent', border:'none', color:theme.palette.secondary.main, fontWeight:600, fontSize:'1rem', paddingLeft:0, cursor:'pointer'
         }
    },

    illus:{
        width:'100%',
        '& .illus-cont':{
            display:'flex', justifyContent:'center', flexDirection:'column', width:'100%',
            '& .dtp-signup-title':{
                textAlign: 'center', padding: '5px', boxSizing: 'border-box', 
                fontSize: '2em', fontWeight: 'bold', margin: '10px 5px',
            },

            '& .dtp-signup-side-image':{
                width: 'calc(100% - 20px)', margin: 'auto', maxWidth: '400px', position: 'relative', height: 220,

                '& .gwd-image-1sum':{
                    position: 'absolute',
                    width: '100px',
                    height: '127px',
                    transformStyle: 'preserve-3d',
                    left: '97px',
                    top: '46px',
                    transform: 'translate3d(0px, 0px, 0px) scale3d(0.8, 0.8, 0.8)',
                  },
                  '& .gwd-image-1jod':{
                    position: 'absolute',
                    left: '71px',
                    top: '30px',
                    width: '150px',
                    height: '190.5px',
                    transform: 'scale3d(0.8, 0.8, 0.8)',
                  },

                  '& .gwd-image-1kk0':{
                    position: 'absolute',
                    width: '100px',
                    height: '127px',
                    zIndex: '-1',
                    top: '46px',
                    transformStyle:'preserve-3d',
                    left: '96px',
                    transform: 'translate3d(0px, 0px, 0px) scale3d(0.8, 0.8, 0.8)',
                  }
                
            },
           
        },
            '& .text-color':{
            color:theme.palette.primary.main,
        },
 },
"hint":{transition:'height 0.5s linear',overflow:'hidden'},
 "input-hint-close":{height:0,},
 "input-hint-open":{height:90,width:"100%", padding:"7px 7px"},

 "hint-btn":{"&":{
    display: 'flex', justifyContent:'space-between', width:'60%', marginTop:5, 
    "& button":{
        background: 'transparent', border:'none',fontWeight:600, color:theme.palette.primary.main, borderRadius:5, padding:"5px 8px",cursor:'pointer',
    },
    "& button:last-child":{
        background: 'rgb(154 201 255 / 26%)',fontWeight:600, color:theme.palette.primary.main, 
    },
    "& button:first-child":{
        background:"rgb(144 186 234 / 33%)", border:'thin solid #0000ff2e', color:theme.palette.primary.main,
    }
 }
},
 "hint-btn-sugg":{"&":{
    display:'flex',justifyContent:'flex-start', width:'100%', marginTop:5, 
    "& button":{
        background: 'rgb(154 201 255 / 26%)', border:'thin solid #0000ff2e',fontWeight:600, color:theme.palette.primary.main, borderRadius:5, padding:"5px 8px",cursor:'pointer', marginRight:15
    }

    
 }
 },
 "val-checker":{
    paddingLeft:5, margin:0, listStyle:'none',
    "& .success":{color:'green'},
    "& .error":{color:'red'},
    "& .info":{color:'grey'},
  }
    

}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;