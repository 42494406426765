import { alpha, lighten } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'copyIcons'>()((theme, _params, classes) => ({
  main: {
    width: "100%",
    backgroundColor: "#fff",
    boxSizing: "border-box",
    "& *": {
      boxSizing:"inherit"
    }
  },
  smallTitle:{
    fontSize:"0.7em",
    margin: "15px 0 5px"
  },
  icon: {
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.background.paper,
    margin: "0 10px",
    padding: "5px",
    "& svg": {
      width: "100%",
      height: "100%",
      color: lighten(theme.palette.primary.main, 0.2),
    }
  },
  headerLg: {
    fontSize: 20,
    fontWeight: 600,
  },
  headerMd: {
    fontSize: 17,
    fontWeight: 600,
  },
  section: {
    margin: "20px 0",
  },
  sectionHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "20px 0"
  },
  twoCol: {
    display: "flex",
    "&>*":{
      width: "50%",
    }
  },
  topStats: {
    // backgroundColor: alpha(lighten(theme.palette.primary.main, 0.2), 0.1),
    padding: "40px 20px"
  },
  leftCol: {
    paddingRight: 10,
  },
  rightCol: {
    paddingLeft: 10,
  },
  insideCol: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  desCard: {
    width: "100%",
    borderRadius: "4px",
    overflow: "hidden",
    backgroundColor: "#fff",
    margin: "20px 0",
    padding: 10
  },
  rowTitle: {
    fontWeight: 600
  },
  rowBody: {
    padding: 3
  },
  desItem: {
    border: "none",
    outline:"none",
    backgroundColor: alpha(lighten(theme.palette.primary.main, 0.2), 0.2),
    borderRadius: "4px",
    padding: "5px 10px",
    fontWeight: 600,
    color: lighten(theme.palette.primary.main, 0.2),
    width: "fit-content",
  },
  copyable: {
    display: "flex",
    [`&:hover .${classes.copyIcons}`]: {
      opacity: "1",
      transition: "0.3s ease",
    }
  },
  copyIcons: {
    opacity: "0",
    transition: "0.3s ease",
    cursor: "pointer",
    // padding: "5px",
    // border: `1px solid ${alpha(lighten(theme.palette.primary.main, 0.2), 0.2)}`,
    "& *": {
      color: theme.palette.primary.main,
      margin: "0px 5px"
    }
  },
  smPad: {
    padding: 2
  },
  mdPad: {
    padding: "5px 2px",
  },
  compactRow: {
    padding: "0px 20px",
    cursor: "default"
  },
  plainArea: {
    border: "none",
    borderRadius: "4px",
    outline:"none",
    resize: "none",
    height: "fit-content",
    wordWrap: "break-word",
    width:"90%",
    "&:focus":{
      outline: "1px solid #000"
    },
  },
  searchBox:{
    border: `1px solid ${alpha(lighten(theme.palette.primary.main, 0.2), 0.2)}`,
    borderRadius: "4px",
    backgroundColor: "#fff",
    padding: "0 4px",
    "& input": {
      border: "none",
      outline:"none",
      margin: "0px 10px"
    },
    display: "flex",
    alignItems: "center"
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;