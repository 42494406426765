import { alpha, lighten } from "@mui/material";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'copyIcons' | 'inputLabel'>()((theme, _params, classes) => ({
  main: {
    width: "100%",
    backgroundColor: "#fff",
    boxSizing: "border-box",
    "& *": {
      boxSizing:"inherit"
    }
  },
  smallTitle:{
    fontSize:"0.7em",
    margin: "15px 0 5px"
  },
  icon: {
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.background.paper,
    margin: "0 10px",
    padding: "5px",
    "& svg": {
      width: "100%",
      height: "100%",
      color: lighten(theme.palette.primary.main, 0.2),
    }
  },
  headerLg: {
    fontSize: 20,
    fontWeight: 600,
  },
  headerMd: {
    fontSize: 17,
    fontWeight: 600,
  },
  section: {
    margin: "20px 0",
  },
  sectionHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "20px 0"
  },
  twoCol: {
    display: "flex",
    "&>*":{
      width: "50%",
      [theme.breakpoints.down('sm')]:{
        width:"100%",
      }
    },
      
    '& .statsHeader':{
      display: 'flex', justifyContent: "space-betwwen", padding:'0 10px'
    },
  },

  topStats: {
    // backgroundColor: alpha(lighten(theme.palette.primary.main, 0.2), 0.1),
    padding: "40px 20px",
    [theme.breakpoints.down('sm')]:{
      padding:0,
        flexDirection:'column', 
    }
  },
  wrppa:{'&':{
    display: 'flex', justifyContent: "space-between", width:'100%',

    
  }},
  mobrow:{
      [theme.breakpoints.down('sm')]:{
        flexDirection:'column', 
      }
  }
  ,
  leftCol: {
    paddingRight: 10,
  },
  rightCol: {
    paddingLeft: 10,
    [theme.breakpoints.down('sm')]:{
      paddingLeft:0,
    }
  },
  insideCol: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  desCard: {
    width: "100%",
    borderRadius: "4px",
    overflow: "hidden",
    backgroundColor: "#fff",
    margin: "20px 0",
    padding: 10
  },
  rowTitle: {
    fontWeight: 600
  },
  rowBody: {
    padding: 3
  },
  desItem: {
    border: "none",
    outline:"none",
    backgroundColor: alpha(lighten(theme.palette.primary.main, 0.2), 0.2),
    borderRadius: "4px",
    padding: "5px 10px",
    fontWeight: 600,
    color: lighten(theme.palette.primary.main, 0.2),
    width: "fit-content",
  },
  copyable: {
    display: "flex",
    [`&:hover .${classes.copyIcons}`]: {
      opacity: "1",
      transition: "0.3s ease",
    }
  },
  copyIcons: {
    opacity: "0",
    transition: "0.3s ease",
    cursor: "pointer",
    // padding: "5px",
    // border: `1px solid ${alpha(lighten(theme.palette.primary.main, 0.2), 0.2)}`,
    "& *": {
      color: theme.palette.primary.main,
      margin: "0px 5px"
    }
  },
  smPad: {
    padding: 2
  },
  mdPad: {
    padding: "5px 2px",
  },
  compactRow: {
    padding: "0px 20px",
    cursor: "default"
  },
  plainArea: {
    border: "none",
    borderRadius: "4px",
    outline:"none",
    resize: "none",
    height: "fit-content",
    wordWrap: "break-word",
    width:"90%",
    "&:focus":{
      outline: "1px solid #000"
    },
  },
  searchBox:{
    border: `1px solid ${alpha(lighten(theme.palette.primary.main, 0.2), 0.2)}`,
    borderRadius: "4px",
    backgroundColor: "#fff",
    padding: "0 4px",
    "& input": {
      border: "none",
      outline:"none",
      margin: "0px 10px"
    },
    display: "flex",
    alignItems: "center"
  },

  popup:{
    backgroundColor:'#35ca94',color:"#fff", border:'thin solid lightgrey',
    borderRadius:4, padding:10, position:'absolute', top:0,transform:'translate(-50%, 200%)',left:'50%', fontSize:'0.8rem', zIndex:-1
  },
  popup2:{
    backgroundColor:'#35ca94',color:"#fff", border:'thin solid lightgrey',
    borderRadius:4, padding:10, position:'absolute', top:-8,transform:'translate(-50%, 200%)',left:'50%', fontSize:'0.8rem', zIndex:-1, opacity:0
  },

  accountForm:{
    width:400, padding:30, height:400,
    '&':{display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column"},
    "& form":{
      display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", width:'100%',
      "& .btnCont":{
        width:'100%', marginTop:10, display: 'flex', justifyContent: "flex-end",
      }
    },
    "& .imgCont":{
      width:"100%", height:150,display: 'flex',
      "& img":{
        width:"100%", height:"100%", objectFit:'contain'
      }
    },
    [theme.breakpoints.down('sm')]: {
      width:'100%', marginTop:'25%'
    }
  },


  inputLabel: {
    position: "absolute",
    zIndex: 1,
    top: "36%",
    transform: "translateY(-50%)",
    cursor: "text",
    display: "block",
    border: "none",
    // color: '#222',
    // backgroundColor: "transparent",
   
    fontWeight: "bold",
    outline: "none",
    borderRadius: "4px",
    padding: "2px 5px",
    // fontSize: "0.8em",
    transition: "all 0.4s ease-in",
    // make label up temporary
    fontSize: "0.7em",
    backgroundColor: "rgba(0, 155, 178, 0.7)",
    left:8,color:'#fff'
  },
  ".ff": {
    top: "36%",
    color: "#fff",
    transform: "translate(10%,100%)",
    backgroundColor: "rgba(0, 155, 178, 0.7)",
    borderRadius: "4px",
    fontSize: "0.8em",
    padding: "3px 5px",
    transition: "all 0.4s ease-in"
  },
  inputElement: {
    display: "block",
    width: "100%",
    color: "#000",
    border: "none",
    fontSize: "1.1em",
    padding: "2px 12px",
    fontWeight: "bold",
    outline: "none",
    position: "relative",
    fontFamily: 'Raleway, Montserrat, Source Sans Pro, sans-serif',
    zIndex: 2,
    lineHeight: '1.5',
    backgroundColor: "transparent",
    transition: "all 0.2s ease-in",
    textAlign: "left",
    [`&.focused ~ .${classes.inputLabel}, &.focused.textarea ~ .${classes.inputLabel}`]: {
      top: "0",
      color: "#fff",
      transform: "translateY(-100%)",
      backgroundColor: "rgba(0, 155, 178, 0.7)",
      borderRadius: "4px",
      fontSize: "0.8em",
      padding: "0px 4px",
      transition: "all 0.4s ease-in",
    },
    "&.input-error ~ $errorDisplay": {
      animation: 'errortbblinking 2s ease-in-out infinite',
      content: "''",
      display: 'block',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      boxSizing: 'border-box',
      top: '5px',
      right: '5px',
      position: 'absolute',
    },
    [`&.textarea ~ .${classes.inputLabel}`]: {
      top: "20%"
    },
    "& *, & *::before, & *::after, &:hover *, &:hover *::before, &:hover *::after, &::before, &::after, &:hover, &:hover::before, &:hover::after":{
      border: "none !important"
    }
  },





}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;