import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import useStyles from './styles';

export interface ISmallDropBtnProps {
  onClick?:any,
  className?:string,
  style?:Record<string, any>,
  children?:any,
  text?:any,
  onChange?:any
}

export default function SmallDropBtn (props: ISmallDropBtnProps) {
  const classes = useStyles().classes;
  const {onClick=()=>void(0), className="", style={}, text=null, onChange=()=>void(0)} = props;
  const [optionDisplay, setOptionDisplay] = useState(false);
  const clicked = ()=>{
    onClick();
    optionDisplay ? setOptionDisplay(false) : setOptionDisplay(true);
  }
  return (
    <div style={{position: "relative"}}>
    <button className={`${classes.main} ${className}`} onClick={clicked} onChange={onChange} style={style}>
      {text}
      <div className={classes.dropIcon}><FontAwesomeIcon icon={faAngleDown} /></div>
    </button>
    <div className={`${classes.optionsWrapper} card-1-1 ${optionDisplay === false ? classes.hide : ""}`}>{props.children}</div>
    </div>
  );
}

export const Option = (props:any) => {
  const classes = useStyles().classes;
  const {selected=false, onClick=()=>void(0)} = props;
return <button type="button" onClick={(e:any)=>onClick(e)} onKeyDown={(e:any)=>{
  // e.code === "Enter" && setOptionDisplay(false)
  }} className={`${classes.option} ${selected ? classes.selected : ""}`}>
{props.children}
</button>
}


