import { Theme } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  heroText: {
    opacity: "1",
    transition: "opacity 0.3s ease 0s",
    boxSizing: "border-box",
    width: "100%",
    "& *": {
      boxSizing: "inherit",
    },
  },

  indicatorBox: {
    width: "100%",
    boxSizing: "border-box",
    padding: "5px 15px",
    textAlign: "center",
    color: theme.palette.primary.light,
    "& *": {
      width: "10px",
      height: "10px",
      boxSizing: "border-box",
      borderRadius: "50%",
      display: "inline-block",
      verticalAlign: "middle",
      margin: "0px 3px",
      opacity: "0.4",
      backgroundColor: theme.palette.primary.main,
      transition: "all 1s ease",
      "&.active": {
        opacity: "1",
        transition: "opacity 1s ease-in",
      },
    },
  },
  sliderBox: {
    position: "relative",
    minHeight: "300px",
    "@media (max-width: 600px)": {
      minHeight: "200px",
    },
  },
  slider: {
    padding: "20px 30px",
    position: "absolute",
    top: "0px",
    animation: "zoomOutLeft 0.8s ease-in-out forwards",
    width: "100%",
    "&.active": {
      animation: "zoomInRight 1s ease-in-out forwards",
    },
  },
  sliderTitle: {
    fontSize: "4.2em",
    fontWeight: 600,
    zIndex: 2,
    color: theme.palette.primary.main,
    whiteSpace: "nowrap",
    overflow: "visible",
    "@media (max-width: 600px)": {
      fontSize: "1.8em",
      // fontSize: "2.2em",
      textAlign: "center",
    },
    "@media (max-width: 992px) and (min-width: 601px)": {
      fontSize: "3em",
    },
  },
  sliderOtherText: {
    fontSize: "2.5em",
    lineHeight: "1.1em",
    color: "#000",
    fontWeight:500,
    "@media (max-width: 600px)": {
      fontSize: "1.2em",
      // fontSize: "1.4em",
      textAlign: "center",
    },
    "@media (max-width: 992px) and (min-width: 601px)": {
      fontSize: "1.7em",
    },
  },
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
