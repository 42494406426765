import { Theme } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  main:{
    wrap: "nowrap",
    alignItems: "center",
    marginTop: "18px",
    position: "relative",
    width: "100%"
  },
  displayToggle: {
    width: 30,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    backgroundColor: "#fff",
    right: 0,
    top:0,
    cursor:"pointer",
    borderRadius: 4,
    zIndex: 3
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;