import React from 'react'
import Popover from './Popover';

export interface IMiniDropdownProps {
    children?: any;
    onOpenChange?: ((drop: boolean) => void) | null;
    dropHandle?:
    | "button"
    | "text"
    | { (props: Record<string, any>): JSX.Element };
    dropHandleChildren?: any;
    style?: Record<string, any>
}

export default function Dropdown({dropHandle="text", style, dropHandleChildren, children, ...props}: IMiniDropdownProps) {
    const btnRef = React.useRef<any>(null);
    const [drop, setDrop] = React.useState<boolean>(false);
    const [animType, setAnimType] = React.useState("");
    const closeTimmer = React.useRef<any>(null);
    const popoverRef = React.useRef<any>(null);

    const hideDrop: any = () => {
        setAnimType("zoomOutShort2");
    };
    //start show drop operation
    const showPopup = () => {
        if (closeTimmer.current) {
            clearTimeout(closeTimmer.current);
            closeTimmer.current = null;
        }
        setAnimType("zoomInShort2");
    };

    //close function for window click event
    const closePopup = React.useCallback((e: MouseEvent) => {
        if (
            e.target !== btnRef.current &&
            !btnRef.current?.contains(e.target) &&
            e.target !== popoverRef.current &&
            !popoverRef.current?.contains(e.target)
        ) {
            hideDrop();
        }
    }, []);

    if (animType === "zoomInShort2") {
        !drop && setDrop(true);
    }

    React.useEffect(() => {
        if (animType === "zoomInShort2") {
            // setDrop(true);
            window.addEventListener("mousedown", closePopup);
        } else {
            closeTimmer.current = setTimeout(() => {
                setDrop(false);
            }, 500);
            window.removeEventListener("mousedown", closePopup);
        }
        return () => {
            clearTimeout(closeTimmer.current);
            window.removeEventListener("mousedown", closePopup);
        };
    }, [animType, closePopup]);

    React.useEffect(() => {
        btnRef.current && btnRef.current.addEventListener("focus", showPopup);
        btnRef.current && btnRef.current.addEventListener("click", showPopup);
        return () => {
            btnRef.current && btnRef.current.removeEventListener("focus", showPopup);
            btnRef.current && btnRef.current.removeEventListener("click", showPopup);
        }
    }, []);
    const dropTypeObj =
    dropHandle === "text" ? (
      <div ref={btnRef}>{dropHandleChildren}hhh</div>
    ) : dropHandle === "button" ? (
      <button style={{ backgroundColor: 'transparent', fontSize: '12px', border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }} ref={btnRef} onClick={(e)=>{
        e.stopPropagation();
        e.preventDefault();
      }}>
        {dropHandleChildren}
      </button>
    ) : (
      dropHandle({ ...props, handleRef: btnRef })
    );
    return (
        <>
        {dropTypeObj}
            {drop ? (
                <Popover style={style}
                className={`animated ${animType} faster`}
                owner={btnRef}
                popoverRef={popoverRef}
                >
                {typeof children === "function" ? children({ hideDrop }) : children}
                </Popover>) : null}
        </>
    );
}