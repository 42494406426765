import { lighten } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme)=>({
    popupStyle:{
        display:'flex', justifyContent:'center', alignItems:'center',
        width: '100%', height: '100%',
        boxSizing: 'border-box', position: 'fixed',
        zIndex: 1000, top: 0, left: 0, 

        '& .popup_body':{
            backgroundColor:theme.palette.background.paper,
            width:'auto', minHeight: '50%', maxHeight:'100%',
            boxSizing: 'border-box', padding: '15px 18px', borderRadius:'5px', transition:' all 0.5s ease', 
            '& .content-box':{
                maxHeight:'512px', overflowY:'scroll',boxSizing:'border-box', scrollbarColor:`transparent ${lighten(theme.palette.primary.main, 0.7)}`, scrollbarWidth: "thin"
            },
            
            '& .content-box::-webkit-scrollbar-track, & .content-box::-webkit-scrollbar-track':{
                borderRadius:10
            },
            '& .content-box::-webkit-scrollbar-thumb':{
                    background: lighten(theme.palette.primary.main, 0.7),  borderRadius:10
            },
            '& .hide-scrollbar::-webkit-scrollbar-thumb':{
                    background:'transparent !important',
            },
            '& .content-box::-webkit-scrollbar-thumb:hover':{
                    background:lighten(theme.palette.primary.main, 0.3)
            },
            '::-webkit-scrollbar-track:hover $.content-box::-webkit-scrollbar-thumb':{
                    background:lighten(theme.palette.primary.main, 0.3), width:8
            },
            
            '& .content-box::-webkit-scrollbar-track:hover ':{
                    background:lighten(theme.palette.primary.main, 0.7),
            },
            
            '& .content-box::-webkit-scrollbar':{
                width:8, transition:' all 0.5s ease', boxSizing:'border-box'
            },


            "@media screen and (max-width: 600px) ":{
                width:"100% !important",height:"100%", maxWidth:"100% !important",

                "& .logobox":{margin:'auto', marginTop: "0 !important", marginBottom:'10px'},

                '& .content-box':{
                    height: "calc(100% - 100px)", maxHeight:'100% !important',
                    "& .signup-con > div":{
                        width:"100%",
                    }
                }

                
            }
        },
        

    },

    shell:{
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },

    "closeBtn":{
        backgroundColor:'#9ab3ff47', border:'none', padding:'8px 20px', cursor:'pointer', transition:'0.3s ease', borderRadius:2, position:'absolute',top:0,right:0,
        "&:hover":{
            backgroundColor:'#f83434',color:'#fff',
        }
    },



   
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;