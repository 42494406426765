import {USER,SETTINGS } from './../../utilities/constant';
import axiosInstance from '../https';
import { paginationData } from '../../utilities/types';


export const fetchUsers = () => axiosInstance.get(`${USER}/details`);
export const fetchRoles = () => axiosInstance.get(`${USER}/role`);
export const DeleteRoles = (roleId:string) => axiosInstance.delete(`${USER}/role/${roleId}`);
export const fetchPermissions = () => axiosInstance.get(`${SETTINGS}/permissions`);
export const getOneRole = (roleId:string) => axiosInstance.get(`${USER}/role/${roleId}`);
export const inviteUser = (data:Record<string,any>) => axiosInstance.post(`${USER}/invite`,data);
export const createNewRole = (data:Record<string,any>) => axiosInstance.post(`${USER}/role`,data);

