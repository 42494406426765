/* eslint-disable array-callback-return */
import { Typography } from "@mui/material";
  import React, { useCallback, useEffect, useRef, useState, createRef} from "react";
  import { useNavigate } from "react-router-dom";
  import { Icon } from "@iconify/react";
  import useStyles from "../airtime/styles";
  import TextField from "../../../../components/textField";
  import OptionCard from "../../../../components/optionCard";
  import { currency, getAppStorage ,error, commarize2, setAppStorage} from "../../../../utilities/appHelpers";
  import mtn from "../../../../assets/images/mtn.svg.png";
  import mobile9 from "../../../../assets/images/9mobile.png";
  import glo from "../../../../assets/images/glo.png";
  import airtel from "../../../../assets/images/airtel.png";
  import Button from "../../../../components/button";
  import { getBillables, purchaseData } from "../../../../services/billler";
  import { ROLES, messasgeType, useGlobalState } from "../../../../pages/dashboard";
  import CustomCheckbox from "../../../../components/customCheckbox";
  import Alert, { IType } from "../../../../components/alertBox";
  import Select  from "react-select";
import { createPortal } from "react-dom";
import { getAllWallets } from "../../../../services/wallet";
  
  
  export default function Data(props:any) {
    const classes = useStyles().classes;
    const { className = "", style = {} } = props;
  
    const [phoneNumber,setPhoneNumber] = useState('');
    const [hintState,setHintState] = useGlobalState("hint");
    const [switchNum, setSwitchNum] = useState(0),
    [paymentParam, setPaymentParam]=useState<Record<string,any>>({
      amount: "",
      phone: "",
      billerId: "",
      bundleId: "",
      walletCode: ""
    }), [awaitingNetwork, setAwaitingNetwork] = useState({loading:false,disabled:true}),
    [selectedOption, setSelectedOption] = useState<any>(),
    [options, setOptions] = useState<any>([]);

    const billerID={
      AIRTEL: 'airtel',
      GLO:'glo', 
      MTN:'mtn',
      MOBILE9:'9mobile'
    }
  
  
    const [appState, setAppState] = useState({status:false,msg:'', alertType:IType.warning, title:'Message', icon:messasgeType.default, count:1});
    const [selectedTelcom,setSelectedTelcom] = useState <Record <string, any>>({status:false, id:''}),[finishing, setFinishing]=useState({ text:'Recharge', icon:<Icon icon="eva:arrow-forward-fill"/>, loading:false, disabled:true});
  
      useEffect(()=>{
        setHintState({...hintState,status:appState.status, msg:appState.msg, icon:appState.icon});
      },[appState.count])
  
      useEffect(()=>{
        if(selectedTelcom.id.trim().length>0){
          setPaymentParam({...paymentParam,billerId:selectedTelcom.id});
        }
      },[selectedTelcom.id])

      const fetchUpdatedWallet = async()=>{
        try {
          const resp = await getAllWallets();
          if(resp.status===200){
            setUserUpdatedWallet(resp.data.data);
          }
        } catch (e) {
          let info =  error.handle(e,"You've lost Internet connection",true);
            setAppState({...appState, alertType:IType.error, title:'Error', status:true, msg:info.toLowerCase().includes('internal error')?'...oops! something went wrong, please try again later':info, icon:messasgeType.failed,count:appState.count+1});
        }
        
      }

  
  
      useEffect(()=>{
          if(selectedTelcom.id.trim().length>0){
            (async()=>{
              setAwaitingNetwork({loading:true,disabled:true});
              try{
                const resp = await getBillables({billerId:selectedTelcom.id});
                if(resp.status===200){
                  const resObj =  resp.data.data;
                  resObj && resObj.map((obj:Record<string,any>)=>{
                    Object.keys(obj).forEach(oldkey => {
                      let value = obj[oldkey];
                      let mergedValues = obj.price + "~"+ obj?.bundleId;
                      let newKey = oldkey === 'id'?'value': oldkey === 'name' ?'label' : oldkey;
                      delete obj[oldkey];
                      obj[newKey] =newKey==='value' ?mergedValues: value;
                    });
                  })
                  setOptions(resObj);

                  setAwaitingNetwork({loading:false,disabled:false});
                  
                }
                
              }catch(e){
                //   setSelectedOption({labe:'no data',value:''});
                // setOptions([{labe:'no data',value:''}]);
                setAwaitingNetwork({loading:false,disabled:true});
                let info =  error.handle(e,"You've lost Internet connection",true);
                setAppState({...appState, alertType:IType.error, title:'Error', status:true, msg:info.toLowerCase().includes('internal error')?'...oops! something went wrong, please try again later':info, icon:messasgeType.failed})
  
              }
                
              
              
            })()
          }
      },[selectedTelcom.id])
  
      
      const telNetwks = [
        {name:'MTN',logo:mtn,code:'',id:billerID.MTN},
        {name:'AIRTEL',logo:airtel,code:'',id:billerID.AIRTEL},
        {name:'9MOBILE',logo:mobile9,code:'',id:billerID.MOBILE9},
        {name:'GLO',logo:glo,code:'',id:billerID.GLO}
      ]
  
      const wrapper= useRef<any>(null);
      const numWrapper= useRef<any>(null);
  
  const [currentWallet, setcurrentWallet] = useState<String>(); 
  
    useEffect(() => {
       const elm =  wrapper.current && (wrapper.current as HTMLElement).querySelectorAll('input[type=radio]')[0];
       const l = wrapper.current && (wrapper.current as HTMLElement).querySelectorAll('label')[0];
       l && l.classList.add('selectedwallet101');
       if(elm){
         elm.checked = true;
        }
        const w = getAppStorage('WALLETS')[0];
        w && setcurrentWallet(w.walletCode);
       
    }, [switchNum]);
  
  useEffect(()=>{
    setPaymentParam({...paymentParam,walletCode:currentWallet});
  },[currentWallet])

  const [userUpdatedWallet, setUserUpdatedWallet]=useState(null);
  const [userWallet, setUserWallet]=useState<any>(null);

  useEffect(()=>{
    if(userUpdatedWallet)setAppStorage('WALLETS',userUpdatedWallet);
    setUserWallet(getAppStorage('WALLETS'));
  },[userUpdatedWallet])
  
  
    //proceed to making payments
   
    const sendRecharge = useCallback(async()=>{
      
      setFinishing({...finishing, text:'Please wait...', icon:<Icon icon="humbleicons:dots-horizontal"/>, loading:true, disabled:true});
      try {
        const resp = await purchaseData(paymentParam);
        if(resp.status===200 || resp.status===201){
          fetchUpdatedWallet();
          setAppState({...appState, status:true, title:'Success', alertType:IType.success, msg:resp.data.message, icon:messasgeType.success})
          setFinishing({...finishing, text:'Recharge', icon:<Icon icon="eva:arrow-forward-fill"/>, loading:false});
          setPhoneNumber('');setPaymentParam({...paymentParam,phone:''});
        }
      } catch (er) {
        setFinishing({...finishing, text:'Recharge', icon:<Icon icon="eva:arrow-forward-fill"/>, loading:false, disabled:false});
        setAppState({...appState, alertType:IType.error, title:'Error', status:true, msg:error.handle(er,"You've lost Internet connection",true), icon:messasgeType.failed})
  
      }
      
  
    },[paymentParam.bundleId, paymentParam.amount, paymentParam.walletCode,paymentParam.billerId, paymentParam.phone])
  
    const handleChange = (e:any, wallet:string, btn:HTMLLabelElement)=>{
      const el = wrapper.current && (wrapper.current as HTMLElement).querySelector('.selectedwallet101');
      if(el){
        if(el !== btn){
          el.classList.remove('selectedwallet101');
          btn.classList.add('selectedwallet101');
        }
      }else{btn.classList.add('selectedwallet101');}
      setPaymentParam({...paymentParam,walletCode:wallet});
  
    }
     
      useEffect(()=>{
        setPaymentParam({...paymentParam,phone:phoneNumber});
        if(phoneNumber.trim().length>=11){
          setFinishing({...finishing,disabled:false});
        }else{
          setFinishing({...finishing,disabled:true});

        }

    },[phoneNumber])

    useEffect(()=>{
      if(selectedOption && selectedOption.value.trim().length>0){

        const [price, id] = selectedOption.value && selectedOption.value.split('~');
        setPaymentParam({...paymentParam,amount:price,bundleId:id});
        
      }
    },[selectedOption])
  
    
    function handleNetworkChange(e: MouseEvent, id: string, ref:any):void {
        setSelectedTelcom({...selectedTelcom,status:true,id:id});
        const btn = numWrapper.current && (numWrapper.current as HTMLElement).querySelector('.telcom-selected101');
        if(btn){
          if(ref && btn !== ref){
            btn.classList.remove('telcom-selected101');
            ref.classList.add('telcom-selected101');
          }
          
        }else{        
          ref && ref.classList.add('telcom-selected101');
        }
           
   }
              
        
    
   
  
    return (
      <>
      {createPortal(
        <Alert type={appState.alertType} title={appState.title} show={appState.status} onClose={(stat: boolean) => setAppState({...appState,status:stat})} message={appState.msg} />,
        document.body
      )}
      
  
      <div>
        <div className={`${className} ${classes.main}`} style={{ ...style }}>
       
  
          <div>
            {switchNum === 0 && (
              <>
                <div>
                  <Typography component="h3" className={`${classes.smallTitle}`}>
                    Which wallet would you like to use for the purchase?
                  </Typography>
                  <div ref={wrapper} className={`${classes.walletchecker} scrollbar-transparent`}>
                    {getAppStorage('WALLETS') && getAppStorage('WALLETS').map((w: Record<string, any>, i: number) => {
                      let wRef = createRef<HTMLLabelElement>();
                      //@ts-ignore
                      return <CustomCheckbox title={w.walletName} className={`slideInRight animated delay-0-${i}s`} ref={wRef} isSelected={i === 0} onChange={(e: any) => handleChange(e, w.walletCode, wRef.current)} txt1={w.accNo} txt2={currency.naira + w.bal} id={'wallet' + i} key={i} />;
  
                    }
                    )}
  
                  </div>
                  <Typography component="h3" className={`${classes.smallTitle} fadeIn animated delay-0-4s`}>
                    Please select a network
                  </Typography>
                  <div className={classes.telnets} ref={numWrapper}>
                    {telNetwks && telNetwks.map((t: Record<string, any>, i: number) => {
                      let ref = createRef<HTMLButtonElement>();
                      return <OptionCard key={i} ref={ref} style={{ padding: '10px 20px', gap: 5, flexDirection: 'column' }}
                        className={`fadeInUp animated delay-0-${i}s`}
                        content={{
                          logo: <img src={t.logo} alt="telco" />,
                          title: (
                            <div style={{ fontSize: "0.8em" }}>
                              <div>{t.name}</div>
                            </div>
                            //@ts-ignore
                          )
                        }} onClick={(e: MouseEvent) => handleNetworkChange(e, t.id, ref!.current)} />;
                    }
                    )}
                  </div>
                </div>
  
                  <div className="">
                    <Typography component="h3" className={`${classes.smallTitle} fadeIn animated delay-0-4s`}>Select a data bundle</Typography>
                    <div className='card-1 fadeInUp animated delay-0-2s' style={{borderRadius:5, margin:'10px',zIndex:1000,position:'relative'}}>
                 <Select
                   defaultValue={selectedOption}
                   onChange={setSelectedOption}
                   options={options}
                   isClearable={false}
                   isDisabled={awaitingNetwork.disabled}
                   isLoading={awaitingNetwork.loading}
                   placeholder={'Select bundle'}
                   />
                 </div>
                 <div style={{ padding: '0 10px', zIndex:-100}} className="fadeInUp animated delay-0-5s">
                      <TextField className={`animated faster slideInDown`} placeholder={"Phone Number"} onChange={(e: any) => setPhoneNumber(e.target.value)} value={phoneNumber} />
                  </div>

                 <div style={{ padding: '0 10px', zIndex:-100}} className="fadeInUp animated delay-0-6s">
                      <TextField disabled currency={currency.naira} className={`animated faster slideInDown`} placeholder={"Amount"} value={paymentParam.amount&&commarize2(+paymentParam.amount+'')+'.00'} />
                  </div>
                 </div>
  
                  <div className={classes.cta}>
                    {(ROLES?.UTILITIES_MODULE?.privileges?.PURCHASE_DATA)&&<Button style={{ fontWeight: 800, margin: "30px 0" }} text={finishing.text} icon={finishing.icon} loading={finishing.loading} disabled={finishing.disabled} onClick={sendRecharge} />}
                  </div>
               
  
              </>
  
            )}
  
  
  
          </div>
        </div>
      </div></>
    );
  }
  