import { lighten } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'design' | 'logoBox'>()((theme, _params, classes) => ({
  main: {
    width: 250,
    height: 175,
    borderRadius: 10,
    position: "relative",
    boxSizing: "border-box",
    padding: 16,
    overflow: "hidden",
    fontSize: 11, flexBasis:'700px',
    "& *": {
    boxSizing: "inherit",
    },
    [theme.breakpoints.down('sm')]:{
      width: 220,height: 120,
    }
  },
  altBg: {
    backgroundColor: theme.palette.secondary.contrastText,
    color:"#444",
    [`& .${classes.design} path`]: {
      fill: theme.palette.secondary.contrastText
    },
    [`& .${classes.logoBox}`]: {
      color:lighten(theme.palette.primary.main, 0.2)
    },
    border:"1px solid #00000020"
  },
  mainBg: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    [`& .${classes.design} path`]: {
      fill: lighten(theme.palette.primary.main, 0.2)
    }
  },
  design: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top:-6,
  },
  textBox: {
    width: "100%",
    height: "60%",
    position: "relative",
    zIndex: 1,
    "&>*": {
      lineHeight: 1.3
    }
  },
  controls: {
    width: "100%",
    height: "40%",
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
    zIndex: 1,
  },
  btn: {
    marginRight: 15
  },
  logoBox: {
    width: "2em",
    height:"2em",
    borderRadius: "50%",
    backgroundColor: 'transparent',
    position: "absolute",
    top:"2em",
    right:"0.5em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.4em",
    cursor:'pointer',
    zIndex:2,
    "&:hover":{
          backgroundColor: lighten(lighten(theme.palette.primary.main, 0.2), 0.2),
    }
  },
  bigBold: {
    fontSize: "2.5em",
    fontWeight: 600,
    margin: "5px 0"
  },
  title: {
    fontSize:"1em",
    fontWeight:600
  },
  descr: {
    fontSize:"1.25em",
    letterSpacing: "3px"
  },
  lightborder: {
    border: `2px solid ${lighten(theme.palette.primary.main, 0.2)}`
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
