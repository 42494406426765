import { Typography } from "@mui/material"
import React, { useCallback, useEffect, useRef, useState } from "react"
import Popup from "../../components/popup"
import useStyles from "./styles"
import Line from "../../components/line";
import signup1 from "../heroMod/images/signup1.png";
import signup2 from "../heroMod/images/signup2.png"
import { faUser, faDiamondTurnRight } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addUser, resendLoginLink, verifyEmail, verifyUsername } from "../../services/signup";
import { error, getAppStorage, isValidEmail, setAppStorage } from "../../utilities/appHelpers";
import PinVerifcation from "../pinVerifcationMod";
import PasswordCreation from "../passwordCreationMod";
import { resendPin } from "../../services/pinVerification";
import { useNavigate } from "react-router-dom";
import TextField from "../../components/textField";
import { messasgeType, useGlobalState } from "../../pages/dashboard";
import { Icon } from "@iconify/react";
import { useUserTheme } from "../../themes";
import { NetworkError } from "../../utilities/constant";
import DynamicInput from "../../components/dynamicInput";


const SignUpMod = (props: any) => {
  const { onPopChange = () => void (0), action = null, SigInForm = () => void (0), show = false, data:dataParam = null } = props;
  const [appState, setAppState] = useState<Record<string, any>>({ loading: false, disabled: true, verified: false, icon: <FontAwesomeIcon icon={faUser} />, btnText: 'REGISTER', res: null, data: null, regStatus: 0, uid: null, inputHintonEmail: false, inputHintonUsername: false, inputLoader: false, SuggUsername: '' });

  const { isMobile } = useUserTheme();

  const back = useCallback(() => {
    setNextPageReady(false)
    setAppState({ ...appState, regStatus: 0 });
  }, [])

  const [nextPageReady, setNextPageReady] = useState(false);
  const classes = useStyles().classes;
  const [formData, setFormData] = useState<Record<string, any>>({
    email: '', firstname: '', lastname: '', phone: '', password: ''
  });
  const [errorHandler, setErrorHandler] = useState<Record<string, any>>({ type: '', errorData: null, message: '', showing: false, count: 0 });
  const [Etimer, setEtimer] = useState<any>(null);

  const [hintState, setHintState] = useGlobalState("hint"), [inputChecker, setInputChecker] = useState({ loading: false, status: false, icon: "clarity:warning-solid", show: false }), [inputChecker2, setInputChecker2] = useState({ loading: false, status: false, icon: 'clarity:warning-solid', show: false }); //fa-solid:times

  const navigator = useNavigate();

  const callValidator = (element: any) => { // -- 2
    setAppState({ ...appState, disabled: true });
    setInputChecker({ ...inputChecker, loading: true, status: false, show: true, icon: messasgeType.default });

    Etimer && window.clearTimeout(Etimer);

    if (isValidEmail(element.target.value)) {
      let timer = setTimeout(() => verifyUserEmail(element.target.value), 1000);
      setEtimer(timer);

    } else {
      setAppState({ ...appState, verified: false, status: false, show: true });
      if (element.target.value.trim()?.length < 1) {
        setInputChecker({ ...inputChecker, loading: false, status: false, show: false, icon: messasgeType.default });
      } else {
        setInputChecker({ ...inputChecker, loading: false, show: true, status: false, icon: messasgeType.default });
      }
    }
  }

  const verifyUserEmail = async (input: any) => { // -- 3

    if (isValidEmail(input)) {
      try {

        const resp = await verifyEmail({ email: input });
        if (resp.status === 200 || resp.status === 201) {
          setAppState({ ...appState, verified: true, res2: resp, inputLoading: false, inputHintonEmail: false });
          setInputChecker({ ...inputChecker, loading: false, status: true, icon: 'fa-solid:check', show: true });

        }

      } catch (e: any) {

        if (error.getData(e)?.message === 'Email already exist') {
          setInputChecker({ ...inputChecker, loading: false, status: false, icon: messasgeType.default, show: true });
          setAppState({ ...appState, inputHintonEmail: true, regStatus: parseInt(error.getData(e)?.data?.status), uid: error.getData(e)?.data?.uniqueId });
          setAppStorage('uid', error.getData(e)?.data?.uniqueId);
        }

      }
    }

  }


  const continueTo = async () => {
    // if (appState.regStatus === 1) {
    //   if (appState.uid) {

    //     try {
    //       const resp = await resendPin(appState.uid);
    //       if (resp.status == 200 || resp.status == 201) {
    //         setNextPageReady(true);
    //       }
    //     } catch (e) {
    //       setErrorHandler({ ...errorHandler, message: error.handle(e, NetworkError), showing: true, count: errorHandler.count + 1 })
    //     }

    //   }
    // }

    if (appState.regStatus === 0) {
      const resendToken = async ()=> {
        const email = getAppStorage('userEmail');
        if(email) {
            const res = await resendLoginLink({email});
            if(res.status === 200) {
                // show message
            } else {
                // handle error
            }
        } else {
            // handle error
        }
    }
    resendToken();
      setNextPageReady(true);
    }
    
    if (appState.regStatus > 0) {
      SigInForm && SigInForm();
    }

  }


  const SubmitForm = async (e: any) => {
    e.preventDefault();
    setAppState({ ...appState, btnText: 'REGISTERING...', loading: true });
    try {
      //post
      const resp = await addUser(formData);
      if (resp.status === 201 || resp.status === 200) {
        setAppState({ ...appState, icon: <FontAwesomeIcon icon={faDiamondTurnRight} />, btnText: 'Redirecting...', loading: true, res: resp, regStatus: 1 });
        setAppStorage('uid', resp?.data?.data?.uniqueId);
        setAppStorage('userEmail', formData.email)
        setNextPageReady(true);
      }

    } catch (e: any) {
      setAppState({ ...appState, btnText: 'REGISTER', loading: false });
      setErrorHandler({ ...errorHandler, message: error.handle(e, NetworkError), showing: true, count: errorHandler.count + 1 });

    }

  }

  // const validateUsername = async () => {
  //   try {
  //     const resp = await verifyUsername(formData);
  //     if (resp.status === 200 || resp.status === 201) {
  //       setAppState({ ...appState, disabled: false })
  //       setInputChecker2({ ...inputChecker2, loading: false, show: true, status: true, icon: 'fa-solid:check' });
  //     }
  //   } catch (e: any) {
  //     let sugg = e?.response?.data?.data?.sugestions;
  //     setInputChecker2({ ...inputChecker2, loading: false, show: true, status: false, icon: messasgeType.default });
  //     setSuggestionAvailable(sugg);
  //     setAppState({ ...appState, disabled: false, data: sugg, inputHintonUsername: true });
  //     setErrorHandler({ ...errorHandler, message: error.handle(e, NetworkError), showing: true, count: errorHandler.count + 1 });
  //   }
  // }



  // useEffect(() => {
  //   if (Array.isArray(suggestionAvailable) && suggestionAvailable.length > 0) {
  //     setAppState({ ...appState, disabled: false, inputHintonUsername: true });
  //   }
  // }, [suggestionAvailable])

  // useEffect(() => {
  //   let ut: any;
  //   if (formData.username !== '') {
  //     setInputChecker2({ ...inputChecker2, loading: true, show: true });

  //     ut = setTimeout(() => validateUsername(), 2000);
  //     setUtimer(ut)
  //     Utimer && window.clearTimeout(Utimer);
  //   }

  //   return () => window.clearTimeout(ut);
  // }, [formData.username])




  // useEffect(() => {

  //   if (Etimer && formData.email.trim().length < 1) {
  //     window.clearTimeout(Etimer);
  //     setInputChecker2({ ...inputChecker, loading: false, show: false });
  //   }

  // }, [formData.email, Etimer])

  // useEffect(() => {

  //   if (Utimer && formData.username.trim().length < 1) {
  //     window.clearTimeout(Utimer);
  //     setInputChecker2({ ...inputChecker2, loading: false, show: false });
  //   }
  // }, [Utimer, formData.username])





  useEffect(() => {

    if (formData['email'].trim() !== '' && formData['firstname'].trim() !== '' && formData['lastname'].trim() !== '' && formData['password'].trim() !== '' && formData['phone'].trim() !== '') {
      setAppState({ ...appState, disabled: false });
    } else {
      setAppState({ ...appState, disabled: true, inputHintonEmail: false });
    }
    

  }, [formData.email,formData.password,formData.firstname,formData.lastname,formData.phone])


  useEffect(() => {
    setHintState({ ...hintState, status: errorHandler.showing, msg: error.info(errorHandler.message), icon: messasgeType.network })
  }, [errorHandler.showing, errorHandler.count])

  const resetData = () => {
    setFormData({ ...formData, email: "", lastname: '', firstname: '', password: '', phone: '' });
    setInputChecker(d => ({ ...d, show: false }));
    setAppState({ ...appState, regStatus: 0, loading: false, btnText: 'REGISTER' });
    setNextPageReady(false);

  }

  const [lowercase, setLowercase] = useState(0), [uppercase, setUppercase] = useState(0), [number, setNumber] = useState(0), [length, setLengh] = useState(0), [isValidPwd, setIsValidPwd] = useState(false), [proceed, setProceed] = useState(false);
  const validatePassword = useCallback((input: string) => {
    /[0-9]/.test(input) ? setNumber(2) : setNumber(1);
    /[a-z]/.test(input) ? setLowercase(2) : setLowercase(1);
    /[A-Z]/.test(input) ? setUppercase(2) : setUppercase(1);
    input.length >= 8 ? setLengh(2) : setLengh(1);

    if (new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, 'g').test(input)) {
      
      // return;
      // setFormData({ ...formData, password: input });
      setIsValidPwd(true);
    }

  }, []);
  const [pwdIsFocused, setPwdIsFocused] = useState(false);

  enum vIcon {
    success = 'akar-icons:check',
    info = "clarity:info-circle-solid",
    error = 'humbleicons:times',
  }
  
  const [popupAttributes, setPopupAttributes]=useState({
    show:show,
    background:true,
    exitButton:true,
    allowOutsideClick:true,
    bgcolor:'rgb(219, 229, 236)'
  })

  useEffect(()=>{
    if(dataParam){
      console.log(dataParam);
    }

    
  },[dataParam])

  return <>
    <Popup show={show} onPopChange={onPopChange} scrollbar={false} onReset={resetData}>

      {
        nextPageReady === false ?

          (<div className={`${classes.signup} signup-con`}>
            <div className={`${classes.illus} illus-wrapper`}>

              <div className="illus-cont">
                <Typography component={'div'}>
                  <div className="dtp-signup-title text-color">Sign Up</div>
                </Typography>
                <div className="dtp-signup-side-image-wrapper">
                  <div className="dtp-signup-side-image" style={isMobile ? { width: 300 } : {}}>
                    <img src={signup2} className={`shieldOpeningLeft gwd-image-1sum`} alt="DTECHPAY" />
                    <img src={signup1} className={`shieldOpeningCenter gwd-image-1jod`} alt="DTECHPAY" />
                    <img src={signup2} className={`shieldOpeningRight gwd-image-1kk0`} alt="DTECHPAY" />
                  </div>
                </div>

                <Typography component={'div'} style={{ fontSize: '0.7rem' }}>
                  <div className="dtp-signup-title w3-xlarge fadeInUp animated delay-0-4s">Join the <span className="text-color">League</span><br />of <span className="text-color">Seamless Payment</span></div>
                </Typography>
              </div>

            </div>
            <div className="inputFields">
              <form name="dtp-signup-form" onSubmit={SubmitForm}>
                <div className="hintW" style={{ position: 'relative', width: '100%' }}>

                  <TextField placeholder="Email" className='inputbx fadeInUp animated delay-0-1s animated' value={formData.email.trim()} onInput={(e: any) => { setFormData({ ...formData, email: e.target.value }); callValidator(e); }} withLoader={true} inputloader={
                    { loading: inputChecker.loading, status: inputChecker.status, icon: <Icon icon={inputChecker.icon} />, show: inputChecker.show }
                  } />

                  <Typography component={'div'} className={classes[appState.inputHintonEmail ? "input-hint-open" : "input-hint-close"] + "  " + classes["hint"]}>
                    <strong style={{ color: "#4e708f" }}>Your email is already registered with us</strong>
                    <br />
                    <span style={{ color: "grey", marginBottom: 10 }}>Continue or try another email address.</span>
                    <br />

                    <div className={classes["hint-btn"]}>
                      <button type="button" onClick={continueTo}> Ok, continue</button>
                      <button type="button" onClick={() => { setFormData({ ...formData, email: '' }); setInputChecker({ ...inputChecker, show: false }); }}>No, not me</button>
                    </div>
                  </Typography>

                </div>

                <div className="row1 fadeInUp animated delay-0-4s">
                  <TextField className='inputbx' placeholder={"First name"} onInput={(e: any) => setFormData({ ...formData, firstname: e.target.value })} value={formData.firstname} />

                  <TextField className='inputbx' placeholder={"Last name"} onInput={(e: any) => setFormData({ ...formData, lastname: e.target.value })} value={formData.lastname} />
                </div>

                {/* <div className="hintW" style={{ position: 'relative', width: '100%' }}>
                  <TextField className='inputbx fadeInUp animated delay-0-6s' placeholder={"Username"} value={formData.username} onInput={(e: any) => { setFormData({ ...formData, username: e.target.value }); setAppState({ ...appState, inputHintonUsername: false }) }} withLoader={true} inputloader={
                    { loading: inputChecker2.loading, status: inputChecker2.status, icon: <Icon icon={inputChecker2.icon} />, show: inputChecker2.show }
                  } />


                  {
                    (Array.isArray(suggestionAvailable) && suggestionAvailable.length > 0) &&
                    (<Typography component={'div'} className={classes[appState.inputHintonUsername ? "input-hint-open" : "input-hint-close"] + "  " + classes["hint"]}>
                      <strong style={{ color: "#4e708f" }}>Oops... Username already taken </strong>
                      <br />
                      <span style={{ color: "grey", marginBottom: 10 }}>Select from the suggestion or try another one</span>
                      <br />

                      <div className={classes["hint-btn-sugg"] + " " + "hint"}>
                        {
                          Array.isArray(suggestionAvailable) && suggestionAvailable.map((d: any, i: number) =>
                            <button type="button" onClick={(e: any) => { setFormData({ ...formData, username: e.target.innerText }); setAppState({ ...appState, inputHintonUsername: false }) }} key={i} >{d}</button>)
                        }
                      </div>
                    </Typography >)

                  }
                </div> */}

                <div className="pwd-wrapper">
                  <TextField type="password" placeholder="Password" onInput={(e: any) => { validatePassword(e.target.value); }} onFocus={() => setPwdIsFocused(true)} onBlur={(e: any) => { isValidPwd && setPwdIsFocused(false); setFormData({ ...formData, password: e.target.value }) }} className='inputbx inputbx-pwd fadeInUp animated delay-0-4s' />

                  <Typography component={'div'} className={classes[pwdIsFocused ? "input-hint-open" : "input-hint-close"] + "  " + classes["hint"]}>
                    <strong style={{ color: "#4e708f" }}>Your password must contain at least:</strong>
                    <ul className={classes["val-checker"]}>
                      <li className={lowercase === 2 ? 'success' : lowercase === 1 ? 'error' : 'info'}><Icon icon={lowercase === 2 ? vIcon.success : lowercase === 1 ? vIcon.error : vIcon.info} style={{ marginRight: 3 }} />A lowercase</li>

                      <li className={uppercase === 2 ? 'success' : uppercase === 1 ? 'error' : 'info'}><Icon icon={uppercase === 2 ? vIcon.success : uppercase === 1 ? vIcon.error : vIcon.info} style={{ marginRight: 3 }} />An uppercase</li>

                      <li className={number === 2 ? 'success' : number === 1 ? 'error' : 'info'}><Icon icon={number === 2 ? vIcon.success : number === 1 ? vIcon.error : vIcon.info} style={{ marginRight: 3 }} />A Number</li>

                      <li className={length === 2 ? 'success' : length === 1 ? 'error' : 'info'}><Icon icon={length === 2 ? vIcon.success : length === 1 ? vIcon.error : vIcon.info} style={{ marginRight: 3 }} />Minimum 8 characters</li>
                    </ul>
                  </Typography>
                </div>

                <TextField className='inputbx fadeInUp animated delay-0-8s' placeholder={"Phone Number"} onInput={
                  (e: any) => setFormData({ ...formData, phone: e.target.value })} value={formData.phone} />

                <div className="btn fadeInUp animated delay-0-9s">
                  <Button disabled={appState.loading || appState.disabled ? true : false} style={{ marginLeft: 0 }} variant='primary' icon={appState.icon} text={appState.btnText} fontStyle={{ fontSize: '0.9rem', fontWeight: 600 }} onClick={SubmitForm} loading={appState.loading} />
                  {/* <Line orientaton='v' style={{ height: '35px', marginLeft: '10px' }} /> */}

                  <div className="dtp-signup-tc">
                    <Typography component={'div'} style={{ fontSize: '0.7rem', lineHeight: '1.2em', }}>
                      <div>By clicking <b>register</b> you agree to our <a href="#">terms and conditions</a>.</div>
                      <div className="dtp-signup-tc-login">Already have an account? <button type="button" className="link" onClick={SigInForm} >Login</button></div>
                    </Typography>
                  </div>

                </div>
              </form>
            </div>
          </div>) : navigator('/welcome')

      }
    </Popup>
</>
}

export default React.memo(SignUpMod);