import { Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import Table, { Th, Td, Tr } from '../../../../components/designerTable';
import useStyles from './../styles';
import SimpleButton, { ButtonVariant } from '../../../../components/simpleButton';
import React from "react";

export interface IABPSetupProps {
}

export default function ABPSetup(props: IABPSetupProps) {
  const classes = useStyles().classes;
  const apiCRDW = ["10%", "45%", "45%"];
  const accCRDW = ["15%", "15%", "20%", "35%", "25%"];
  const sdkCRDW = ["10%", "45%", "45%"];
  return (
    <div className="fadeInUp animated faster">
      <div style={{ margin: "20px 0" }}>
      <Typography component="h2" className={`${classes.headerMd}`} style={{ marginBottom: "5px" }}>
            <div style={{display: "flex"}}>
              <div style={{width: "50%", fontSize: 20, fontWeight: 600}}>
                API Keys
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", width: "50%" }}>
                <SimpleButton variant={ButtonVariant.alt} onClick={() => { }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ padding: "0 5px" }}><Icon icon="fluent:key-reset-20-regular" width="16" height="16" /></div>
                    <div>Reset All</div>
                  </div>
                </SimpleButton>
              </div>
            </div>
          </Typography>
        <div className={`${classes.desCard} card-2`}>
          <div>
            <Th className={classes.compactRow} style={{ backgroundColor: "transparent" }}>
              <Td className={classes.smPad} style={{ width: apiCRDW[0] }}></Td>
              <Td className={classes.smPad} style={{ width: apiCRDW[1] }}>Demo</Td>
              <Td className={classes.smPad} style={{ width: apiCRDW[2] }}>Live</Td>
            </Th>
            <Tr className={classes.compactRow}>
              <Td className={`${classes.smPad} ${classes.rowTitle}`} style={{ width: apiCRDW[0] }}>Business ID</Td>
              <Td className={`${classes.smPad} ${classes.copyable}`} style={{ width: apiCRDW[1] }}>
                <input type="password" className={classes.desItem} value="jnsdfkuhier8iw83i3ijs" readOnly />
                <div className={classes.copyIcons}><Icon icon="fluent:copy-select-20-regular" width="16" height="16" /><Icon icon="fluent:key-reset-20-regular" width="16" height="16" /></div>
              </Td>
              <Td className={`${classes.smPad} ${classes.copyable}`} style={{ width: apiCRDW[2] }}>
                <input type="password" className={classes.desItem} value="4rtfg0987ytgf4rtyghb098uyh" readOnly />
              <div className={classes.copyIcons}><Icon icon="fluent:copy-select-20-regular" width="16" height="16" /><Icon icon="fluent:key-reset-20-regular" width="16" height="16" /></div>
              </Td>
            </Tr>
            <Tr className={classes.compactRow}>
              <Td className={`${classes.smPad} ${classes.rowTitle}`} style={{ width: apiCRDW[0] }}>Secret Key</Td>
              <Td className={`${classes.smPad} ${classes.copyable}`} style={{ width: apiCRDW[1] }}>
                <input type="password" className={classes.desItem} value="jnsdfkuhier8iw83i3ijs" readOnly />
              <div className={classes.copyIcons}><Icon icon="fluent:copy-select-20-regular" width="16" height="16" /><Icon icon="fluent:key-reset-20-regular" width="16" height="16" /></div>
              </Td>
              <Td className={`${classes.smPad} ${classes.copyable}`} style={{ width: apiCRDW[2] }}>
                <input type="password" className={classes.desItem} value="4rtfg0987ytgf4rtyghb098uyh" readOnly />
              <div className={classes.copyIcons}><Icon icon="fluent:copy-select-20-regular" width="16" height="16" /><Icon icon="fluent:key-reset-20-regular" width="16" height="16" /></div>
              </Td>
            </Tr>
            <Tr className={classes.compactRow}>
              <Td className={`${classes.smPad} ${classes.rowTitle}`} style={{ width: apiCRDW[0] }}>Service ID</Td>
              <Td className={`${classes.smPad} ${classes.copyable}`} style={{ width: apiCRDW[1] }}>
                <input type="password" className={classes.desItem} value="jnsdfkuhier8iw83i3ijs" readOnly />
              <div className={classes.copyIcons}><Icon icon="fluent:copy-select-20-regular" width="16" height="16" /><Icon icon="fluent:key-reset-20-regular" width="16" height="16" /></div>
              </Td>
              <Td className={`${classes.smPad} ${classes.copyable}`} style={{ width: apiCRDW[2] }}>
                <input type="password" className={classes.desItem} value="4rtfg0987ytgf4rtyghb098uyh" readOnly />
              <div className={classes.copyIcons}><Icon icon="fluent:copy-select-20-regular" width="16" height="16" /><Icon icon="fluent:key-reset-20-regular" width="16" height="16" /></div>
              </Td>
            </Tr>
          </div>
        </div>

        
        {/* section headings */}
        <Typography component="h2" className={`${classes.headerMd}`} style={{ marginBottom: "5px", }}>
            <div style={{display: "flex"}}>
              <div style={{width: "50%", fontSize: 20, fontWeight: 600}}>
                Accounts
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", width: "50%" }}>
                {/* <SimpleButton variant={ButtonVariant.alt} onClick={() => { }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ padding: "0 5px" }}><Icon icon="fluent:key-reset-20-regular" width="16" height="16" /></div>
                    <div>Reset All</div>
                  </div>
                </SimpleButton> */}
                <SimpleButton>Add New</SimpleButton>
              </div>
            </div>
          </Typography>

        

        {/* new table implementation */}
        <Table
              fields={[
                { name: "Account name", size: 20 },
                { name: "Wallet", size: 15 },
                { name: "Account Code", size: 15 },
                { name: "Description", size: 30 },
                { name: "Payment Link", size: 15 },
              ]}
              records={[
                {
                  transaction: <div className={classes.plainArea} contentEditable={true}>School Fees</div>, amount: <div className={classes.plainArea} contentEditable={true}>768990038322</div>, AccountCode: <div className={classes.desItem}>76yt54er453hyj</div>, customer: <div className={classes.plainArea} contentEditable={true} >made part payment of school fees </div>, date: <div className={classes.desItem}>https://www.dtechpay.com/76yt54er453hyj</div>, controls: { isDropdown: false, incoming: false },
                },
                {
                  transaction: <div className={classes.plainArea} contentEditable={true}>Cable Subscription</div>, amount: <div className={classes.plainArea} contentEditable={true}>768990038322</div>, AccountCode: <div className={classes.desItem}>76yt54er453hyj</div>, customer: <div className={classes.plainArea} contentEditable={true} >paid for Gotv Subscription</div>, date: <div className={classes.desItem}>https://www.dtechpay.com/76yt54er453hyj</div>, controls: { isDropdown: false, incoming: false },
                },
                
              ]}
            />


        {/* add some descr abt the apis and sdks */}
        <div className={`${classes.desCard}`}>
        <Typography component="h2" className={classes.headerMd} style={{ marginBottom: "10px" }}>APIs and SDKs</Typography>
          <div>
            <Th className={classes.compactRow} style={{ backgroundColor: "transparent" }}>
              <Td className={classes.smPad} style={{ width: sdkCRDW[0] }}></Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[1] }}>APIs</Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[2] }}>SDKs</Td>
            </Th>
            <Tr className={classes.compactRow} style={{alignItems: "flex-start"}}>
              <Td className={`${classes.smPad}`} style={{ width: sdkCRDW[0] }}><div className={classes.plainArea}>PHP</div></Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[1] }}>
                <div className={classes.plainArea}>
                  {/* <div>blablajdp</div> */}
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Documentation &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Learn More &gt; </div>
                </div>
              </Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[2] }}>
                <div className={classes.plainArea}>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Install &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Documentation &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Learn More &gt; </div>
                </div>
              </Td>
            </Tr>
            <Tr className={classes.compactRow} style={{alignItems: "flex-start"}}>
              <Td className={`${classes.smPad}`} style={{ width: sdkCRDW[0] }}><div className={classes.plainArea}>NodeJS</div></Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[1] }}>
                <div className={classes.plainArea}>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Documentation &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Learn More &gt; </div>
                </div>
              </Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[2] }}>
                <div className={classes.plainArea}>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Install &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Documentation &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Learn More &gt; </div>
                </div>
              </Td>
            </Tr>
            <Tr className={classes.compactRow} style={{alignItems: "flex-start"}}>
              <Td className={`${classes.smPad}`} style={{ width: sdkCRDW[0] }}><div className={classes.plainArea}>Java</div></Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[1] }}>
                <div className={classes.plainArea}>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Documentation &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Learn More &gt; </div>
                </div>
              </Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[2] }}>
                <div className={classes.plainArea}>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Install &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Documentation &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Learn More &gt; </div>
                </div>
              </Td>
            </Tr>
            <Tr className={classes.compactRow} style={{alignItems: "flex-start"}}>
              <Td className={`${classes.smPad}`} style={{ width: sdkCRDW[0] }}><div className={classes.plainArea}>C++</div></Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[1] }}>
                <div className={classes.plainArea}>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Documentation &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Learn More &gt; </div>
                </div>
              </Td>
              <Td className={classes.smPad} style={{ width: sdkCRDW[2] }}>

                <div className={classes.plainArea}>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Install &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Documentation &gt; </div>
                  <div style={{margin: "4px 0", cursor: "pointer"}} className={classes.desItem}>Learn More &gt; </div>
                </div>
              </Td>
              
            </Tr>
          </div>
        </div>
      </div>
    </div>
  );
}
