import { Theme } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  main: {
    display: "flex",
    padding: "8px 40px",
    height: "60px",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.contrastText,
    position: "sticky",
    top: 0,
    zIndex: 500
  },
  logo: {
    transform: "scale(0.6)",
     transformOrigin:"left",
     [theme.breakpoints.down('sm')]: {
      transformOrigin: "center",
    }
  },
  navUt:{
    display: "flex",
    alignItems: "center",
    "&>*": {
      marginLeft: 16
    }
  }

}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;