import { lighten, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import Table, { Status, DescBox } from '../../../../components/designerTable';
import useStyles from './../styles';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useEffect, useState } from 'react';
import { STable } from '../../../../components/skeletonLoader';
import { filterServiceDashboard, getServiceDetails } from '../../../../services/bussiness';
import { commarize, currency, error, formatDateWithMonthName, getAppStorage, isObject, objIsEmpty, setAppStorage } from '../../../../utilities/appHelpers';
import { dispatch, messasgeType, SessionCheck, useGlobalState } from '../../../../pages/dashboard';
import { useUserTheme } from '../../../../themes';
import SimpleButton, { ButtonVariant } from '../../../../components/simpleButton';
import theme from '../../../../resource/theme';
import React from 'react'
ChartJS.register(ArcElement, Tooltip, Legend);



export default function PGSHome({ data }: Record<string, any>) {
  const classes = useStyles().classes; const { isMobile } = useUserTheme();
  const [loading, setLoading] = useState(true), [isData, setIsData] = useState(0);
  const [pageData, setPageData] = useState<Record<string, any>>({});
  const [tempPageData, setTempPageData] = useState<Record<string, any>>({});
  const [errorHandler, setErrorHandler] = useState({ msg: '', status: false, count: 0 });
  const [chartData, setChartData] = useState<Record<string, any>>({ labels: [], data: [], render: 0 })
  const [filterAccName, setFilterAccName] = useState<any>('')
  const [filterDate, setFilterDate] = useState<any>({
    from: '',
    to: ''
  })
  const [isFilter, setIsFilter] = useState(false)
  const [currPage, setCurrPage] = useState<any>(1)
  const [selPage, setSelPage] = useState<any>(1)
  const [pageOptions, setPageOptions] = useState(false)
  const [pieData, setPieData] = useState({
    labels: ['dsd', 'dsd'],
    datasets: [
      {
        label: '# of Votes',
        data: [1, 1],
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(52, 235, 73, 0.5)',
          'rgba(255, 206, 86, 0.5)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(52, 235, 73, 1)',
          'rgba(255, 206, 86, 1)'
        ],
        borderWidth: 1,
      },
    ],
  })


  useEffect(() => {

    setPieData({
      ...pieData, ...{
        labels: chartData.labels,
        datasets: [
          {
            label: '# of Votes',
            data: chartData.data,
            backgroundColor: [
              'rgba(255, 99, 132, 0.5)',
              'rgba(52, 235, 73, 0.5)',
              'rgba(255, 206, 86, 0.5)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(52, 235, 73, 1)',
              'rgba(255, 206, 86, 1)'
            ],
            borderWidth: 1,
          },
        ],
      }
    }

    );
  }, [chartData.render, chartData])

  useEffect(() => {
    if (Array.isArray(pageData?.top_pie)) {
      let obj = {}
      pageData?.top_pie && pageData.top_pie.map((x: any) => Object.assign(obj, x));
      setChartData({ ...chartData, labels: [...Object.keys(obj)], data: [...Object.values(obj)], render: chartData.render + 1 })
    } else if (isObject(pageData?.top_pie)) {
      setChartData({ ...chartData, labels: [...Object.keys(pageData?.top_pie)], data: [...Object.values(pageData?.top_pie)], render: chartData.render + 1 })
    }

  }, [pageData])

  // handle data redering for optimazation
  useEffect(() => {
    const savedTransRec = getAppStorage('serviceRecord');
    if (savedTransRec && objIsEmpty(pageData) && JSON.stringify(savedTransRec) === JSON.stringify(pageData)) {
      setLoading(false);

    } else if (savedTransRec && JSON.stringify(savedTransRec) !== JSON.stringify(pageData)) {
      setPageData(savedTransRec);
      setTempPageData(savedTransRec);
      setLoading(false);

    }
  }, [isData])

  useEffect(() => {
    setCurrPage(1)
  }, [filterAccName, filterDate.from && filterDate.to])


  useEffect(() => {
    (filterAccName || (filterDate.from && filterDate.to)) ? (async () => {
      try {
        const FSD: any = await filterServiceDashboard(data?.bizCode, data?.services[0].serviceCode, currPage, filterAccName ? filterAccName : '', (filterDate.from || filterDate.to) ? `${filterDate.from}to${filterDate.to}` : '');
        if (FSD.status === 200) {
          setTempPageData(FSD.data.data);
          setSelPage(currPage);
        }
      } catch (err) {
        console.log(err, 'err');
      }
    })()
      : setTempPageData(pageData);
  }, [filterAccName, filterDate.from, filterDate.to, currPage])


  useEffect(() => {
    (async () => {
      try {
        const resp = await getServiceDetails(data?.bizCode, data?.services[0].serviceCode, currPage);
        if (resp.status === 200) {
          setLoading(false);
          setAppStorage('serviceRecord', resp.data.data);
          setIsData((n) => n + 1);

          setTempPageData(resp.data.data);
          setSelPage(currPage);
        }

      } catch (e: any) {
        SessionCheck(e);
        setErrorHandler({ ...errorHandler, status: true, count: errorHandler.count + 1, msg: error.handle(e, "You're offline. Please check your network and try again", true) })


      }
    })()

  }, [(!filterAccName && !filterDate.from && !filterDate.to) && currPage])


  const [hintState, setHintState] = useGlobalState("hint");
  useEffect(() => {
    setHintState({ ...hintState, status: errorHandler.status, msg: errorHandler.msg, icon: messasgeType.network });
  }, [errorHandler.count])

  return (
    <div className="fadeInUp animated faster">
      {/* <div className={`${classes.twoCol} ${classes.topStats}`} style={{ borderBottom: "1px solid #f2f2f250" }}>
        <div className={classes.leftCol}>
          <div style={{ width: 350, height: 350 }}>
            <Pie data={pieData} />
          </div>
          {/* {!isMobile && (<Typography component="div" style={{display: "flex",alignItems: "center", margin: "10px 0"}}>Filter By: 
            <SmallDropBtn text={<div style={{padding: "0 10px"}}>Date</div>}>
              <Option>Monthly</Option>
              <Option>Bi-Annual</Option>
              <Option>Annual</Option>
              <Option>Custom</Option>
            </SmallDropBtn>
          </Typography>)}
        </div>

        {isMobile && (
          <div className='statsHeader'>
            <Typography component="h3" className={`${classes.headerMd}`} style={{ marginBottom: 10 }}>Top Payments</Typography>

           <Typography component="div" style={{display: "flex",alignItems: "center", margin: "10px 0"}}>Filter By: 
            <SmallDropBtn text={<div style={{padding: "0 10px"}}>Date</div>}>
              <Option>Monthly</Option>
              <Option>Bi-Annual</Option>
              <Option>Annual</Option>
              <Option>Custom</Option>
            </SmallDropBtn>
          </Typography> 
          </div>
        )}

        <div className={classes.rightCol}>
          {!isMobile && (<Typography component="h3" className={`${classes.headerMd}`} style={{ marginBottom: 10 }}>Top Payments</Typography>)}
          <div>
            {loading ? <SPaymentCard cards={5} /> : pageData?.top_table && pageData?.top_table.length < 1 ? <EmptyTrans /> : <ArrowedList>

              {pageData?.top_table && pageData?.top_table.map((P: any, index: number) => {
                return (
                  <ListItem key={index} textStyle={{ width: '100%' }} title={
                    <div className={classes.twoCol}>
                      <div className={classes.leftCol}>
                        <div>{P?.payerDetails ? P?.payerDetails?.payerName : 'Unkown customer'}</div>
                        <div style={{ fontWeight: 600 }}>{P?.accName}</div>
                      </div>
                      <div className={classes.rightCol}>
                        <div>{currency.naira + commarize(P.amt)}</div>
                        <div style={{ fontWeight: 600 }}>{formatDateWithMonthName(P.initTime)}</div>
                      </div>
                    </div>
                  } icon={<Icon icon="fluent:payment-20-regular" width="16" height="16" />} showArrow={false} style={{ textAlign: "left" }} />
                )
              })}
            </ArrowedList>}

          </div>
        </div>
      </div> */}
      <div style={{ margin: "40px 0" }}>
        <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'unset', justifyContent: 'space-between' }}>
          <Typography component="h3" className={`${classes.headerMd}`} style={{ margin: "10px 0 20px" }}>Reports</Typography>
          {tempPageData.pagination ? <div style={{ display: 'flex', justifyContent: 'space-between', gap: '8px', width: !isMobile ? '65%' : 'unset' }}>
            <div title='Filter by account name and/or date' style={{ position: 'relative', zIndex: '1' }}>
              <SimpleButton onClick={() => { setIsFilter(true) }}>Filter by:</SimpleButton>
            </div>
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', position: 'relative', fontSize: '13px' }}>
                <button onClick={() => { setCurrPage(1) }} style={{ width: '40px', height: '40px', backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.2)', outline: 'none', color: lighten(theme.palette.secondary.main, 0.0), display: 'grid', placeItems: 'center', cursor: 'pointer' }} title='Go to first page'><Icon fontSize={24} icon={'material-symbols:keyboard-double-arrow-left-rounded'} />
                </button>
                <button onClick={() => { setCurrPage((prev: number) => currPage > 1 ? prev - 1 : prev) }} style={{ width: '40px', height: '40px', backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.2)', outline: 'none', color: lighten(theme.palette.secondary.main, 0.0), display: 'grid', placeItems: 'center', cursor: 'pointer' }} title='Go to previous page'><Icon icon={'material-symbols:arrow-back-ios-rounded'} />
                </button>
                <button onClick={() => { setPageOptions(true) }} style={{ width: '100px', height: '40px', backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.2)', outline: 'none', color: '', display: 'grid', placeItems: 'center', cursor: 'pointer' }} title='Choose a page'>{`Page ${tempPageData.pagination['total_pages'] ? selPage : 0} of ${tempPageData.pagination['total_pages']}`}</button>
                <button onClick={() => { setCurrPage((prev: number) => currPage < tempPageData.pagination['total_pages'] ? prev + 1 : prev) }} style={{ width: '40px', height: '40px', backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.2)', outline: 'none', color: lighten(theme.palette.secondary.main, 0.0), display: 'grid', placeItems: 'center', cursor: 'pointer' }} title='Go to next page'><Icon icon={'material-symbols:arrow-forward-ios-rounded'} />
                </button>
                <button onClick={() => { setCurrPage(tempPageData.pagination['total_pages']) }} style={{ width: '40px', height: '40px', backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.2)', outline: 'none', color: lighten(theme.palette.secondary.main, 0.0), display: 'grid', placeItems: 'center', cursor: 'pointer' }} title='Go to last page'><Icon fontSize={24} icon={'material-symbols:keyboard-double-arrow-right-rounded'} />
                </button>
                {pageOptions && <div className='fadeInUp animated faster card-2' style={{ position: 'absolute', right: '0', top: '45px', backgroundColor: 'white', minWidth: '250px', zIndex: '1' }}>
                  {new Array(tempPageData.pagination['total_pages']).fill('').map((pages: any, index: number) => (<div key={index} onClick={() => { setCurrPage(index + 1); setPageOptions(false) }} style={{ borderBottom: (index + 1 !== tempPageData.pagination['total_pages']) ? '1px solid rgba(0,0,0,0.2)' : '', backgroundColor: index + 1 === currPage ? lighten(theme.palette.primary.main, 0.12) : '', color: index + 1 === currPage ? 'white' : '', padding: '5px 8px' }}>{index + 1}</div>))}

                </div>}
              </div>
              <div style={{ textAlign: 'right' }}>{`${tempPageData.pagination['page_description']}`}</div>
            </div>
          </div> : null}

        </div>
        {isFilter && <div className={`fadeInUp animated faster card-2`} style={{ top: '38px', right: '0', backgroundColor: 'white', display: 'flex', flexDirection: isMobile ? 'column' : 'unset', padding: '8px 16px', alignItems: isMobile ? 'unset' : 'center', justifyContent: 'space-between', gap: isMobile ? '5px' : 'unset', minWidth: '250px', borderRadius: '5px' }}>

          <div style={{ width: !isMobile ? '35%' : 'unset', fontSize: '13px' }}>
            <div>Account Name</div>
            <input className={`zoomIn animated faster`} value={filterAccName} onInput={(e: any) => { setFilterAccName(e.target.value) }} placeholder='Search by account name' style={{ border: '1px solid rgba(0, 0, 0, 0.2)', padding: '8px', borderRadius: '5px', width: '100%', outline: 'none' }} />
          </div>
          <div style={{ width: '43%', fontSize: '13px' }}>
            <div>Date</div>
            <div className={`zoomIn animated faster`} style={{ display: 'flex', gap: '8px' }}>
              <div>
                <label htmlFor="from">From: <input id='from' onChange={(e: any) => { setFilterDate((prev: any) => ({ ...prev, from: e.target.value })) }} type='date' style={{ border: '1px solid rgba(0, 0, 0, 0.2)', outline: 'none', padding: '5px' }} />
                </label>
              </div>
              <div>
                <label htmlFor=""> To:
                  <input onChange={(e: any) => { setFilterDate((prev: any) => ({ ...prev, to: e.target.value })) }} type='date' style={{ border: '1px solid rgba(0, 0, 0, 0.2)', outline: 'none', padding: '5px' }} />
                </label>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right', width: isMobile ? 'auto' : '15%', fontSize: '13px' }}>
            <SimpleButton variant={ButtonVariant.white} onClick={() => { setIsFilter(false); setFilterDate((prev: any) => ({ ...prev, from: '', to: '' })); setFilterAccName("") }}>Clear filter</SimpleButton>
          </div>
        </div>}
        <div style={!isMobile ? { alignItems: "flex-start", margin: "10px 0" } : { alignItems: "flex-start", margin: "10px 0", }}>
          <div className={classes.twoCol} style={isMobile ? { flexDirection: 'column-reverse' } : {}}>
            {/* <div className={classes.leftCol}>
                <div className={classes.searchBox}>
                  <Icon icon="akar-icons:search" width="16" height="16" />
                  <input type="search" name="" id="" />
                </div>
              </div> */}
            {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 20px" }} className={classes.rightCol}>
                <Typography component="div" style={{ display: "flex", alignItems: "center" }}>Filter By:
                  <SmallDropBtn text={<div style={{ padding: "0 10px" }}>Account Type</div>}>
                    <Option>School Fee</Option>
                    <Option>SUG Fee</Option>
                  </SmallDropBtn>
                </Typography>
                <Typography component="div" style={{ display: "flex", alignItems: "center" }}>Filter By:
                  <SmallDropBtn text={<div style={{ padding: "0 10px" }}>Date</div>}>
                    <Option>Monthly</Option>
                    <Option>Bi-Annual</Option>
                    <Option>Annual</Option>
                    <Option>Custom</Option>
                  </SmallDropBtn>
                </Typography>
              </div> */}
          </div>

        </div>
        <div>
          {loading ? <STable /> : <Table
            fields={[
              { name: "Account", size: 25 },
              { name: "Amount", size: 15 },
              { name: "Charges", size: 15 },
              { name: "Status", size: 15 },
              { name: "Customer", size: 30 },
              { name: "Date", size: 15 },
            ]}
            records={tempPageData?.reports && tempPageData?.reports.map((r: any, index: number) => {

              return {
                transaction: r?.accName, amount: currency.naira + commarize(r?.amt), charges: currency.naira + commarize(r?.charges), status: +r.statusCode === 1 ? <Status status={true}>Successfull</Status> : +r.statusCode === 0 ? <Status status={false}>Pending</Status> : <Status status={false}>Failed</Status>, customer: r?.payerDetails ? r?.payerDetails?.payerName : 'Unknown customer', date: formatDateWithMonthName(r.initTime), controls: { isDropdown: true, incoming: r.statusCode > 1 ? false : true },
                details: (
                  <div key={index} style={{ display: "flex", padding: "5px 0" }}>
                    <div style={{ width: "40%", paddingRight: 20 }}>
                      <DescBox title='Account Name' descr={r?.accName} style={{ margin: "10px 0" }} descrStyle={{ fontSize: "1.5em" }} />
                      {/* <InfoBox>
                          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Pariatur amet veniam, totam iusto incidunt est? Consequatur labore nobis cumque similique!
                        </InfoBox> */}
                    </div>

                    <div style={{ width: "20%", borderRight: "1px solid #00000050" }}>
                      <DescBox title='Date Initialized' descr={formatDateWithMonthName(r?.initTime)} style={{ margin: "10px 0" }} />
                      <DescBox title='Date Completed' descr={formatDateWithMonthName(r?.respTime)} style={{ margin: "10px 0" }} />
                    </div>
                    <div style={{ width: "20%", textAlign: "right" }}>
                      <DescBox title='Amount' descr={currency.naira + commarize(r.amt)} style={{ margin: "10px 0" }} descrStyle={{ fontSize: "1.5em" }} />
                      <DescBox title='Charges' descr={currency.naira + commarize(r?.charges)} style={{ margin: "10px 0" }} descrStyle={{ fontSize: "1.5em" }} />
                    </div>
                  </div>
                )
              }

            })




            }
          />}


        </div>
      </div>
    </div>
  );
}

