import { lighten } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'btnBody'>()((theme, _params, classes) => ({
  main: {
    width: "100%",
    borderRadius: "4px",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  btn: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    border: "none",
    color: "#333",
    fontSize: "0.7em",
    fontWeight: 500,
    cursor: "pointer",
    padding: "5px 0 5px 5px",
    width:"100%",
    [`&:only-child .${classes.btnBody}, &:last-child .${classes.btnBody}`]: {
      border: "none"
    }
  },
  icon: {
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.background.paper,
    margin: "0 10px",
    padding: "5px",
    "& svg": {
      width: "100%",
      height: "100%",
      color: lighten(theme.palette.primary.main, 0.2),
    }
  },
  btnBody: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between", 
    width: "calc(100% - 50px)",
    borderBottom: `1px solid ${theme.palette.background.paper}`,
    padding: "10px 0",
    lineHeight: 1
  },
  checkbox: {
    border: `1px solid ${theme.palette.background.paper}`,
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 10px",
    padding: "5px",
    "& svg": {
      width: "100%",
      height: "100%",
      color: lighten(theme.palette.primary.main, 0.2),
    }
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;