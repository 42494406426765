import "./App.css";
import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./routes";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import theme from "./resource/theme";
import './resource/css/animate.css'
import './resource/css/w3.css';
import React from "react";


declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <MainRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
