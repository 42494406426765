import React from "react";
import { paginationData } from "../utilities/types";

export interface usePaginationInterface{
    pageNumber: number,
    pageSize: number,
    totalRecords: number,
    pageFrom: number,
    pageTo: number,
    nextPageNumber: number,
    previousPageNumber: number,
    totalPageNumber: number,
    displayPageNumbers : number[]
}

const usePagination = (
  pageDetails: paginationData,
  maxDisplayPages: number = 10
): usePaginationInterface => {

  const { pageSize, pageNumber, totalRecords } = pageDetails;
  //get the current page count start
  const pageStart: number = (pageNumber - 1) * pageSize + 1;

  //get the current page count end
  const pageEndCalc: number = pageStart + pageSize - 1;
  const pageEnd: number =
    pageEndCalc > totalRecords ? totalRecords : pageEndCalc;

  //available pages
  const totalPageNumber: number = Math.ceil(totalRecords / pageSize);

  //next and previous pageNumber
  const nextPageNumber: number =
    pageNumber + 1 > totalPageNumber ? totalPageNumber : pageNumber + 1;
  const prevPageNumber: number = pageNumber - 1 < 1 ? 1 : pageNumber - 1;

  //get display pages
  let displayPages: any = [];
  //remaining pages
 const remPages = totalPageNumber - pageNumber;

 const newArray = (length:number, start:number = 1):number[] =>{
return new Array(length).fill(start).map((val: any, ind: number) => ind + val)
 }

  if (totalPageNumber < 10) {
    displayPages = newArray(totalPageNumber);
  }else if(pageNumber < 6){
    displayPages = newArray(10);
  }else if(remPages >= 5){
    displayPages = [...newArray(4, pageNumber - 4), pageNumber, ...newArray(5, pageNumber + 1)];
  }else{
    displayPages = newArray(10, totalPageNumber - 9);
  }

  return {
    pageNumber: pageNumber,
    pageSize: pageSize,
    totalRecords: totalRecords,
    pageFrom: pageStart,
    pageTo: pageEnd,
    nextPageNumber: nextPageNumber,
    previousPageNumber: prevPageNumber,
    totalPageNumber: totalPageNumber,
    displayPageNumbers : displayPages
}
};

export default usePagination;
