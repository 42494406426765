import React from "react";

export default function useNetwork() {
    const [isOnline, setNetwork] = React.useState(window.navigator.onLine);
    const [networkChange, setNetwrkChange] = React.useState(false);
  
    const updateNetwork = () => {
      setNetwork(window.navigator.onLine);
      setNetwrkChange(true);
    };
  
    React.useEffect(() => {
      window.addEventListener("offline", updateNetwork);
  
      window.addEventListener("online", updateNetwork);
  
      return () => {
        window.removeEventListener("offline", updateNetwork);
  
        window.removeEventListener("online", updateNetwork);
      };
    });
  
    return [isOnline,networkChange];
  }

