import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme)=>({
    progress:{
        display:'flex',
        justifyContent:'center',
        alightItems:'center',
       
        '& .circle-bx':{
            '& svg':{
                position:"absolute",

             }
        },
        '&.show':{
        animation:'zoomInShort 0.8s ease-out both'
        },
        '&.hide':{
            animation:'zoomOutShort 0.5s ease-in both'
            },
    "&.brightenSpinner":{
        "& > div .path":{
            stroke:'#a3edf8' ,
        }
    }
    },

   
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;