import React, { KeyboardEvent, useMemo, useRef } from "react";
import useStyles from "./styles";

//Pin Verification Input (PVI)
const PVI =(props:any)=>{
    const {boxes=4, getValue=()=>void(0), type="password"} =props;
    const styles = useStyles().classes;
    const parent = useRef <any>(null);
    // const [pin, setPin] = useState('');
    const setPinByField = (field:any)=>{
      let fields = field.parentElement.children;
      let pPin = '';
      for(let i = 0; i<fields.length; i++){
        // fields[i].value=nums[i]; fields[i].focus();
        pPin += fields[i].value;
      }
      getValue("pin",pPin);
    }
    const handleInputs=(field:any)=>{
      
        // let e=window.event;
        // if(!field) field = e.target;
        field = field.target;

    
      if(!/^[0-9]*$/.test(field.value)){
         field.value = '';
      }else{
        if (field.value.length === 1 ) {
            if(field.nextElementSibling){
                field.nextElementSibling.focus();
            }else{
                //return; 
            }
      }   
        }
        setPinByField(field);
       
        }

        

    const handlePaste=(e:any)=>{
        // e = window.event;
        e.stopPropagation();
        e.preventDefault();
        
    //    let clipboardData = e.clipboardData || window.clipboardData;
       let clipboardData = e.clipboardData;
        let pastedData = clipboardData.getData('Text');
    
        if(Number.isInteger(+pastedData)){
            let str = pastedData.toString();
            if(str.length > 0){
              let nums = str.split('');
            let fields = e.target.parentElement.children;
            let pPin = '';
            for(let i = 0; i<nums.length; i++){
              fields[i].value=nums[i]; fields[i].focus();
              pPin += nums[i];
              if(i===fields.length-1)break;
            }
            // setPin(pPin);
            getValue("pin",pPin);
          }
        }
        
    }

    const handleArrowMovement=(e:KeyboardEvent<object>)=>{
        let box:any = e.target;
      if(e.keyCode === 37){
          if(!box.previousElementSibling)return; 
            box.previousElementSibling.focus();
          box.selectionStart = box.selectionEnd = box.value.length;
      } else if(e.keyCode === 39){
          if(!box.nextElementSibling)return; 
            box.nextElementSibling.focus();
      }
      
      if(e.keyCode === 8){
        e.preventDefault();
        if(!box.previousElementSibling) return box.value = '';
        box.value = '';
        box.previousElementSibling.focus();
         
      }else if(e.keyCode === 46){
        e.preventDefault();
        if(!box.nextElementSibling) return box.value = '';
        box.value = '';
        box.nextElementSibling.focus();   
    }

    if(box.value.length >=1 && box.nextElementSibling && e.keyCode !==37 && e.keyCode !==39){
        box.nextElementSibling.focus();
    }
}

    let pvis:number[] = useMemo(()=>Array.from(Array(boxes).keys()),[boxes]);
    return( <div ref={parent} className={`${styles.pviInputs} fadeInUp delay-0-2s animated`}>
        {
            pvis.map(box=><input key={box} type={type} maxLength={1} onKeyDown={(e)=>handleArrowMovement(e)} onPaste={(e:any)=>handlePaste(e)} onInput={(e:any)=>{handleInputs(e)}} />)

        }
  </div>);
}

export default PVI;