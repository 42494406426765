import {TRANSFERS, SEND, VERIFY, TYPES, DESTINATIONS } from './../../utilities/constant';
import axiosInstance from '../https'

export const GetTransferTypes =  ()=>{
    return axiosInstance.get(`${TRANSFERS}/${TYPES}`);
}

export const GetBanks =  (typeID:string)=>{
    return axiosInstance.get(`${TRANSFERS}/${TYPES}/${typeID}/${DESTINATIONS}`);
}


export const VerifyDetails =  (typeID:string,data: Record <string, any>)=>{
    return axiosInstance.post(`${TRANSFERS}/${VERIFY}/${typeID}`,data);
}

export const TransferFund =  (typeID:string,data: Record <string, any>)=>{
    return axiosInstance.post(`${TRANSFERS}/${SEND}/${typeID}`,data);
}
    
