import { alpha, darken, Theme } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  wizardMain: {
    maxWidth: "900px",
    margin: "auto",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    overflow: "hidden",
    boxSizing: "border-box",
    position: 'relative',
    color: "#555",
    "& *":{
      boxSizing: "border-box"
    },
  },
  wizardIndicators: {
    height: "100px",
    boxShadow: "0 2px 2px #00000020",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    position: "relative",
    top: 0,
    zIndex: 3,
  },
  wizardControls: {
    height: "80px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "relative",
    zIndex: 3
  },
  wizardBody: {
    width: "100%",
    height: "60vh",
    position: 'relative',
    overflowY: "scroll",
    zIndex: 2
  },
  wizardContent: {
    padding: '40px 0',
    position: 'relative',
    "&>*":{
      position: "absolute",
      bottom: "0",
      width: "100%",
      height: "100%",
      opacity: 0,
      transition: "1s"
    },
    "&>*.right":{
      animation: "fadeOutLeft 0.8s ease-in-out forwards",
      "&.active": {
        animation: "fadeInRight 0.5s ease-in-out forwards",
        position: "relative",
        zIndex: 3
      },
    },
    "&>*.left":{
      animation: "fadeOutRight 0.8s ease-in-out forwards",
      "&.active": {
        animation: "fadeInLeft 0.5s ease-in-out forwards",
        position: "relative",
        zIndex: 3
      },
    }
  },
  contentLogo:{
    position: "absolute",
    width: "400px",
    height: "400px",
    borderRadius: "50%",
    right: "-12%",
    bottom: "-10%",
    backgroundColor: alpha(theme.palette.background.default, 0.5),
    opacity: 0,
    animation: "fadeIn 2s ease-in-out forwards",
    zIndex: 0,
    "& svg": {
      color: darken(theme.palette.background.paper, 0.03),
      fontSize: "12em",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -70%)",
      opacity: 0,
      animation: "fadeIn 2s ease-in-out forwards",
      "@media (max-width:600px)":{
        fontSize: "8em",
      }
    },
    "@media (max-width:600px)":{
      width: "300px",
      height: "300px",
    }
  },
  buttons: {
    transform: "scale(0.8)"
  },
  backButton: {
    "& .first, & .second, & .firstText, & .secondText": {
      color: "#444"
    }
  },
  continueButton: {
    flexDirection: "row-reverse"
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;