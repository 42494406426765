import React, { useState } from "react";
import { usePaginationInterface } from "../../customHooks/pagination";
import { paginationData } from "../../utilities/types";
import { Icon } from '@iconify/react';
import { lighten } from "@mui/material";
import theme from '../../resource/theme';

export const defaultPageData: paginationData = {
    pageNumber: 1,
    pageSize: 15,
    totalRecords: 0
  }
  
  interface IPaginationControl {
    pageDataState: [paginationData, React.Dispatch<React.SetStateAction<paginationData>>];
    pagination: usePaginationInterface;
  }
  
  export const PaginationControl = ({ pageDataState, pagination }: IPaginationControl) => {
    const [selPage, setSelPage] = useState<any>(1)
    const [pageOptions, setPageOptions] = useState(false)
  
    if (selPage != pagination.pageNumber) {
      setSelPage(pagination.pageNumber)
    }
  
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', position: 'relative', fontSize: '13px' }}>
          <button onClick={() => { pageDataState[1]({...pageDataState[0],pageNumber: 1}) }} style={{ width: '40px', height: '40px', backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.2)', outline: 'none', color: lighten(theme.palette.secondary.main, 0.0), display: 'grid', placeItems: 'center', cursor: 'pointer' }} title='Go to first page'><Icon fontSize={24} icon={'material-symbols:keyboard-double-arrow-left-rounded'} />
          </button>
          <button onClick={() => { pageDataState[1]({...pageDataState[0],pageNumber:pagination.previousPageNumber}) }} style={{ width: '40px', height: '40px', backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.2)', outline: 'none', color: lighten(theme.palette.secondary.main, 0.0), display: 'grid', placeItems: 'center', cursor: 'pointer' }} title='Go to previous page'><Icon icon={'material-symbols:arrow-back-ios-rounded'} />
          </button>
          <button onClick={() => { setPageOptions(true) }} style={{ width: '100px', height: '40px', backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.2)', outline: 'none', color: '', display: 'grid', placeItems: 'center', cursor: 'pointer' }} title='Choose a page'>{`Page ${selPage} of ${pagination?.totalPageNumber}`}</button>
          <button onClick={() => { pageDataState[1]({...pageDataState[0],pageNumber:pagination.nextPageNumber}) }} style={{ width: '40px', height: '40px', backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.2)', outline: 'none', color: lighten(theme.palette.secondary.main, 0.0), display: 'grid', placeItems: 'center', cursor: 'pointer' }} title='Go to next page'><Icon icon={'material-symbols:arrow-forward-ios-rounded'} />
          </button>
          <button onClick={() => { pageDataState[1]({...pageDataState[0],pageNumber:pagination.totalPageNumber})  }} style={{ width: '40px', height: '40px', backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.2)', outline: 'none', color: lighten(theme.palette.secondary.main, 0.0), display: 'grid', placeItems: 'center', cursor: 'pointer' }} title='Go to last page'><Icon fontSize={24} icon={'material-symbols:keyboard-double-arrow-right-rounded'} />
          </button>
          {pageOptions && <div className='fadeInUp animated faster card-2' style={{ position: 'absolute', right: '0', top: '45px', backgroundColor: 'white', minWidth: '250px', zIndex: '1' }}>
            {new Array(pagination.totalPageNumber).fill(0).map((pages: any, index: number) => (<div key={index} onClick={() => { pageDataState[1]({...pageDataState[0],pageNumber: index + 1}); setPageOptions(false) }} style={{ borderBottom: (index + 1 !== pagination.totalPageNumber) ? '1px solid rgba(0,0,0,0.2)' : '', backgroundColor: index + 1 === pagination.pageNumber ? lighten(theme.palette.primary.main, 0.12) : '', color: index + 1 === pagination.pageNumber ? 'white' : '', padding: '5px 8px' }}>{index + 1}</div>))}
  
          </div>}
        </div>
        <div style={{ textAlign: 'right' }}>{pagination.pageFrom} -  {pagination.pageTo} of {pagination.totalRecords}</div>
      </div>
    );
  }