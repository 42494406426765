import * as React from 'react';
import { allowOnClick, commarize, currency, formatDateWithMonthName } from '../../utilities/appHelpers';
import useStyles from './styles';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

export interface ITableProps {
  children?: any,
  fields?:Record<string, any>,
  records?:Record<string, any>
  className?:string,
  style?:Record<string,any>
  title?:string
  data?:Record<string,any>
}

export default function Table(props: ITableProps) {
  const {data=[]}=props;
  const classes = useStyles().classes;

  const { fields = [{name:"Amount",size:15},{name:"Status",size:15},{name:"Type",size:30},{name:"Date",size:20},{name:"Payment Method",size:20}],
  records =  Array.isArray(data) && data.length>0 && data.map((td:any,i:number)=>{
    const status = td.status ===2 ?'Failed':td.status===1?'Completed':'Pending';

      return {amount:currency.naira + commarize(td.amt),status:<Status status={td.status < 1?false:true}>{status}</Status>,recipient:<span>{td.category}</span>,date:formatDateWithMonthName(td.finishDate),paymentmethod:" ",animate:`animated delay-0-${i} fadeInUp`, 
      details: (
        <div style={{display:"flex", padding: "5px 0"}}>
          <div style={{width: "40%", paddingRight: 20}}>
            <DescBox title='Transaction Type' descr={td.category} style={{margin:"10px 0"}} descrStyle={{fontSize: "1.5em"}}/>
            <InfoBox>
              {td.descr === 'Registration Bonus'?'Your Wallet has been credited with 100.00. Thanks for Choosing Dtechpay':''}
            </InfoBox>
          </div>
          <div style={{width: "20%"}}>
            <DescBox title='Status' descr={status} style={{margin:"10px 0"}}/>
            <DescBox title='Transaction No.' descr={td.refNo} style={{margin:"10px 0"}}/>
          </div>
          <div style={{width: "20%", borderRight: "1px solid #00000050"}}>
            <DescBox title='Date Commenced' descr={formatDateWithMonthName(td.initDate)} style={{margin:"10px 0"}}/>
            <DescBox title='Date Paid' descr={formatDateWithMonthName(td.finishDate)}  style={{margin:"10px 0"}}/>
          </div>
          <div style={{width: "20%", textAlign: "right"}}>
            <DescBox title='Amount' descr={currency.naira + commarize(td.amt)} style={{margin:"10px 0"}} descrStyle={{fontSize: "1.5em"}} />
          </div>
        </div>
      ), 
      controls: {onClick:()=>void(0), isDropdown:true, incoming:td.flow>0?true:false }}
  }),


  className="",
  style={},children
} = props;

  return (
    <div className={`${classes.table} ${className}`} style={style}>
      <Th>
        {fields.map((field:Record<string, number>, index:number)=>{
          return ( <Td key={index} style={{width:`${field.size}%`}}>{field.name}</Td>)
        })}
      </Th>
      {records&&records.map((record:any, index:number)=>{
        return (
          <TrX key={index} record={record} fields={fields}>
           
          </TrX>
        )
      })}
      {children}
    </div>
  );
}

export interface IThProps {
  children?: any
  className?:string,
  style?:Record<string,any>
}

export function Th(props: IThProps) {
  const classes = useStyles().classes;
  const { className = "", style={} } = props;
  return (
    <div className={`${classes.head} ${className}`} style={style}>
      {props.children}
    </div>
  );
}

export interface ITrProps {
  children?: any
  className?:string,
  style?:Record<string,any>
  onClick?:any
}

export function Tr(props: ITrProps) {
  const classes = useStyles().classes;
  const { className = "", style={}, onClick=()=>void(0) } = props;
  return (
    <div className={`${classes.row} ${className}`} style={style} {...allowOnClick((onClick))}>
      {props.children}
    </div>
  );


}

export interface ITrXProps {
  children?: any,
  className?:string,
  style?:Record<string,any>,
  record?:any,
  fields?:any,
  animate?:string,
}

export function TrX(props: ITrXProps) {
  const classes = useStyles().classes;
  const { className = "", style={}, record = null, fields = null} = props;
  const [drop, setDrop] = React.useState("");
  const [logo, setLogo] = React.useState(<FontAwesomeIcon icon={faAngleDown} />);
  const dropdown = ()=>{ 
    if(record["controls"]?.isDropdown) {
      if(drop === "clicked card-4") {
        setDrop("");
        setLogo(<FontAwesomeIcon icon={faAngleDown} />);
      } else {
        setDrop("clicked card-4");
        setLogo(<FontAwesomeIcon icon={faAngleUp} />);
      }}}
  return (
    <div className={`${classes.rowdrop} ${record["controls"]?.isDropdown && 'hoverEffect'} ${drop} ${className} ${record['animate'] && record['animate']}`} style={style}>
      <div className={`${classes.rowInd} ${(record["controls"]?.incoming === null || record["controls"]?.incoming === undefined)  ? "" : record["controls"].incoming ? "incoming" : "outgoing"}`} style={style}></div>
      
      <Tr onClick={dropdown}>
        {Object.keys(record).map((data: any, ind: number) => {
          return (
            (data !== "details" || data !== "controls") && (ind < fields.length) && <Td key={ind} style={{ width: `${fields[ind].size}%` }}>{record[data]}</Td>
          )
        })}
        {(record["controls"]?.isDropdown) && <div className={classes.dropArrow}>{logo}</div>}
      </Tr>
      {record["details"] && <Drop>{record["details"]}</Drop>}
      {props.children}
    </div>
  );
}


export interface ITdProps {
  children?: any
  className?:string,
  style?:Record<string,any>
}

export function Td(props: ITdProps) {
  const classes = useStyles().classes;
  const { className = "", style={} } = props;
  return (
    <Typography component="div" className={`${classes.data} ${className}`} style={style}>
      {props.children}
    </Typography>
  );
}

export interface IDropProps {
  children?: any,
  className?:string,
  style?:Record<string,any>
}

export function Drop(props: IDropProps) {
  const classes = useStyles().classes;
  const { className = "", style={} } = props;
  return (
    <div className={`${classes.dropdown} ${className}`} style={style}>
      {props.children}
    </div>
  );
}

export interface IStatusProps {
  children?: any,
  className?:string,
  style?:Record<string,any>
  status?:boolean
}

export function Status(props: IStatusProps) {
  const classes = useStyles().classes;
  const { className = "", style={}, status=false } = props;
  return (
    <div className={`${classes.status} ${className} ${status ? "completed":"pending"}`} 
    style={style}>
      {props.children}
    </div>
  );
}

export interface IInfoBoxProps {
  children?: any,
  className?:string,
  style?:Record<string,any>
}

export function InfoBox(props: IInfoBoxProps) {
  const classes = useStyles().classes;
  const { className = "", style={} } = props;
  return (
    <div className={`${classes.info} ${className}`} style={style}>
      {props.children}
    </div>
  );
}

export interface IDescBoxProps {
  children?: any,
  className?:string,
  title?:any,
  descr?:any,
  style?:Record<string,any>
  titleStyle?:Record<string,any>
  descrStyle?:Record<string,any>
}

export function DescBox(props: IDescBoxProps) {
  const classes = useStyles().classes;
  const { className = "", title = "", descr = "", style={}, titleStyle={}, descrStyle={} } = props;
  return (
    <div className={`${className}`} style={style}>
      <Typography component="h4" className={`${classes.descrBoxHeader}`} style={titleStyle}>{title}</Typography>
      <Typography component="p" className={`${classes.descrBoxDescr}`} style={descrStyle}>{descr}</Typography>
    </div>
  );
}