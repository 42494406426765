import Checkbox, { ICheckbox } from "../Checkbox2";


/**
 * ## Switch
 *
 * An easy to use Switch component, used to toggle on and off modes 
 * extends the type, size and variant properties of the **ICheckbox** interface
 *
 * By:
 *
 * - Academa Team
 *
 * For:
 *
 * - Academa UI
 */

const Switch = (props:Omit<ICheckbox,'type'>) =>{
    return <Checkbox {...props} type='switch' />
}

export default Switch;