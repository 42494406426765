import { Icon } from "@iconify/react";
import md5 from "md5";
import React, { useState, useEffect, useRef, memo, useCallback} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Alert from "../components/alertBox";
import Button from "../components/button";
import LogoBox from "../components/logoBox";
import Spinner from "../components/spinner";
import approved1 from "../modules/gateWayMod/images/approved1.gif";
import failed from "../modules/gateWayMod/images/failed.svg";
import axiosInstance from "../services/httpsGateway";
import { fundWallet } from "../services/wallet";
import { error, getAppStorage, objIsEmpty, setAppStorage } from "../utilities/appHelpers";
import { useStyles } from "./styles";
import PDFGen, { pdfstyles } from "../components/pdf";
import ReactPDF, { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, usePDF, Image } from '@react-pdf/renderer';
import logo from "../resource/images/logo2.png";
import moment from "moment";


export enum AppStatus{
        processing = 0,
        success = 1,
        failed = 2
    }



function ConfirmPayment() {
    const styles = useStyles().classes, [URLSearchParams, SetURLSearchParams] = useSearchParams(),
    loc = useLocation(), navigation = useNavigate();
    const [appState, setAppState] = useState({ status: AppStatus.processing, message: "", icon: "eva:arrow-forward-fill", btnText: 'Continue' }),
    trnsRef = useRef<any>(''), statusCode = useRef<any>(''),
    wallet_code = useRef(''), [alertStatus, setAlertStatus] = useState(false),
    checksum = useRef<any>(''), transChecksum = useRef<any>(''), walletFunding = useRef<any>();

    // const [PDFUrl, setPDFUrl] = useState('initial');
    const [recpData, setRecpData] = useState<Record<string,any>>({});
    const [instance, update] = usePDF({});
    const url = useRef('');





    const paymentStatusInfo = {
        internal:{
            failed:{
                descr:'An error occured while proceesing your payment',
                btn:[{
                    text:'Cancel',
                    icon:'mdi:close',
                    variant:'secondary',
                    onclick:()=>navigation('/dashboard')
                },{
                    text:'Try again',
                    icon:'pajamas:repeat',
                    variant:'primary',
                    onclick:()=>navigation(`/checkout/${trnsRef.current}`)
                }
            ],
                logo:failed,
                title:'Payment Failed',
                loading:false,
                status:false
                
            },
            success:{
                descr:'Your wallet balance have been updated successfully',
                btn:[{
                    text:'Continue',
                    icon:'eva:arrow-forward-fill',
                    variant:'primary',
                    onclick:()=>navigation('/dashboard')
                }
            ],
                logo:approved1,
                title:'Payment Successful',
                loading:false,
                status:true
                
            }
        },
        external:{
            failed:{
                descr:'Unable to process payment',
                btn:[{
                    text:'Try again',
                    icon:'pajamas:repeat',
                    variant:'primary',
                    onclick:()=>navigation(`/checkout/${trnsRef.current}`)
                }
            ],
                logo:failed,
                title:'Payment Failed',
                loading:false,
                status:false

            },
            success:{
                descr:'Thank you! Your payment as been received',
                btn:[{
                    text:'Download Receipt',
                    icon:'material-symbols:download',
                    variant:'primary',
                    onclick:()=>downloadReciept()
                }
            ],
                logo:approved1,
                title:'Payment Successful',
                loading:false,
                status:true

            }
        },
        initial:{
            loading:true,
            title:'Confirming Payment...',
            logo:'eva:arrow-forward-fill',
            status: false,
            descr:'',
            btn:[{
                text:'Processing...',
                icon:'',
                variant:'primary',
                onclick:()=>navigation('/dashboard')
            }
        ]
            
        }
    }

    const [status, setStatus] = useState(paymentStatusInfo.initial);


useEffect(()=>{
    const renderPDF = async()=>{
        const pdfdata = (<Document pageLayout="twoPageRight">

        <Page size="A5" style={pdfstyles.page}>
        <View style={{width:"100%", height:50, display:'flex',justifyContent:'center',alignItems:'center',marginTop:10}}>
        <View style={{width:100, height:50, display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Image src={logo} style={{width:'120px', height:"50px", display:'flex',justifyContent:'center',alignItems:'center'}}/>
        </View>

    </View>

        <View style={pdfstyles.thinkLine}></View>
        <View style={pdfstyles.first}>
            <Text>Receipt from {recpData?.businessName}</Text>
            <Text style={{textAlign:'center', fontSize:9, margin:10,color:'grey'}}>Your payment was successful and has been received by {recpData?.businessName}</Text>
            <Text style={{margin:'12px', fontSize:'23px', fontWeight:800}}>{recpData?.currency} {recpData?.amount}</Text>

        <View style={pdfstyles.line}></View>

            
            <Text style={{margin:'15px 0 10px 0', fontSize:10, fontWeight:800, color:'grey'}}>Payment Details</Text>

            <View style={pdfstyles.section}>
            <View style={pdfstyles.row}>
                <Text style={pdfstyles.txt}>Amount Paid</Text>
                <Text style={pdfstyles.txt}>{recpData?.currency} {recpData?.amount}</Text>
            </View>

            <View style={pdfstyles.row}>
                <Text style={pdfstyles.txt}>Transaction Fee</Text>
                <Text style={pdfstyles.txt}>{recpData?.currency} {recpData?.charges}</Text>
            </View>

            <View style={pdfstyles.row}>
              <Text style={pdfstyles.txt}>Payer Name</Text>
              <Text style={pdfstyles.txt}>{recpData.payerName}</Text>
            </View>

            <View style={pdfstyles.row}>
                <Text style={pdfstyles.txt}>Payment Method</Text>
                <Text style={pdfstyles.txt}>{recpData?.channel}</Text>
            </View>

            <View style={pdfstyles.row}>
                <Text style={pdfstyles.txt}>Transaction Reference</Text>
                <Text style={pdfstyles.txt}>{recpData?.ref}</Text>
            </View>

            </View>

            <Text style={{margin:'15px 0 5px 0', fontSize:10, fontWeight:800, color:'grey'}}>{recpData.date}</Text>

            <View style={pdfstyles.line}></View>
           
           <View style={{flexDirection:'column',alignSelf:'flex-start',margin:'10px 0'}}>
              <Text style={[pdfstyles.txt,{fontSize:8}]}>If you questions or issues with this payment, kindly contact Dtech Support Services Limited at invoice@dtechpay.com</Text>
            </View>


            <View style={pdfstyles.line}></View>
            
            <View style={{flexDirection:'column',alignSelf:'flex-start',margin:0}}>
                <Text style={{color:'#000', fontSize:9}}>Please Note:</Text>
                <Text style={[pdfstyles.txt,{fontSize:8}]}>This debit will appear as <Text style={{fontWeight:600, color:'#000'}}>Dtechpay or Dtechpay payment</Text> in your Bank Statement</Text>
            </View>

            </View>
        
        </Page>
    </Document>);

    update(pdfdata);

    }

    renderPDF();

},[recpData])


  useEffect(()=>{
    if(instance.loading === false){
        url.current = (instance.url)?instance.url:'';
       
    }
  },[instance.loading,instance.url])

  const downloadReciept = useCallback(()=>{
    const downloadLink = document.createElement('a');
    downloadLink.href = url.current;
    const datetime = moment().format("YYYY-MM-DD HH.mm.ss").replace(' ',' at ');    
    downloadLink.setAttribute('download', `Transaction Receipt ${datetime}.pdf`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

},[instance.loading,instance.url])

    const finalizePage=()=>{
        
        if (+statusCode.current === 4 && walletFunding.current) { // is internal transaction and it's successful
            setStatus(paymentStatusInfo.internal.success);
        } else if (+statusCode.current === 0 && walletFunding.current) {// is internal transaction and it's Not successful
            setStatus(paymentStatusInfo.internal.failed);
        } else if (+statusCode.current === 4 && !walletFunding.current) {// is external transaction and it's successful
            setStatus(paymentStatusInfo.external.success);
        } else if (+statusCode.current === 0 && !walletFunding.current) {// is external transaction and it's Not successful
            setStatus(paymentStatusInfo.external.failed);
        }

    }



    //functinon to requry transactions
    const requeryTransaction = async (reference:any)=>{
        if(reference){
            try {
                 const resp = await axiosInstance.get(`gateway/requery/${reference}`);
                if(resp.status=== 200){
                    const data = resp.data.data;
                    let stat = +data.code; // status
                    let wf = false; //wallet funding
                    trnsRef.current = data.ref;
                    if(data.metadata ??true){
                        let md = JSON.parse(data.metadata);
                        wf= (md?.walletFunding && (md.walletFunding==='true'));                        
                    }
                        
                    const recieptdata = {
                        channel:data?.channel,
                        currency:data?.currency,
                        amount:data?.amount,
                        ref:data?.ref,
                        charges:data?.charges,
                        businessName:data?.businessName,
                        date:data?.date,
                        payerName:data?.payerName

                    }
                    
                    verifyPaymentParam({status:stat,walletFunding:wf,receipt:recieptdata}); 
                }

            } catch (e) {
                 setAppState({...appState,status:AppStatus.failed, btnText:'Cancel', icon:'mdi:close', message:error.handle(e,"You've lost internet connect, try again leter",true)})
            }
           
        }
    }



    const verifyPaymentParam = (resp:Record<string,any> = {})=>{
        //get transaction details        
        // const td:Record<string, any> = getAppStorage('transDet') || {}; 
        if(objIsEmpty(resp)){
            if(URLSearchParams.has('ref') && URLSearchParams.has('sc') && URLSearchParams.has('cs') && URLSearchParams.has('wf') &&  URLSearchParams.has('recp')){

                //meaning of--- ref = reference; sc = statusCode; cs=checksum; wf=walletFunding;

                let recpt = JSON.parse(atob(URLSearchParams.get('recp') ?? ''));

                setRecpData(recpt);
           
                trnsRef.current = URLSearchParams.get('ref'); //get reference
                statusCode.current = URLSearchParams.get('sc'); //get statuscode
                transChecksum.current = URLSearchParams.get('cs'); //get checksum
                walletFunding.current = (URLSearchParams.get('wf')==="true"); //is it walletfunding?
                let toencrypt = statusCode.current+trnsRef.current+process.env.REACT_APP_SECRET;
                checksum.current =md5(toencrypt); // form a checksum to verify transaction

//           localhost:3000/finish?sc=4&ref=735704844261&cs=15a4b9daa4b002bd414e8a1d892e3c2a&wf=false&recp=eyJjaGFubmVsIjoxLCJjdXJyZW5jeSI6Ik5HTiIsImFtb3VudCI6IjQ5MDAuMDAiLCJyZWYiOiI3MzU3MDQ4NDQyNjEiLCJjaGFyZ2VzIjoiMTAwLjAwIn0=

                //compare checksums to verify payment
                if(checksum.current!==transChecksum.current){
                    navigation('/badrequest');  
                    return;
                }

                finalizePage();
            
                
            }else if(URLSearchParams.has('reference')){
                //requery transaction
                const reference = URLSearchParams.get('reference');
                requeryTransaction(reference);
            }
        }else{
            statusCode.current = resp.status; //get statuscode
            walletFunding.current = resp.walletFunding; //is it walletfunding? 
            setRecpData(resp.receipt);           
            finalizePage();
        }
    }

   

    
    //chekout on page load
    useEffect(()=>verifyPaymentParam(),[]);

    
    return (
        <>
        <Alert title={"Failed"} show={alertStatus} onClose={(stat:boolean)=>setAlertStatus(stat)} message={appState.message}/>
        <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f6f6f6" }}>

            <div className={styles.gatewayBody}>
                <div className={`${styles.cont} gateCont`}>

                    <div className="paymentapproved">
                            <div className="logoicon">

                                {status.status && !status.loading && (
                                    <div className="succ">
                                        <img src={status.logo} alt="payment comfirmation - success" />
                                    </div>
                                )}

                                {!status.status && !status.loading && (
                                    <div className="failed">
                                        <img id="small-img" src={status.logo} alt="payment comfirmation - failed" />
                                    </div>
                                )}

                                {status.loading && (<Spinner size={150} style={{ position: "absolute" }} show={true} />)}

                            </div>

                            <div className="finishInfo">
                                <h1 className="title">{status.title}</h1>
                                <p>{status.descr}</p>
                                <br />
                            </div>

                            <div className={styles.cta}>
                            {
                                !status.loading && (<div className={styles.cta}>
                                    {
                                        status.btn.map((b:Record<string, any>, i:number)=>{

                                            return (<Button style={{fontSize:'1.5rem'}} key={i} variant={b.variant} text={b.text} icon={<Icon icon={b.icon} />} onClick={b.onclick}/>)
                                        })
                                    }
                                    
                                </div>)}

                            {status.loading && (<small><i>DON'T CLOSE THIS PAGE</i> </small>)}
                        </div>



                    </div>
                </div>
            </div>
        </div></>

    )
}

export default memo(ConfirmPayment);