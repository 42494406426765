import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import * as React from "react";
import { allowOnClick } from "../../utilities/appHelpers";
import useStyles from "./styles";

export enum IInputStatus {
  "empty" = 0,
  "hasValue" = 1,
}

export interface IDynamicInputProps {
  className?: string;
  focusClassName?: string;
  labelName?: string;
  labelStyle?: Record<string, any>;
  style?: Record<string, any>;
  inputType?: string;
  inputName?: string;
  inputId?: string;
  inputPlaceholder?: string;
  inputStyle?: Record<string, any>;
  inputStatus?: IInputStatus;
  verifyLogo?: any;
  onInput?:any,
  value?:string,
  children?:any,
}

/**
 *
 * @param props
 * @param props.className
 * @param props.labelName
 * @returns JSX.Element
 */


export default function DynamicInput(props: any) {
  const {
    className = "",
    focusClassName = "focused",
    labelName = "",
    labelStyle = {},
    style = {},
    inputStatus = IInputStatus.empty,
    inputId = "",
    inputType = "text",
    inputName = "",
    inputPlaceholder = "",
    onInput = () => void 0,
    inputStyle = {},
    verifyLogo = null,
    value,
    children,
    ...rest
  } = props;

  const inputValue = "";

  const classes = useStyles().classes;
  const [labelState, setLabelState] = React.useState(inputStatus);
  const [inputtedValue, setInputtedValue] = React.useState(inputValue);
  const [inputTypeState, setInputTypeState] = React.useState(inputType);
  React.useEffect(() => {
    setLabelState(inputStatus);
  }, [inputStatus]);
  // const React.createRef()
  
  const setValue = (e: any) => {
    setInputtedValue(e.target.value);
    onInput(e);
    const stat =
      e.target.value == "" ? IInputStatus.empty : IInputStatus.hasValue;
    setLabelState(stat);
    e.target.style.opacity =
      inputType === "date" && e.target.value === "" ? 0 : 1;
  };
  const toggleValueVisibility = (e: any) => {
    if (inputTypeState == "password") {
      setInputTypeState("text");
    } else {
      setInputTypeState("password");
    }
  };
  return (
    <div
      className={`${classes.dynamicInputBox} ${className} card-1`}
      style={style}
    >
      <Typography className={`${classes.innerWrapper}`}>
        <div className={`${classes.logoWrapper}`}>
          {inputType == "password" ? (
            <span
              {...allowOnClick(toggleValueVisibility)}
              className={`${classes.visibilityControlIcon}`}
            >
              {inputTypeState == "password" ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon icon={faEyeSlash} />
              )}
            </span>
          ) : null}
          {verifyLogo != null || verifyLogo != undefined ? (
            <span className={`${classes.verifyLogo}`}>{verifyLogo}</span>
          ) : null}
        </div>

        {inputType != "textarea" ? (
          <>
            <input 
              {...rest}
              type={`${inputTypeState}`}
              name={`${inputName}`}
              placeholder={`${inputPlaceholder}`}
              id={`${inputId}`}
              className={`${classes.inputElement} ${
                labelState == IInputStatus.hasValue ? focusClassName : ""
              }`}
              value={inputtedValue}
              onChange={setValue}
              style={inputStyle}
            />
            <label
              htmlFor={`${classes.inputElement} ${inputId}`}
              className={`${classes.inputLabel}`}
              style={labelStyle}
            >
              {labelName}
            </label>
          </>
        ) : (
          <>
            <textarea
              {...rest}
              name={`${inputName}`}
              placeholder={`${labelName == "" ? inputPlaceholder : ""}`}
              id={`${inputId}`}
              className={`${classes.inputElement} ${
                labelState == IInputStatus.hasValue ? focusClassName : ""
              }`}
              value={inputtedValue}
              onChange={setValue}
              style={inputStyle}
            ></textarea>
            <label
              htmlFor={`${classes.inputElement} ${inputId}`}
              className={`${classes.inputLabel}`}
              style={{ ...labelStyle, transform: "translateY(-140%)" }}
            >
              {labelName}
            </label>
          </>
        )}
        <span className={`${classes.errorDisplay}`}></span>
      </Typography>
    </div>
  );
}


/* export default React.forwardRef((props:any,ref)=>{

  return <DynamicInput ref={ref} props={props}/>
  
}) */