import {SimplePaletteColorOptions } from "@mui/material";
import { Theme, ThemeOptions } from "@mui/material/styles";
import React from "react";

//Add Custome option to material ui Theme and DeprecatedThemeOptions Interface
declare module '@mui/material/styles' {
    interface Theme {
      background: {
        main: string;
        container: string;
        element: string; 
      };
      shadow: {
        z1: string;
        z2: string;
        z3: string;
        z4: string;
        z5: string;
        z6: string;
      };
      colorOpacity:{
        100:string
        90:string
        80:string
        70:string
        60:string
        50:string
        40:string
        30:string
        20:string
        10:string
        0:string
      }
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
      background?: {
        main?: string;
        container?: string;
        element?: string;
      };
      shadow?: {
        z1?: string;
        z2?: string;
        z3?: string;
        z4?: string;
        z5?: string;
        z6?: string;
      };
      colorOpacity?:{
        100?:string
        90?:string
        80?:string
        70?:string
        60?:string
        50?:string
        40?:string
        30?:string
        20?:string
        10?:string
        0?:string
      };
    }
  }

  /**
   * lightTheme Options
   */
const generalTheme:ThemeOptions ={
    typography:{
      fontFamily: "'Montserrat', sans-serif"
    },
    colorOpacity:{
        100:'ff',
        90:'ee',
        80:'dd',
        70:'cc',
        60:'bb',
        50:'aa',
        40:'88',
        30:'66',
        20:'44',
        10:'22',
        0:'00'
      }
}
export const lightTheme:ThemeOptions = {
    palette:{
        text:{
            primary:'#555555'
        },
        mode:'light'
    },
    background:{
        main:'#eeeeee',
        container:'#FFFFFF',
        element:"#dedede"
        
    },
    shadow: {
        z1: '0 1px 2px 0 rgba(145,158,171,0.24)',
        z2: '0 8px 16px 0 rgba(145,158,171,0.24)',
        z3: '0 0 2px 0 rgba(145,158,171,0.24), 0 12px 24px 0 rgba(145,158,171,0.24)',
        z4: '0 0 2px 0 rgba(145,158,171,0.24), 0 16px 32px -4px rgba(145,158,171,0.24)',
        z5: '0 0 2px 0 rgba(145,158,171,0.24), 0 20px 40px -4px rgba(145,158,171,0.24)',
        z6: '0 0 4px 0 rgba(145,158,171,0.24), 0 24px 48px 0 rgba(145,158,171,0.24)',
      },
    ...generalTheme
}

/**
   * darkTheme Options
   */
export const darkTheme: ThemeOptions = {
    palette:{
        text:{
            primary:'#eeeeee'
        },
        mode:'dark',
    },
    background:{
        main:'#031627',
        container:'#102232',
        element:'#ffffff0d'
    },
    shadow: {
        z1: '0 1px 2px 0 rgba(22, 24, 25,0.24)',
        z2: '0 8px 16px 0 rgba(22, 24, 25,0.24)',
        z3: '0 0 2px 0 rgba(22, 24, 25,0.24), 0 12px 24px 0 rgba(22, 24, 25,0.24)',
        z4: '0 0 2px 0 rgba(22, 24, 25,0.24), 0 16px 32px -4px rgba(22, 24, 25,0.24)',
        z5: '0 0 2px 0 rgba(22, 24, 25,0.24), 0 20px 40px -4px rgba(22, 24, 25,0.24)',
        z6: '0 0 4px 0 rgba(22, 24, 25,0.24), 0 24px 48px 0 rgba(22, 24, 25,0.24)',
      },
    ...generalTheme
}

/**
 * Colors - represent primary colors in Theme
 */
export const colors:Record<string,SimplePaletteColorOptions> = {
    standard:{
        main:'#00AB55',
        light:'#00ab553d',
        dark:'#02562c',
        contrastText:'#FFFFFF',
    },
    classic:{
        main:'#1890FF',
        light:'#1890FF3d',
        dark:'#0e4376',
        contrastText:'#FFFFFF',
    }
}

//Available color palletes
export const colorSet = ['default' , 'primary' , 'secondary' , 'info' , 'warning' , 'error'];