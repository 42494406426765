import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import * as React from "react";
import Button from "../../components/button";
import Popup from "../../components/popup";
import useStyles from "./styles";
import loginImg2 from "../heroMod/images/login2.png";
import loginImg from "../heroMod/images/login.png";
import theme from "../../resource/theme";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { error, setAppStorage } from "../../utilities/appHelpers";
import { useState } from "react";
import TextField from "../../components/textField";
import PasswordField from "../../components/passwordField";
import { Login } from "../../services/login";
import { useNavigate } from "react-router-dom";
import { messasgeType, useGlobalState } from "../../pages/dashboard";
import { FormGroup } from "@mui/material";
import Resetpassword from "./resetpassword";
import EmailVerification from "./verifyemail";
import { NetworkError } from "../../utilities/constant";

export interface ILoginModProps {
  show?:boolean;
  onPopChange?:any;
  action?:any
  SignupForm?:React.MouseEventHandler
}
export enum pageRoute {
    login=0,
    requestResetPassword=1,
    createNewPassword=2

  }
function LoginMod(props: ILoginModProps) {
  const {onPopChange=()=>void(0), action=null, show=true, SignupForm} = props;
  
  const classes = useStyles().classes;
  const [hint, setHint] = useState<Record<string,any>>({errorData:null, message:'', count:0, show:false,title:"",loading:false,btnText:"LOGIN",disabled:true}); 
  const [formData, setFormData] = useState<Record<string, any>>({username:'',password:''});
  const [uemail, setUEmail] = useState("");
  const [password, setPassword] = useState(""), [completionMessage, setCompletionMessage]=useState(''),[page, setPage]=useState(0);
  const navigator = useNavigate();

  
  const [hintState,setHintState] = useGlobalState("hint");

  const loadDashboard = (data:any)=>{
    navigator('/dashboard', {state:data})
  }
  
  const submitForm = async ()=>{
    setHint({...hint,loading:true,btnText:"Verifying..."})
    try {
      const res = await Login(formData);
      if (res.status === 200) {
       //localstorage
       setAppStorage("DTECHPAY_TOKEN",res?.data?.data?.token);
       setAppStorage('DTECHPAY_USERID',res?.data?.data?.uniqueId);  
       setAppStorage('USER_DETAILS',res?.data?.data);    
       loadDashboard(res?.data?.data);
      setAppStorage('sessionExp',false);
      }
    } catch (e: any) {
      setHint({...hint,show: true,loading:false,btnText:"LOGIN", count:hint.count ===10 ?0 : hint.count +1, message: error.handle(e,NetworkError,true),});
    }
  }
  React.useEffect(()=>{
    if(formData["username"]!=="" && formData["password"]!==""){
      setHint({...hint,disabled:false})
    }
  },[formData])


  const loginForm = (
    <form onKeyUp={(e:any)=>e.keyCode===13 && submitForm()} onSubmit={()=>submitForm()} name="dtp-login">
    <div
      className={`${classes.loginForm} `}
    >
      <Typography component={'div'}
        className={`${classes.title}`}
        style={{ color: theme.palette.primary.main }}
      >
        Log In
      </Typography>
      <div className={`${classes.imageWrapper}`}>
        <img
          className={`${classes.images} ${classes.imageHeightSetter}`}
          src={loginImg2}
          alt="DTECHPAY"
        />
        <img
          className={`${classes.images} ${classes.imageAnimated}`}
          src={loginImg2}
          alt="DTECHPAY"
        />
        <img
          className={`${classes.images} ${classes.imageCenter}`}
          src={loginImg}
          alt="DTECHPAY"
        />
      </div>
      <Typography component={'div'} className={`${classes.title} small fadeInUp animated delay-0-1s`}>
        Access your <span style={{ color: theme.palette.primary.main }}>World</span>{" "}
        of <span style={{ color: theme.palette.primary.main }}>DTECHPAY</span>
      </Typography>
      <TextField className={`fadeInUp animated delay-0-3s`} type="email" value={uemail} onChange={(e:any)=>{
        setUEmail(e.target.value)
        setFormData({...formData, username:e.target.value})
      }} placeholder="Email address"/>
      <PasswordField className={`fadeInUp animated delay-0-5s`} onChange={(e:any)=>{
        setPassword(e.target.value); 
        setFormData({...formData, password:e.target.value})
      }} value={password} placeholder="Password" />

<div className="flex fadeInUp animated delay-0-6s" style={{display:'flex',justifyContent:'space-between', width:"100%" , alignItems:'center'}}>
      <FormControlLabel control={<Checkbox  />} label="Remember Me" />
      <button type="button" onClick={()=>setPage(pageRoute.requestResetPassword)} className="link" >Forget Password?</button>
</div>
      <div className="cta-cont">
          <Button className={`fadeInUp animated delay-0-7s`} loading={hint.loading?true:false}
            text={hint.btnText} disabled={hint.loading || hint.disabled ?true:false}
            icon={<FontAwesomeIcon icon={faUserLock} />}
            style={{ marginTop: "18px" }} type="submit"
            onClick={(e:any)=>{
              submitForm()
            }}
            />
          <div className="fadeInUp animated delay-0-8s dtp-signup-tc-login">Don't have an account? <br /> <button type="button" className="link" onClick={SignupForm}>Signup</button></div>
        </div>

    </div>
    </form>
  );

    React.useEffect(()=>{
      if(completionMessage!=='')setHint({...hint,show: true, count: hint.count +1, message: completionMessage, icon:messasgeType.success});
    },[completionMessage])

    React.useEffect(()=>{
      setHintState({...hintState,status:hint.show, msg:hint.message, icon:messasgeType.network});
    },[hint.show, hint.message, hint.count])

    const [closingForm, setClosingForm]=useState(false);
    const resetFormData = ()=>{
      setPassword(''); setUEmail('');setClosingForm(true);
      setPage(pageRoute.login);
    }
    
  return (
    <>
    <Popup onReset={resetFormData}
      children={
        (page=== pageRoute.login)?
        loginForm //show login page
        :(page=== pageRoute.requestResetPassword)?
         <Resetpassword onPreviousPage={()=>setPage(pageRoute.login)} isFormClosing={closingForm} onNextPage={setPage}/> // show reset passowrd request via email page
         :<EmailVerification isFormClosing={closingForm} onNextPage={setPage} returnMessage={setCompletionMessage}/> // show new password creation page
        }
      style={{maxWidth: "450px", width: "calc(100% - 50px)"}}
      show={show}
      onPopChange={(s:boolean)=>{onPopChange(s); setClosingForm(false)}}
      scrollbar={false}
    />
    </>
  );
}


export default React.memo(LoginMod);