import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NoMenuNav from '../../components/noMenuNav';
import SideBar, { SideBarHeader, SideBarItem } from "../../components/sideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCheck, faClose, faTimes } from "@fortawesome/free-solid-svg-icons";
import SetupWizard from '../../modules/setupWizard';
import useMediaQuery from '@mui/material/useMediaQuery';
import useStyles from './styles';
import theme from '../../resource/theme';
import { lighten, Typography } from '@mui/material';
import BalanceCard from '../../components/balanceCard';
import SimpleButton, { ButtonVariant } from '../../components/simpleButton';
import Table from '../../components/designerTable';
import { Icon } from '@iconify/react';
import { useCallback, useEffect, useState } from 'react';
import { clearAppStore, commarize, currency, formatNum, getAppStorage, setAppStorage } from '../../utilities/appHelpers';
import AirtimeMod from './pages/airtime';
import { GetUserDetails } from '../../services/login';
import { clearItemStore, error, getAccurateGreeting } from '../../utilities/appHelpers';
import TransactionMod from './pages/transaction';
import BusinessMod from './pages/business';
import FundWalletMod from './pages/fundWallet';
import TransferFundMod from './pages/transferFund';
import UtilitiesMod from './pages/utilities';
import Spinner from '../../components/spinner';
import PGS from '../services/paymentGateway';
import SMS from '../services/schoolManagment';
import ECP from '../services/eCommerce';
import PRS from '../services/payroll';
import ABP from '../services/agencyBanking';
import WalletAdder from '../../components/walletAdder';
import { addWallet, getAllWallets } from '../../services/wallet';
import { STable, SWallet } from '../../components/skeletonLoader';
import { getAllTransactions } from '../../services/transaction';
import { ROLES, SessionCheck,messasgeType, useGlobalState } from '../../pages/dashboard';
import { userStatus } from '../../components/textImgBox';
import { useUserTheme } from '../../themes';
import React from 'react';
import usePagination, { usePaginationInterface } from '../../customHooks/pagination';
import { paginationData } from '../../utilities/types';
import { defaultPageData, PaginationControl } from '../../components/PaginationControl';
import useNetwork from '../../customHooks/useNetwork';
import { NetworkError } from '../../utilities/constant';
import AlertBox, { IType } from '../../components/alertBox';
import Button from '../../components/button';
import PaymentLink from './pages/paymentLink';
import CreateLink from './pages/paymentLink/CreateLink';
import LinkAccess from './pages/paymentLink/LinkAccess';
import IndLinkAccess from './pages/paymentLink/IndLinkAccess';
import Popup from '../../components/popup';
import TextField from '../../components/textField';
import { trim } from 'lodash';
import Line from '../../components/line';
import Users from './pages/userManagment';
import Roles from './pages/userManagment/role';
export interface IDashboardModProps {
}

export default function DashboardMod(props: IDashboardModProps) {
  const { isMobile } = useUserTheme();
  const classes = useStyles().classes;
  const location: Record<string, any> = useLocation();
  const isMD = useMediaQuery(theme.breakpoints.up('md'));
  const isLG = useMediaQuery(theme.breakpoints.up('lg'));
  const isXLG = useMediaQuery(theme.breakpoints.up(2200));
  const [wizardDisplay, setWizardDisplay] = useState(false);
  const navigate = useNavigate();

  const loadPage = (path: string) => {
    navigate(path);
  }

  const [clickedMenu, setClickedMenu] = useState(0);
  const paths = ["/dashboard", "/dashboard/transactions", "/dashboard/business", "/dashboard/transfer-fund", "/dashboard/fund-wallet", "/dashboard/airtime", "/dashboard/utilities", "/dashboard/payment", "/dashboard/help", "/dashboard/settings"]

  useEffect(() => {
    let index = paths.indexOf(location.pathname);
    setClickedMenu(index);
  })

  //states
  const [userDetails, setUserDetails] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState({ global: true, wallet: true });
  const [wallet, setWallet] = useState(1);
  const [userWallet, setUserWallet] = useState([]|| null);
  const [walletIsLoading, setWalletIsLoading] = useState(true);
  const [transactionIsLoading, setTransactionIsLoading] = useState(true);
  const [transRes, setTransRes] = useState<any>([]);
  const [pageData, setPageData] = useState<paginationData>(defaultPageData);
  const [controlPageData, setControlPageData] = useState<paginationData>(defaultPageData);

  const [appState, setAppState] = useState({ res: [], retry: 0 });
  const [errorHandler, setErrorHandler] = useState({ status: false, msg: '', count: 0 }), [uStatus, setUStatus] = useState(userStatus.online);
  const pagination: usePaginationInterface = usePagination(pageData);
  const fetchUser = async () => {
    // setLoading(true);
    //get the user details
    try {
      const userDet = await GetUserDetails();
      if (userDet.status === 200) {
        setUserDetails(userDet?.data?.data);
        setAppStorage("USER_DETAILS", userDet?.data?.data);
      }
      setLoading({ ...loading, global: false });
    } catch (e: any) {
      if (e?.response) {
        if (e?.response?.status === 401) {
          //unauthorized
          navigate('../');
        } else {
          setErrorHandler({...errorHandler, status:true, msg:error.handle(e,NetworkError,true), count:errorHandler.count+1})
        }
      }
      setLoading({ ...loading, global: false });
    }
  }

  useEffect(() => {
    if (!getAppStorage("USER_DETAILS")) {
      fetchUser();
    } else {
      setUserDetails(getAppStorage("USER_DETAILS"));
      setLoading({ ...loading, global: false });
    }
  }, []);

  useEffect(() => {
    /*   --> DISABLING WIZARD FROM SHOWING TILL BUGS ARE FIXED
    userDetails === undefined ? navigate('/login') : userDetails.status < 4 && setWizardDisplay(true); */
    userDetails === undefined && navigate('/login')
  }, [loading.global, userDetails]);

  
  /*TODO::
  * To remove an item kindly set disabled to true on the the menu you which to remove; then copy,paste and edit to add new ones
  * The the Sqaure brackets means grouping; All the menu in one array[] will be grouped togther.
  * So how do you group? Put all related features together in one array
*/
  const menus = [
    [{
      name: 'Dashboard',
      icon: <Icon icon="iconamoon:home-light" width="20" height="20" />,
      path: '/dashboard',
      disabled: false,
      onclick: function () { loadPage(this.path) },
      privilege: true,
      style: {}
    },
    {
      name: 'Transactions',
      icon: <Icon icon="icon-park-outline:transaction-order" width="20" height="20" />,
      path: '/dashboard/transactions',
      disabled: false,
      onclick: function () { loadPage(this.path) },
      privilege: ROLES?.ALL_MODULES?.privileges?.TRANSACTION_MODULE ?? false,
      style: {}
    },
    {
      name: 'Business',
      icon: <Icon icon="clarity:store-line" width="20" height="20" />,
      path: '/dashboard/business',
      disabled: false,
      onclick: function () { loadPage(this.path) },
      privilege: ROLES?.ALL_MODULES?.privileges?.BUSINESS_MODULE ?? false,
      style: {}
    }, {
      name: 'Payment Links',
      icon: <Icon icon="iconamoon:link-light" width="20" height="20" />,
      path: '/dashboard/payment',
      disabled: false,
      onclick: function () { loadPage(this.path) },
      privilege: ROLES?.ALL_MODULES?.privileges?.PAYMENT_LINK_MODULE ?? false,
      style: {}
    },

    ],
    [{
      name: 'Fund Wallet',
      icon: <Icon icon="solar:wallet-linear" width="20" height="20" />,
      path: '/dashboard/fund-wallet',
      disabled: false,
      onclick: function () { loadPage(this.path) },
      privilege: ROLES?.ALL_MODULES?.privileges?.WALLET_MODULE ?? false,
      style: {}
    },
    {
      name: 'Transfer Funds',
      icon: <Icon icon="mdi:bank-transfer" width="20" height="20" />,
      path: '/dashboard/payment',
      disabled: true,
      onclick: function () { loadPage(this.path) },
      privilege: true,
      style: {}
    }],
    [{
      name: 'Users',
      icon: <Icon icon="ph:users" width="20" height="20" />,
      path: '/dashboard/users',
      disabled: false,
      onclick: function () { loadPage(this.path) },
      privilege: ROLES?.ALL_MODULES?.privileges?.USER_MANAGEMENT_MODULE ?? false,
      style: {}
    },
    {
      name: 'Role Management',
      icon: <Icon icon="la:users-cog" width="20" height="20" />,
      path: '/dashboard/roles',
      disabled: false,
      onclick: function () { loadPage(this.path) },
      privilege: ROLES?.ALL_MODULES?.privileges?.USER_MANAGEMENT_MODULE ?? false,
      style: {}
    }],
    [
      {
        name: 'Airtime',
        icon: <Icon icon="carbon:application-mobile" width="20" height="20" />,
        path: '/dashboard/airtime',
        disabled: false,
        onclick: function () { loadPage(this.path) },
        privilege: (ROLES?.ALL_MODULES?.privileges?.UTILITIES_MODULE )?? false,
        style: {}
      },
      {
        name: 'Utilities',
        icon: <Icon icon="healthicons:electricity-outline" width="20" height="20" />,
        path: '/dashboard/utilities',
        disabled: false,
        onclick: function () { loadPage(this.path) },
        privilege: ROLES?.ALL_MODULES?.privileges?.UTILITIES_MODULE ?? false,
        style: {}
      }
    ],
    [{
      name: 'Settings',
      icon: <Icon icon="bytesize:settings" width="20" height="20" />,
      path: '/dashboard/settings',
      disabled: true,
      onclick: function () { loadPage(this.path) },
      privilege: ROLES?.ALL_MODULES?.privileges?.SETTINGS_MODULE ?? false,
      style: {}
    },
    {
      name: 'Logout',
      icon: <Icon icon="ri:logout-circle-line" width="20" height="20" />,
      path: '/',
      disabled: false,
      onclick: () => setAlert(true),
      privilege: true,
      style: {
        marginTop: "10%"
      }
    }
    ]
  ];   /*TODO::
  * To remove an item kindly set disabled to true on the the menu you which to remove; then copy,paste and edit to add new ones
  * The the Sqaure brackets means grouping; All the menu in one array[] will be grouped togther.
  * So how do you group? Put all related features together in one array
*/

const [alert, setAlert] = useState(false);
  const Logout = useCallback(()=>{
    clearItemStore();
    clearAppStore();
    setAlert(false);
    navigate('../');
  },[])
    
    

  const [walletName, setWalletName] = useState('');
  const [isAddWallet, setIsAddWallet] = useState(false);
  const [processingNewWallet, setProcessingNewWallet] = useState(false);

  const resetWalletAlert = useCallback(async () => {
    setIsAddWallet(false);
    setWalletName('');
    setProcessingNewWallet(false);
  },[])

  const createNewWallet = useCallback(async () => {
    if (trim(walletName) === '') {
      setErrorHandler(prev => ({...prev, status:true, msg:'Please provide the name of the wallet', count:prev.count+1}));
      return;
    }
      try {
        const resp = await addWallet({walletName: walletName});
        if (resp.status === 201 || resp.status === 200) {
          setWallet(wallet + 1);
          setAppState({ ...appState, retry: appState.retry + 1 });
          resetWalletAlert();
        }
      } catch (e: any) {
        SessionCheck(e);
        setErrorHandler({...errorHandler, status:true, msg:error.handle(e,NetworkError,true), count:errorHandler.count+1})

      }finally{
        setProcessingNewWallet(false);
      }


  }, [walletName])

  useEffect(() => {
    (async () => {
      try {
        const resp = await getAllWallets();
        if (resp.status === 200) {
          setWalletIsLoading(false);
          if(Array.isArray(resp.data.data)){
            setUserWallet(resp.data.data);
            setAppStorage("WALLETS",resp.data.data);
          }

        }

      } catch (e: any) {
        SessionCheck(e);
        setErrorHandler({...errorHandler, status:true, msg:error.handle(e,NetworkError,true), count:errorHandler.count+1})
        if(error.handle(e,"",true)==='Network Error')setAppState({...appState,retry:+1})
      }
    })()
  }, [appState.retry, wallet])


  //Get all transactions
  useEffect(() => {
    (async () => {
      try {
        const resp = await getAllTransactions(controlPageData);
        if (resp.status === 200) {
          setTransactionIsLoading(false);
          const transPag = resp.data.data.pagination;
          setTransRes(resp.data.data.transactions);
          setPageData({ pageNumber: Number(transPag['current_page']), pageSize: Number(transPag['page_size']), totalRecords: Number(transPag['total_items'] || 0) });
        }
      } catch (e: any) {
        SessionCheck(e);
        setErrorHandler({...errorHandler, status:true, msg:error.handle(e,NetworkError,true), count:errorHandler.count+1})
      }
    })()

  }, [controlPageData])

  const params = useParams()


  // useEffect(() => {
  //   const savedTransRec = getAppStorage('transRecord1');
  //   const savedTransPag = getAppStorage('transPagination');    
  //   if (savedTransRec && transRes.length > 0 && JSON.stringify(savedTransRec) === JSON.stringify(transRes)) {
  //     setTransactionIsLoading(false);
  //   } else if (savedTransRec && savedTransRec.length > 0 && JSON.stringify(savedTransRec) !== JSON.stringify(transRes)) {
  //     setTransRes(savedTransRec); setPageData({pageNumber:Number(savedTransPag['current_page']), pageSize:Number(savedTransPag['page_size']), totalRecords:Number(savedTransPag['total_items'] || 0)}); setTransactionIsLoading(false);
  //   }
  // }, [isData])


  const [hintState, setHintState] = useGlobalState("hint");
  useEffect(() => {
    setHintState({ ...hintState, status: errorHandler.status, msg: errorHandler.msg, icon: messasgeType.network });
  }, [errorHandler.count])

  const [isOnline, netChange] = useNetwork();
  useEffect(() => {
    if (netChange) {
      if (isOnline) {
        setUStatus(userStatus.online);
        setHintState({ ...hintState, status: true, msg: "You're back online", icon: messasgeType.network })
      }else {
      setUStatus(userStatus.offline);
      setHintState({ ...hintState, status: true, msg: "You're offline", icon: messasgeType.network })
    }
    } 
  }, [isOnline])
  const [IsMobile, setIsMobile] = useState(false);
const mobileStyle = isMobile?{display:'flex',justifyContent:'space-around',gap:50, marginTop:10}:{display:'flex',gap:50, marginTop:10};
const textStyle = isMobile?{padding:'10px 0 15px 0', display:'flex',justifyContent:'center', fontSize:'1.1rem'}:{padding:'10px 0 15px 0', fontSize:'1.1rem'};

  return (
    <>

    <AlertBox title='Confirm Logout' show={alert} onClose={(s:boolean)=>setAlert(s)} type={IType.warning}>
        <p style={textStyle}>Are you sure you want to logout?</p>
      <div style={mobileStyle}>
        <Button style={{padding:0, width:'calc(100% - 300px)'}} text='YES' onClick={Logout} icon={<FontAwesomeIcon icon={faCheck}/>}/>
        <Button style={{padding:0, width:'calc(100% - 300px)'}} onClick={()=>setAlert(false)} text='NO' icon={<FontAwesomeIcon icon={faClose}/>} variant='secondary'/>
      </div>
    </AlertBox>
      <div className={`${classes.fullPage}`}>
        {loading.global ? <div style={{ position: "absolute", width: "100vw", height: "100vh", top: 0, left: 0, right: 0, bottom: 0, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}><Spinner size={200} show={loading.global} /></div> : (<>
          {wizardDisplay && <SetupWizard />}
          {/* <SetupWizard /> WORK ON WISZARD DISPLAYING*/}
          <div className={`${classes.main}`}>
            <NoMenuNav onClickMobile={() => setIsMobile((stat) => !stat)} />
            <div className={`${classes.body}`}>

              {/* <SideBar onToggle={(stat:boolean)=>setIsMobile(stat)} visibility={IsMobile}> */}
              <SideBar onToggle={() => setIsMobile((stat) => !stat)} visibility={IsMobile}>
                <SideBarHeader status={true} presence={uStatus}>
                  {(<>
                    <div style={{ fontSize: 12, fontWeight: 600 }}>{`${userDetails?.lastname}, ${userDetails?.firstname}`}</div>
                  </>)}
                </SideBarHeader>


                {
                menus.map((menugroup:Record<string,any>, i: number) => {
                  if(Array.isArray(menugroup)){
                    return menugroup.map((menu,ind)=>{
                      if(i!==0){
                        if(!menu.disabled && menu.privilege){
                        return( <>
                        {ind === 0 && (<Line style={{borderTop:'#00000000 thin solid', margin:'5px 0'}}/>)}
                        <SideBarItem key={i} onClick={() => {menu.onclick(); setIsMobile(isMobile?true:false) }} logo={menu.icon} style={menu?.style} isClicked={(location.pathname === menu.path)}>{menu.name}</SideBarItem>
                      </>)
                        }else{
                          return (ind === 0 && menu.privilege) && (<Line style={{borderTop:'#00000000 thin solid', margin:'5px 0'}}/>)
                        }
                        
                      }else{
                        return (!menu.disabled && menu.privilege)&&( <>
                        <SideBarItem key={i} onClick={() => {menu.onclick(); setIsMobile(isMobile?true:false) }} logo={menu.icon} style={menu?.style} isClicked={(location.pathname === menu.path)}>{menu.name}</SideBarItem>
                      </>)
                      }

                    })
                  }else{
                        return (!menugroup.disabled && menugroup?.privilege)&&(
                        <SideBarItem key={i} onClick={() => {menugroup.onclick(); setIsMobile(isMobile?true:false) }} logo={menugroup.icon} style={menugroup?.style} isClicked={(location.pathname === menugroup.path)}>{menugroup.name}</SideBarItem>
                     )
                    
                  }
                    
              })
                }

              </SideBar>
              <div className={classes.mainDisplay} style={{ width: isLG ? "calc(100% - 250px)" : "100%" }}>

                {location.pathname.includes("/dashboard") && (<>
                  <div className={classes.firstDisplay} id='bodycontent' style={{ width: (location.pathname !== "/dashboard/services/pgs") && isLG ? "100%" : "100%", maxWidth: isXLG ? '65%' : "100%" }}>
                    {/* <div className={classes.firstDisplay} style={{ width: (location.pathname !== "/dashboard/services/pgs") && isLG ? "calc(100% - 350px)" : "100%" }}> */}
                    {location.pathname === "/dashboard" && (<>
                      <Typography component="h2" className={classes.headerLg}>{getAccurateGreeting()}, {userDetails?.firstname}</Typography>
                      <div className={`${classes.balSec} ${classes.section} scrollStyler`}>
                        <div className={`${classes.walletWapper}`}>
                          {walletIsLoading && <SWallet />}
                          { ((ROLES?.WALLET_MODULE?.privileges?.VIEW_WALLET ?? false) && Array.isArray(userWallet)) && userWallet.map((w: any, i:number) =>
                            <BalanceCard key={i} title={w.walletName}
                              amount={`${currency.naira}${commarize(w.bal)}`} descr={(w.accNo)}>
                              {!isMobile &&
                                <>
                                 { (ROLES?.WALLET_MODULE?.privileges?.FUND_WALLET ?? false) && <SimpleButton variant={ButtonVariant.white} onClick={() => { /* location.pathname = paths[4] */ navigate("/dashboard/fund-wallet",{state:w});}
                                  }>
                                    <Typography component="span" style={{fontSize:'0.7rem'}}>Fund Wallet</Typography>
                                  </SimpleButton>}
            {/* <SimpleButton style={{ border: `1px solid #fff`, marginLeft: 15 }} onClick={() => {navigate("/dashboard/transfer-fund"); }}>
                <Typography component="span" style={{fontSize:'0.7rem'}}>Transfer Funds</Typography>
                                  </SimpleButton> */}
                                </>
                              }
                            </BalanceCard>
                          )}

                          {(ROLES?.WALLET_MODULE?.privileges?.ADD_WALLET ?? false)&& (Array.isArray(userWallet) && userWallet.length< 3) && <WalletAdder title='Create New Wallet' text={`Add New Wallet`} icon={<FontAwesomeIcon icon={faAdd} />} action={() => {/* createNewWallet(); */ setIsAddWallet(true)}} />}



                                
                          <Popup show={isAddWallet} onPopChange={(isShow: boolean) => { setIsAddWallet(isShow) }} showLogo={false}>

                            <div style={{ marginTop:'70px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                              <Typography style={{ fontSize: 18, fontWeight: '600', marginBottom: '24px', color: 'black', textAlign:'center' }} component={'h3'}>Kindly provide the name of the wallet</Typography>
                              <div style={{ width: '100%', backgroundColor: '#0000000d', color: '#000', padding: '16px', borderRadius: '5px' }}>
                                <TextField onInput={(e: any) => setWalletName(e.target.value)} placeholder="Wallet Name" value={walletName} style={{ marginTop: '0px' }} />
                              </div>
                              <div className='conf_btn'>
                                <Button spinnerSize='small' style={{padding:0}} variant='secondary' icon={<FontAwesomeIcon icon={faTimes}/>} onClick={() => resetWalletAlert() } text={'Cancel'}/>
                                <Button spinnerSize='small' style={{padding:0}} icon={<FontAwesomeIcon icon={faCheck}/>} disabled={processingNewWallet} loading={processingNewWallet} onClick={() => { createNewWallet(); }} text={'Sumbit'}/>
                              </div>

                            </div>

                          </Popup>



                        </div>

                      </div>

                      {(ROLES?.TRANSACTION_MODULE?.privileges?.VIEW_TRANSACTION ?? false) && <div className={classes.section}>
                        <Typography component="div" className={classes.sectionHeader}>
                          <Typography component="h3" className={classes.headerMd}>Transactions</Typography>
                          {pagination.totalRecords > pagination.pageSize ?
                            <PaginationControl pageDataState={[controlPageData, setControlPageData]} pagination={pagination} />
                            : null}
                          {/* <div>
                          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', position: 'relative', fontSize: '13px' }}>
                            <button onClick={() => { setCurrPage(1) }} style={{ width: '40px', height: '40px', backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.2)', outline: 'none', color: lighten(theme.palette.secondary.main, 0.0), display: 'grid', placeItems: 'center', cursor: 'pointer' }} title='Go to first page'><Icon fontSize={24} icon={'material-symbols:keyboard-double-arrow-left-rounded'} />
                            </button>
                            <button onClick={() => { setCurrPage((prev: number) => currPage > 1 ? prev - 1 : prev) }} style={{ width: '40px', height: '40px', backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.2)', outline: 'none', color: lighten(theme.palette.secondary.main, 0.0), display: 'grid', placeItems: 'center', cursor: 'pointer' }} title='Go to previous page'><Icon icon={'material-symbols:arrow-back-ios-rounded'} />
                            </button>
                            <button onClick={() => { setPageOptions(true) }} style={{ width: '100px', height: '40px', backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.2)', outline: 'none', color: '', display: 'grid', placeItems: 'center', cursor: 'pointer' }} title='Choose a page'>{`Page ${pageData['total_pages'] ? selPage : 0} of ${pageData['total_pages']}`}</button>
                            <button onClick={() => { setCurrPage((prev: number) => currPage < pageData['total_pages'] ? prev + 1 : prev) }} style={{ width: '40px', height: '40px', backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.2)', outline: 'none', color: lighten(theme.palette.secondary.main, 0.0), display: 'grid', placeItems: 'center', cursor: 'pointer' }} title='Go to next page'><Icon icon={'material-symbols:arrow-forward-ios-rounded'} />
                            </button>
                            <button onClick={() => { setCurrPage(pageData['total_pages']) }} style={{ width: '40px', height: '40px', backgroundColor: 'white', border: '1px solid rgba(0,0,0,0.2)', outline: 'none', color: lighten(theme.palette.secondary.main, 0.0), display: 'grid', placeItems: 'center', cursor: 'pointer' }} title='Go to last page'><Icon fontSize={24} icon={'material-symbols:keyboard-double-arrow-right-rounded'} />
                            </button>
                            {pageOptions && <div className='fadeInUp animated faster card-2' style={{ position: 'absolute', right: '0', top: '45px', backgroundColor: 'white', minWidth: '250px', zIndex: '1' }}>
                              {new Array(pageData['total_pages']).fill('').map((pages: any, index: number) => (<div key={index} onClick={() => { setCurrPage(index + 1); setPageOptions(false) }} style={{ borderBottom: (index + 1 !== pageData['total_pages']) ? '1px solid rgba(0,0,0,0.2)' : '', backgroundColor: index + 1 === currPage ? lighten(theme.palette.primary.main, 0.12) : '', color: index + 1 === currPage ? 'white' : '', padding: '5px 8px' }}>{index + 1}</div>))}

                            </div>}
                          </div>
                          <div style={{ textAlign: 'right' }}>{`${pageData['page_description']}`}</div>
                        </div> */}
                          {/* <div style={{ display: "flex" }}>
                          <SimpleButton>
                            <Typography component="span">Statement</Typography>
                          </SimpleButton>
                        </div> */}
                        </Typography>

                        {transactionIsLoading ? <STable /> : <Table data={transRes} />}
                      </div>}
                    </>)}
                    {location.pathname === "/dashboard/users" && (<Users />)}
                    {location.pathname === "/dashboard/roles" && (<Roles />)}

                    {location.pathname === "/dashboard/business" && <BusinessMod />}
                    {location.pathname === "/dashboard/airtime" && <AirtimeMod />}
                    {location.pathname === "/dashboard/transactions" && <TransactionMod />}
                    {location.pathname === "/dashboard/fund-wallet" && <FundWalletMod />}
                    {location.pathname === "/dashboard/transfer-fund" && <TransferFundMod />}
                    {location.pathname === "/dashboard/utilities" && <UtilitiesMod />}
                    {location.pathname === "/dashboard/payment" && <PaymentLink />}
                    {location.pathname === "/dashboard/payment/create" && <CreateLink />}
                    {location.pathname === `/dashboard/payment/edit/${params.id?params.id:''}` && <CreateLink />}
                    {location.pathname === `/dashboard/payment/link/${params.id?params.id:''}` && <LinkAccess />}
                    {location.pathname === `/dashboard/payment/link/${params.id?params.id:''}/access/${params.aid?params.aid:''}` && <IndLinkAccess />}
                    {location.pathname === "/dashboard/services/pgs" && <PGS />}
                    {location.pathname === "/dashboard/services/sms" && <SMS />}
                    {location.pathname === "/dashboard/services/ecp" && <ECP />}
                    {location.pathname === "/dashboard/services/prs" && <PRS />}
                    {location.pathname === "/dashboard/services/abp" && <ABP />}

                  </div>

                  {/* <div style={{
                    width:400, height:'calc(100% - 20px)', marginTop:10, borderRadius:'20px 0 0 20px', backgroundColor:'#fff', boxShadow:'-4px 6px 15px rgba(0, 0, 0, 0.09)'
                    }}>
                      <div style={{height:60, width:'100%', borderBottom:'thin solid #f2f2f2', padding:'0 12px', display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <button title='Close'><Icon icon={'line-md:chevron-right'} fontSize={25} /></button>
                        <Button spinnerSize={'small'} style={{padding:0}} text={'Save'} icon={<Icon icon='fluent:save-24-filled' />}loading={true}/>
                      </div>

                  </div> */}

                  {/* {(isLG || location.pathname !== "/dashboard/services/pgs") && !isMobile  && (
                  <div className={classes.secondDisplay}>
                    <QuickLinks>
                      <Link icon={<Icon icon="carbon:application-mobile" />} onClick={() => loadPage("/dashboard/airtime")}>Airtime</Link>
                      <Link icon={<Icon icon="ep:cellphone" />} onClick={() => loadPage("/dashboard/airtime")}>Data</Link>
                      <Link icon={<Icon icon="ic:outline-connected-tv" />} onClick={() => loadPage("/dashboard/utilities")}>Cable</Link>
                      <Link icon={<Icon icon="healthicons:electricity-outline" />} onClick={() => loadPage("/dashboard/utilities")}>Electricity</Link>
                      <Link icon={<Icon icon="cil:casino" />} onClick={() => loadPage("/dashboard/utilities")}>Betting</Link>
                      <Link icon={<Icon icon="el:cogs" />} onClick={() => loadPage("/dashboard/utilities")}>Utilities</Link>
                    </QuickLinks>
                    <div style={{ margin: "20px 0" }}>
                      <Typography component="h3" className={`${classes.headerMd}`}>
                        Recent Transactions
                      </Typography>
                      <div>
                        <ArrowedList >
                          <ListItem  title='Cable Subscription' icon={<Icon icon="ic:outline-connected-tv" />} />
                          <ListItem title='Fund Transfer' icon={<Icon icon="mdi:bank-transfer" />} />
                          <ListItem title='Shopping on DtechStore' icon={<Icon icon="emojione-monotone:shopping-bags" />} />
                          <ListItem title='Airtime Purchase' icon={<Icon icon="carbon:application-mobile" />} />
                          <ListItem title='Electricity Bill Payment' icon={<Icon icon="healthicons:electricity-outline" />} />
                        </ArrowedList>
                      </div>
                    </div>
                  </div>
                )} */}
                </>)}


              </div>
            </div>
          </div>
        </>)
        }
      </div>
    </>

  );
}

export function ContentLoader({show=false}:any){
  const classes = useStyles().classes;

  return(
    <div className={`${!show && classes.hide}`} style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%', height:'100%',background:'#ffffff90', position:'absolute',left:0,top:0,zIndex:10}}>
      <Icon icon={'svg-spinners:270-ring-with-bg'} fontSize={35} color={theme.palette.primary.main}></Icon>
    </div>
    );
}