import { useLocation } from 'react-router-dom';
import useStyles from './styles';
import { Typography } from '@mui/material';
import SimpleButton, { ButtonVariant } from '../../../components/simpleButton';
import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { error } from '../../../utilities/appHelpers';
import PGSHome from './home';
import PGSSetup from './setup';
import { getServiceSetups } from '../../../services/bussiness';
import Hint from '../../../components/hintBox';
import { useGlobalState } from '../../../pages/dashboard';
import { useUserTheme } from '../../../themes';

export enum IPGSPages {
  "home" = 0,
  "setup" = 1,
}

export interface IPGSProps {
}

export default function PGS(props: IPGSProps) {

  //STATES
  const classes = useStyles().classes;
  const {state}:Record<string,any> = useLocation()  
  const {name, service = state[0]?.services[0],bizCode} = state[0];
  const [setupData, setSetupData] = useState([]);
  const [errorHandler, setErrorHandler] = useState({status:false, msg:"",count:0});

  const [{status, msg, icon},setHintState] = useGlobalState("hint");


  //FUNCTIONS
 useEffect(()=>{
  (async()=>{
    try {
    const resp = await getServiceSetups(bizCode,state[1]);
    if(resp.status === 200){
      setSetupData(resp.data.data);
    }
  } catch (e) {
    setErrorHandler({...errorHandler, status:true,msg:error.handle(e,"You're offline. Please check your network and try again",true), count:errorHandler.count+1})
    
  }
})()

},[])



//OTHERS
const {isMobile}=useUserTheme();

 
  const [page, setPage] = useState(IPGSPages.home);
  return (
    <div>
      <Typography component="h2" className="fadeInUp animated faster" style={{ margin: "20px 0 10px" }}>
        <div className={classes.twoCol}>
          <div className={classes.leftCol} style={!isMobile?{ fontSize: 20, fontWeight: 600 }:{fontSize: 15, fontWeight: 600 }}>
            {name}: {service.serviceName}
          </div>
          <div className={classes.rightCol} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            {page === IPGSPages.home && (
              <SimpleButton variant={ButtonVariant.alt} onClick={() => { setPage(IPGSPages.setup) }} className="fadeIn animated delay-0-8s">
                <div style={!isMobile?{ display: "flex", alignItems: "center" }:{ display: "flex", alignItems: "center",width:'max-content' }}>
                  <div style={{ padding: "0 5px" }}><Icon icon="carbon:cloud-service-management" width="16" height="16" /></div>
                  <div>Setup</div>
                </div>
              </SimpleButton>
            )}
            {page !== IPGSPages.home && (
              <SimpleButton variant={ButtonVariant.alt} onClick={() => { setPage(IPGSPages.home) }} className="fadeIn animated delay-0-8s">
                <div style={!isMobile?{ display: "flex", alignItems: "center" }:{ display: "flex", alignItems: "center",width:'max-content' }}>
                  <Icon icon="eva:arrow-back-fill" width="16" height="16" style={{ marginRight: 5 }} />
                  <div>Back</div>
                </div>
              </SimpleButton>
            )}
          </div>
        </div>
      </Typography>
      <div style={{ margin: "20px 0" }} className="fadeIn animated delay-0-4s">
      <Hint show={status} message={msg} onClose={(stat:boolean)=>setHintState(current=>({...current,status:stat,msg:''}))} icon={<Icon icon={icon}/>} escButton={true}/>

        {page === IPGSPages.home && <PGSHome data={state[0]}/>}
        {page === IPGSPages.setup && <PGSSetup data={{...setupData,...service,...{bizCode:bizCode,serviceCode:state[1]}}}/>}
      </div>
    </div>
  );
}
