import { memo } from "react";
import { Typography } from "@mui/material";
import SimpleButton from "../simpleButton";
import { useStyles } from "./styles";
import img from "./images/brokenpage.png";
interface IErrorScrn{
  message?:string;
  onClick?:()=>void;
  buttonText?:string
  illustration?:string;
  showButton?:boolean;
}

function BrokenPage({message,illustration,onClick,buttonText, showButton=true}:IErrorScrn){
    const style = useStyles().classes;

    return (<div className={`${style.wrapper}`}>

      <div className={`${style.imgCont}`}>
        <img src={illustration??img} alt="Dtechpay -  Bad Request"/>
      </div>
      <Typography component='div' className={`${style.infoCont} text`}>
          <p>{message??'Error occured while loading page'}</p>
        {showButton && <SimpleButton onClick={onClick} className={'btn'}>{buttonText??'Retry'}</SimpleButton>}
      </Typography>


    </div>)
}
export default memo(BrokenPage);