import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import theme from "../resource/theme";
import useStyles from "./styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import md5 from 'md5';
import axiosInstance from "../services/httpsGateway";
import { error } from "../utilities/appHelpers";
import { useNavigate } from "react-router-dom";

export interface IPlaygroundProps { }

export default function Playground(props: IPlaygroundProps) {
  const classes = useStyles().classes;
  const [formData,setFormData] = useState({payerName:'',email:'',phone:'',amount:'',checkSum:"",callbackUrl:'',accountCode:''});
  const navigate = useNavigate();
  const [sk, setSk] = useState('');
  const [msg, setMsg] = useState('');
  const [appState, setAppState] = useState('Pay now');

  useEffect(()=>{
    // const secretKey = "2bb8b52234d27ff8fbf4d3a6ffcb102b";
      
   const toDecrypt = formData.email+formData.accountCode+formData.amount+formData.callbackUrl+sk;
    setFormData({...formData,checkSum:md5(toDecrypt)});
  },[formData.email, formData.amount, formData.checkSum, sk])


  const processPayment = async()=>{
  setAppState('Please wait...');
  try {
    const resp = await axiosInstance.post('gateway/initiate',formData);
    if(resp.status===200|| resp.status===201){
      let main = resp?.data?.data?.payUrl.split("com");
      navigate(main[1]);
    }
    
  } catch (e) {
      setAppState('Pay now');
      setMsg(error.handle(e,'',true));
    }

  }





  return (
    <>
    <div className={''} style={{display:'flex',flexDirection:'column', alignItems:'center', width:"100%", height:"100vh", gap:5, padding:'20px'}}>

      <Typography component={'div'} style={{padding:20, backgroundColor:'#2f3371', color:'#fff', borderRadius:5, fontSize:"2.5rem", fontWeight:600, textAlign:'center'}} className="card-1"><strong>Merchant's Website</strong></Typography> 

    <p style={{margin:10, color:'red'}}>{msg}</p>
    <form className={classes.form} style={{display:'flex',flexDirection:'column', margin:'100px', gap:5, background:'#f2f2f2', padding:'20px', borderRadius:5}}>
      <div style={{display:'flex', gap:5, width:"100%"}}>
        <input style={{width:"100%"}} type="text" placeholder="Secret Key" onInput={(e:any)=>setSk(e.target.value)}/>
        <input style={{width:"100%"}} type="text" placeholder="Account Code" onInput={(e:any)=>setFormData({...formData,accountCode:e.target.value})}/>
      </div>
      <hr />
        <input style={{width:"100%"}} type="text" placeholder="Callback URL" onInput={(e:any)=>setFormData({...formData,callbackUrl:e.target.value})}/>
      <hr />

      <input type="text" placeholder="Name" onInput={(e:any)=>setFormData({...formData,payerName:e.target.value})}/>
      <input type="email" placeholder="Email" onInput={(e:any)=>setFormData({...formData,email:e.target.value})}/>
      <input type="text" placeholder="Phone" onInput={(e:any)=> setFormData({...formData,phone:e.target.value})}/>
      <input type="text" placeholder="Amount" onInput={(e:any)=>setFormData({...formData,amount:e.target.value})}/>
      <button onClick={processPayment} type="button">{appState}</button>
    </form>
    </div>
    {/* <button onClick={()=>setNewData(demo)}>DEMO</button> */}
    </>
  );
}
