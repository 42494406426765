export const BOARDING = "boarding";
export const GENESIS = "genesis";
export const LOOKUPS = "lookups";
export const ACCTYPES = "acctypes";
export const CONTACT = "contact";
export const COUNTRIES = "countries";
export const VPIN = "vpin";
export const NEWPIN = "newpin";
export const VEMAIL = "vemail";
export const VUSERNAME = "vusername";
export const CPASSW = "cpassw";
export const IDENTITY = "identity";
export const AUTH = "auth";
export const USER = "user";
export const SETTINGS = "settings";
export const BUSINESS = "business";
export const BUSINESSES = "businesses";
export const BUSINESSESTYPES = "businessTypes";
export const SERVICETYPES = "serviceTypes";
export const ADDBUSINESS = "addbusiness";
export const ADDSERVICE = "addservice";
export const SERVICES = "services";
export const ADD = "add";
export const WALLET = "wallet";
export const WALLETS = "wallets";
export const TRANSACTIONS = "transactions";
export const TRANSACTION = "transaction";
export const PERFORM = "perform";
export const GATEWAY = "gateway";
export const PROCESS = "process";
export const ADDACCOUNT = "addAccount";
export const SETUP = "setup";
export const  ACCOUNTS = "accounts";
export const  OTP = "otp";
export const  RESENDOTP = "resendotp";
export const  BILLER = "biller";
export const  BILLABLES = "billables";
export const  CATEGORIES = "categories";
export const PAY = "pay";
export const PAYMENTS = "payments";
export const TYPES = "types";
export const TRANSFERS = "transfers";
export const DESTINATIONS = "destinations";
export const VERIFY = "verify";
export const SEND = "send";
export const DTPACC = "dtpacc";
export const FUND = "fund";
export const NEW = "new";
export const UPDATE = "update";
export const RESET = "reset";
export const RESENDCODE = "resendcode";
export const PASSWORD = "password";
export const NetworkError = "You're offline. Please check your network and try again";
export const LINKS = "links"
export const ACCESS = "access"

