import { Typography } from '@mui/material';
import * as React from 'react';
import useStyles from './styles';

export enum ButtonVariant {
  "main"=0,
  "alt"=1,
  "white"=2,
  "danger"=3,
  "success"=4
}

export interface ISimpleButtonProps {
  children:any,
  className?:string,
  style?:Record<string,any>
  variant?:ButtonVariant
  onClick?:any,
  disabled?: boolean,
}

export default function SimpleButton (props: any) {
  const classes = useStyles().classes;
  const { className = "", style={}, variant=ButtonVariant.main, onClick=()=>void(0), disabled=false,...rest } = props;
  return (
    <button {...rest} type="button" className={`${classes.main} ${className} card-1 ${ variant === ButtonVariant.white ? classes.whiteBg : variant === ButtonVariant.alt ? classes.altBg : variant === ButtonVariant.danger ? classes.redBg : variant === ButtonVariant.success ? classes.greenBg : classes.mainBg }`} style={style} onClick={onClick} disabled={disabled}>
      <Typography component="div" style={{height: "fit-content"}}>{props.children}</Typography>
    </button>
  );
}
