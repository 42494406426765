import { TRANSACTION,TRANSACTIONS, PERFORM} from './../../utilities/constant';
import axiosInstance from '../https';
import { paginationData } from '../../utilities/types';


//Get resquests
export const getAllTransactions =  ( pagination:paginationData, )=>{
    return axiosInstance.get(`${TRANSACTION}/${TRANSACTIONS}?${pagination.pageNumber? 'page_number='+pagination.pageNumber: ''}${pagination.pageSize? '&page_size='+pagination.pageSize: ''}`);
}

export const getTransaction =  (refNo:string)=>{
    return axiosInstance.get(`${TRANSACTION}/${TRANSACTIONS}/${refNo}`);
}

//POST
export const performTransaction =  (data:Record<string,any>)=>{
    return axiosInstance.get(`${TRANSACTION}/${PERFORM}`,data);
}
