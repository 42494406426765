import React from "react";
import useStyles from "./styles";
import logo from "../../resource/images/logo.png";

interface ILogoBoxProps {
    className?:string,
    style?:Record<string,any>
}
const LogoBox = (props: ILogoBoxProps) => {
  const classes = useStyles().classes;
  const {style = {},className =''} = props;
  return (
    <div className={`${classes.mainBox} card-1 ${className}`} style={style}>
      <img src={logo} alt="Main Logo" />
    </div>
  );
};

export default LogoBox;
