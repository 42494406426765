import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  main: {
    width: "calc(100% - 100px)", marginLeft: "50%", transform: "translateX(-50%)",
    boxSizing: "border-box",
    "& *": {
      boxSizing: "inherit"
    },
    [theme.breakpoints.up('xs')]: {
      width: "100%",
    }
  },
  smallTitle: {
    fontSize: "0.7em",
    margin: "15px 0 5px 10px"
  },
  walletchecker: {
    '&': {
      display: 'flex', justifyContent:"flex-start", gap: 10,
      '& > label > div': {
      },
      [theme.breakpoints.down('sm')]: {
        overflowX: 'scroll', padding: '20px 10px', justifyContent: 'flex-start'
      }
    }
  },
  telnets:{
    "&":{
      display: 'flex', justifyContent:"flex-start", gap:10, padding:'10px',
      "& button":{
        margin:0
      }
    }
  }
  ,
  gridCont: {
    display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20, padding: '0 10px', width:'100%',
    '& > div': {
      padding: 10, cursor: 'pointer', borderRadius: 3, userSelect: 'none', backgroundColor: '#fff', fontWeight: 600, color: '#0000009f', textAlign:'center',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    }
  },
  cta: {
    width: '100%', 
    [theme.breakpoints.down('sm')]: {
      width: '100%',display:'flex',justifyContent:'center', alignItems:'center', 
      "& button": {
        width: 'calc(100% - 20px)',
      }
    }
  }

}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;