import { Theme } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  main: {
    padding: 20,
    textAlign: "center"
  },
  accountPage: {
    display: "flex",
    justifyContent: "center",
    boxSizing: "border-box",
    padding:20,
    "& *": {
      boxSizing: "inherit",
    },
    "&>*": {
      minWidth: "200px"
    }
  },
  illus: {
    height: "30vh",
    "& img":{
      width: "100%",
      height: "100%",
      objectFit: "contain",
      objectPosition: "center",
    }
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
