import { createTheme } from "@mui/material/styles";
import React from "react";
 declare module '@mui/material/styles' {
    interface Themes {
      mode:string,
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
     /*  status?: {
        danger?: string;
      }; */
      mode?:string
    }
  }

const theme = createTheme({
  mode:'light',
  palette: {
    primary:{
        main:'rgb(14, 47, 125)',
        light:'rgba(14, 47, 125, 0.5)',
        dark:'#135807',
        contrastText:'#57cc04'
    },
    secondary:{
      main:'rgb(0, 155, 178)',
      light:'rgba(0, 155, 178, 0.5)'
  },
  background:{
     default:'#f0f0f0',
     paper:'rgb(219, 229, 236)' 
  }
  },
  typography:{
      fontFamily: 'Nanum Gothic, Raleway, Montserrat, Source Sans Pro, sans-serif',
      fontSize:11
  }
});

  export default theme;