
import { Icon } from "@iconify/react";
import { InputLabel,Select, lighten, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../components/button";
import TextField from "../../components/textField";
import TextLogoBox from "../../components/textLogoBox";
import { SessionCheck, dispatch, messasgeType, useGlobalState } from "../../pages/dashboard";
import theme from "../../resource/theme";
import { getBusinesstypes, addBusiness} from "../../services/bussiness";
import { error, getAppStorage } from "../../utilities/appHelpers";
import useStyles from "../dashboard/pages/business/styles";
const BusinessForm=(props:any)=>{
  //states
    const classes = useStyles().classes;
    const [bName, setBName] = useState("");
    const [busType, setBusType] = useState("");
    const [focus, setFocus] = useState("");
    const [bRegNo, setBRegNo] = useState("");
    const [bAddr, setBAddr] = useState("");
    const [formData, setFormData] = useState({name:'',cac:'',type:'',address:''});
    const [appState,setAppState]=useState({loading:false, res:[], msg:'',isMsg:false,status:false,count:0, msgType:messasgeType.default});

    const [hintState,setHintState] = useGlobalState("hint");


  //functions
    const handleSelect = (e:any)=>{
      e.target.value !== "" ? setFocus("ff") : setFocus("");
      setBusType(e.target.value as string);
      setFormData({...formData,type:e.target.value});
    }


      const submit = async()=>{
        const uid = getAppStorage('DTECHPAY_USERID');
        setAppState({...appState,loading:true});
        // return;
      try {
        const resp:Record<string, any> = await addBusiness(uid,formData);
  
        if(resp.status===201 || resp.status===200){
          setAppState({...appState,loading:false,status:resp.data.status});
          props.onSubmitSuccess(resp.data.status);
          
        }
        
        } catch (e:any) {
          SessionCheck(e);
          setAppState({...appState, msg:error.handle(e,"You're offline. Please check your network and try again",true),isMsg:true}); 
        }
        // finally{
        //   setAppState({...appState,loading:false});
        // }
    }


    useEffect(()=>{
      (async()=>{
        try {
          const resp = await getBusinesstypes();
          if(resp.status ===200 || resp.status===201){
            setAppState({...appState,res:resp.data.data});
          }

       } catch (e:any) {
        SessionCheck(e);

          setAppState({...appState,isMsg:true,msg:error.handle(e,"Failed to fetch required Data, please check your network and try again",true),count:appState.count+1, msgType:messasgeType.network})
      }
     

      })()

    },[])
    
    useEffect(()=>{
      setHintState({...hintState,status:appState.isMsg, msg:appState.msg, icon:appState.msgType})
    },[appState.count])
    return(
    <>
    {/* <Hint message={appState.msg} show={appState.isMsg} onClose={(stat:boolean)=>setAppState({...appState,isMsg:stat})}/> */}
    <div>
        <Typography component="h2" className="fadeInUp animated faster" style={{ margin: "20px 0 10px", fontSize: 20, fontWeight: 600 }}>Create a business</Typography>
        <div style={{ margin: "20px 0", textAlign: "center" }} className="fadeIn animated delay-0-4s">
          <form style={{ maxWidth: 600, margin: "auto", display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <TextLogoBox logo={<Icon icon="typcn:business-card" width="24" height="24" style={{ color: lighten(theme.palette.primary.main, 0.2) }} />} style={{alignItems: 'flex-start'}}> 
              <TextField onInput={(e:any)=>setFormData({...formData,name:e.target.value})} placeholder="Business Name" className="fadeIn animated delay-0-5s" onChange={(e: any) => setBName(e.target.value)} value={bName} style={{marginTop: '0px'}}></TextField>
            </TextLogoBox>
            <TextLogoBox logo={<Icon icon="bytesize:options" width="24" height="24" style={{ color: lighten(theme.palette.primary.main, 0.2), position:"relative" }} />}  style={{alignItems: 'flex-start'}}>
              
              <InputLabel className={`${classes.inputLabel} ${focus}`} style={{zIndex: 5, paddingLeft: '8px', top: '50%'}}>Business Type</InputLabel>
              <Select labelId="business-type" className={`${classes.inputElement} card-1`} style={{backgroundColor:"#fff", /* marginTop:20, */ borderRadius: 4}} onChange={(e:any)=>handleSelect(e)} value={busType}>
                {
                 appState.res.map((d:any,i:number)=><MenuItem value={d.btRecId}><Typography>{d.businessType}</Typography></MenuItem>)
                 
                }

            
              </Select>

            </TextLogoBox>
            <TextLogoBox logo={<Icon icon="ant-design:field-number-outlined" width="24" height="24" style={{ color: lighten(theme.palette.primary.main, 0.2) }} />}  style={{alignItems: 'flex-start'}}>
              <TextField placeholder="Registration Number" className="fadeIn animated delay-0-5s" onChange={(e: any) =>{ setBRegNo(e.target.value); setFormData({...formData,cac:e.target.value})}} value={bRegNo} style={{marginTop: '0px'}}></TextField>
            </TextLogoBox>
            <TextLogoBox logo={<Icon icon="wpf:business-contact" width="24" height="24" style={{ color: lighten(theme.palette.primary.main, 0.2) }} />}  style={{alignItems: 'flex-start'}}>
              <TextField onInput={(e:any)=>setFormData({...formData,address:e.target.value})} placeholder="Business Address" type="textarea" className="fadeIn animated delay-0-6s" onChange={(e: any) => setBAddr(e.target.value)} value={bAddr} style={{ width: "100%",marginTop: '0px' }}></TextField>
            </TextLogoBox>

            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                {/* <SimpleButton variant={ButtonVariant.success} onClick={submit} className="fadeIn animated delay-0-8s" style={{ margin: "20px 5px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Icon icon="fluent:save-28-regular" width="24" height="24" style={{ marginRight: 5 }} />
                    <div>Save</div>
                  </div>
                </SimpleButton> */}
              <Button onClick={submit} style={{margin: "20px 5px"}} className={`fadeIn animated delay-0-8s`}  text={"Save"} variant={"secondary"} loading={appState.loading} icon={ <Icon icon="fluent:save-28-regular"/>}/>
            </div>
          </form>
        </div>
      </div>
      </>)
}

export default BusinessForm;