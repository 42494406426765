import { faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import * as React from "react";
import useStyles from "./styles";

export interface IOptionCardProps {
  className?: string;
  style?: Record<string, any>;
  content?: {
    logo?: any;
    logoStyle?: Record<string, any>;
    title?: any;
    titleStyle?: Record<string, any>;
    text?: any;
    textStyle?: Record<string, any>;
  };
  onClick?:any,
  onHover?:any
}

 const OptionCard = React.forwardRef ((props:IOptionCardProps, ref)=>{




  const {
    className = "",
    style = {},
    content = {
      logo: <FontAwesomeIcon icon={faUser} />,
      title: "Personal",
      text: (
        <>
          <div>fghjkljhgf</div>
          <div>ertyuihgvc vb</div>
          <div>wqeszdxcfghu</div>
        </>
      ),
    },
    onClick = ()=>void(0)
  } = props;
  const classes = useStyles().classes;
  return (
    //@ts-ignore
    <button ref={ref} role={'main'}
      className={`${classes.optionCard} card-1-1 ${className}`}
      style={style}
      onClick = {onClick}
    >
      {content.logo != null || content.logo != undefined ? (
        <div className={`${classes.optionCardIcon}`} style={content.logoStyle}>
          {content.logo}
        </div>
      ) : null}
      <div>
        {content.title != null || content.title != undefined ? (
          <Typography
            className={`${classes.optionCardHeader}`}
            style={content.titleStyle}
          >
            {content.title}
          </Typography>
        ) : null}
        {content.text != null || content.text != undefined ? (
          <Typography
            className={`${classes.optionCardText}`}
            style={content.textStyle}
          >
            {content.text}
          </Typography>
        ) : null}
      </div>
    </button>
  );
        })
export default OptionCard;