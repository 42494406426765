import { useState, useEffect } from 'react';

  export const useCounter = (counters = [0,0])=>{
    const [status, setStatus] =useState(false);
 
    const [[mins, secs], setTime] = useState(counters);
  const counter = () => { 
   
        if (mins === 0 && secs === 0){
          setStatus(true);
        }else if (secs === 0) {
            setTime([mins - 1, 59]);
            setStatus(false);
        } else {
            setStatus(false);
            setTime([mins, secs - 1]);
        }
  }
 
  useEffect(() => {
      const timerId = setInterval(() => counter(), 900);
      return () => clearInterval(timerId);
  });

  return [mins, secs, setTime,status,setStatus] as const;
    }