import { Icon } from "@iconify/react"
import { lighten, Typography } from "@mui/material"
import { memo } from "react"
import Table, { DescBox, InfoBox } from "../../components/designerTable"
import SimpleButton, { ButtonVariant } from "../../components/simpleButton"
import theme from "../../resource/theme"
import { formatDateWithMonthName } from "../../utilities/appHelpers"
import React from "react";
import { ROLES } from "../../pages/dashboard"
const Businesses =(props:any)=>{
//functions


  //others

    return(<div>
        <Typography component="h2" className="fadeInUp animated faster" style={{ margin: "20px 0 10px"}}>
          <div style={{display: "flex"}}>
            <div style={{width: "50%", fontSize: 20, fontWeight: 600}}>
              Your Businesses
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", width: "50%" }}>
              {(ROLES?.BUSINESS_MODULE?.privileges?.ADD_BUSINESS ?? false) && <SimpleButton variant={ButtonVariant.white} onClick={props.createBusinessBtn}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ padding: "0 5px" }}><Icon icon="ic:baseline-add-business" width="16" height="16" /></div>
                  <div>Add a business</div>
                </div>
              </SimpleButton>}
            </div>
          </div>
        </Typography>
        <div style={{ margin: "20px 0" }} className="fadeIn animated delay-0-4s">

              <Table
            fields={[
              { name: "Business Name", size: 28 },
              { name: "Business Type", size: 20 },
              { name: "Registration Number", size: 20 },
              { name: "Services", size: 10 },
              { name: "Registration Date", size: 20 }
            ]}
         
            records={props.data ? props.data.map((d:any, i:number, arr:Array<any>)=>{
              return{ 
                name:d.name,type:d.type,regno:d.cac,services:d.services.length,date:formatDateWithMonthName(d.dateCreated),controls: { isDropdown: true },
                details: (
                  <div key={i} style={{ display: "flex", padding: "5px 0" }}>
                    <div style={{ width: "40%", paddingRight: 20 }}>
                      <DescBox title='Company Name' descr={d.name} style={{ margin: "10px 0" }} descrStyle={{ fontSize: "1.5em" }} />
                      <DescBox title='Business Type' descr={d.type} style={{ margin: "10px 0" }}/>
                      <InfoBox>
                        <div>Tel: {d.phone}</div>
                        <div>Email: {d.email}</div>
                        <div>Address: {d.address}</div>
                      </InfoBox>
                    </div>
                    <div style={{ width: "20%" }}>
                      <DescBox title='Registration Number' descr={d.cac} style={{ margin: "10px 0" }} />
                      <DescBox title='Registration Date' descr={formatDateWithMonthName(d.dateCreated)} style={{ margin: "10px 0" }} />
                    </div>
                    <div style={{ width: "40%", textAlign: "right"}}>
                      <DescBox title='Services' descr={
                        (
                          <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                            {d.services && d.services.length>0?d.services.map((s:any, i:number, all:Record<string, any>)=>(<div key={i} data-record={all}> 
                              <Icon icon={s.icon} width="16" height="16" color={(lighten(theme.palette.primary.main, 0.2))} /> {s.serviceName}
                            </div>)):"N/A"}
                            

                            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                              {(ROLES?.BUSINESS_MODULE?.privileges?.ADD_SERVICE ?? false) && <SimpleButton onClick={()=>props.onClickAdd(d.bizCode)} style={{ margin: "15px 5px 15px 0" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <div style={{ padding: "0 5px" }}><Icon icon="ic:baseline-add-business" width="16" height="16" /></div>
                                  <div>Add</div>
                                </div>
                              </SimpleButton>}
                              {(ROLES?.BUSINESS_MODULE?.privileges?.VIEW_SERVICE ?? false) && <SimpleButton variant={ButtonVariant.alt} onClick={()=>props.onClickExplore(d.bizCode,arr)} style={{ margin: "15px 0 15px 5px" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ padding: "0 5px" }}><Icon icon="cib:elastic-search" width="16" height="16" /></div>
                                    <div>Explore</div>
                                </div>
                              </SimpleButton>}
                              
                            </div>
                          </div>
                        )
                      } style={{ margin: "10px 0" }} descrStyle={{}} />
                    </div>
                  </div>
                )
              }
                
            }):[]}
            />


            
 
        </div>
      </div>)
}
export default memo(Businesses);