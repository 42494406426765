import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import * as React from "react";
import { allowOnClick } from "../../utilities/appHelpers";
import InputLoader from "../inputLoader";
import useStyles from "./styles";

export interface ITextFieldProps {
  className?: string;
  style?: Record<string, any>;
  label?: string;
  labelStyle?:Record<string, any>;
  inputClass?: string
  current?:string
}

function TextField(props: any) {
  const {
    className = "",
    style = {},
    label = "",
    labelStyle = {},
    inputloader = {},
    // value="",
    withLoader= false,
    currency ='',
    ...rest
  } = props;

  const classes = useStyles().classes;
  

  return (
    <div
      className={`${classes.textFieldBox} ${className} card-1`}
      style={style}
    >
      <Typography component={'div'} className={`${classes.innerWrapper}`}>
          {rest.type === "textarea" ? (<textarea
            {...rest}
            className={`${classes.inputElement} textarea ${/* labelState? "focused" : "" */  ""} ${rest.inputClass}`}
            // value = {value}
          ></textarea>) : (
          <div style={{display:'flex',justifyContent:'flex-start', alignItems:'center',}}>
           { currency && <span style={{color:'grey',fontWeight:600,fontSize:'1rem'}}>{ currency}</span>}
            <input {...rest} className={`${classes.inputElement} ${ /* labelState ? "focused" : ""  */""} ${rest.inputClass}`} required />
          </div>
            )}
        
        <label
          htmlFor={`${classes.inputElement}`}
          className={`${classes.inputLabel}`}
          style={{...labelStyle}}
        >
          {label}
        </label>
        <span className={`${classes.errorDisplay}`}></span>

      </Typography>
          
        {withLoader && <InputLoader {...inputloader}/>}

    </div>
  );
}

export default React.memo(TextField);
