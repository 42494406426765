import { Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import useStyles from "./styles";
import { allowOnClick } from "../../utilities/appHelpers";
import { useUserTheme } from "../../themes";
export interface ISliderSlides {
  title?: any;
  content?: any;
}
export interface ISliderProps {
  className?: string;
  style?: Record<string, any>;
  slides?: ISliderSlides[];
  interval?: number;
}

export default function Slider(props: ISliderProps) {
  const {isMobile} = useUserTheme(); 
  const {
    className = "",
    style = {},
    slides = [
      {
        title: "Payment Like ABC",
        content:
          "Payment can't be this Easy.Enjoy easy and secure payment flow",
      },
    ],
    interval = 1000,
  } = props;

  const classes = useStyles().classes;
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const indicators = slides.map((item: any, ind: number) => (
    <div
    key={`slider${ind}`}
      {...allowOnClick((e: any) => {
        showSlide(ind);
      })}
      className={`indicators ${
        currentSlideIndex == ind ? "active" : ""
      }`}
    ></div>
  ));
  let timmer: any = null;
  const showSlide = (slideIndex: any) => {
    setCurrentSlideIndex(
      slideIndex < 0
        ? slides.length - 1
        : slideIndex > slides.length - 1
        ? 0
        : slideIndex
    );
  };

  React.useEffect(() => {
    timmer = setTimeout(() => showSlide(currentSlideIndex + 1), interval);
    return () => {
      clearTimeout(timmer);
    };
  }, [currentSlideIndex]);
  const slidesf = slides.map((indText: ISliderSlides, index: number) => (
    <div key={index}
      className={`${classes.slider} ${
        currentSlideIndex == index ? "active" : ""
      }`}
    >
      <Typography className={`${classes.sliderTitle}`}>
        {indText.title}
      </Typography>
      <Typography className={`${classes.sliderOtherText}`}>
        {indText.content}
      </Typography>
    </div>
  ));

  return (
    <div className={`${classes.heroText} ${className}`} style={style}>
      {!isMobile && <div className={`${classes.indicatorBox}`}>{indicators}</div>}
      <div className={`${classes.sliderBox}`}>{slidesf}</div>
    </div>
  );
}
