import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';


const useStyles = makeStyles()((theme: Theme)=>({
        hintStyle:{
            width: 'calc(100% - 40px)',
            maxWidth: '400px',
            position: 'fixed',
            bottom: '-100px',
            zIndex: 2000,
            left: '20px',
        },
        body:{
            padding: 20,
            color: '#000',
            backgroundColor: '#fff',
            minHeight: 50,
            borderRadius: 5,
            border:' lightgrey solid thin',
            margin: '10px 0px',display:'flex',justifyContent:'flex-start',alignItems:'center',
            '& .iconl':{
                padding: 10,
                fontSize: "1.5rem",
                borderRadius: 50,
                backgroundColor: "#5994d230",
                display:'flex',
                marginRight: 10,color: "#294a81",
            },
            '& button':{
                padding:'5px 8px', backgroundColor:'transparent', border:'none', transition: 'background-color 0.5s ease', borderRadius:4, marginRight:'auto'
            },
            '& button:hover':{
                 backgroundColor:'lightgrey',
            },

            '& p':{
                width:'100%'
            }
        }
})); 

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;