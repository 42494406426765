import {useState} from "react"
import { useIdleTimer } from "react-idle-timer"
import { setAppStorage } from "../utilities/appHelpers";

/**
 * @param idleTime - number of seconds to wait before user is logged out
 */
function useSessionTimeout(idleTime = 900) { // half of the actual time (30)mins
    const idleTimeout = 1000 * idleTime;
    const [isIdle, setIdle] = useState(false);

    const handleIdle = () => {
        setIdle(true);
        setAppStorage('sessionExp',true);
    }
    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        onIdle: handleIdle,
        debounce: 500
    })
    return {
        isIdle,
        setIdle,
        idleTimer
    }
}

export default useSessionTimeout;