import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react"
import { useUserTheme } from "../../themes";
import LogoBox from "../logoBox";
import useStyles from "./styles";

export interface IPopupProps {
  showLogo?: boolean;
  children?: any;
  style?: Record<string, any>;
  className?: string;
  allowOutsideClick?: boolean;
  useBackground?:boolean;
  show?:boolean
  onPopChange?:any
  contentBoxStyle?:Record<string, any>;
  scrollbar?:boolean
  onReset?:any
  onClose?:any
  exitButton?:boolean
}

const Popup = (props: IPopupProps) => {

    const { showLogo = true, children, style,exitButton=true, onReset = null, onClose=null, className='', scrollbar=true, allowOutsideClick=true, useBackground=true, show = false, onPopChange=null, contentBoxStyle={}} = props;

    const [isShow, setIsShow] = useState<boolean>(false);
    const [display, setDisplay] = useState<string>('none');
    const {isMobile} = useUserTheme();
    useEffect(()=>{
      setIsShow(show);
    },[show])
    const handleClicks = (event:any)=>{
        if(allowOutsideClick){
          let classname = event?.target?.className;
          if(typeof classname === 'string' || classname instanceof String){
            if(classname.includes('popup_wrapper')){
              setIsShow(false);
             return onReset && onReset();
            }
            
          }
        }
        return
    }


    useEffect(()=>{
      const timmer = setTimeout(()=>setDisplay(isShow?'flex':'none'),isShow?0:500);
      onPopChange && onPopChange(isShow);
     
      return ()=>{
        clearTimeout(timmer);
      }
    },[isShow])

    
  const rules = useStyles().classes;    

  //   const theme = useTheme(); to be able to access theme while using inline styling
  return (
    <div style={{display:display}} className={`${rules.popupStyle} ${useBackground?rules.shell:''} animated faster popup_wrapper ${isShow?'fadeIn':'fadeOut'}`} onClick={(e:any)=>handleClicks(e)}>
      <div className={`popup_body ${isShow?'zoomInShort2':'zoomOut2'} animated faster ${className}`} style={style}>
        {showLogo ? <LogoBox className="logobox" style={!isMobile? {margin:'auto', marginTop: "-50px",marginBottom:'10px'}:{transform:'scale(0.7)',margin:'auto', marginTop: "-50px",marginBottom:'10px'}} /> : null}
        {exitButton&&<button type="button" onClick={()=>{onReset && onReset();setIsShow(false);}} className={rules["closeBtn"]}><FontAwesomeIcon icon={faTimes}/></button>}
        <div className={`${!scrollbar ?'hide-scrollbar':''} content-box`} style={contentBoxStyle}>{children}</div>
      </div>
    </div>
  );
};

export default Popup;
