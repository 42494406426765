import { lighten, Theme } from "@mui/material";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  fullPage:{
    width: "100vw", minHeight: "100vh",
    boxSizing: "border-box",
    "& *": {
      boxSizing: "inherit"
    }
  },
  main: {
    width: "100%", height: "100%", 
    backgroundColor: lighten(theme.palette.background.default, 0.5)
  },
  body: {
    display: "flex",
    position: "relative",
    minHeight: "calc(100% - 60px)" 
  },
  
  mainDisplay:{
    width: "100%",
    display: "flex",
    position: "relative",
    backgroundColor: lighten(theme.palette.background.paper, 0.8),
    height: "calc(100vh - 60px)",
    overflowY: "hidden"
  },
  firstDisplay:{
    position: "relative",
    padding: "20px 40px",
    [theme.breakpoints.down('sm')]:{
      padding: "20px",
    },
    overflowY:'scroll'
  },
  secondDisplay: {
    width: "350px",
    padding: "20px 40px", 
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    height: "fit-content"
  },
  balSec: {
    display: "flex", overflowX:'scroll',
    "&>*~*":{
      marginLeft: 10,
    },
    "&::--webkit-scrollbar":{
      width:6,
    },

    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },

    "&::-webkit-scrollbar-thumb:hover":{
      background: "#888",
    } ,
    ".conf_btn":{
      display: "flex",
      justifyContent: "space-between",
      marginTop: '2rem', width:"100%",
    }
  },
  headerLg: {
    fontSize: 20,
    fontWeight: 500,
  },
  headerMd: {
    fontSize: 15,
    fontWeight: 700,
  },
  section: {
    margin: "20px 0",
    // [theme.breakpoints.down('sm')]:{
    //  margin: 0,
    // }
  },
  sectionHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 0 10px 0"
  },
  walletWapper:{"&":{
    display: 'flex', padding:'0 0 5px 0',
  },
  "& > button, & > div":{
    marginRight:20
  },


  },
  hide:{
    display:'none !important'
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;