import { Typography } from '@mui/material';
import useStyles from './styles';
import { Icon } from '@iconify/react';
import React from 'react';

export interface IArrowedListProps {
  className?:string,
  style?:Record<string, any>,
  children?:any
}

export default function ArrowedList (props: IArrowedListProps) {
  const classes = useStyles().classes;
  const { className = "", style = {}} = props;
  return (
    <div className={`${className} ${classes.main} card-2`} style={style}>
      {props.children}
    </div>
  );
}

export interface IListItemProps {
  className?:string,
  style?:Record<string, any>,
  title:any,
  onClick?:any
  icon?:any
  showArrow?:boolean
  textStyle?:Record<string, any>,
  // clicked?:boolean
}

export function ListItem (props: IListItemProps) {
  const classes = useStyles().classes;
  const { className = "", style = {}, textStyle={}, title="", icon="", onClick=null, showArrow=true} = props;

  return (
    <button className={`${className} ${classes.btn}`} style={style} onClick={onClick}>
      {icon !== "" && <div className={`${classes.icon}`}>{icon}</div>}
      <div className={`${classes.btnBody}`} style={icon === "" ? {width: "100%",padding: "10px 0 10px 10px"} : {}}>
        <Typography component="div" style={textStyle}>{title}</Typography>
        {showArrow && (
          <div className={`${classes.arrow}`}>
            <Icon icon="la:angle-right" />
          </div>
        )}
      </div>
    </button>
  );
}