import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import React, { useEffect, useState, memo } from "react";
import useStyles from "./styles";

export interface IHintProps{
    message:string,
    icon?:any,
    pauseOnMouseHover?:boolean,
    escButton?:boolean,
    show:boolean,
    onClose?:any
}
const Hint = (props:IHintProps)=>{
    const{icon=null,message, show=false, pauseOnMouseHover=false,escButton=false, onClose=null}=props;
    
    const rules = useStyles().classes;
    const [showing, setShowing] = useState<boolean>(false);
    const [mousePause, setMousePause] = useState<boolean>(false);


    useEffect(()=>{
        setShowing(show);
    },[show])

    useEffect(()=>{
        if(showing && !mousePause){
            const timer = setTimeout(()=>setShowing(!showing),10000);
            return()=>{
                clearTimeout(timer);
            }
        }
        onClose && onClose(showing);
    },[showing,mousePause])


    
    return(
            <div className={`${rules.hintStyle} hintCover animated ${showing?'bounceInUp2':'slideOutDown fastest'}`} onMouseOver={pauseOnMouseHover ? ()=>setMousePause(true) :()=>void(0)} onMouseOut={pauseOnMouseHover ? ()=>setMousePause(false) :()=>void(0)}>
                <div className={`${rules.body} hint-body card-3 `}>
                    <div className="iconl">
                    {icon && icon}

                    </div>
                    <Typography style={{fontSize:'1rem', lineHeight:'1.08rem'}}>{message}</Typography>

                        {escButton && <button onClick={()=>setShowing(false)} ><FontAwesomeIcon icon={faTimes}/></button>}

                </div>
            </div>
    );
}

export default memo(Hint);