
import { BUSINESS, BUSINESSES, ADD, BUSINESSESTYPES, SERVICETYPES, ADDSERVICE, SERVICES, ADDACCOUNT, SETUP,ACCOUNTS } from './../../utilities/constant';
import axiosInstance from '../https';

//Post requests
export const addBusiness = (uid: any, data: Record<string, any>) => axiosInstance.post(`${BUSINESS}/${ADD}`, data);

export const addService = (code: any, data: Record<string, any>) => axiosInstance.post(`${BUSINESS}/${BUSINESSES}/${code}/${ADDSERVICE}`, data);

export const addAcount = (serCode:string,data:Record<string, any>) => axiosInstance.post(`${BUSINESS}/${SERVICES}/${serCode}/${ADDACCOUNT}`,data);



//Get resquests
export const getAllBusiness = () => axiosInstance.get(`${BUSINESS}/${BUSINESSES}`);

export const getBusinesstypes = () => axiosInstance.get(`${BUSINESS}/${BUSINESSESTYPES}`);

export const getServicetypes = () => axiosInstance.get(`${BUSINESS}/${SERVICETYPES}`);

export const getBusinessByCode = (code: string) => axiosInstance.get(`${BUSINESS}/${BUSINESSES}/${code}`);

export const getServiceDetails = (bizCode:string,serCode:string, pageNum?: number ) => axiosInstance.get(`${BUSINESS}/${bizCode}/${SERVICES}/${serCode}/?${pageNum? 'page_number='+pageNum: ''}`);

export const getServiceSetups = (bizCode:string,serCode:string)=>axiosInstance.get(`${BUSINESS}/${bizCode}/${SERVICES}/${serCode}/${SETUP}`);

export const getAccount = (bizCode:string,serCode:string)=>axiosInstance.get(`${BUSINESS}/${bizCode}/${SERVICES}/${serCode}/${ACCOUNTS}`);

export const filterServiceDashboard = (bizCode: string, serCode: string, pageNum?: number, acc?: string, date?: string) => axiosInstance.get(`${BUSINESS}/${bizCode}/${SERVICES}/${serCode}/fs?${pageNum? 'page_number='+pageNum: ''}${acc? '&acc='+acc: ''}${date?'&date='+date: ''}`)