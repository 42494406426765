import { Theme } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  main: {
    display: "flex", alignItems: "center",
    boxSizing: "border-box",
    "& *": {
      boxSizing: "inherit"
    }
  },
  
  textWrapper: {
    padding: "0 8px",
    paddingLeft: 15,
    width: "100%",
    position: "relative"
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;