import * as React from "react";
import DynamicInput from "../../../../components/dynamicInput";
import Select from "../../../../components/select";
import useStyles from "./styles";
import image from '../../images/location.png';

export interface IContactDetailsPageProps {
  formElems: any
}

export default function ContactDetailsPage(props: IContactDetailsPageProps) {
  const classes = useStyles().classes;
  const {
    formElems = [
      {type:"text",label:"Business Name", onInput:()=>void(0)}
    ]
  } = props;
  
  return (
    <div className={`${classes.detailsPage}`}>
      <div className={`${classes.detailsPageIllus}`}>
        <img src={image} alt="" />
      </div>
      <div className={`${classes.detailsPageForm}`}>
        <form action="" method="post">
          {formElems.map((elem:any, index:number)=>{
            return (
              <>
                {(elem.type === "select") ? (
                  <Select value={elem.value} label={elem.label} onChange={(e:any)=>elem.onChange(e)} onKeyDown={(e:any)=>elem.onKeyDown(e)} key={index}>
                    {elem.options}
                  </Select>
                ) : (elem.type !== "") && (
                  <DynamicInput inputType={elem.type} labelName={elem.label} onInput={(e:any)=>elem.onInput(e)} inputStyle={elem.inputStyle} key={index} />
                ) }
              </>
            )
          })}
        </form>
      </div>
    </div>
  );
}
