import React, { useEffect, useState } from "react";
import Button from "../../components/button";
import GatewayInputfield from "../../components/GatewayInputfield";
import { useStyles } from "./styles";
import { useStyles as useStyle } from "../../components/cardDetail/styles";
import { Typography, useMediaQuery } from "@mui/material";
import Line from "../../components/line";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoBox from "../../components/logoBox";
import Spinner from "../../components/spinner";
import { currency, error } from "../../utilities/appHelpers";
import { useNavigate, useParams } from "react-router-dom";
import { SessionCheck, dispatch, messasgeType } from "../../pages/dashboard";
import Hint from '../../components/hintBox';
import { Icon } from "@iconify/react";
import { getLinkByurlPath, processPayer } from "../../services/paymentLink";
import { trim } from "lodash";
import { NetworkError } from "../../utilities/constant";
import logo from "./images/signup1.png";

const PayeeDetails = () => {
    const isTabletOrMobile = useMediaQuery('(max-width:950px)');
    const styles = useStyles().classes;
    const lStyles = useStyle().classes;
    const params = useParams();
    const [isLoading, setisProcessing] = useState(false);
    const [appState, setAppState] = useState({ loading: false, res: [], msg: '', isMsg: false, status: false, count: 0, msgType: messasgeType.default });
    const [showSummary, setShowSummary] = useState(true);
    const [showCards, setShowCards] = useState(false);
    const [linkDetails, setLinkDetails] = useState({
        name: '',
        amount: '',
        descr: '',
        currency: '',
        chargeFrom: '',
        urlPath: '',
        callbackUrl: '',
        dateCreated: '',
        businessName:''
    })
    //   Get Link Details
    useEffect(() => {
        (async () => {
            try {
                setAppState(prev => ({ ...prev, loading: true }));
                const resp = await getLinkByurlPath(params.link);
                if (resp.status === 200) {
                    const linkDets = resp?.data?.data;
                    setLinkDetails(linkDets)
                }
                setAppState(prev => ({ ...prev, loading: false }));
            } catch (e: any) {
                SessionCheck(e);
                setAppState(prev => ({ ...prev, isMsg: true, msg: error.handle(e, "Failed to fetch required Data, please check your network and try again", true), count: prev.count + 1, msgType: messasgeType.network, loading: false, status: true }));
            }
        })();
    }, []);

    const [linkData, setLinkData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        linkCode: params.link ? params.link : ''
    });
    const navigate = useNavigate();

    const ProcessPayer = async () => {
        try {
            setisProcessing(true);
            if (trim(linkData.firstName) === '') {
                setAppState(prev => ({ ...prev, msg: 'Please provide your first name', isMsg: true, count: prev.count + 1, status: true, }));
                setisProcessing(false);
                return;
            }
            if (trim(linkData.lastName) === '') {
                setAppState(prev => ({ ...prev, msg: 'Please provide your last name', isMsg: true, count: prev.count + 1, status: true, }));
                setisProcessing(false);
                return;
            }
            if (trim(linkData.phone) === '') {
                setAppState(prev => ({ ...prev, msg: 'Please provide a phone number', isMsg: true, count: prev.count + 1, status: true, }));
                setisProcessing(false);
                return;
            }
            if (trim(linkData.email) === '') {
                setAppState(prev => ({ ...prev, msg: 'Please an email address', isMsg: true, count: prev.count + 1, status: true, }));
                setisProcessing(false);
                return;
            }
            if (!linkData.linkCode) {
                setAppState(prev => ({ ...prev, msg: 'Please a link code', isMsg: true, count: prev.count + 1, status: true, }));
                setisProcessing(false);
                return;
            }

            const resp = await processPayer(linkData);

            if (resp.status === 201 || resp.status === 200) {
                setisProcessing(false);
                navigate(`../checkout/${resp.data?.data?.ref}`);
            }
        } catch (e: any) {
            SessionCheck(e);
            setAppState(prev => ({ ...prev, msg: error.handle(e, NetworkError, true), isMsg: true, count: prev.count + 1, status: true }));
            setisProcessing(false);
        }
    }

    return (
        <>
            <Hint show={appState.status} message={appState.msg} onClose={(stat: boolean) => { setAppState(prev => ({ ...prev, status: false, msg: '', loading: false })); }} icon={<Icon icon={appState.msgType} />} escButton={true} />
            <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f6f6f6" }}>

                <div className={styles.gatewayBody}>
                    {appState.loading ? <Spinner size={100} style={{ position: "absolute" }} show={true} /> :

                        <div className={`${styles.cont} gateCont`}>
                            {/* <LogoBox className="dtpLogo" /> */}
                            {
                                isTabletOrMobile && (<div className="mob-nav">
                                    {/* <button style={{ visibility: "visible" }} title="Show Cards" onClick={() => { setShowCards(!showCards); setShowSummary(false) }}>
                                        <Icon icon="charm:menu-kebab" />
                                    </button> */}&nbsp;
                                    <span style={{fontSize:'1.5rem', textAlign:'center'}}> You'll be Paying  <strong>₦{linkDetails.amount}</strong></span>
                                    <button onClick={() => { setShowSummary(!showSummary); setShowCards(false) }} title="Show payment summary">
                                        {showSummary ? <Icon icon="humbleicons:times" /> : <Icon icon="lucide:info" style={{color:'grey'}}/> }
                                    </button>
                                </div>)
                            }

                            <div className={`${lStyles.sideCont}`}>
                                <form className={lStyles.linkData} style={{ marginTop: 18 }}>
                                    <Typography component={'h2'} style={{ fontSize: '16px', fontWeight: '600', marginBottom: '24px',color:'grey' }}>Securely enter your details for a seamless payment experience</Typography>
                                    <div className="inputsfields" >
                                        <GatewayInputfield id="card" type="text" label="First Name" animate={`fadeInUp delay-0-1s animated`} onInput={(e: any) => { setLinkData(prev => ({ ...prev, firstName: e.target.value })) }} value={linkData.firstName} autoFocus />
                                        <GatewayInputfield id="card2" type="text" label="Last Name" animate={`fadeInUp delay-0-1s animated`} onInput={(e: any) => { setLinkData(prev => ({ ...prev, lastName: e.target.value })) }} value={linkData.lastName} autoFocus />
                                        <div className="inline fadeInUp delay-0-3s animated">
                                            <GatewayInputfield id="card3" type="text" label="Email" animate={`fadeInUp delay-0-1s animated`} onInput={(e: any) => { setLinkData(prev => ({ ...prev, email: e.target.value })) }} value={linkData.email} autoFocus />
                                            <GatewayInputfield id="card4" type="text" label="Phone Number" animate={`fadeInUp delay-0-1s animated`} onInput={(e: any) => { setLinkData(prev => ({ ...prev, phone: e.target.value })) }} value={linkData.phone} autoFocus />
                                        </div>
                                    </div>
                                    <div className="actionBtn fadeInUp delay-0-3s animated">
                                        <div>
                                            <input type="checkbox" id="saveCard" hidden />
                                            <label htmlFor="saveCard" hidden>Save card</label>
                                        </div>
                                        <Button text={!isLoading ? `Pay ₦${linkDetails.amount}` : 'Please wait...'} reversed={true} loading={isLoading} icon={< FontAwesomeIcon icon={faArrowRight} />} onClick={() => { ProcessPayer(); }} />
                                    </div>
                                </form>
                            </div>

                            {/* Order summary */}
                            <div onClick={() => { setShowSummary(!showSummary);}} className={`${styles.paymentSummary}   ${showSummary ? styles['showSummary'] : ''} animated fadeIn faster`}>
                                <p>{''}</p>
                                <div className="summ-wrapper animated slideInRight faster">
                                    <div className="logodet">
                                        <div className="logo"><img src={logo} alt=" dtechpay"/></div>
                                    </div>
                                    <div className="summ">
                                        <div>
                                           {linkDetails?.businessName}
                                        </div>
                                        <Line />
                                        <div>
                                            {linkDetails.name}
                                        </div>
                                        <div>
                                            {linkDetails.descr}
                                        </div>
                                        <Line />
                                        <div>
                                            <span>Amount</span>
                                            <strong style={{ color: "green", fontSize: 20, fontWeight: 600 }}>{`${currency.naira + linkDetails.amount} `}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default PayeeDetails;