import moment from 'moment';
import SimpleCrypto from 'simple-crypto-js';

export const uuidv4 = () => {
   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
         v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
   });
};

export const calculateAge = (dateString: string) => {
   // birthday is a date
   if (dateString !== '' && dateString !== null && dateString !== undefined) {
      const today = new Date();
      const birthDate = new Date(dateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
         age--;
      }
      return age;
   } else {
      return '';
   }
};


export const capitalize = (str: string) => {
   const string = str && str.toLowerCase();
   return string ? string.charAt(0).toUpperCase() + string.slice(1) + ' ' : '';
};

export const maxAge = () => {
   const currentDate = new Date();
   const maxDate = `${currentDate.getFullYear() - 15}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

   return maxDate;
};



export const formatDisplayDate = (date: string) => {
   if (!date) return '00/00/0000';

   const newDate = new Date(date);
   const year = newDate.getFullYear();
   const month = newDate.getMonth() + 1;

   const formattedDate = `${newDate.getDate().toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;

   return formattedDate;
};

export const getMonthName = (month: number) => {
   switch (month) {
      case 1:
         return 'January';
      case 2:
         return 'February';
      case 3:
         return 'March';
      case 4:
         return 'April';
      case 5:
         return 'May';
      case 6:
         return 'June';
      case 7:
         return 'July';
      case 8:
         return 'August';
      case 9:
         return 'September';
      case 10:
         return 'October';
      case 11:
         return 'Novemeber';
      case 12:
         return 'December';
      default:
         break;
   }
};

export const calculateAMonth = (date: any) => {
   const theDate = new Date(date);
   theDate.setDate(theDate.getDate() + 29);
   return moment(theDate).format('MM-DD-YYYY');
};

export const calculateAMonthLess = (date: any) => {
   const theDate = new Date(date);
   theDate.setDate(theDate.getDate() - 29);
   return moment(theDate).format('MM-DD-YYYY');
};



export const formatDateWithMonthName = (date: string) => {
   if (!date) return '';

   const newDate = new Date(date);
   const year = newDate.getFullYear();
   const month = newDate.getMonth() + 1;

   const formattedDate = `${newDate.getDate().toString().padStart(2, '0')} ${getMonthName(month)}, ${year}`;

   return formattedDate;
};

export const getAccurateGreeting = () => {
   const time = new Date();
   if (time.getHours() < 12) {
      return 'Good Morning';
   } else if (time.getHours() > 12 && time.getHours() < 17) {
      return 'Good Afternoon';
   } else {
      return 'Good Evening';
   }
};


export function changeNullToString(value: Record<string, any>) {
   return value === null ? '' : value;
}


export const returnNaForNull = (data: any) => {
   if (data) {
      return data;
   } else {
      return 'N/A';
   }
};


export const regexValidation = (e: KeyboardEvent, regex: RegExp) => {
   if (!regex.test(e.key)) {
      e.preventDefault();
      e.stopPropagation();
   }
};



export const setItemStore = (key: string, data: any) => {
   const _secret = process.env.REACT_APP_SECRET;
   const simpleCrypto = new SimpleCrypto(_secret);

   
   const payload = simpleCrypto.encrypt(data);
   sessionStorage.setItem(key, payload);
};

export const getItemStore = (key: string) => {
   const _secret = process.env.REACT_APP_SECRET;
   const simpleCrypto = new SimpleCrypto(_secret);

   const data = sessionStorage.getItem(key);

   const result: any = data ? simpleCrypto.decrypt(data) : null;
   return result;
};

export const clearItemStore = ()=>{
   sessionStorage.clear();
}
export const clearAppStore = ()=>{
   localStorage.clear();
}

export const setAppStorage = (key:string, data:any)=>{
   const _secret = process.env.REACT_APP_SECRET;
   const simpleCrypto = new SimpleCrypto(_secret);
   let payload = simpleCrypto.encrypt(data);
   localStorage.setItem(key,payload);

}

export const getAppStorage=(key:string)=>{
      const _secret = process.env.REACT_APP_SECRET;
      const simpleCrypto = new SimpleCrypto(_secret);
      let data = localStorage.getItem(key);

      const result: any = data ? simpleCrypto.decrypt(data) : null;
      return result;
}

export const allowOnClick = (onClick:(e:any)=>any) =>{
  return {
    role:'button',
    onClick:onClick,
    tabIndex:-1,
    onKeyPress:onClick
  }
}

//use for checking if value is actually an object
export const isObject = (value:any)=>{
   if (typeof value === 'object' &&
      !Array.isArray(value) &&
      value !== null) {
      return true;
   }else{
     return false;
   }
}

/**
 * check if an object is empty
 * @param obj - Object {}
 * @returns Boolean
 */
export const objIsEmpty = (obj:any)=>{
  if(obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype) {
    return true;
  } else {
    return false;
  }
}

export const IsJsonString = (str:string) =>{
   try {
       JSON.parse(str);
   } catch (e) {
       return false;
   }
   return true;
}

export const db ={
    post:(key:string, data:any)=>{
       if(isObject(data)){
         return localStorage.setItem(key,JSON.stringify(data));
       }
      return localStorage.setItem(key,data);
         
   },

   get:(key:any)=>{
      let rst = localStorage.getItem(key);
      try {
         if(rst){
            let obj = JSON.parse(rst);
           if(obj && isObject(obj)){
               return obj;
           }
            
         } else{
            throw new ReferenceError("cannot find value of "+key);
         }

      } catch (e) {
         return rst;
      }
 
   }
}

export const error={
      handle:(e:any, customErrorMsg?:string,getInfo=false)=>{
         if(e.response){
            if(getInfo)return error.info(e?.response?.data?.message);
            return e?.response?.data?.message;
         } 
         
         if(e.message){
            if(getInfo)return error.info(customErrorMsg || e.message);
            return customErrorMsg || e.message;
         }
      },

      info:(handledError:any)=>{
         if(isObject(handledError)){
            let keys = Object.keys(handledError);
            if(keys.length>0){
               if(Array.isArray(handledError[keys[0]])){
                 return handledError[keys[0]].map((info:any)=>info).join(', ');
               }
            }
         }else if(typeof handledError === 'string' && !Array.isArray(handledError)){
            return handledError;
         }else if(Array.isArray(handledError)){
             return handledError.map((info:any)=>info).join(', ');
         }

      },

      getData:(e:any)=>{
         // if(e.message)return;
        if(!e.response)return;
        return e.response.data   
      }
         
} 


/**
 * @param email string value
 * 
 *  useCase - validate email
 */ 

 export const isValidEmail =(email:string)=>{
   return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}


/**
 * @param value string value
 * 
 *  useCase - check for a correct password
 */ 
 export const isValidPassword =(value:any)=>{
   return new RegExp("^((?=.*[A-Z])|(?=.*[a-z])(?=.*[0-9]))(?=.{8,})").test(value);
}

export const flex =(justifyContent="center",alignItems="center", direction="row")=>{
   let jc, ai, dir;
   if(justifyContent === "sb"){
       jc = "space-between";
   }else if(justifyContent === "se"){
       jc = "space-evenly";
       
   }else if(justifyContent === "sa"){
       jc = "space-around";
   }else if(justifyContent === "end"){
       jc = "flex-end";
       
   }else if(justifyContent === "start"){
       jc = "flex-start";
   
   }else if(justifyContent === "col"){
       dir = "column"
       direction = "column"
       jc = 'center';
   }
   else{
       jc = justifyContent;
   }


   if(alignItems === "start"){
       ai = "flex-start";
   }else if(alignItems === "end"){
       ai = "flex-end";
   }else{
       ai = alignItems;
   }

   if(direction === "col"){
       dir = "column"
   }else{
       dir = direction;
   }

return {
   display:"flex",justifyContent:jc,alignItems:ai,flexDirection:dir
}

}

export const formatNum = (digit:string,groupBy=4)=>{
   let splitted = digit.split("");
   let i =0, newDigit= '';
   while(i<splitted.length){
     if(i!==0)
     if(i%groupBy===0){
       newDigit += ' ';
    }
     newDigit += splitted[i];
     i++;
   }
   
   return newDigit;
 }


/**
 * 
 * @param {string | inputEvent} char characters to formate of an inputEvent
 * @param {{}} options separator | inPosition
 * @returns string
 */
 export const insertTextSeperator  = (char:any,options:Record<string,any>)=>{
   const {separator=' ',inPosition=4, onEvent=typeof char === 'object'&& char.target !==undefined?true:false} = options;
   
   let input = char;
   if(onEvent){
        if((char.inputType&&char.inputType==="deleteContentBackward") || (char.nativeEvent.inputType&&char.nativeEvent.inputType === "deleteContentBackward")) return char.target.value;
        input = char.target.value;
      }
          let charArray = [...input.replaceAll(new RegExp('[^a-zA-Z0-9 ]|'+separator,'g'),'')];
        let i =0, newchar= '';
        while(i<charArray.length){
          if(i%inPosition===0&&i!==0){ //every 4th position;
            newchar +=separator;
          }
          newchar += charArray[i];
          i++;
        }
        
        return newchar;
 
  }
   //detect card issuer by number (IIN)
  export const isMasterCardIIN = (value:string)=>/^5[1-5]{2,}\d+/.test(value);
  export const isVisaIIN = (value:string)=>/^4\d+/.test(value);
  export const isVisaElectronIIN = (value:string)=>/^(4026|417500|4508|4844|4913|4917)\d+$/.test(value);
  export const isVerveIIN = (value:string)=>/^(506[0-9])\d+|650([0-9])\d+|(507[0-9])\d+$/.test(value);
  //detect cardtype
  export const isVisaElectronCard = (value:string)=>/^(4026|417500|4405|4508|4844|4913|4917)\d+$/.test(value);
  export const isVisaCard = (value:string)=>/^4[0-9]{12}(?:[0-9]{3})?$/.test(value);
  export const isVerveCard = (value:string)=>/^((506(0|1))|(507(8|9))|(6500))[0-9]{12,15}$/.test(value);
  export const isMasterCard = (value:string)=>/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(value);
  
export const isValidMonth=(value:string)=>/^(0[1-9]{1}|1[0-2]{1})$/g.test(value);
 export const getCurrentYear=(full=true)=>{
	var d = new Date().getFullYear()
  return full?d:d.toString().slice(2);
}

export const getCurrentMonth=()=>new Date().getMonth()>9?'0'+new Date().getMonth():new Date().getMonth();


export const sanitize = (value:any,allow:Record<string,any> ={})=>{
   const {numbers=true,letters=false,characters=false} = allow;
   if(numbers && !letters){
      return value.replaceAll(new RegExp(/[^\d]/,'g'),'');
   }
   if(letters && !numbers){
      return value.replaceAll(new RegExp(/[^a-zA-Z]/,'g'),'');
   }

   if(numbers && letters){
      return value.replaceAll(new RegExp(/[^a-zAZ0-9]/,'g'),'');
   }
   
}

export const truncate = (value:string, length:number=11)=> {
   let newval = value.match(new RegExp(`.{1,${length}}`))
   if(newval){
     return newval[0];
   }
   return value;
}

export const isValidCard=(value:any)=>{
   if(isVisaElectronCard(value)||isVisaCard(value)||isVerveCard(value)||isMasterCard(value)) return true;
   return false;
}

export const isValidCardExpiry=(expDate:any)=>{

   if((expDate.slice(expDate.length-2) < getCurrentYear(false)) || +expDate.slice(expDate.length-2) > +getCurrentYear(false)+20){
      return false;
      
   }else if(expDate.slice(0,2)<=getCurrentMonth() && +expDate.slice(expDate.length-2) <= +getCurrentYear(false)){
      return false;
   }else{
      return true;
   }

      // if(expDate.length>= 6) return true;
}

export const isValidCVV = (value:any)=>value.trim().length===3;

 export enum currency{
   naira = '₦'
 }


 export const commarize = (num:any)=>{
   let dec='';
   // return;   
   dec = num.toString().search(/\./) > 0? '.'+num.split('.').at(-1):'';
     num = num.toString().search(/\./) < 0? num.toString().split("") :num.toString().split(".")[0];
     let newnum: any=[], r=1;
     
     for(let i=num.length-1;i>=0;i--){
           newnum.unshift(num[i])
      if(r%3===0 && i!=0)newnum.unshift(",");
       r++;
      
     }
     
     return dec.length<1 ?newnum.join(""):newnum.join("")+dec;
   }

 export const commarize2 = (num:any)=>{
   num = num.replaceAll(new RegExp('[^a-zA-Z0-9\s]|,+\s','g'),'');
   let dec='';
   dec = num.toString().search(/\./) > 0? '.'+num.split('.').at(-1):'';
     num = num.toString().search(/\./) < 0? num.toString().split("") :num.toString().split(".")[0];
     let newnum: any=[], r=1;
     
     for(let i=num.length-1;i>=0;i--){
           newnum.unshift(num[i])
      if(r%3===0 && i!=0)newnum.unshift(",");
       r++;
      
     }
     
     return dec.length<1 ?newnum.join(""):newnum.join("")+dec;
   }
   

// export const alterObject =(obj:Record<string,any>,newProps= [{k:'label',v:'value'}])=>{
//    Object.keys(obj).forEach(oldkey => {
//       let value = obj[oldkey];
//       newProps && newProps.map((p)=>{

//          let newKey = oldkey == 'cbn_code'?'value': oldkey == 'bank_name' ?'label' : oldkey;
//          delete obj[oldkey];
//          obj[newKey] = value;
//       })
//   });
// }

