import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import Playground from '../playground';
import Home from '../modules/heroMod';
import WelcomePage from '../modules/welcomePageMod';
import Dashboard from '../pages/dashboard';
import LoginMod from '../modules/loginMod';
import Gateway from '../modules/gateWayMod';
import { BadRequest } from '../components/errorPage/badRequest';
import PGS from '../modules/services/paymentGateway';
import SMS from '../modules/services/schoolManagment';
import PRS from '../modules/services/payroll';
import ABP from '../modules/services/agencyBanking';
import ECP from '../modules/services/eCommerce';
import ConfirmPayment from '../confirm';
import PayerDetails from '../modules/gateWayMod/PayerDetails';
import PDFTest from '../test';
import Receipt from '../receipt';
import Users from '../modules/dashboard/pages/userManagment';
import VerifyEmailPage from '../modules/verifyEmail';
import SignupMod from '../modules/signupMod';


export interface IMainRoutesProps {
 
}


export default function MainRoutes (props: IMainRoutesProps) {
  return (
    <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='playground' element={<Playground />} />
        <Route path='receipt' element={<Receipt/>} />
        <Route path='welcome' element={<WelcomePage />} />
        <Route path='verify/email' element={<VerifyEmailPage />}  />
        <Route path='badrequest' element={<BadRequest/>} />
        <Route path='dashboard' element={<Dashboard />}>
          <Route path='users' element={''} />
          <Route path='roles' element={''} />
          
          <Route path='transactions' element={''} />
          <Route path='business' element={''} />
          <Route path='transfer-fund' element={''} />
          <Route path='fund-wallet' element={''} />
          <Route path='airtime' element={''} />
          <Route path='utilities' element={''} />
          <Route path='help' element={''} />
          <Route path='settings' element={''} />
          <Route path='payment' element={''} >
          <Route path='create' element={''} />
          <Route path='link/:id/access/:aid' element={''} />
          <Route path='link/:id' element={''} />
          <Route path='edit/:id' element={''} />
          </Route>
          <Route path='services' element={''}>
            <Route path='pgs' element={<PGS/>}>
              <Route path='home' element={''} />
              <Route path='setup' element={''} />
            </Route>
            <Route path='sms' element={<SMS/>}>
              <Route path='home' element={''} />
              <Route path='setup' element={''} />
            </Route>
            <Route path='ecp' element={<ECP/>}>
              <Route path='home' element={''} />
              <Route path='setup' element={''} />
            </Route>
            <Route path='prs' element={<PRS/>}>
              <Route path='home' element={''} />
              <Route path='setup' element={''} />
            </Route>
            <Route path='abp' element={<ABP/>}>
              <Route path='home' element={''} />
              <Route path='setup' element={''} />
            </Route>
          </Route>
        </Route>
        <Route path='login' element={<LoginMod />} />
        <Route path='checkout/:ref' element={<Gateway/>}/>
        <Route path='pay/:link' element={<PayerDetails/>}/>
        <Route path='finish' element={<ConfirmPayment/>}/>
        {/* <Route path='invite' element={<SignupMod/>}/> */}
        <Route path='*' element={<Home/>} />
        
    </Routes>
  );
}
