import { Icon } from "@iconify/react";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button";
import { Loader } from "../../components/loader";
import { SessionCheck, dispatch, messasgeType, useGlobalState } from "../../pages/dashboard";
import { addService, getServicetypes } from "../../services/bussiness";
import { error, getAppStorage, setAppStorage } from "../../utilities/appHelpers";
import OptionChecker, { CheckerBtn } from "../dashboard/components/optionChecker";

const Services = (props:any)=>{
  //States
  const navigate = useNavigate();
  const [appState,setAppState] = useState({res:[],loading:true,msg:'',isMsg:false,count:0});
  const [check, setCheck]=useState<Record<string,any>>({});
  const [btnState, setBtnState]=useState<Record<string,any>>({text:'Add',logo:"carbon:bookmark-add",loading:false,disabled:false}), [callbackUrl,setCallbackUrl] = useState('')

    const [hintState,setHintState] = useGlobalState("hint");
    const [isData, setIsData]=useState(false);

  //Functions 
    const clickHandler = async(e:Event, data:Array<string>)=>{
      e.stopPropagation();
      const [sid,url] =data;
      setCheck({...check, [sid]:!check[sid]});
    }
      
    

    const submitSelectedService=async(code:string)=>{
      setBtnState({...btnState,loading:true,text:'Submitting...',logo:"mdi:progress-clock",disabled:true})
      let sIds:any =[]
      for(const b in check){
        if(check.hasOwnProperty(b) && Number.isInteger(+b)){
          if(check[b]) sIds.push(b);
        }
      }

      try {
        const resp = await addService(code,{serviceIds:sIds});
        if(resp.status===200||resp.status===201){
          // loadPage(url);
          props.onAdded && props.onAdded(resp.data.status);
          setAppState({...appState,msg:"Service(s) Successfully added",isMsg:true, count:appState.count+1});

        }
      } catch (e:any) {
        SessionCheck(e);

        setAppState({...appState,msg:error.handle(e,"You're Offline. Please Check your network and try agian"),isMsg:true, count:appState.count+1});
  
        
      }finally{
        setBtnState({...btnState,loading:false,text:'Add',logo:"carbon:bookmark-add",disabled:false});

      }

    }
    

    useEffect(()=>{
      const fetch = async()=>{
        try {
          const resp = await getServicetypes();
          if(resp.status===200||resp.status===201){
            setAppState({...appState,res:resp.data.data,loading:false});
            setAppStorage('services',resp.data.data);
            setIsData(true);
            let i:number =0;
            let obj:Record<string, any> = {};
            while (i<resp.data.data.length) {
              obj[resp.data.data[i].stRecId] = false;
             i++; 
            }
            setCheck({obj})
          }
        
      } catch (e:any) {
        SessionCheck(e);

        setAppState({...appState,msg:error.handle(e,"You're Offline. Please Check your network and try agian"),isMsg:true,count:appState.count+1});
      }
      
    }

    getAppStorage('services') !==null? setAppState({...appState,res:getAppStorage('services'),loading:false}):fetch();


    },[isData])

    useEffect(()=>{
      setHintState({...hintState,status:appState.isMsg, msg:appState.msg, icon:messasgeType.network})
    },[appState.count])

    return (
    <>
    <div>
        <Typography component="h2" className="fadeInUp animated faster" style={{ margin: "20px 0 10px", fontSize: 20, fontWeight: 600 }}>Add Service</Typography>
        <div style={{ margin: "20px 0" }} className="fadeIn animated delay-0-4s">
          <div>
            <Typography component="h3" style={{ fontSize: "0.8em", margin: "15px 0 5px" }}>
              Select the service you would like to add<sup style={{color:"red",fontSize:11}}>*</sup>
            </Typography>
            
            <div>
              <OptionChecker>

                  {appState.loading ? <Loader show={appState.loading}/>: appState.res.map((d:any,i:number)=>{
                  
                  return (d.status === 1 || d.status === "1") && <CheckerBtn onClick={(e:any)=>{
                    clickHandler(e,[d.stRecId,d.buildUrl]);
                  }
                    
                  } 

                    title={<div key={i} style={{ fontSize: 15, fontWeight: 500, textAlign:"left"}}>{d.serviceName}</div>}
                  icon={<Icon icon={d.icon} width="24" height="24" />} checked={check[d.stRecId]}/>})}

              </OptionChecker>

              <Button style={{marginLeft:"auto", marginTop:20}} text={btnState.text} variant='secondary' loading={btnState.loading} icon={<Icon icon={btnState.logo}/>} disabled={btnState.disabled} onClick={()=>submitSelectedService(getAppStorage("bizCode"))}/>


            </div>
          </div>
        </div>
      </div>
      </>)
}
export default Services;