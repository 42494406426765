import { lighten } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme)=>({
  main: {
    width: "100%",
    height: "30px",
    borderRadius: "100px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.default
  },
  btn: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    borderRadius: "100px",
    border: "none",
    color: "#333",
    fontSize: "0.7em",
    fontWeight: 500,
    padding: "5px 10px",
    cursor: "pointer",
    "&.active": {
      backgroundColor: lighten(theme.palette.primary.main, 0.2),
      color: "#fff",
      transition: "0.4s ease-in",
      boxShadow: "0 0 28px rgba(0, 0, 0, 0.25), 0 0 10px rgba(0, 0, 0, 0.22)"
    }
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;