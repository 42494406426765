import { WALLET,WALLETS, ADD, LOOKUPS,DTPACC, FUND} from './../../utilities/constant';
import axiosInstance from '../https';


//Get resquests
export const getAllWallets =  ()=>{
    return axiosInstance.get(`${WALLET}/${WALLETS}`);
}
export const addWallet =  (data:Record<string,any>)=>{
    return axiosInstance.post(`${WALLET}/${ADD}`, data);
}

export const getWalletByCode=(walletCode:string)=>{
    return axiosInstance.get(`${WALLET}/${walletCode}`);
}

export const fundWallet=(walletcode:string,data:Record<string,any>)=>{
    return axiosInstance.post(`${WALLET}/${FUND}/${walletcode}`,data);
}

export const getDTPAcc=()=>{
    return axiosInstance.get(`${LOOKUPS}/${DTPACC}`);
}

