import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import * as React from 'react';
import { getAppStorage, setAppStorage } from '../../utilities/appHelpers';
import useStyles from './styles';

export enum CardVariant {
  main = 0,
  alt = 1
}

export interface IBalanceCardProps {
  variant?: CardVariant,
  className?: string,
  style?: Record<string, any>,
  children?: any
  title?:any,
  amount?:any,
  descr?:any,
}

function BalanceCard(props: IBalanceCardProps) {
  const classes = useStyles().classes;
  const { variant = CardVariant.main, style = {}, className = "", title="", amount="", descr=""} = props;
  const [isVisibile, setIsVisibile] = React.useState(true);
  const status = {
    visible:{
      icon:faEyeSlash,value:amount,
    },
    hidden:{
      icon:faEye,value:'****',
    }
  }

  const [states,setStates]=React.useState(status.visible);

  const handleVisibility = React.useCallback(()=>{
    setIsVisibile((p)=>{
      setAppStorage('wallet_bal_visility',!p);
      return !p;
    });
  },[isVisibile])

  React.useEffect(()=>{
      let d:boolean|any = getAppStorage('wallet_bal_visility');
      if(d!==null){
        setIsVisibile(d);
      }
      
  },[])
  React.useEffect(()=>{
    let u = getAppStorage('wallet_bal_visility');
    (u !==null && u)? setStates(status.visible):setStates(status.hidden);
  },[isVisibile])

  return (
    <div className={`${classes.main} card-2 slideInLeft animated faster ${variant === CardVariant.alt ? classes.altBg : classes.mainBg} ${className}`} style={style}>
      
        <svg version="1.1" id="Layer_1" x="0px" y="0px"
          width="407.555px" height="255px" viewBox="0 0 407.555 255" enableBackground="new 0 0 407.555 255" xmlSpace="preserve"
          className={classes.design}
        >
          <path d="M-9.161-37.421v226.247c0,0,7.257-28.074,74.018-32.543c56.138,1.431,97.936,27.715,164.813,29.325
	c66.877,1.608,173.808-50.781,187.046-195.255v-27.773H-9.161z"/>
        </svg>
      <div className={classes.logoBox} onClick={handleVisibility}><FontAwesomeIcon icon={states.icon} /></div>
      <Typography component="div" className={classes.textBox}>
        <div className={classes.title}>{title}</div>
        <div className={classes.bigBold}>{states.value}</div>
        <div className={classes.descr}>{descr}</div>
      </Typography>
      <div className={classes.controls}>
        {props.children}
      </div>
    </div>
  );
}
export default React.memo(BalanceCard);