import React, { useEffect, useState } from 'react'
import Table from '../../../../components/designerTable';
import { error } from '../../../../utilities/appHelpers';
import { alpha, darken, lighten, Typography } from '@mui/material';
import { defaultPageData, PaginationControl } from '../../../../components/PaginationControl';
import { paginationData } from '../../../../utilities/types';
import usePagination, { usePaginationInterface } from '../../../../customHooks/pagination';
import { STable } from '../../../../components/skeletonLoader';
import Switch from '../../../../components/Switch2';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import SimpleButton, { ButtonVariant } from '../../../../components/simpleButton';
import Dropdown from '../../../../components/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy, faEllipsis, faLink } from '@fortawesome/free-solid-svg-icons';
import { ROLES, SessionCheck, dispatch, messasgeType, useGlobalState } from '../../../../pages/dashboard';
import { deleteLink, editLink, getLinks } from '../../../../services/paymentLink';
import { NetworkError } from '../../../../utilities/constant';
import useStyles from '../business/styles';
import empty from "../business/images/emptycart.svg";
import theme from "../../../../resource/theme";
import Popup from '../../../../components/popup';
import { useUserTheme } from '../../../../themes';


export default function PaymentLink() {
    const classes = useStyles().classes;
    const {isMobile} = useUserTheme();
    const [linkData, setLinkData] = useState<any>([]);
    const [pageData, setPageData] = useState<paginationData>(defaultPageData);
    const pagination: usePaginationInterface = usePagination(pageData);
    const [controlPageData, setControlPageData] = useState<paginationData>(defaultPageData);
    const navigate = useNavigate();
    const [appState, setAppState] = useState({ loading: false, res: [], msg: '', isMsg: false, status: false, count: 0, msgType: messasgeType.default });
    const [, setHintState] = useGlobalState("hint");
    const [isDeleting, setIsDeleting] = useState(false);

    const {VIEW_PAYMENT_LINK_ACCESS=false,DELETE_PAYMENT_LINK,EDIT_PAYMENT_LINK} = ROLES?.PAYMENT_LINK_MODULE?.privileges;

    useEffect(() => {
        (async () => {
            try {
                setAppState(prev => ({ ...prev, loading: true }));
                const resp = await getLinks(controlPageData);
                if (resp.status === 200) {
                    const linkDets = resp?.data?.data;
                    const linkDetsData = linkDets.items || [];
                    setLinkData(linkDetsData);
                    setPageData({ pageNumber: Number(linkDets.pageNumber), pageSize: Number(linkDets.pageSize), totalRecords: Number(linkDets.totalRecords || 0) });
                }
                setAppState(prev => ({ ...prev, loading: false }));
            } catch (e: any) {
                SessionCheck(e);
                setAppState(prev => ({ ...prev, isMsg: true, msg: error.handle(e, "Failed to fetch required Data, please check your network and try again", true), count: prev.count + 1, msgType: messasgeType.network, loading: false }));
            }
        })();
    }, [controlPageData, isDeleting]);

    const EditLink = async (linkData: Record<string, any>, id: number) => {
        try {
            const resp: Record<string, any> = await editLink(linkData, id);

            if (resp.status === 200) {
                setAppState({ ...appState, loading: false, msg: resp.data.message, status: resp.data.status, isMsg: true, count: appState.count + 1 });
            }
        } catch (e: any) {
            SessionCheck(e);
            setAppState({ ...appState, msg: error.handle(e, NetworkError, true), isMsg: true, count: appState.count + 1 });
        }
    }

    const DeleteLink = async (linkId: number) => {
        try {
            setIsDeleting(true);
            const delLinkResp = await deleteLink(linkId);

            if (delLinkResp.status == 200) {
                setIsDeleting(false);
            }
        } catch (e: any) {
            setAppState(prev => ({ ...prev, msg: error.handle(e, NetworkError, true), isMsg: true, count: prev.count + 1, loading: false }));
            setIsDeleting(false);
        }
    };

    useEffect(() => {
        setHintState(prev => ({ ...prev, status: appState.isMsg, msg: appState.msg, icon: appState.msgType }));
    }, [appState.count])

    const [copied, setCopied] = React.useState(false);
    const negateCopiedState = () => {
        setTimeout(() => { setCopied(false); }, 3000);
    }
    const [selLink, setSelLink] = useState(-1)

    return (
        <div className="fadeIn animated faster">
            <div style={{ margin: "20px 0 10px", display: 'flex', justifyContent: 'space-between' }}>
                <Typography component="h2" style={{ fontSize: 20, fontWeight: 600 }}>Payment Links</Typography>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div></div>
                        <div style={{ width: '1px', height: '100%', backgroundColor: 'red' }}></div>
                        <div></div>
                        <div style={{ width: '1px', height: '100%', backgroundColor: 'red' }}></div>
                        {!appState.loading && linkData.length > 0 && (ROLES?.PAYMENT_LINK_MODULE?.privileges?.ADD_PAYMENT_LINK ?? false) && <SimpleButton  onClick={() =>navigate("/dashboard/payment/create") }>
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center',gap:5}}>
                                <Icon icon="ant-design:link-outlined" fontSize={17} /> <span>Create new link</span>
                            </div>
                        </SimpleButton> 
                        }
                    </div>
                    {!appState.loading && (pagination.totalRecords > pagination.pageSize) ?
                        <PaginationControl pageDataState={[controlPageData, setControlPageData]} pagination={pagination} />
                        : null}

                </div>
            </div>
            {appState.loading && <STable style={{ marginTop: 20 }} />}

            {!appState.loading && linkData.length > 0 &&
                <div style={{ margin: "20px 0" }}>
                    <Table fields={[{ name: "Created On", size: 20 }, { name: "Link Name", size: 20 }, { name: "Processed", size: 12 }, ((VIEW_PAYMENT_LINK_ACCESS)&&{ name: "Accessed", size: 12 }), { name: "Action", size: 12 }, ((ROLES?.PAYMENT_LINK_MODULE?.privileges?.CHANGE_PAYMENT_LINK_STATUS ?? false) && { name: "Status", size: 12 }), (( VIEW_PAYMENT_LINK_ACCESS || DELETE_PAYMENT_LINK || EDIT_PAYMENT_LINK)&&{ name: "More", size: 12 })]}
                        records={linkData.length > 0 && linkData.map((link: any, index: number) => {
                            const dateCreated = link.dateCreated.split(' ');
                            const linkUrl = `${window.location.origin}/pay/${link.urlPath}`;
                            return {
                                createdon: <Typography>{dateCreated[0]} <span style={{ color: 'grey', fontSize: '10px' }}>{dateCreated[1]}</span></Typography>,
                                linkname: link.name,
                                processed: 0,
                                accessed: (ROLES?.PAYMENT_LINK_MODULE?.privileges?.VIEW_PAYMENT_LINK_ACCESS ?? false) ? link?.accessed??'N/A' : null,
                                action: <div>
                                        <SimpleButton onClick={() => {setSelLink(link.id)}} variant={ButtonVariant.white}  >Share</SimpleButton>
                                        <Popup children={
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <Typography style={{fontSize: 20, fontWeight: '600', marginBottom: '24px', color: 'black'}} component={'h3'}>Your payment link is ready! </Typography>
                                                    <Typography style={{fontSize: 14, fontWeight: '500', marginBottom: '16px', color: 'black'}}>Copy this payment link to share with your customers</Typography>
                                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', backgroundColor: '#0000000d', color: '#000', padding: '16px', borderRadius: '5px'}}>
                                                        <div style={{width: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: 600}}>{linkUrl}</div>
                                                        <div style={{}} onClick={() => {
                                                            navigator.clipboard.writeText(`${linkUrl}`).then(() => {
                                                                setCopied(true);
                                                                negateCopiedState();
                                                            }).catch((error) => {
        
                                                            });
                                                        }}><FontAwesomeIcon fontSize={20} icon={ copied ? faCheck : faCopy } /></div>
                                                    </div>
                                                </div>
                                            } show={selLink === link.id} style={{ maxWidth: "450px", width: "calc(100% - 50px)", minHeight: isMobile ? '25%' :'180px', height: isMobile ? 'unset' : '180px' }} onReset={() => {setSelLink(-1) }} showLogo={false} />
                                </div>,
                                status: (ROLES?.PAYMENT_LINK_MODULE?.privileges?.CHANGE_PAYMENT_LINK_STATUS ?? false) ?<Switch variant='circle' onChange={(e: any) => {
                                    EditLink({ status: e.target.checked ? 1 : 0 }, link.id)
                                }} checked={link.status} />: null
                                ,
                                more: (VIEW_PAYMENT_LINK_ACCESS || EDIT_PAYMENT_LINK || DELETE_PAYMENT_LINK) && <Dropdown
                                    dropHandle={'button'}
                                    dropHandleChildren={<FontAwesomeIcon icon={faEllipsis} fontSize={20} />}
                                >
                                    {({ hideDrop }: any) => {
                                        return (
                                            <div onClick={() => { hideDrop() }} style={{ display: 'flex', flexDirection: 'column', gap: '8px', fontSize: '12px', cursor: 'pointer', padding: '14px' }}>
                                                {(ROLES?.PAYMENT_LINK_MODULE?.privileges?.VIEW_PAYMENT_LINK_ACCESS ?? false) && <div onClick={() => { navigate(`/dashboard/payment/link/${link.id}`) }}>View Link Access</div>}
                                                {(ROLES?.PAYMENT_LINK_MODULE?.privileges?.EDIT_PAYMENT_LINK ?? false) && <div onClick={() => { navigate(`/dashboard/payment/edit/${link.id.toString()}`) }}>Edit Link</div>}
                                                {(ROLES?.PAYMENT_LINK_MODULE?.privileges?.DELETE_PAYMENT_LINK ?? false) && <div onClick={() => { DeleteLink(link.id);}}>Delete</div>}
                                            </div>
                                        );
                                    }}
                                </Dropdown>
                            }
                        })} />
                </div>}
            {!appState.loading && linkData.length < 1 &&
                <div style={{ margin: "20px 0", textAlign: "center" }} className="fadeIn animated delay-0-4s">
                    <div className={classes.imgCont} style={{ color: darken(theme.palette.background.paper, 0.2), margin: "20px 0" }}>
                        <img src={empty} alt="empty business" />
                    </div>
                    <Typography component="div" style={{ fontSize: "1.2em", margin: "20px 0" }}>You have not created links yet</Typography>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {(ROLES?.PAYMENT_LINK_MODULE?.privileges?.ADD_PAYMENT_LINK ?? false) && <SimpleButton onClick={() => { navigate("/dashboard/payment/create"); }}>Create a Link</SimpleButton>}
                    </div>
                </div>}
        </div>
    );
}