import React from 'react';
import { Typography } from '@mui/material';
import useStyles from './styles';

export interface IIndicatorProps {
  className?:string,
  style?:Record<string,any>
  logo:any
  logoStyle?:Record<string,any>
  title:string
  titleStyle?:Record<string,any>
  indicate?:boolean
  onClick?: any
}

export default function Indicator (props: IIndicatorProps) {

  const classes = useStyles().classes;

  const { style = {}, logo = null, title = "", logoStyle = {}, titleStyle = {}, indicate = false, onClick = ()=>void(0) } = props;

  return (
    <button className={`${classes.ind} ${indicate ? "indicate" : ""}`} style={style} onClick={onClick}>
      <div className={classes.logoW} style={logoStyle}>
        {logo}
      </div>
      <Typography style={titleStyle} className={classes.indsTitle}>
        {title}
      </Typography>
    </button>
  );
}
