import { forwardRef, memo } from "react";
import { useStyles } from "./styles";
import React from "react";
import { Typography } from "@mui/material";

const CustomCheckbox = forwardRef((props: any, ref) => {
  const { className, ...rest } = props;
  const styles = useStyles().classes;
  return (
    // @ts-ignore
    <label ref={ref} htmlFor={props.id} className={`${styles.main} radio-option-box101 ${className}`}>
      <div>
        <input type="radio" name="optionlisting" {...rest} id={props.id} className={'radio-option-input101'} />
        <Typography component={"span"} className={styles.title} >{props.title}</Typography>
        <Typography component={"div"} className={styles.text1} >{props.txt1}</Typography>
        <Typography component={"div"} className={styles.text2} >{props.txt2}</Typography>
      </div>
    </label>
  )
})

export default memo(CustomCheckbox);