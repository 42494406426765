import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import React, { useState, memo, useCallback, useEffect } from "react";
import Button from "../../components/button";
import TextField from "../../components/textField";
import theme from "../../resource/theme";
import useStyles from "./styles";
import resetIcon from "../../assets/images/password-reset.png";
import { Icon } from "@iconify/react";
import { pageRoute } from ".";
import { RequestResetPassword } from "../../services/pinVerification";
import { error, isValidEmail, setAppStorage } from "../../utilities/appHelpers";
import { NetworkError } from "../../utilities/constant";
import { messasgeType, useGlobalState } from "../../pages/dashboard";

export interface IResetPassword {
  onPreviousPage: any,
  isFormClosing: boolean,
  onNextPage: any
}

function ResetPassword({onPreviousPage,isFormClosing=false,onNextPage}:IResetPassword){
    const classes = useStyles().classes;
    const [hint, setHint] = useState<Record<string,any>>({
      message:'', 
      title:'',
      show:false,icon:messasgeType.default
    }),[emailValue, setEmailValue]=useState(''),
    [buttonState, setButtonState]=useState({
        text:'SEND RESET PASSWORD',
        disabled:true,
        loading:false,
        icon:''
    });
    const [hintState,setHintState] = useGlobalState("hint"),[errorCount,setErrorCount]=useState(0);


    const processCode = useCallback(async()=>{
      if(emailValue.trim().length>0){
        if(!isValidEmail(emailValue)){
          setHint({...hint,show:true, message:'Invalid email'});
          setErrorCount((n)=>n+1);
          return;
        }

      setButtonState({...buttonState,loading:true, disabled:true,text:"VALIDATING..." });
        try {
          const resp = await RequestResetPassword({email:emailValue});
          if(resp.status===200){
            setAppStorage('uid',resp?.data?.data?.uniqueId);
            // to move to the next page
            onNextPage && onNextPage(pageRoute.createNewPassword);
          }
          
        } catch (e) {
          setHint({...hint,show:true, message:error.handle(e,NetworkError,true)});
          setErrorCount((n)=>n+1);
        }
        finally{
          setButtonState({...buttonState,loading:false, disabled:false,text:"SEND RESET PASSWORD"});

        }
      }
   

    },[emailValue])


   useEffect(()=>{
    if(emailValue.trim().length>0){
      setButtonState({...buttonState,loading:false, disabled:false });
      
    }
    },[emailValue])

   useEffect(()=>{
    if(emailValue.trim().length>6){
      setHint({...hint,loading:false, disabled:false});
    }
    },[emailValue])

    useEffect(()=>{
      setHintState({...hintState,status:hint.show, msg:hint.message, icon:hint.icon});      
    },[errorCount])


return(

    <div
      className={`${classes.loginForm} `}
    >
      <Typography
        className={`${classes.title}`}
        style={{ color: theme.palette.primary.main }}
      >Password Reset</Typography>
      <div className={`reset-icon`}>
        <img
          className={``}
          src={resetIcon}
          alt="DTECHPAY"
        />
        
      </div>
      <Typography className={`${classes.title} small fadeInUp animated delay-0-1s`} style={{marginBottom:10, marginTop:20, color:'grey'}}>
        Please enter your email address associaated with this account to request a password reset
      </Typography>

      <TextField className='inputbx fadeInUp animated delay-0-2s' placeholder={"Email address"} value={emailValue} onChange={(e:any)=>setEmailValue(e.target.value)} />


      <div className="cta-cont" style={{width:'100%'}}>
          <Button className={`fadeInUp animated delay-0-7s`} loading={buttonState.loading}
            text={buttonState.text} disabled={buttonState.disabled}
            icon={<FontAwesomeIcon icon={faUserLock} />}
            style={{ marginTop: "18px" ,width:'100%'}}
            onClick={processCode}
            />
        </div>

        <div style={{userSelect:'none', textAlign:'center', marginTop:20}} className="fadeInUp animated delay-0-8s dtp-signup-tc-login">Remembered your password? <button className="link" style={{fontSize:'1rem'}} onClick={onPreviousPage} >Login</button></div>
    </div>

);
}

export default memo(ResetPassword)