import { Typography } from "@mui/material";
import useStyles from "./styles";
import image from '../../images/seal.svg';
import React from "react";

export interface IFinishPageProps {}

export default function FinishPage(props: IFinishPageProps) {
  const classes = useStyles().classes;
  return (
    <div className={classes.main}>
      <div className={classes.illus}>
        <img src={image} alt="" />
      </div>
      <Typography className={`${classes.finishHeader}`}>
        Setup Complete
      </Typography>
      <Typography className={`${classes.finishText}`}>
        Kindly proceed to your dashoard
      </Typography>
    </div>
  );
}
