import * as React from "react";
import Spinner from "../spinner";
import { useStyles } from "./styles";

const InputLoader = ({ size = 20, icon, status=false, loading = true, show=true }: any) => {
  const style = useStyles().classes;
  return (
    <div className={style.cont}>
      <Spinner size={size} show={show && loading} />

     { !loading && show && <div className={`icon ${status?'success':'error'}`}>{icon}</div>}

    </div>
  );
};

export default React.memo(InputLoader);
