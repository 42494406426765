import { Typography } from "@mui/material";
import error400 from "./images/400Error.png";
import { useStyles } from "./styles";
import React from "react";
export const BadRequest = () => {
  const styles = useStyles().classes;
  return (
    <div className={styles.cont}>
      <div className={`${styles.imageCont}`}>
        <img src={error400} alt="Dtechpay -  Bad Request"/>
      </div>
      <Typography component='div' className={`${styles.infoCont}`}>
         <h1>We are sorry...</h1>
          <p>Your Payment reference cannot be resolved or not valid. <br/>
            Please refer to your system adminstrator 
          </p>
      </Typography>
    </div>
  );
};
