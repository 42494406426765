import { makeStyles } from 'tss-react/mui';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((themes)=>({
    cont:{
        width:"100%", borderBottom:"2px solid lightgrey", position: "relative", marginBottom:30,
        backgroundColor:"#fff",transition:"border 0.3s ease", display:"flex", padding:"0 10px",
        
        "& input:focus &":{
            borderColor: "blue",
        },
        "& label":{
            position:"absolute",top:"50%", left:15, transition:"all 300ms ease", fontSize: "1rem", transform:"translateY(-50%)", color:"grey", userSelect:"none", cursor:"text"
        },
        "& .icon":{
            minWidth:1, width:"max-content", maxWidth:20,
        },
        "& input:focus":{ outline:"none",border:"none"},

        "& input":{
            width:"calc(100% - 20px)", padding:"15px 0 5px 0", backgroundColor:"transparent",border:"none",
        },   

        "& input:focus ~ label, & input:valid ~ label":{
            top:0,left:10,fontSize: "0.8rem", color:themes.palette.secondary.main
        },
         
    },

    active:{
        borderColor: themes.palette.secondary.main
    },
    error:{
        borderColor:'#fe5151 !important',
        "& .err-msg":{
            position:'absolute', display:'block !important',fontSize:'0.8rem', color:'#fe5151', bottom:-30
        }
    },
    noError:{
        "& .err-msg":{
            display:'none',
        }
    },

    icon:{"&":{display:'flex',overflow:'hidden',width:'20%'},
        "& img":{width:"calc(100% - 50%)",height:'auto'}

    }


}));