import { alpha, Theme } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  main: {
    width: "auto",
    height: "25px",
    display: "flex",
    padding: "8px",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",
    border: `1px solid ${alpha(theme.palette.background.default, 0.7)}`,
    borderRadius: "8px",
    cursor: "pointer",
    boxSizing: "border-box",
    color: "#555",
    transition: "0.3s ease",
    position: "relative",
    "& *":{
      boxSizing: "inherit",
    },
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.default,0.7),
    },
    "& svg": {
      height: "12px",
    }
  },
  dropIcon:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  optionsWrapper: {
    width: "max-content",
    position: "absolute",
    height: "max-content",
    backgroundColor: "#fff",
    zIndex:100
  },
  hide:{
    display: "none"
  },
  option: {
    border: "none",
    display: "block",
    padding: "5px 15px",
    width: "100%",
    backgroundColor: "transparent",
    transition: "all 0.1s ease-in",
    cursor: "pointer",
    fontFamily: 'Raleway, Montserrat, Source Sans Pro, sans-serif',
    textAlign: "left",
    "&:hover":{
      backgroundColor: alpha(theme.palette.secondary.light, 0.5),
      transition: "all 0.3s ease-in"
    }
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
    transition: "all 0.3s ease-in"
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;