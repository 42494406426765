import { Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import Table, { Status, DescBox, InfoBox } from '../../../../components/designerTable';
import TextImgBox from '../../../../components/textImgBox';
import ArrowedList, { ListItem } from '../../../dashboard/components/ArrowListItem';
import personImg from '../../../../assets/images/person-icon.png';
import useStyles from './../styles';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import SmallDropBtn, {Option} from '../../../../components/smallDropBtn';
import React from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['School Fee', 'Cable', 'Transfers'],
  datasets: [
    {
      label: '# of Votes',
      data: [300000, 190000, 1000000],
      backgroundColor: [
        'rgba(255, 99, 132, 0.5)',
        'rgba(52, 235, 73, 0.5)',
        'rgba(255, 206, 86, 0.5)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(52, 235, 73, 1)',
        'rgba(255, 206, 86, 1)'
      ],
      borderWidth: 1,
    },
  ],
};

export interface IABPHomeProps {
}

export default function ABPHome (props: IABPHomeProps) {
  const classes = useStyles().classes;

  const topP = [
    {cusName: "Adams Israel", accType: "School Fee", icon: <Icon icon="fluent:payment-20-regular" width="16" height="16" />, amount: "30000.00", date: "09-Jun-2022"},
    {cusName: "Mary John", accType: "School Fee", icon: <Icon icon="fluent:payment-20-regular" width="16" height="16" />, amount: "2500.00", date: "09-Jun-2022"},
    {cusName: "Adams Israel", accType: "SUG Fee", icon: <Icon icon="fluent:payment-20-regular" width="16" height="16" />, amount: "2000.00", date: "09-Jun-2022"},
    {cusName: "James Randon", accType: "School Fee", icon: <Icon icon="fluent:payment-20-regular" width="16" height="16" />, amount: "100000.00", date: "09-Jun-2022"},
    {cusName: "Mary John", accType: "SUG Fee", icon: <Icon icon="fluent:payment-20-regular" width="16" height="16" />, amount: "300.00", date: "09-Jun-2022"},
  ]
  
  return (
    <div className="fadeInUp animated faster">
      <div className={`${classes.twoCol} ${classes.topStats}`} style={{borderBottom: "1px solid #00000020"}}>
          <div className={classes.leftCol}>
            <div style={{width:350,height:350}}>
              <Pie data={data} />
            </div>
            <Typography component="div" style={{display: "flex",alignItems: "center", margin: "10px 0"}}>Filter By: 
            <SmallDropBtn text={<div style={{padding: "0 10px"}}>Date</div>}>
              <Option>Monthly</Option>
              <Option>Bi-Annual</Option>
              <Option>Annual</Option>
              <Option>Custom</Option>
            </SmallDropBtn>
          </Typography>
          </div>
          <div className={classes.rightCol}>
            <Typography component="h3" className={`${classes.headerMd}`} style={{marginBottom: 10}}>Top Payments</Typography>
            <div>
              <ArrowedList>
                {topP.map((P:any, index)=>{
                  return (
                    <ListItem key={index} title={
                      <div className={classes.twoCol}>
                        <div className={classes.leftCol}>
                          <div>{P.cusName}</div>
                          <div style={{fontWeight:600}}>{P.accType}</div>
                        </div>
                        <div className={classes.rightCol}>
                          <div>{P.amount}</div>
                          <div style={{fontWeight:600}}>{P.date}</div>
                        </div>
                      </div>
                    } icon={P.icon} showArrow={false} style={{textAlign: "left"}}/>
                  )
                })}
              </ArrowedList>
            </div>
          </div>
        </div>
        <div style={{ margin: "40px 0"}}>
          <Typography component="h3" className={`${classes.headerMd}`} style={{ margin: "10px 0 20px" }}>Reports</Typography>
          <div style={{alignItems: "flex-start", margin: "10px 0"}}>
            <div className={classes.twoCol}>
              <div className={classes.leftCol}>
                <div className={classes.searchBox}>
                  <Icon icon="akar-icons:search" width="16" height="16" />
                  <input type="search" name="" id="" />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 20px" }} className={classes.rightCol}>
                <Typography component="div" style={{ display: "flex", alignItems: "center" }}>Filter By:
                  <SmallDropBtn text={<div style={{ padding: "0 10px" }}>Account Type</div>}>
                    <Option>School Fee</Option>
                    <Option>SUG Fee</Option>
                  </SmallDropBtn>
                </Typography>
                <Typography component="div" style={{ display: "flex", alignItems: "center" }}>Filter By:
                  <SmallDropBtn text={<div style={{ padding: "0 10px" }}>Date</div>}>
                    <Option>Monthly</Option>
                    <Option>Bi-Annual</Option>
                    <Option>Annual</Option>
                    <Option>Custom</Option>
                  </SmallDropBtn>
                </Typography>
              </div>
            </div>
          
          </div>
          <div>
            <Table
              fields={[
                { name: "Transaction", size: 25 },
                { name: "Amount", size: 15 },
                { name: "Status", size: 15 },
                { name: "Customer", size: 30 },
                { name: "Date", size: 15 },
              ]}
              records={[
                {
                  transaction: "School Fee", amount: "200000.00", status: <Status status={false}>Pending</Status>, customer: <TextImgBox imgSrc={personImg} imgSize="30px">Adam Israel</TextImgBox>, date: "13-Jun-2023", controls: { isDropdown: true, incoming: false },
                  details: (
                    <div style={{ display: "flex", padding: "5px 0" }}>
                      <div style={{ width: "40%", paddingRight: 20 }}>
                        <DescBox title='Transaction Type' descr='School Fee' style={{ margin: "10px 0" }} descrStyle={{ fontSize: "1.5em" }} />
                        <InfoBox>
                          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Pariatur amet veniam, totam iusto incidunt est? Consequatur labore nobis cumque similique!
                        </InfoBox>
                      </div>
                      {/* <div style={{ width: "20%" }}>
                        <DescBox title='Status' descr='Completed' style={{ margin: "10px 0" }} />
                        <DescBox title='Transaction No.' descr='RE56RYUREHJ' style={{ margin: "10px 0" }} />
                      </div> */}
                      <div style={{ width: "20%", borderRight: "1px solid #00000050" }}>
                        <DescBox title='Date Initialized' descr='June 09, 2020' style={{ margin: "10px 0" }} />
                        <DescBox title='Date Completed' descr='June 09, 2020' style={{ margin: "10px 0" }} />
                      </div>
                      <div style={{ width: "20%", textAlign: "right" }}>
                        <DescBox title='Amount' descr='$250.00' style={{ margin: "10px 0" }} descrStyle={{ fontSize: "1.5em" }} />
                        <DescBox title='Charges' descr='$2.5' style={{ margin: "10px 0" }} descrStyle={{ fontSize: "1.5em" }} />
                      </div>
                    </div>
                  )
                },
                {
                  transaction: "SUG Fee", amount: "200000.00", status: <Status status={true}>Completed</Status>, customer: <TextImgBox imgSrc={personImg} imgSize="30px">Mary John</TextImgBox>, date: "13-Jun-2023", controls: { isDropdown: true, incoming: true },
                  details: (
                    <div style={{ display: "flex", padding: "5px 0" }}>
                      <div style={{ width: "40%", paddingRight: 20 }}>
                        <DescBox title='Transaction Type' descr='Fund Wallet' style={{ margin: "10px 0" }} descrStyle={{ fontSize: "1.5em" }} />
                        <InfoBox>
                          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Pariatur amet veniam, totam iusto incidunt est? Consequatur labore nobis cumque similique!
                        </InfoBox>
                      </div>
                      {/* <div style={{ width: "20%" }}>
                        <DescBox title='Status' descr='Completed' style={{ margin: "10px 0" }} />
                        <DescBox title='Transaction No.' descr='RE56RYUREHJ' style={{ margin: "10px 0" }} />
                      </div> */}
                      <div style={{ width: "20%", borderRight: "1px solid #00000050" }}>
                        <DescBox title='Date Initialized' descr='June 09, 2020' style={{ margin: "10px 0" }} />
                        <DescBox title='Date Completed' descr='June 09, 2020' style={{ margin: "10px 0" }} />
                      </div>
                      <div style={{ width: "20%", textAlign: "right" }}>
                        <DescBox title='Amount' descr='$250.00' style={{ margin: "10px 0" }} descrStyle={{ fontSize: "1.5em" }} />
                        <DescBox title='Charges' descr='$5.00' style={{ margin: "10px 0" }} descrStyle={{ fontSize: "1.5em" }} />
                      </div>
                    </div>
                  )
                }
              ]}
            />

            
          </div>
        </div>
    </div>
  );
}
