import { Typography } from '@mui/material';
import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import OptionChecker, { CheckerBtn } from '../../components/optionChecker';
import ReturnBar from '../../components/returnBar';
import useStyles from './styles';
import Table, { DescBox, InfoBox, Status } from '../../../../components/designerTable';
import { commarize, currency, error, formatDateWithMonthName } from '../../../../utilities/appHelpers';
import { getAllTransactions } from '../../../../services/transaction';
import { STable } from '../../../../components/skeletonLoader';
import { SessionCheck, dispatch } from '../../../../pages/dashboard';
import usePagination, { usePaginationInterface } from '../../../../customHooks/pagination';
import { paginationData } from '../../../../utilities/types';
import { defaultPageData, PaginationControl } from '../../../../components/PaginationControl';

export interface ITransactionModProps {
  className?:string,
  style?:Record<string, any>,
}

export default function TransactionMod (props: ITransactionModProps) {
  const classes = useStyles().classes;
  const { className = "", style = {}} = props;
  const navigate = useNavigate();
  const toDashboard = ()=>{
    navigate("/dashboard");
  }
  const [switchNum, setSwitchNum] = useState(0);
  const [filter, setFilter] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [errorHandler, setErrorHandler] = useState({status:false, msg:"",count:0});
  const [transRes, setTransRes] = useState<any>([]);
  const [pageData, setPageData] = useState<paginationData>(defaultPageData);
  const pagination:usePaginationInterface = usePagination(pageData);
  const [controlPageData, setControlPageData] = useState<paginationData>(defaultPageData)

    //Get all transactions
    useEffect(()=>{
      (async()=>{
        try {
          setLoading(true);
          const resp = await getAllTransactions( controlPageData );
          if(resp.status ===200){
            setLoading(false);
            const transPag = resp.data.data.pagination;
            setTransRes(resp.data.data.transactions);
            setPageData({pageNumber:Number(transPag['current_page']), pageSize:Number(transPag['page_size']), totalRecords:Number(transPag['total_items'] || 0)});
          }
          
        } catch (e:any) {
          SessionCheck(e);

          setErrorHandler({...errorHandler, status:true,msg:error.handle(e,"You're offline. Please check your network and try again",true), count:errorHandler.count+1})
          setLoading(false);
        }
      })()

    },[ controlPageData ])
 
  

  return (
    <div className={`${className} ${classes.main}`} style={style}>
     
      <ReturnBar title='DashBoard' onClick={()=>{toDashboard()}}>
        {/* <button>filter</button> */}
      </ReturnBar>
      <div style={{margin:"20px 0 10px",display: 'flex', justifyContent: 'space-between'}}>
          <Typography component="h2" style={{ fontSize: 20, fontWeight: 600}}>Transactions</Typography>
          {pagination.totalRecords > pagination.pageSize ?
          <PaginationControl pageDataState={[controlPageData, setControlPageData]} pagination={pagination} />
        : null }
      </div>
      {/* <OptionSwitch>
        <SwitchBtn title='Today' className={`${switchNum === 0 ? "active" : ""}`}  style={{width:"33.3%"}} onClick={()=>{switchNum !== 0 && setSwitchNum(0)}}/>
        <SwitchBtn title='This Month' className={`${switchNum === 1 ? "active" : ""}`} style={{width:"33.3%"}} onClick={()=>{switchNum !== 1 && setSwitchNum(1)}}/>
        <SwitchBtn title='More Filters' className={`${switchNum === 2 ? "active" : ""}`} style={{width:"33.3%"}} onClick={()=>{switchNum !== 2 && setSwitchNum(2)}}/>
      </OptionSwitch> */}
      <div>
        {loading  &&  <STable style={{marginTop:20}}/>}
        {switchNum === 0 &&  !loading &&(
            <div style={{ margin: "20px 0" }}>
            <Table fields={[{ name: "Amount", size: 15 }, { name: "Status", size: 15 }, { name: "Type", size: 30 }, { name: "Date", size: 20 }, { name: "Payment Method", size: 20 }]}
              records= {transRes && transRes.map((td:any, i:number)=>{
                const status = td.status ===2 ?'Failed':td.status===1?'Completed':'Pending';
                return {amount:currency.naira + commarize(td.amt),status:<Status status={td.status < 1?false:true}>{status}</Status>,recipient:<span>{td.category}</span>,date: formatDateWithMonthName(td.finishDate),paymentmethod:" ", animate:`animated delay-0-${i} fadeInUp`, details: (
                  <div style={{display:"flex", padding: "5px 0"}}>
                    <div style={{width: "40%", paddingRight: 20}}>
                      <DescBox title='Transaction Type' descr={td.category} style={{margin:"10px 0"}} descrStyle={{fontSize: "1.5em"}}/>
                      <InfoBox>
                        {td.descr !== null&&td.descr}
                      </InfoBox>
                    </div>
                    <div style={{width: "20%"}}>
                      <DescBox title='Status' descr={status} style={{margin:"10px 0"}}/>
                      <DescBox title='Transaction No.' descr={td.refNo} style={{margin:"10px 0"}}/>
                    </div>
                    <div style={{width: "20%", borderRight: "1px solid #00000050"}}>
                      <DescBox title='Date Commenced' descr={formatDateWithMonthName(td.initDate)} style={{margin:"10px 0"}}/>
                      <DescBox title='Date Paid' descr={formatDateWithMonthName(td.finishDate)}  style={{margin:"10px 0"}}/>
                    </div>
                    <div style={{width: "20%", textAlign: "right"}}>
                      <DescBox title='Amount' descr={currency.naira + commarize(td.amt)} style={{margin:"10px 0"}} descrStyle={{fontSize: "1.5em"}} />
                    </div>
                  </div>
                ), controls: {onClick:()=>void(0), isDropdown:true, incoming:td?.flow>0?true:false}}
            })}/>
          </div>)}
        {switchNum === 1 && !loading &&(
          <div style={{ margin: "20px 0" }}>
          <Table fields={[{ name: "Amount", size: 15 }, { name: "Status", size: 15 }, { name: "Type", size: 30 }, { name: "Date", size: 20 }, { name: "Payment Method", size: 20 }]}
            records={transRes && transRes.map((td:any)=>{
              const status = td.status ===2 ?'Failed':td.status===1?'Completed':'Pending';

              return {amount:currency.naira + commarize(td.amt),status:<Status status={td.status < 1?false:true}>{status}</Status>,recipient:<span>{td.descr}</span>,date:formatDateWithMonthName(td.finishDate),paymentmethod:" ",details: (
                <div style={{display:"flex", padding: "5px 0"}}>
                  <div style={{width: "40%", paddingRight: 20}}>
                    <DescBox title='Transaction Type' descr={td.descr} style={{margin:"10px 0"}} descrStyle={{fontSize: "1.5em"}}/>
                    <InfoBox>
                      {td.descr === 'Registration Bonus'?'Your Wallet has been credited with 100.00. Thanks for Choosing Dtechpay':''}
                    </InfoBox>
                  </div>
                  <div style={{width: "20%"}}>
                    <DescBox title='Status' descr={status} style={{margin:"10px 0"}}/>
                    <DescBox title='Transaction No.' descr={td.refNo} style={{margin:"10px 0"}}/>
                  </div>
                  <div style={{width: "20%", borderRight: "1px solid #00000050"}}>
                    <DescBox title='Date Commenced' descr={formatDateWithMonthName(td.initDate)} style={{margin:"10px 0"}}/>
                    <DescBox title='Date Paid' descr={formatDateWithMonthName(td.finishDate)}  style={{margin:"10px 0"}}/>
                  </div>
                  <div style={{width: "20%", textAlign: "right"}}>
                    <DescBox title='Amount' descr={currency.naira + commarize(td.amt)} style={{margin:"10px 0"}} descrStyle={{fontSize: "1.5em"}} />
                  </div>
                </div>
              ), controls: {onClick:()=>void(0), isDropdown:true, incoming:'incoming',}}
          })} />
        </div>
        )}
        {switchNum === 2 &&  !loading && (
          <div style={{ margin: "20px 0" }}>
            <OptionChecker>
            <CheckerBtn title="This Week" icon={<Icon icon="arcticons:calendar-7" />} onClick={() => { filter !== 0 ? setFilter(0) : setFilter(-1); }} checked={filter === 0 ? true : false} />
            <CheckerBtn title="6 Months" icon={<Icon icon="system-uicons:calendar-month" />} onClick={() => { filter !== 1 ? setFilter(1) : setFilter(-1); }} checked={filter === 1 ? true : false} />
            <CheckerBtn title="This Year" icon={<Icon icon="iwwa:year" />} onClick={() => { filter !== 2 ? setFilter(2) : setFilter(-1); }} checked={filter === 2 ? true : false} />
            </OptionChecker>
          </div>
        )}
      </div>
    </div>
  );
}
