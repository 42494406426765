import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme)=>({
    mainBox:{
        cursor:'pointer',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingRight: '10px',
        paddingLeft: '10px',
        boxSizing:'border-box',
        borderRadius:'5px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        '&:disabled':{
            cursor:'not-allowed',boxShadow:'none',
        },
        '& .logo':{
            width:'40px',
            height:'40px',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          
            '& .progress-icon':{
                width:'40px',
                height:'40px',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                position:'relative',
                 /* '& svg':{
                    color:'#fff',
                    position:'absolute',
                },
                 '& :nth-child(2)':{
                    visibility:'hidden',
                    color:'#fff'
                }, */

            }
            
        },
        '& .text':{
           fontWeight:'600',
           position:'relative',
           color:'#fff',
           display:'flex',
           justifyContent:'center',
           alignItems:'center',
           paddingRight:'10px',
        //    marginLeft:'10px',

           '& .firstText, & .secondText':{
            position:'absolute',
           },

          

           '& .sizing':{
            visibility: 'hidden', 
           },
           '& .sizing .ttt':{

           }

        }
    },

    text:{
        '&.secondText, .showsecondtext &.firstText':{
            animation:'zoomOutShort 0.5s ease-in both'
        },
        '.showsecondtext &.secondText, &.firstText':{
            // visibility:'visible',
            animation:'zoomInShort2 0.6s 0.3s ease-out both'
        }
    },

    icon:{
        color:'#fff',
        position:'absolute',
        display:'flex',
        '&.second, .showsecond &.first':{
            animation:'zoomOut 0.5s ease-in both'
        },
        '.showsecond &.second, &.first':{
            // visibility:'visible',
            animation:'zoomIn2 0.6s 0.3s ease-out both'
        }
    },

    reverse:{
        display:'flex',
        flexDirection:'row-reverse',
   },

   brightenSpinner:{
    "& circle .path":{
        stroke:'#a3edf8',
    }
}
    
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;