import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { Typography } from '@mui/material';
import axiosInstance from '../../services/https';
import { loginWithLink } from '../../services/signup';
import { clearAppStore, clearItemStore, getAppStorage, setAppStorage } from '../../utilities/appHelpers';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import blue from "../welcomePageMod/congratimgs/blue.png" 
import bluesmall from "../welcomePageMod/congratimgs/bluesmall.png" 
import greenlong from "../welcomePageMod/congratimgs/greenlong.png" 
import purplelong from "../welcomePageMod/congratimgs/purplelong.png" 
import purplesmall from "../welcomePageMod/congratimgs/purplesmall.png" 
import purplesmallsmall from "../welcomePageMod/congratimgs/purplesmallsmall.png" 
import redlong from "../welcomePageMod/congratimgs/redlong.png" 
import redsmall from "../welcomePageMod/congratimgs/redsmall.png" 
import redsmallsmall from "../welcomePageMod/congratimgs/redsmallsmall.png" 
import yellowlong from "../welcomePageMod/congratimgs/yellowlong.png" 
import yellowsmall from "../welcomePageMod/congratimgs/yellowsmall.png" 
import login2 from "../welcomePageMod/congratimgs/login2.png" 
import login from "../welcomePageMod/congratimgs/login.png" 

const VerifyEmailPage = () => {
  const [verificationStatus, setVerificationStatus] = useState('Verifying...');
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles().classes;
  const navigator = useNavigate();
  const location = useLocation();
//   const {token, uid} = useParams();
// console.log(token);

  useEffect(() => {
    // Simulate email verification process with a timeout
    const searchParams = new URLSearchParams(location.search);
    
    // Access individual query parameters
    const token = searchParams.get('token');
    const id = searchParams.get('uid');
    setTimeout(() => {
      // Replace this with your actual verification logic
      // const isEmailVerified = true; // Replace with your actual verification logic result

      // if (isEmailVerified) {
      //   setVerificationStatus('Email Verified!');
      //   setIsLoading(false);
      // } else {
      //   setVerificationStatus('Email verification failed. Please try again.');
      //   setIsLoading(false);
      // }
      if (token && id) {
        verifyEmail(token, id);
      } else {
        setVerificationStatus('Email verification failed. Please try again.');
        // setIsLoading(false);
        const timeout = setTimeout(()=>{
          clearItemStore();
          clearAppStore();
          navigator('/');
          clearTimeout(timeout)
        }, 1000);
      }
    }, 1000); // Simulated 3-second verification process
  }, [location.search]);

  const loadDashboard = (data:any)=>{
    navigator('/dashboard', {state:data})
  }

  const checkError = (res:any) => {
    if (res.data.data?.status === null || res.data.data?.status === undefined) {
      setVerificationStatus('Email verification failed. ' + res?.data?.message);
      const timeout = setTimeout(() => {
        clearItemStore();
        clearAppStore();
        navigator('/');
        clearTimeout(timeout)
      }, 1000);
    } else {
      setVerificationStatus(res?.data?.message);
      const timeout = setTimeout(() => {
        clearItemStore();
        clearAppStore();
        navigator('/');
        clearTimeout(timeout)
      }, 1000);
    }
  }

  const verifyEmail = async (token: string, uid: string) => {
    try {
      const res = await loginWithLink({ token, id: uid })
      if (res.status === 200) {
        setVerificationStatus('Email verified!');

        const timeout = setTimeout(()=>{
          setAppStorage("DTECHPAY_TOKEN",res?.data?.data?.token);
          setAppStorage('DTECHPAY_USERID',res?.data?.data?.uniqueId);  
          setAppStorage('USER_DETAILS',res?.data?.data);    
          loadDashboard(res?.data?.data);
          setAppStorage('sessionExp',false);
          clearTimeout(timeout)
        }, 1000);
      } else {
        checkError(res);
      }
    } catch (error:any) {
      checkError(error?.response)
    }
  }

  return (
    <Typography className={classes.main}>
      {isLoading && (
        <div className="loading-spinner" />
      )}
      <div className="verification-status">
        {verificationStatus === 'Email Verified!' && (
          <div className="dtp-welc-anim-bx">
          <img src={blue} style={{width: "80px"}} className="dtp-pop-ribons ml5"/>
          <img src={bluesmall} style={{width: "8px"}} className="dtp-pop-ribons ml6"/>
          <img src={greenlong} style={{width: "30px"}} className="dtp-pop-ribons ml2"/>
          <img src={purplelong} style={{width: "60px"}} className="dtp-pop-ribons ml7"/>
          <img src={ purplesmall } style={{width: "13px"}} className="dtp-pop-ribons ml8"/>
          <img src={ purplesmallsmall } style={{width: "10px"}} className="dtp-pop-ribons ml9"/>
          <img src={ redlong } style={{width: "90px" }}className="dtp-pop-ribons ml4"/>
          <img src={ redsmall } style={{width: "8px"}} className="dtp-pop-ribons ml10"/>
          <img src={ redsmallsmall } style={{width: "7px"}} className="dtp-pop-ribons ml11"/>
          <img  src={ yellowlong } style={{width: "60px" }}className="dtp-pop-ribons ml1"/>
          <img src={ yellowsmall } style={{width: "10px"}} className="dtp-pop-ribons ml3"/>
          <img src={login2} className="dtp-dashboard-welimg1 dtp-dashboard-welimg" alt="DTECHPAY"/>
          <img src={login} className="dtp-dashboard-welimg2 dtp-dashboard-welimg scaleuser" alt="DTECHPAY"/>
      </div>
        )}
        <p>{verificationStatus}</p>
        {/* You can add additional content or redirection logic here */}
      </div>
    </Typography>
  );
};

export default VerifyEmailPage;
