import { alpha, lighten, Theme } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void, 'indicator' | 'gradient' | 'text'>()((theme: Theme, _params, classes) => ({
  main: {
    width: "100%",
    maxWidth: 250,
    backgroundColor: lighten(theme.palette.primary.main, 0.12),
    height: "calc(100vh - 60px)", position:"sticky", top:60,
    zIndex: 400,
    boxSizing: "border-box",
    transition: "all 0.2s ease",
    "& *": {
      boxSizing: "inherit",
      color: theme.palette.secondary.contrastText
    },
    [theme.breakpoints.down('md')]: {
      width:'auto', position: "absolute",top:0, 
    },
    // [theme.breakpoints.up('xl')]: {
    //   maxWidth: '500px'
    // }
  },
  
  item: {
    width: "100%",
    height: "40px",
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    cursor: "pointer",
    // margin: "10px 0",
    "& *": {
      boxSizing: "inherit",
    },
    [`&.active .${classes.indicator}`]: {
      backgroundColor: theme.palette.background.paper,
      transition: "0.3s ease-in"
    },
    [`&.active .${classes.gradient}, &:hover .${classes.gradient}`]: {
      left:0,
      transition: "left 0.3s linear"
    },
    [`&.active .${classes.text}, &:hover .${classes.text}`]: {
      fontWeight: 600,
      transition: "0.5s ease-in"
    }
  },
  gradient: {
    width: "100%",
    height: "100%",
    backgroundImage: `linear-gradient(90deg, transparent 30%, ${alpha(theme.palette.background.paper, 0.7)})`,
    position: "absolute",
    left: "100%",
    transition: "left 0.4s ease-out"
  },
  textLogoBox: {
    width: "100%",
    height: "100%",
    padding: "0 10px",
    [theme.breakpoints.up('md')]: {
      padding: "0 0 0 40px",
    }
  },
  text: {
    fontSize: 13, 
  },
  indicator: {
    backgroundColor: "transparent",
    width: 3,
    position: "absolute",
    left: 0,
    top: 5,
    bottom: 5,
    transition: "0.5s ease-out"
  },
  // [theme.breakpoints.down('sm')]: {
    hide:{
      transform:'translateX(-100%)'
    },
    show:{
      transform:'translateX(0%)'
    },
  // },

  
  cover:{
    width:'100%', height:'calc(100vh - 60px)', position:'absolute', top:0, left:0, zIndex:5, backgroundColor: "#ffffffad", backdropFilter: "blur(0.2rem)",
    [theme.breakpoints.up('md')]: {
      backgroundColor: "#ffffff00",
      backdropFilter: "blur(0)"
    }
  }

}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;