import { LINKS, GATEWAY, PROCESS, UPDATE, ACCESS } from './../../utilities/constant';
import axiosInstance from '../https';
import { paginationData } from '../../utilities/types';


export const createLink = (data: Record<string, any>) => axiosInstance.post(`${LINKS}/create`, data);

export const processPayer = (data: Record<string, any>) => axiosInstance.post(`${LINKS}/${PROCESS}`, data);

export const getLinks = (pagination:paginationData | null, name?: string) => axiosInstance.get(`${LINKS}/fetch?${pagination?.pageNumber? 'pageNumber='+pagination?.pageNumber: ''}${pagination?.pageSize? '&pageSize='+pagination?.pageSize: ''}${name ? pagination? "&name=" : 'name=' + name : ''}`);

export const getLinkById = (uid:any) => axiosInstance.get(`${LINKS}/${uid}`);

export const getLinkByurlPath = (url:any) => axiosInstance.get(`${LINKS}/path/${url}`);

export const getLinkStats = (uid:any) => axiosInstance.get(`${LINKS}/${uid}/stats`);

export const getLinkAccess = (uid:any) => axiosInstance.get(`${LINKS}/${uid}/${ACCESS}`);

export const getLinkAccessById = (lid: any, uid: any) => axiosInstance.get(`${LINKS}/${lid}/${ACCESS}/${uid}`);

export const editLink = (data: Record<string, any>, uid: any) => axiosInstance.patch(`${LINKS}/${uid}/${UPDATE}`, data);

export const deleteLink = (uid: any) => axiosInstance.delete(`${LINKS}/${uid}/delete`);