import { Icon } from "@iconify/react";
import Button from "../button";
import { css } from "@emotion/css";
import { ContentLoader } from "../../modules/dashboard";
import { useCallback, useEffect, useRef, useState } from "react";


export function Aside({children, button=null,close=true,loading=false, onClose=()=>void(0)}:any){
const cont = useRef<HTMLDivElement | any >();
const classes = css({
    width:500, height:'calc(100%)', marginRight:'-40px', borderRadius:'20px 0 0 20px', backgroundColor:'#fff', boxShadow:'-4px 6px 15px rgba(0, 0, 0, 0.09)',
    overflow:'hidden',
    ".closebtn":{
        background:'#00000000',
        border:'none',
        display:'flex', justifyContent:'center',alignItems:'center', transition:'background 0.2s ease-in-out',borderRadius:'4px', cursor:'pointer',
        //  padding:'8px 16px',
        ":hover":{
            backgroundColor:'#f2f2f2'
        }
    },
    ".contents":{
        // paddingLeft:20
        overflowY:'scroll', height:'100%'
    }
    
});
const [anim,setAnim]=useState('slideInRight');

const Close = useCallback(()=>{
   setAnim('slideOutRight');
    let time = setTimeout(()=>{
        cont.current.remove();
    },100);
    onClose && onClose(close);
   return ()=> clearTimeout(time);

},[close])

    return (
    <div className={`${classes} animated faster ${anim}`} ref={cont}>
          <div style={{height:60, width:'100%', borderBottom:'thin solid #f2f2f2', padding:'0 12px', display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <button onClick={Close} className='closebtn' title='Close'><Icon icon={'line-md:chevron-right'} fontSize={25} /></button>
            {(button) ?button:
            <Button spinnerSize={'small'} style={{padding:0}} text={'Save'} icon={<Icon icon='fluent:save-24-filled' />}loading={true}/>
            }
          </div>
            <div className="contents" style={{padding:'10px 10px 10px 30px'}}><ContentLoader show={loading}/>{children}</div>

      </div>)
}