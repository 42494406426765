import { Typography } from '@mui/material';
import useStyles from './styles';
import React from "react";
export interface IOptionSwitchProps {
  className?:string,
  style?:Record<string, any>,
  children?:any
}

export default function OptionSwitch (props: IOptionSwitchProps) {
  const classes = useStyles().classes;
  const { className = "", style = {}} = props;
  return (
    <div className={`${className} ${classes.main}`} style={style}>
      {props.children}
    </div>
  );
}

export interface ISwitchBtnProps {
  className?:string,
  style?:Record<string, any>,
  title:string,
  onClick?:any
}

export function SwitchBtn (props: ISwitchBtnProps) {
  const classes = useStyles().classes;
  const { className = "", style = {}, title="", onClick=null} = props;
  return (
    <button className={`${className} ${classes.btn}`} style={style} onClick={onClick}>
      <Typography component='span'>{title}</Typography>
    </button>
  );
}