import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  main: {"&":{display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column",},
    width: 250,
    height: 175,
    borderRadius: 10,
    boxSizing: "border-box",
    position:"relative",
    padding: 16,
    overflow: "hidden",
    fontSize: "1.2rem", backgroundColor:"#fff", cursor:"pointer", flexBasis:'700px',
    "& *": {
    boxSizing: "inherit",
    },
    "& p":{
      color:"grey",
    },
    [theme.breakpoints.down('sm')]:{
      width: 220,height: 120,
    }

  },
  iconCont:{
    "&":{display:"flex",justifyContent: "center", alignItems: "center",},
    width:50,height:50,borderRadius:"50%", backgroundColor:"#c4d0ee85", color:theme.palette.primary.main,
    "& *":{fontWeight:'700',fontSize:"1.5rem"},
    
  }
  
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
