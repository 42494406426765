import * as React from 'react';
import useStyles from './styles';
import image from '../../images/options-illus.svg';

export interface IAccountPageProps {
  children:any
}

export default function AccountPage (props: IAccountPageProps) {
  const classes = useStyles().classes;
  return (
    <div className={classes.main}>
      <div className={classes.illus}>
        <img src={image} alt="" />
      </div>
      <div className={`${classes.accountPage}`}>{props.children}</div>
    </div>
  );
}
