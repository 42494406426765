import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "../button";
import GatewayInputfield from "../GatewayInputfield";
import mastercard from "./images/icons8-mastercard-logo-color/icons8-mastercard-logo-48.svg"
import visa from "./images/icons8-visa-color/icons8-visa-48.svg";
import verve from "./images/verve.jpg";
import { useStyles } from "./styles";
import { faArrowRight, faPlus} from "@fortawesome/free-solid-svg-icons";
import { getCurrentMonth, getCurrentYear, insertTextSeperator, isMasterCardIIN, isValidMonth, isVerveIIN, isVisaIIN, isVisaElectronIIN, isVisaCard, isMasterCard, isVerveCard, isVisaElectronCard, isValidCard } from "../../utilities/appHelpers";
import Transfer from "./Transfer";

type IView = "card" | "transfer";

export const CardDetail=(props:any)=>{
    
const styles = useStyles().classes;
const [icon4, setIcon4] = useState(< FontAwesomeIcon icon={faArrowRight}/>);
const [inputValue, setInputValue] = useState({cardNum:'',cvv:'',expDate:'',name:''})


enum cardType{
    nil=0,
    mastercard=1,
    visa=2,
    VisaElectron=3,
    verve=4
    
}
const [card, setCard] = useState<Record <string,any>>({logo:'',type:cardType.nil,maxLength:19, length:19})

const [cardNumError, setCardNumError] = useState({status:false,msg:''});
const [cvvError, setCvvError] = useState({status:false,msg:''});
const [expDateError, setExpDateError] = useState({status:false,msg:''});
const [isOk, setIsOk]=useState(false)
const [cardDetails, setCardDetails] = useState({cardNum:'',expDate:'',cvv:'',type:''});

const cardNumRef = useRef<HTMLInputElement>(null), cardExpRef = useRef<HTMLInputElement>(null), cardCvvRef = useRef<HTMLInputElement>(null);


const handleInput = useCallback((e:any,key:Record<string,number>)=>{
    // field:1 = cardNumberfield
    // field:2 = exprDate
    // field:3 = cvv
    const rawValue = e.target.value.replaceAll(new RegExp(/\/|_|\s/g,'g'),'') // remove all separator characters from input.
    if(!/^[\d\s]+$/g.test(rawValue) && e.nativeEvent.inputType === "insertText")return;
    const lng = rawValue.length;
    let inpFld:string, opt = {};
    if((key.field===1 && lng > card.length) || key.field===2 && lng>4|| (key.field ===3 && lng > 3)) return;
    
        switch (key.field) {
            case 1:
            inpFld = 'cardNum';
           opt= {separator:' ',inPosition:4};
            setCardDetails({...cardDetails,cardNum:rawValue})
        

           // verve
           if(isVerveIIN(rawValue)){ 

            setCard({...card,logo:verve, type:cardType.verve,maxLength:19});

            // master
            }else if(isMasterCardIIN(rawValue)){
            setCard({...card,logo:mastercard, type:cardType.mastercard,maxLength:16});
            
            // visa & VisaElectron
           }else if(isVisaIIN(rawValue) || isVisaElectronIIN(rawValue)){ 
            setCard({...card,logo:visa, type:cardType.visa, maxLength:16});

           }else{
            setCard({...card,logo:'',type:cardType.nil});
           }
                
                break;
            case 2:
            inpFld = 'expDate';
            setCardDetails({...cardDetails,expDate:e.target.value})
            opt ={separator:'/',inPosition:2};
                
                break;
            case 3:
            inpFld = 'cvv';
            setCardDetails({...cardDetails,cvv:rawValue})
            opt ={separator:' ',inPosition:1};
            
                break;
                
            default:
            inpFld = 'name';
            break;
        }
    if(key.field===2){
        if(lng===1 && rawValue>1){
            e.target.value = '0'+rawValue.slice(0,2);
        }

        if(lng>1 && !isValidMonth(rawValue.slice(0,2))){ return

        }

        if((lng>3 && rawValue.slice(2) < getCurrentYear(false)) || rawValue.slice(2) > +getCurrentYear(false)+20){
            setExpDateError({...expDateError,status:true,msg:'Invalid expiry date'});

        }else if(lng>3 && rawValue.slice(0,2)<=getCurrentMonth() && rawValue.slice(2) <= +getCurrentYear(false)){
            setExpDateError({...expDateError,status:true,msg:'Card expired'});

        }else{
            setExpDateError({...expDateError,status:false,msg:''});
            e.target.value.length>=5 && cardCvvRef?.current?.focus();
            // if(!expDateError.status){}
        }

    }
 
        

    if(key.field===1){
        // mastercard check
        if(card.type === cardType.mastercard && lng >= card.maxLength){
            !isMasterCard(rawValue) && setCardNumError({...cardNumError,status:true,msg:'Invalid card number'});
            isValidCard(rawValue)&&cardExpRef?.current?.focus();
        }else if(card.type === cardType.mastercard){
            setCardNumError({...cardNumError,status:false,msg:''});
        }



        //visa check
        if(card.type === cardType.visa && lng >= card.maxLength){
            !isVisaCard(rawValue) && setCardNumError({...cardNumError,status:true,msg:'Invalid card number'});
            isValidCard(rawValue)&&cardExpRef?.current?.focus();
        }else if(card.type === cardType.visa){
             setCardNumError({...cardNumError,status:false,msg:''});
        }


        //verve check
        if(card.type === cardType.verve && lng >= card.maxLength){
            isValidCard(rawValue)&&cardExpRef?.current?.focus();
           !isVerveCard(rawValue) && setCardNumError({...cardNumError,status:true,msg:'Invalid card number'});
        }else if(card.type === cardType.verve){
            setCardNumError({...cardNumError,status:false,msg:''});
        }

    }

    if(key.field===3){
        lng>0&&setCvvError({...cvvError,status:false,msg:''});
    }

    

   
    
    key.field===2? 
    setInputValue({...inputValue,[inpFld]:insertTextSeperator(e,opt).replaceAll(new RegExp(/[^a-zA-Z0-9\s/]|[a-zA-Z]/,'g'),'')}):
    setInputValue({...inputValue,[inpFld]:insertTextSeperator(e,opt).replaceAll(new RegExp(/[^a-zA-Z0-9\s]|[a-zA-Z]/,'g'),'')});

},[inputValue.cardNum,inputValue.cvv,inputValue.expDate])
    
    const handleProceeding = useCallback(()=>{
 
        const {cardNum,expDate,cvv}=cardDetails;
       
        //cvv
        if(cvv.trim().length>0){
            if(cvv.length<3){
                setCvvError({...cvvError,status:true,msg:'Invalid Card CVV'});

            }else{
                setCvvError({...cvvError,status:false,msg:''});
            }

        }else if(cvv.trim().length<1){
            setCvvError({...cvvError,status:true,msg:'Please enter your Card CVV'});
            
        }

        //expiry data
        if(expDate.trim().length>0){
            //if the card expriry year is lessthan the currrent year or greaterthan the next 20yrs -> error
            if((expDate.slice(expDate.length-2) < getCurrentYear(false)) || +expDate.slice(expDate.length-2) > +getCurrentYear(false)+20){
                setExpDateError({...expDateError,status:true,msg:'Invalid expiry date'});
                
            }else if(expDate.slice(0,2)<=getCurrentMonth() && +expDate.slice(expDate.length-2) <= +getCurrentYear(false)){
                setExpDateError({...expDateError,status:true,msg:'Card expired'});
            }else{
                setExpDateError({...expDateError,status:false,msg:''});
            }

           
        }else if(expDate.trim().length<1){
            setExpDateError({...expDateError,status:true,msg:'Card expiry is required'});
        }
            

        if(cardNum.trim().length>1){
            if(!isMasterCard(cardNum) && !isVisaCard(cardNum) && !isVerveCard(cardNum) && !isVisaElectronCard(cardNum)){
                setCardNumError({...cardNumError,status:true,msg:'Invalid card number'});
                
            }else if(isMasterCard(cardNum) || isVisaCard(cardNum) || isVerveCard(cardNum) || isVisaElectronCard(cardNum)){
                setCardNumError({...cardNumError,status:false,msg:''});
        }
        
        }else{
            setCardNumError({...cardNumError,status:true,msg:'Please enter your card number'});
        }

        if(isOk){
           
            props.data({...card,...cardDetails});
        }

    },[cardDetails,expDateError,cvvError,cardNumError])




    useEffect(()=>{
        if(!expDateError.status && !cvvError.status && !cardNumError.status){
            setIsOk(true);
        }else{
            setIsOk(false);
        }

    },[isOk,expDateError.status,cvvError.status,cardNumError.status]);

   const [selPaymentMethod, setSelPaymentMethod] = useState(0);
   const [pageView, setPageView] = useState<IView | string>('card');
   

    return(<div className={`${styles.sideCont}`}>
        <h4>Select a payment method</h4>
        <div className={`${styles.menu}`}>
            {['Card', 'Transfer'].map((menuu, index) => (<div onClick={() =>{setSelPaymentMethod(index);
            setPageView(menuu.toLowerCase());
            }}>
                <button className={selPaymentMethod == index ? "active" : ''}>{menuu}</button>
                <div className="line"></div>
            </div>))}
            {/* <div>
                <button className="active">Card</button>
                <div className="line"></div>
            </div>

            <div>
                <button>Transfer</button>
                <div className="line"></div>
            </div> */}

            {/* <div>
                <button>QR</button>
                <div className="line"></div>
            </div>

            <div>
                <button>Others</button>
                <div className="line"></div>
            </div> */}
        </div>

        <div className={`${styles.cardDetails}`}>
            {pageView === 'card' ? 
            <>
            {/* <div className={`${styles.cards} ${ props.cardVisibility ? styles['showCards'] :''} animated fadeIn faster`}>

                <p>
                    Select a card or Click to add one
                </p>
                {/* <div>
                    <div className="bgarts top"></div>
                    <div className="bgarts btm"></div>

                    <div className="cardcont">
                        <div className="fs">
                            <div>
                                <img src={mastercard} />
                                <small>MasterCard</small> 
                            </div>
                        </div>

                        <div className="num">
                            <p>9645 2484 5359 0241</p>
                        </div>

                        <div className="names">
                            <p>James Ubong</p>
                            <p>20/3/23</p>
                        </div>
                    </div>
                </div>

                <div className={`animated fadeInDown delay-0-0s fast`}>
                    <div className="bgarts top"></div>
                    <div className="bgarts btm"></div>
                    <div className="newcont">
                        <span>Add new</span>
                        <FontAwesomeIcon icon={faPlus}/>
                    </div>
                </div>
                
                <div className={`animated fadeInDown delay-0-1s fast`}>
                    <div className="bgarts top"></div>
                    <div className="bgarts btm"></div>
                    <div className="newcont">
                        <span>Add new</span>
                        <FontAwesomeIcon icon={faPlus}/>
                    </div>
                </div>

                <div  className={`animated fadeInDown delay-0-2s fast`}>
                    <div className="bgarts top"></div>
                    <div className="bgarts btm"></div>
                    <div className="newcont">
                        <span>Add new</span>
                        <FontAwesomeIcon icon={faPlus}/>
                    </div>
                </div>
            </div> */}

            <div className={styles.detailsArea}>
                <div className="fadeInUp delay-0-1s animated" style={{marginBottom:40}}>
                    <p>Pay using Debit Cards</p>
                    <div className="cardlogos">
                        <div> <img src={mastercard} alt="master cardLogo" /> </div>
                        <div> <img src={visa} alt="visa cardLogo" /> </div>
                        <div className="verve"> <img id="verve" src={verve} alt="verve cardLogo" /> </div>
                    </div>
                    
                </div>


                <div className="inputsfields">
                    <GatewayInputfield id="card" type="text" label="Credit card number" animate={`fadeInUp delay-0-1s animated`} onInput={(e:any)=>handleInput(e,{field:1})} value={inputValue.cardNum} autoFocus icon={card.logo} logoIsImage={card.logo.trim().length < 1?false:true} isError={cardNumError.status} errorMsg={cardNumError.msg} forwardedRef={cardNumRef}/>

                    <div className="inline fadeInUp delay-0-3s animated">
                        <GatewayInputfield  id="card3" type="text" label="Expiration date" onInput={(e:any)=>handleInput(e,{field:2})} value={inputValue.expDate} isError={expDateError.status} errorMsg={expDateError.msg} forwardedRef={cardExpRef}/>

                        <GatewayInputfield  id="card4" type="text" label="CVV" onInput={(e:any)=>handleInput(e,{field:3})} value={inputValue.cvv} isError={cvvError.status} errorMsg={cvvError.msg} forwardedRef={cardCvvRef}/>
                    </div>
                </div>

                <div className="actionBtn fadeInUp delay-0-3s animated">
                    <div >
                        <input type="checkbox" id="saveCard" hidden/>
                        <label htmlFor="saveCard" hidden>Save card</label>
                    </div>
                    <Button text="Proceed" reversed={true} icon={icon4} loading={props.processing} disabled={props.processing} onClick={handleProceeding}/>
                </div>

            </div>
            </> : 
            <Transfer amount={props.amount} reference={props.reference} isTransfer={pageView === 'transfer'} />
            }
            
        </div>
    </div>
);
}