import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme)=>({
  main: {
    width: "100%",
    boxSizing: "border-box",
    "& *": {
      boxSizing:"inherit"
    }
  },
  smallTitle:{
    fontSize:"0.7em",
    margin: "15px 10px 5px"
  },
  walletchecker: {
    '&': {
      display:'flex',justifyContent:'flex-start', gap: 10,
      width: "70%",
      '& > label > div': {
      },
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        overflowX: 'scroll', padding: '20px 10px', justifyContent: 'flex-start'
      }
    }
  },
  cta: {
    width: '100%', 
    [theme.breakpoints.down('sm')]: {
      width: '100%',display:'flex',justifyContent:'center', alignItems:'center', 
      "& button": {
        width: 'calc(100% - 20px)',
      }
    }
  },
  accountForm:{
    width:400, padding:30, height:350,
    '&':{display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column",},
    "& form":{
      display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", width:'100%',
      "& .btnCont":{
        width:'100%', marginTop:10, display: 'flex', justifyContent: "flex-end",
      }
    },
    "& .imgCont":{
      width:"100%", height:150,display: 'flex',
      "& img":{
        width:"100%", height:"100%", objectFit:'contain'
      }
    },
    [theme.breakpoints.down('sm')]: {
      width:'100%', marginTop:'25%'
    }
  },
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;