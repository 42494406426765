import SimpleCrypto from "simple-crypto-js";

/**
 * **saveInStore** - encrypt and save data in browser storage
 * @param {string} key - the key/ field name to access the data
 * @param {object | string | number | boolean} data - data to save
 * @param {string} storage - browser storage type - default:=session
 * @return {void}
 */
export const saveInStore = (key: string, data: object | string | number | boolean, storage:'session' | 'local'='session') => {
    const _secret = process.env.REACT_APP_SECRET;
    
    const simpleCrypto = new SimpleCrypto(_secret);
 
    const payload = simpleCrypto.encrypt(data);
    storage === 'session'?sessionStorage.setItem(key, payload):localStorage.setItem(key, payload)
 };
 
 /**
 * **getFromStore** - retrieve and decrypt data in browser storage
 * @param {string} key - the key/ field name to access the data
 * @param {string} storage - browser storage type - default:=session
 * @return {object | string | number | boolean}
 */
 export const getFromStore = (key: string, storage:'session' | 'local'='session') => {
   try {
      const _secret = process.env.REACT_APP_SECRET;
    const simpleCrypto = new SimpleCrypto(_secret);
 
    const data = storage === 'session'?sessionStorage.getItem(key):localStorage.getItem(key);
   
    const result: any = data ? simpleCrypto.decrypt(data) : null;
    return result;
   } catch (error) {
      return null
   }
    
 };

 /**
 * **removeFromStore** - delete data in browser storage
 * @param {string} key - the key/ field name to access the data
 * @param {string} storage - browser storage type - default:=session
 * @return {void}
 */
  export const removeFromStore = (key: string, storage:'session' | 'local'='session') => {
      storage === 'session'?sessionStorage.removeItem(key):localStorage.removeItem(key);
 };