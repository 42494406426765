import { Typography } from '@mui/material';
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReturnBar from '../../components/returnBar';
import useStyles from './styles';
import TextField from '../../../../components/textField';
import Popup from '../../../../components/popup';
import { commarize, commarize2, currency, error, getAppStorage, sanitize, setAppStorage } from '../../../../utilities/appHelpers';
import CustomCheckbox from '../../../../components/customCheckbox';
import Button from '../../../../components/button';
import { Icon } from '@iconify/react';
import SimpleButton from '../../../../components/simpleButton';
import { getDTPAcc } from '../../../../services/wallet';
import md5 from 'md5';
import axiosInstance from '../../../../services/https';
import { SessionCheck, messasgeType, useGlobalState } from '../../../../pages/dashboard';
import { useUserTheme } from '../../../../themes';


export interface ITransferFundModProps {
  className?: string,
  style?: Record<string, any>,
}

export default function TransferFundMod(props: ITransferFundModProps) {
  const classes = useStyles().classes, {isMobile} = useUserTheme();
  const navigate = useNavigate();
  const toDashboard = () => {
    navigate("/dashboard");
  }

  const {state}:Record<string,any> = useLocation();

const wrapper = useRef<any>(null),
[amount, setAmount] = useState(""),
[paymentParam, setPaymentParam]=useState({
  amount: '',
  wallet_code: "",
  acctNo:''
}),
[appState, setAppState]=useState({ text:'Proceed', icon:<Icon icon="eva:arrow-forward-fill"/>, disabled:true, loading:false,hintIcon:messasgeType.default, status:false, count:1,message:''}),
[password,setPassword] = useState(''),
[showPrompt,setShowPrompt] = useState(false),
[accountData,setAccountData] = useState<Record<string,any>>({
  amount:'',
}),
[hintState,setHintState] = useGlobalState("hint"),
[pageLoading,setPageLoading] = useState(true),
key = useRef('');



useEffect(()=>{
  setPaymentParam({...paymentParam,amount:sanitize(amount)});
  if(sanitize(amount.trim()).length>0){
    return setAppState({...appState,disabled:false});
  }else{
    return setAppState({...appState,disabled:true});

  }
},[amount])

useEffect(()=>{
  let toDecrypt = accountData.email+accountData.walletCode+paymentParam.amount+accountData.callbackUrl+key.current;
  setAccountData({...accountData,checkSum:md5(toDecrypt), amount:paymentParam.amount});

},[key.current, accountData.email, accountData.walletCode,paymentParam.amount])

//select a default wallet...
useEffect(() => {
  
  let l = wrapper.current && (wrapper.current as HTMLElement).querySelectorAll('label')[0];

  if(state){
    l = wrapper.current && (wrapper.current as HTMLElement).querySelector(`label[for="${state.walletCode}"]`);
    setPaymentParam({...paymentParam,wallet_code:state.walletCode, acctNo:state.accNo});
  }else{
    const w = getAppStorage('WALLETS')[0];
    w && setPaymentParam({...paymentParam,wallet_code:w.walletCode, acctNo:w.accNo});
  }
  
  const elm = l&& l.querySelector('input[type=radio]');

  l && l.classList.add('selectedwallet101');
  if(elm){
    elm.checked = true;
   }
  
}, []);

const handleChange = (wallet:Record<string, any>, btn:HTMLLabelElement)=>{
  const el = wrapper.current && (wrapper.current as HTMLElement).querySelector('.selectedwallet101');
  if(el){
    if(el !== btn){
      el.classList.remove('selectedwallet101');
      btn.classList.add('selectedwallet101');   
    }
  }else{btn.classList.add('selectedwallet101');}
  setPaymentParam({...paymentParam,wallet_code:wallet.walletCode, acctNo:wallet.accNo});

}

const submitFunding = useCallback(async()=>{
  if(paymentParam.amount.length < 1 ){
    setAppState({...appState,text:'Proceed', loading:false,disabled:false, status:true, message:"Kindly enter the Amount to proceed", hintIcon:messasgeType.default,count:appState.count+1});
    return;
  }
  setAppState({...appState,text:'Please wait', loading:true});
        
  try {
    const resp = await axiosInstance.post('wallet/fund/'+paymentParam.wallet_code,accountData);
    if(resp.status===200|| resp.status===201){

      window.location.href = resp?.data?.data?.payUrl;
      setAppState({...appState,text:'Proceed', loading:false,disabled:false});
      //localstorage
      setAppStorage("transDet",{secret_key:key.current,checksum:accountData.checkSum,walletCode:paymentParam.wallet_code, ref:resp?.data?.data?.ref});

    }
    
  } catch (e) {
    setAppState({...appState,text:'Proceed', loading:false,disabled:false, status:true, message:error.handle(e,"Oops! You've lost internet connection",true), hintIcon:messasgeType.failed,count:appState.count+1});
    SessionCheck(e);
  }

},[accountData.checkSum,paymentParam.wallet_code,paymentParam.amount])

useEffect(()=>{
  setHintState({...hintState,status:appState.status, msg:appState.message, icon:appState.hintIcon});
},[appState.count])



  return (<>
<Popup show={showPrompt} showLogo={false} onPopChange={(stat:boolean)=>setShowPrompt(stat)} onReset={()=>setPassword('')}>
      <div className={`${classes.accountForm}`}>
        
        <Typography className={`fadeInUp animated delay-0-1s`} component={'h1'} style={{fontSize:'1.2rem', fontWeight:600,marginBottom:20,textAlign:'center',lineHeight:'1.4rem'}}>
          <p>You're about to fund this wallet <span style={{color:'rgb(0 155 178)'}}> {paymentParam.acctNo}</span>  with <span style={{color:'rgb(0 155 178)'}}>{currency.naira+commarize(paymentParam.amount)} </span> </p> 
          <small style={{ color:'grey'}}>Enter your password to proceed</small> 
          </Typography>

          <TextField className={`fadeInUp animated delay-0-2s`} type="password" value={password} placeholder="Password" onChange={(e:any)=>setPassword(e.target.value)}/>

          <div className='btnCont flex-space-between' style={{width:'100%', marginTop:50}}>
            <Button className={`fadeInUp animated delay-0-4s`} variant={'secondary'} text={'Cancel'} icon={<Icon icon='dashicons:arrow-left-alt'></Icon>} onClick={null}/> 
            <Button className={`fadeInUp animated delay-0-4s`} text={appState.text} icon={appState.icon} loading={appState.loading} disabled={appState.loading} onClick={submitFunding}/> 
          </div>
      </div>
    </Popup>
    <ReturnBar title="DashBoard" onClick={() => { toDashboard(); }}></ReturnBar>
    

    <Typography  component="h3" className={`${classes.smallTitle}`} style={!isMobile?{marginLeft:0}:{}}>
      Select a wallet to fund
      </Typography>
      <div ref={wrapper} className={`${classes.walletchecker} scrollbar-transparent`}>
      {
        
        getAppStorage('WALLETS') && getAppStorage('WALLETS').map((w:Record<string, any>,i:number)=>{
          let wRef = createRef<HTMLLabelElement>();
          //@ts-ignore
          return <CustomCheckbox className={`slideInRight animated delay-0-${i}s`} ref={wRef} onChange={(e:any)=>handleChange(w,wRef.current)} title={w.walletName} txt1={w.accNo} txt2={currency.naira + w.bal} id={w.walletCode} key={i}/>
        }
        )
      }
      
      </div>
      <div className={`animated fadeInUp delay-0-3s`}>

      <Typography component="h3" style={isMobile?{marginBottom:-20}:{marginBottom:-20,marginLeft:0}}  className={`${classes.smallTitle}`}> Enter Amount </Typography>
        <div style={isMobile?{ padding: '10px', marginBottom: '10px' }:{ padding: '10px 0', marginBottom: '10px' }}>
            <TextField currency={currency.naira}
              placeholder={"Amount"}
              onChange={(e: any) => setAmount(e.target.value)}
              value={commarize2(sanitize(amount))} />
          </div>
          </div>


      <div className={`${classes.cta} animated fadeInUp delay-0-6s`}>
        {/* <SimpleButton disabled={appState.disabled} style={{ fontWeight: 800, margin: "30px 0", padding:'10px 20px', fontSize:'1.2rem'}} onClick={()=>setShowPrompt(true)}> Submit </SimpleButton> */}

        <Button text={appState.text} icon={appState.icon} loading={appState.loading} disabled={appState.loading} onClick={submitFunding}/> 
      </div>
                    
  </>);
}
