import React from "react";
import { faCheck, faTimes, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import {useEffect, useState, memo} from "react";
import useStyles from "./styles";
import { useUserTheme } from "../../themes";
export enum IType{
    error='error',
    success='success',
    warning='warning'
}
export interface IAlertProps {
  style?: Record<string, any>;
  className?: string;
  allowOutsideClick?: boolean;
  setBackground?:boolean;
  show?:boolean;
  onClose?:any;
  type?:IType;
  title:string;
  message?:any;
  children?:any

}

const Alert = (props: IAlertProps) => {
    const {style, className='', type=IType.warning, allowOutsideClick=true, setBackground=true, title, message, show = false, onClose=null} = props;

    const [isShow, setIsShow] = useState<boolean>(false);
    const [display, setDisplay] = useState<string>('none');

    useEffect(()=>{
      setIsShow(show);
    },[show])
    
    const handleClicks = (event:any)=>{
        if(allowOutsideClick){
          let classname = event?.target?.className;
          if(typeof classname === 'string' || classname instanceof String){
            if(classname.includes('customAlertBox')){
              setIsShow(false);
            }
            
          }
        }
    return;
    }

    useEffect(()=>{

      
      const timmer = setTimeout(()=>setDisplay(isShow?'flex':'none'),isShow?0:500);
      onClose && onClose(isShow);
      return ()=>{
        clearTimeout(timmer);
      }

    },[isShow])
const {isMobile} = useUserTheme();
    
  const rules = useStyles().classes;
  //   const theme = useTheme(); to be able to access theme while using inline styling
  return (
    <div style={{display:display}} className={`${rules.alertStyle} customAlertBox ${setBackground?rules.shell:''} animated faster alert_wrapper ${isShow?'fadeIn':'fadeOut'}`} onClick={handleClicks}>
      <div className={`alert_body card-3 ${isShow?'zoomInShort2':'zoomOut2'} animated faster ${className} ${type===IType.success?rules.succ_light:type===IType.warning?rules.warn_light:rules.err_light}`} style={style}>
          <div className="header">
              <div className={`icon card-1 ${type===IType.success?rules.success:type===IType.warning?rules.warning:rules.error}`}><FontAwesomeIcon icon={type===IType.success?faCheck:faWarning}/></div>
              <button onClick={()=>setIsShow(false)}><FontAwesomeIcon  icon={faTimes}/></button>
          </div>
        <Typography component='div' style={{lineHeight:'1.2rem'}}  className="content-box">
            <h1 style={isMobile?{lineHeight: 1,textAlign:'center'}:{fontStyle:'uppercase'}} id="title">{title}</h1>
            {
              message?<p style={{fontSize:'1rem'}} id="msg">{message}</p>:props.children
            }
            
        </Typography>
      </div>
    </div>
  );
};

export default memo(Alert);
