import { faLock, faUserLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import React, { useState, memo, useCallback, useEffect, createRef, useRef } from "react";
import Button from "../../components/button";
import TextField from "../../components/textField";
import theme from "../../resource/theme";
import useStyles from "./styles";
import resetIcon from "../../assets/images/password-reset.png";
import Spinner from "../../components/spinner";
import { useCounter } from "../../components/counter";
import { Icon } from "@iconify/react";
import { error, getAppStorage, isValidPassword } from "../../utilities/appHelpers";
import { icon, messasgeType, useGlobalState } from "../../pages/dashboard";
import PasswordField from "../../components/passwordField";
import styles from "../passwordCreationMod/styles";
import { createNewPassword, resendPasswordResetCode, verifyPasswordResetCode } from "../../services/pinVerification";
import { NetworkError } from "../../utilities/constant";
import { pageRoute } from ".";

export interface IEmailVerification {
  isFormClosing: boolean,
  onNextPage: any
  returnMessage: any,
}

function EmailVerification({isFormClosing=false,onNextPage, returnMessage}:IEmailVerification){

  //STATES / VARABLES
  const [hintState,setHintState] = useGlobalState("hint"),[errorCount,setErrorCount]=useState(0);

  const el = useRef<HTMLParagraphElement>(null);
  const [codeInput,setCodeInput] = useState({loading:false,status:false,icon:'clarity:warning-solid',show:false});
    const classes = useStyles().classes;
    const classes2 = styles().classes;
    const [hint, setHint] = useState<Record<string,any>>({message:'', show:false,title:"",loading:false, icon:messasgeType.default
    }),[isResending,setIsResending] = useState(false);
    const [mins, secs,setTime,status ]= useCounter([1,60]); 
    const [formData, setFormData] = useState<Record<string,any>>({password:'',cPassword:''}), [code,setCode]=useState(''), [pwdIsFocused, setPwdIsFocused]=useState(false);
    const [appState, setAppState] = useState<Record<string, any>>({loading:false, icon:<FontAwesomeIcon icon={faLock}/>, btnText:'SUBMIT',  res:null, regStatus:0});


    //FUNCTIONS / USEFFECTS
    const codeLength = 6;

    useEffect(()=>{
      let data = code.trim();
      if(data.length>0 && data.length < codeLength){
         setCodeInput({...codeInput,loading:true,status:false,show:true,icon:"fa:check"});
      }

      if(data.length ===0){
         setCodeInput({...codeInput,loading:false,status:false,show:false});
      }
       if(data.length === codeLength ){
        // call backend here once the data is about 8 character
        (async()=>{
          try {
            let uid = getAppStorage('uid');
            const res = await verifyPasswordResetCode({pin:code},uid);
          if(res.status===200){
            setCodeInput({...codeInput,loading:false,status:true,show:true,icon:"fa:check"});
            setPwdIsFocused(true);
            let t= setTimeout(()=>{
              
              el.current?.scrollIntoView();
              
              return ()=>clearTimeout(t)
            },200)
          }
          } catch (e) {
            setCodeInput({...codeInput,loading:false,status:false,show:false});
            setHint({...hint,show:true, message:error.handle(e,NetworkError,true), icon:messasgeType.failed});
            setErrorCount((n)=>n+1);
            
          }
          
        })()
        
        
        
      }
      
    },[code])

    const resetFormData = ()=>{
      setCode('');
      setCodeInput({...codeInput,loading:false,status:false,show:false});
      setFormData({...formData,passwword:'',cpassword:''});
      setNumber(0); setLowercase(0); setUppercase(0); setLengh(0);
     }
    useEffect(()=>{
      isFormClosing && resetFormData();
    },[isFormClosing])
    

  const submitForm = async(e:any)=>{
    e.preventDefault();

   let p1 = formData.password;
   let p2 = formData.cPassword;

   //if it's empty
   if(p1 === '' || p2 ===''){
    setHint({...hint,show:true, message:'Password cannot be empty',icon:messasgeType.failed});
    setErrorCount((n)=>n+1);
    return;
   }

   //if password doesnt meet requirements
   if(!isValidPassword(p1)){
    setHint({...hint,show:true, message:'Password must have at least one letter and digit and not less than 8 characters',icon:messasgeType.failed});
    setErrorCount((n)=>n+1);
    return;
  }
   
   //check if password and confirm fields are both the same
   if(p1 !== p2 ){
    setHint({...hint,show:true, message:'Password mismatch',icon:messasgeType.failed});
    setErrorCount((n)=>n+1);
    return;
   }
    setAppState({...appState, btnText:'PROCESSING...', loading:true});
      
    try{
      //get the uniqued Id
      let uid = getAppStorage('uid');
      //call the endpoint to submit new password
      const resp = await createNewPassword(formData,uid);
      if(resp.status === 200){
        // HANDLE RESPONSES FROM SERVER
        setAppState({...appState, btnText:'REDIRECTING...', loading:true, icon:icon.redirect});
        //go back to login page
        onNextPage && onNextPage(pageRoute.login);
        returnMessage && returnMessage(resp?.data?.message);

      }
    } catch (e:any) {
      setHint({...hint,show:true, message:error.handle(e,NetworkError,true), icon:messasgeType.failed});
      setErrorCount((n)=>n+1);
      
    }
    finally{
      setAppState({...appState, btnText:'SUBMIT', loading:false});

    }
  }


  useEffect(()=>{
    setHintState({...hintState,status:hint.show, msg:hint.message, icon:hint.icon});
  },[errorCount])

  enum vIcon{
    success='akar-icons:check',
    info="clarity:info-circle-solid",
    error='humbleicons:times',
  }

  const [lowercase, setLowercase] =useState(0),[uppercase, setUppercase] =useState(0),[number, setNumber] =useState(0),[length, setLengh] =useState(0), [isValidPwd, setIsValidPwd]=useState(false), [proceed, setProceed] = useState(false);
  const validatePassword = useCallback((input:string)=>{
    /[0-9]/.test(input)?setNumber(2):setNumber(1);
    /[a-z]/.test(input)?setLowercase(2):setLowercase(1);
    /[A-Z]/.test(input)?setUppercase(2):setUppercase(1);
    input.length>=8?setLengh(2):setLengh(1);

    if(new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,'g').test(input)){
      setFormData({...formData,password:input});
      setIsValidPwd(true);
    }

  },[])

  useEffect(()=>{
    lowercase===2 && uppercase===2 && number===2 && length===2?  setIsValidPwd(true):setIsValidPwd(false);

    if(formData.password.trim().length>0 && formData.cPassword.trim().length>0){
      setProceed(true);
    }else{
      setProceed(false);
    }
    
    
  },[lowercase,uppercase,number,length, isValidPwd, formData.cPassword,formData.password])
  


const resendCode = useCallback(async()=>{
  setIsResending(true);
  try {
     let uid = getAppStorage('uid');
    const resp  = await resendPasswordResetCode(uid);
    if(resp.status===200){
      setTime([2,59]);
      setHint({...hint,show:true, message:resp?.data?.message, icon:messasgeType.success});
      setErrorCount((n)=>n+1);
    }
  } catch (e) {
      setHint({...hint,show:true, message:error.handle(e,NetworkError,true)});
      setErrorCount((n)=>n+1);
  }finally{
     setIsResending(false);
  }
 

},[])

return(

    <div
      className={`${classes.loginForm} `}
    >
      <Typography
        className={`${classes.title}`}
        style={{ color: theme.palette.primary.main }}
      >Password Reset</Typography>
      <div className={`reset-icon`}>
        <img
          className={``}
          src={resetIcon}
          alt="DTECHPAY"
        />
        
      </div>
      <Typography className={`${classes.title} small fadeInUp animated delay-0-1s`} style={{marginBottom:10, marginTop:20, color:'grey'}}>
       Enter a password reset code that was sent to your mail
      </Typography>

      <TextField className='inputbx fadeInUp animated delay-0-2s' disabled={codeInput.status} placeholder={"Code"} value={code} onChange={(e:any)=>setCode(e.target.value)} withLoader={true} inputloader={
            {loading:codeInput.loading, status:codeInput.status, icon:<Icon icon={codeInput.icon}/>, show:codeInput.show}}/>



{
    codeInput.status && (
        <>
        <form onSubmit={submitForm}>
        <div className="pwd-wrapper">
        <Typography style={{margin:'20px 0 -10px 0',color:"#4e708f", fontWeight:700, fontSize:'1rem'}}>Create a new password you'll remember</Typography>
            <PasswordField onInput={(e:any)=>{validatePassword(e.target.value);}} onFocus={()=>!isValidPwd && setPwdIsFocused(true)} onBlur={(e:any)=>{isValidPwd && setPwdIsFocused(false); setFormData({...formData,password:e.target.value})}} placeholder={"New Password"} className='inputbx inputbx-pwd fadeInUp animated delay-0-4s'  /> 

            <Typography component={'div'} className={classes2[pwdIsFocused?"input-hint-open":"input-hint-close"] + "  " + classes2["hint"]}>
              <strong style={{color:"#4e708f"}}>Your password must contain at least:</strong>
                <ul className={classes2["val-checker"]}>
                  <li className={lowercase===2?'success':lowercase===1?'error':'info'}><Icon icon={lowercase===2?vIcon.success:lowercase===1?vIcon.error:vIcon.info} style={{marginRight:3}}/>A lowercase</li>

                  <li className={uppercase===2?'success':uppercase===1?'error':'info'}><Icon icon={uppercase===2?vIcon.success:uppercase===1?vIcon.error:vIcon.info} style={{marginRight:3}}/>An uppercase</li>

                  <li className={number===2?'success':number===1?'error':'info'}><Icon icon={number===2?vIcon.success:number===1?vIcon.error:vIcon.info} style={{marginRight:3}}/>A Number</li>

                  <li className={length===2?'success':length===1?'error':'info'}><Icon icon={length===2?vIcon.success:length===1?vIcon.error:vIcon.info} style={{marginRight:3}} />Minimum 8 characters</li>
                </ul>
            </Typography>     

            <PasswordField disabled={!isValidPwd} onInput={(e:any)=>{setFormData({...formData,cPassword:e.target.value});}} className='inputbx inputbx-conf-pwd fadeInUp animated delay-0-6s' placeholder={"Confirm New Password"}/>      
        </div>

        <div className="btn fadeInUp animated delay-0-7s" style={{width:'100%', marginTop:20}}>
            <Button type='submit' style={{width:'100%'}} loading={appState.loading} icon={appState.icon} text={appState.btnText} disabled={!proceed}/>
        </div>
        </form>

        </>
    )
}


        { !codeInput.status && <Typography ref={el} component={'div'} className="info" style={{width:'100%',display:'flex', gap:10, alignItems:'center', justifyContent:'center'}} >
             <p  >Didn't get a code? </p>
             {   isResending? 
            <div style={{display:'flex', gap:10}}>
                &nbsp;
                  <Spinner size={20}/>
                <strong>Resending OTP...</strong>
            </div>:
               <button className="link"> &nbsp; {!status?`Resend pin in ${mins}:${secs}`:<strong onClick={resendCode}>Resend</strong>} </button>
             }
            </Typography > }
                </div>

);
}

export default memo(EmailVerification);