import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme)=>({
    pviInputs:{
        display:"flex",
      
      "& > input:focus":{outline:"5px solid #87ceeb78"},
      "& > input":{
         width:60, height:70, marginRight:20,
         textAlign:"center",
         fontSize:"3rem", border:"thin solid skyblue", 
         borderRadius:5,
         transition: "outline 0.2s ease",
         outline: "5px solid transparent"
      },
      '& > input:last-child':{
        marginRight:0
      },

      
        "& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
            /* display: none; <- Crashes Chrome on hover */
            "-webkit-appearance": "none",
            margin: 0, /* <-- Apparently some margin are still there even though it's hidden */
        },

        "& input[type=number]": {
            "-moz-appearance":"textfield", 
        }
      
    }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;