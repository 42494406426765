import React, { useCallback, useEffect, useState } from 'react'
import Table, { Status } from '../../../../components/designerTable';
import { error } from '../../../../utilities/appHelpers';
import { alpha, darken, Typography } from '@mui/material';
import { defaultPageData, PaginationControl } from '../../../../components/PaginationControl';
import { paginationData } from '../../../../utilities/types';
import usePagination, { usePaginationInterface } from '../../../../customHooks/pagination';
import { STable } from '../../../../components/skeletonLoader';
import Select  from "react-select";
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import SimpleButton, { ButtonVariant } from '../../../../components/simpleButton';
import { ROLES, SessionCheck, messasgeType, useGlobalState } from '../../../../pages/dashboard';
import { NetworkError } from '../../../../utilities/constant';
import useStyles from './styles';
import Popup from '../../../../components/popup';
import { useUserTheme } from '../../../../themes';
import { fetchRoles, fetchUsers, inviteUser } from '../../../../services/userManagement';
import moment from 'moment';
import img1 from './images/NoResult.png';
// import img2 from './images/NotFound.png';
import BrokenPage from '../../../../components/errorPage/brokenPage';
import TextField from '../../../../components/textField';
import Button from '../../../../components/button';
import Dropdown from '../../../../components/Dropdown';


export default function Users() {
    const classes = useStyles().classes;
    const {isMobile} = useUserTheme();
    const [UserData, setUserData] = useState<any>([]);
    const [pageData, setPageData] = useState<paginationData>(defaultPageData);
    const pagination: usePaginationInterface = usePagination(pageData);
    const [controlPageData, setControlPageData] = useState<paginationData>(defaultPageData);
    const navigate = useNavigate();
    const [appState, setAppState] = useState({loading: true,  msg: '', isMsg: false, status: false, count: 0, msgType: messasgeType.default });
    const [, setHintState] = useGlobalState("hint");

    const [showInvitationForm,setshowInvitationForm] = useState(false);
    const [formData,setFormData] = useState({email:'',roleId:'1'});
    const [selectedRole,setSelectedRole] = useState<any>();
    const [roleOptions,setRoleOptions] = useState([]);
    const [optionLoading,setOptionLoading]=useState(false);
    const [requestState,setRequestState]=useState({loading:false,buttonText:'Invite'});

    const {ADD_USER=false,EDIT_ROLE=false,EDIT_USER=false,DELETE_ROLE=false,DELETE_USER=false,ADD_ROLE=false,VIEW_ROLE=false,VIEW_USER=false} = ROLES.USER_MANAGEMENT_MODULE.privileges;

    const fetchAllRoles = useCallback(async()=>{
        setOptionLoading(true);
        setshowInvitationForm(true);
        setRoleOptions([])
        try {
            const resp = await fetchRoles();
             if(resp.status===200){
                const resObj = resp.data.data;
                resObj && resObj.forEach((obj:Record<string,any>)=>{
                    Object.keys(obj).forEach(oldkey => {
                      let value = obj[oldkey];
                      let newKey = (oldkey === 'roleId')?'value': (oldkey === 'roleName') ?'label' : oldkey;
                      
                      delete obj[oldkey];
                      obj[newKey] = value;
                    });
                  })
                    setRoleOptions(resObj);
                }
        } catch (e) {
            SessionCheck(e);
            setAppState({ ...appState, isMsg: true, msg: error.handle(e, NetworkError, true), count: appState.count + 1, msgType: messasgeType.network, loading: false });
        }finally{
            setOptionLoading(false);
        }
    },[])
    const resetForm=()=>setFormData({email:'',roleId:''});
    const sendInvite= useCallback(async()=>{
        setRequestState({ ...requestState,loading:true,buttonText:'Sending...'});
        try {
            const resp = await inviteUser(formData);
             if(resp.status===200){
                 setAppState({ ...appState, isMsg: true, msg:'Invitation sent', count: appState.count + 1, msgType: messasgeType.success, loading: false });
                 setshowInvitationForm(false);
             }
            
        } catch (e) {
            SessionCheck(e);
            setAppState({ ...appState, isMsg: true, msg: error.handle(e, NetworkError, true), count: appState.count + 1, msgType: messasgeType.network, loading: false });
        }finally{
            setRequestState({...requestState,loading:false,buttonText:'Invite'});
        }

    },[formData.email,formData.roleId]);

    useEffect(()=>{
        (async()=>{
            setAppState({...appState,loading:true});
            try {
                const resp = await fetchUsers();
                if(resp.status===200){
                    setUserData(resp.data.data);
                }
            } catch (e) {
                SessionCheck(e);
                setAppState({ ...appState, isMsg: true, msg: error.handle(e, NetworkError, true), count: appState.count + 1, msgType: messasgeType.network, loading: false });

            }finally{
                setAppState({...appState,loading:false});

            }
            
        })()
    },[])

    useEffect(() => {
        setFormData({...formData,roleId:selectedRole?.value??1});
    }, [selectedRole]);


    useEffect(() => {
        setHintState(prev => ({ ...prev, status: appState.isMsg, msg: appState.msg, icon: appState.msgType }))
    }, [appState.count]);



    return (
        <>
        <Popup show={showInvitationForm} showLogo={false} onPopChange={(stat:boolean)=>setshowInvitationForm(stat)} onReset={resetForm}>
            <Typography  component={'h1'} style={{fontSize:'1.2rem', color:'rgb(91, 91, 91)', fontWeight:700,marginBottom:20}}>Invite User</Typography>
      <div className={`${classes.accountForm}`}>
        <div className="form">

        <div className={`fadeInUp animated delay-0-1s`} style={{width:'100%'}}>
            <Typography  component={'div'} style={{fontSize:'1rem',color:'grey', fontWeight:600,marginBottom:-18}}>Email</Typography>
            <TextField type="email" placeholder='Email address' style={{width:'100%'}} onChange={(e:any)=>setFormData({...formData,email:e.target.value})} value={formData.email}/>
        </div>

            <div className={`fadeInUp animated delay-0-2s`} style={{position:'relative',width:"100%",zIndex:2}}>
            <Typography  component={'div'} style={{fontSize:'1rem',color:'grey', fontWeight:600,marginBottom:0}}>Role</Typography>
            <Select
                  defaultValue={selectedRole}
                //   closeMenuOnSelect={false}
                  onChange={setSelectedRole}
                  options={roleOptions}
                  isClearable={true}
                  isLoading={optionLoading}
                  isSearchable={true}
                  noOptionsMessage={(obj: { inputValue: string; }) => { 
                    return( <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                        <strong>No Roles Available!</strong>
                        <span>Kindly create Roles to continue</span>
                        <SimpleButton variant={ButtonVariant.success} style={{marginTop:10}} onClick={()=>navigate('roles',{state:{showRoleForm:true}})}>Create Roles</SimpleButton>
                    </div>
                  )
                }}
                  
                  />
              </div>

            <div className='btnCont' style={{marginTop:20}}>
                <Button className={`fadeInUp animated delay-0-3s`} text={'Cancel'} variant="secondary" onClick={()=>{setshowInvitationForm(false)}} icon={ <Icon icon="iconamoon:sign-times-bold" fontSize={15} />}/> 

                <Button className={`fadeInUp animated delay-0-4s`} text={requestState.buttonText} variant="primary" disabled={requestState.loading} loading={requestState.loading} onClick={sendInvite} icon={<Icon icon='mingcute:send-fill'/>}/> 
                
            </div>
 </div>

      </div>
    </Popup>

        <div className="fadeIn animated faster">
            <div style={{ margin: "20px 0 10px", display: 'flex', justifyContent: 'space-between' }}>
                <div>

                <Typography component="h2" style={{ fontSize: 20, fontWeight: 600 }}>Users</Typography>
                <Typography component="p" style={{}}>Track and manage your users</Typography>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div></div>
                        <div style={{ width: '1px', height: '100%', backgroundColor: 'red' }}></div>
                        <div></div>
                        <div style={{ width: '1px', height: '100%', backgroundColor: 'red' }}></div>
                        {(!appState.loading && UserData.length >0 && ADD_USER) && <SimpleButton  onClick={fetchAllRoles}>
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center',gap:5}}>
                                <Icon icon="mingcute:add-fill" fontSize={15} /> <span>Invite User</span>
                            </div>
                        </SimpleButton> 
                        }
                    </div>

                </div>
            </div>
            {appState.loading && <STable style={{ marginTop: 20 }} />}

           
            {!appState.loading && UserData.length >0 &&( <div style={{ margin: "20px 0" }}>

                    <Table fields={[{ name: "Name", size: 20 }, { name: "Date Joined", size: 17 }, { name: "Status", size: 12 }, { name: "Email Address", size: 20 }, { name: "Role", size: 14 }, { name: "Last Active", size: 15 }, ((DELETE_USER||EDIT_USER)&&{ name: "", size: 5 })]}

                        records={UserData.length > 0 && UserData.map((user: any, index: number) => {
                            const userName = `${user.firstname} ${user.lastname}`;
                            return {
                                name: <Typography>{userName}</Typography>,
                                datejoined: moment().format("YYYY-MM-DD"),
                                status:<Status status={true}>{'Active'}</Status>,
                                email: user.email,
                                role: user.roleName,
                                lastActive: moment().format("DD-MM-YY"),
                                more:(DELETE_USER||EDIT_USER)?(<Dropdown style={{minWidth:120,borderRadius:10, backgroundColor:'#fff',color:'#000'}}
                                dropHandle={'button'}
                                dropHandleChildren={ <button className={classes.ellipseBtn}> <Icon icon="pepicons-pop:dots-y" fontSize={18} /></button>}
                            >
                                {({ hideDrop }: any) => {
                                    return (
                                        <div onClick={() => { hideDrop() }} style={{ display: 'flex', flexDirection: 'column', gap: 2, fontSize: '12px', cursor: 'pointer', padding: '14px' }}>
                                            {EDIT_USER && <div onClick={() => null} className={classes.dropItem}> 
                                                <div className='items'>
                                                    <Icon icon="iconamoon:edit" fontSize={16} />
                                                    <span>Edit</span>
                                                </div> 
                                            </div>}

                                            {DELETE_USER &&<div onClick={() => null} className={classes.dropItem}>
                                                <div className='items'>
                                                    <Icon icon="tabler:trash" fontSize={16} />
                                                    <span>Archive</span>
                                                </div>
                                            </div>}

                                        </div>
                                    );
                                }}
                            </Dropdown>):null
                            }
                        })
                        } />
                </div>)}
            {!appState.loading && UserData.length < 1 &&
                <BrokenPage 
                showButton={ADD_USER}
                illustration={img1} onClick={()=>null} message="You've not added any user yet, Click the button bellow to invite a user" buttonText='Invite User'/>

                }
        </div>
        </>
    );
}