import { lighten } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((theme)=>({
    
wrapper:{"&":{
    display:'flex', width:"100%",height:"100%", backgroundColor:lighten(theme.palette.background.paper, 0.8),position:"absolute", zIndex:2, marginLeft:-20,
  }
  
  },
    loader:{"&":{
      display:'flex', width:120,height:120,
    },
    "& img":{
      width:"100%", height:"100%", objectFit:"cover",
    }
  }
}));