import { Select as S ,Typography } from '@mui/material';
import React, { useEffect, useState, useRef, createRef, useCallback, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import OptionChecker, { CheckerBtn } from '../../components/optionChecker';
import OptionSwitch, { SwitchBtn } from '../../components/optionSwitch';
import ReturnBar from '../../components/returnBar';
import useStyles from './styles';
import TextField from '../../../../components/textField';
import { commarize2, currency, error, getAppStorage, sanitize } from '../../../../utilities/appHelpers';
import { MetreVerification, getBillerCat, purchaseElectricityToken } from '../../../../services/billler';
import { SChecker } from '../../../../components/skeletonLoader';
import Button from '../../../../components/button';
import { ROLES, SessionCheck, messasgeType, useGlobalState } from '../../../../pages/dashboard';
import CustomCheckbox from '../../../../components/customCheckbox';
import Alert, { IType } from '../../../../components/alertBox';
import { createPortal } from 'react-dom';
import CableTV from './cable';
import BrokenPage from '../../../../components/errorPage/brokenPage';
import { JsxElement } from 'typescript';

export interface IUtilitiesModProps {
  className?: string,
  style?: Record<string, any>,
}

export default function UtilitiesMod(props: IUtilitiesModProps) {
  const classes = useStyles().classes;
  const { className = "", style = {} } = props;
  const navigate = useNavigate();
  const toDashboard = () => {
    navigate("/dashboard");
  }
  const [hintState,setHintState] = useGlobalState("hint");
  const [switchNum, setSwitchNum] = useState(0);
  const [customer, setCustomer] = useState('');
  const [amount, setAmount] = useState('');
  const [selectedBiller, setSelectedBiller] = useState<any>(null), billers = useRef<Record<string, any>>({}),
  [appState,setAppState]=useState({ text:'Recharge', icon:<Icon icon="eva:arrow-forward-fill"/>, hintIcon:messasgeType.default, status:false, count:1, loading:false,msg:'',}),
  [alertState,setAlertState]=useState({ type:IType.warning, title:'', status:false, count:1,message:'',})
  ,[phone,setPhone]=useState(""),[finishing, setFinishing]=useState({ text:'Recharge', icon:<Icon icon="eva:arrow-forward-fill"/>, loading:false, disabled:true});
  
const resetForm= ()=>{
  setCustomer('');
  setAmount('');
  setPhone('');
  metreName.current = '';

}


const [copied, setCopied] = React.useState(false);
const navigateCopiedState = () => {
    setTimeout(() => { setCopied(false); }, 1000)
}

  const [loadingStatus, setLoadingStatus] = useState({utils:false, subs:false,bill:false});
  useEffect(()=>{
    setHintState({...hintState,status:appState.status, msg:appState.msg, icon:appState.hintIcon});
  },[appState.count])

  const [paymentParam, setPaymentParam] = useState({
    billerId: "ikeja electric prepaid",
    meterNumber: "23100009119765",
    amount: 300,
    phone: "08099776655",
    walletCode: "W380XX"
  })
  const wrapper= useRef<any>(null);
  const [currentWallet, setcurrentWallet] = useState(''),[verifyingMetre, setVerifyingMetre] = useState(false);
  const metreName = useRef('');

  
    useEffect(() => {
       const elm =  wrapper.current && (wrapper.current as HTMLElement).querySelectorAll('input[type=radio]')[0];
       const l = wrapper.current && (wrapper.current as HTMLElement).querySelectorAll('label')[0];
       l && l.classList.add('selectedwallet101');
       if(elm){
         elm.checked = true;
        }
        const w = getAppStorage('WALLETS')[0];
        w && setcurrentWallet(w.walletCode);
       
    }, [switchNum]);
  
  useEffect(()=>{
    setPaymentParam({...paymentParam,walletCode:currentWallet});
  },[currentWallet])


  const [subBillers, setSubBillers] = useState<any[]>([]);
  const [brokenPage, setBrokenPage] = useState(false);
  const [reload, setReload] = useState(0);

  
  // call utility sub categories when ever there are selected
  useEffect(()=>{
    if(subBillers.length<1){
      setBrokenPage(false);
        setLoadingStatus({...loadingStatus,subs:true});
        (async()=>{
          try {
            const resp = await getBillerCat();
            if(resp.status===200){
              setLoadingStatus({...loadingStatus,subs:false});         
              setSubBillers(resp?.data?.data);
              setBrokenPage(false);
              
            }
            
          } catch (e) {
            setAppState({...appState, status:true, msg:error.handle(e,"You've lost Internet connection",true).includes('/api')?error.handle(e,"You've lost Internet connection",true).split('/api')[0]+'...':error.handle(e,"You've lost Internet connection",true), hintIcon:messasgeType.failed,count:appState.count+1});
            setBrokenPage(true);
            SessionCheck(e);
          }
          finally{
            setLoadingStatus({...loadingStatus,subs:false});

          }
        })()
    }

  },[reload])

  const verifyMetre= async(number:any)=>{
    setVerifyingMetre(true);
    try {
      let payload = {billerId:paymentParam.billerId,meterNumber:paymentParam.meterNumber};
      const resp = await MetreVerification(payload);
      if(resp.status===200){
        metreName.current = resp.data?.data?.name;
      }
    } catch (e) {
      setAlertState({...alertState, type:IType.error,status:true, title:'Error', message:error.handle(e,"You've lost Internet connection",true)});
      metreName.current = '';
      SessionCheck(e);

    }finally{
      setVerifyingMetre(false);
    }
    
  }

  
  useEffect(()=>{
    setPaymentParam({...paymentParam,billerId:selectedBiller?.billerId});    
  },[selectedBiller])

  
  // const copiedState = useRef<ReactElement>();
  // useEffect(()=>{
  //   copiedState.current = (copied)?<Icon icon="line-md:clipboard-to-clipboard-check-transition"/>:<Icon icon="uil:copy"/>;
  //   console.log(copiedState.current);
    
  // },[copied,copiedState.current])



useEffect(()=>{
if(customer.trim().length>0 &&amount.trim().length>0){
  setFinishing({...finishing,disabled:false});
}else{
  setFinishing({...finishing,disabled:true});
}
setPaymentParam({...paymentParam,meterNumber:customer,amount:sanitize(amount),phone});
},[customer, amount,phone])








const handleChange = (e:any, wallet:string, btn:HTMLLabelElement)=>{
  const el = wrapper.current && (wrapper.current as HTMLElement).querySelector('.selectedwallet101');
  if(el){
    if(el !== btn){
      el.classList.remove('selectedwallet101');
      btn.classList.add('selectedwallet101');
    }
  }else{btn.classList.add('selectedwallet101');}
  setPaymentParam({...paymentParam,walletCode:wallet});

}
const alertChild = useRef<any>();
const sendRecharge = useCallback(async()=>{
  setFinishing({...finishing, text:'Please wait...', icon:<Icon icon="humbleicons:dots-horizontal"/>, loading:true, disabled:true});
  try {
    const resp = await purchaseElectricityToken(paymentParam);
    if(resp.status===200 || resp.status===201){
      const token = resp.data.data?.tokenDetails?.token??'';
      alertChild.current = (<div>
        <p>{resp.data.data?.message}</p>
        <div>Your token is:</div>
        <div style={{padding:'10px', backgroundColor:'rgba(2, 56, 21, 0.07)',borderRadius:4, display:'flex',justifyContent:'space-between'}}>
          <strong>{token}</strong>
          <button className={classes.copyicon} onClick={()=>{
             navigator.clipboard.writeText(`${token}`).then(() => {
              setCopied(true);
              navigateCopiedState();
          }).catch((error) => {

          });
          }}><Icon icon={copied?"line-md:clipboard-to-clipboard-check-transition":"uil:copy"}/> </button>
          
        </div>

      </div>)
      setAlertState({...alertState,type:IType.success, status:true, title:'Success'});
      
      setFinishing({...finishing, text:'Recharge', icon:<Icon icon="eva:arrow-forward-fill"/>, loading:false});
      resetForm();
    }
  } catch (er) {
    setAlertState({...alertState, type:IType.error,status:true, title:'Error', message:error.handle(er,"You've lost Internet connection",true)});
    setFinishing({...finishing, text:'Recharge', icon:<Icon icon="eva:arrow-forward-fill"/>, loading:false, disabled:false});
    SessionCheck(er);
  }

  

},[paymentParam.phone, paymentParam.amount, paymentParam.walletCode,paymentParam.billerId, paymentParam.meterNumber])


const alert = alertChild.current? <Alert type={alertState.type} title={alertState.title} show={alertState.status} onClose={(stat: boolean) => setAlertState({...alertState,status:stat})} >{alertChild.current}</Alert>:<Alert type={alertState.type} title={alertState.title} show={alertState.status} onClose={(stat: boolean) => setAlertState({...alertState,status:stat})} message={alertState.message} />

  return (
    <>
    { 
      createPortal(alert ,document.getElementById('root') as HTMLElement)
    }

    <div className={`${className} ${classes.main}`} style={style}>
    
      <ReturnBar title='DashBoard' onClick={() => { toDashboard() }}>
        {/* <button>filter</button> */}
      </ReturnBar>
      <Typography className="fadeInUp animated faster" component="h2" style={{ margin: "20px 0 10px", fontSize: 20, fontWeight: 600 }}>
      {switchNum===0?'Electricity':'Cable TV'}
        </Typography>
      <OptionSwitch className="fadeIn animated delay-0-4s">
        <SwitchBtn title='Electricity' className={`${switchNum === 0 ? "active" : ""}`} style={{ width: "50%" }} onClick={() => { switchNum !== 0 && setSwitchNum(0) }} />
        <SwitchBtn title='Cable TV' className={`${switchNum === 1 ? "active" : ""}`} style={{ width: "50%" }} onClick={() => { switchNum !== 1 && setSwitchNum(1) }} />
      </OptionSwitch>
      <div>
        {switchNum === 0 && (
          <>
          <Typography component="h3" className={`${classes.smallTitle}`}>Your Wallets</Typography>
          <div ref={wrapper} className={`${classes.walletchecker} scrollbar-transparent`}>
                  {getAppStorage('WALLETS') && getAppStorage('WALLETS').map((w: Record<string, any>, i: number) => {
                    let wRef = createRef<HTMLLabelElement>();
                    //@ts-ignore
                    return <CustomCheckbox title={w.walletName} className={`slideInRight animated delay-0-${i}s`} ref={wRef} isSelected={i === 0} onChange={(e: any) => handleChange(e, w.walletCode, wRef.current)} txt1={w.accNo} txt2={currency.naira + w.bal} id={'wallet' + i} key={i} />;

                  }
                  )}
            </div>
            <div>
           {  subBillers &&  <Typography component="h3" className={`${classes.smallTitle} fadeInUp animated faster`}>Select a biller</Typography>}
              <div>

              {brokenPage &&(<BrokenPage onClick={()=>setReload(reload+1)}/>)}

                <OptionChecker className='fadeIn animated'>

                { loadingStatus.subs? <SChecker cards={6}/>:
                
                !selectedBiller ?
                subBillers && subBillers.map((b:any,i:number)=>{
                  billers.current['biller'+i] = {};

                   return <CheckerBtn key={i} title={b.biller} className={`fadeInUp animated delay-0-${i}s`} onClick={() => {billers.current['biller'+i].selected? billers.current['biller'+i]={selected:false,name:'biller'+i}: billers.current['biller'+i]={selected:true,name:'biller'+i};setSelectedBiller(b);
                 }} checked={billers.current['biller'+i].selected}/>
                }):<CheckerBtn title={selectedBiller && selectedBiller?.biller} className={`fadeIn animated`} onClick={() =>{setSelectedBiller(null); resetForm()}} checked={true}/>
              }
          
                </OptionChecker>
              </div>
              
            </div>

            { loadingStatus.bill ?<SChecker cards={4}/> :
            subBillers&&selectedBiller&& (
            <div>

              <div style={{ zIndex:-100, textAlign:'right'}} className="fadeInUp animated delay-0-1s">
                <TextField className={`animated faster slideInDown`} placeholder={'Metre Number'} onChange={(e: any) => setCustomer(e.target.value)} value={customer} onBlur={verifyMetre}/>
                  {
                    verifyingMetre ? <Icon style={{fontSize:'2.5rem', color:'#2c53b1',margin:'-5px 0 -20px 0'}} icon="eos-icons:three-dots-loading" />:<small style={{textTransform:'uppercase', marginRight:5}}> {metreName.current}</small>
                    }
              </div>

              <div style={{zIndex:-100}} className="fadeInUp animated delay-0-2s">
                <TextField currency={currency.naira} className={`animated faster slideInDown`} placeholder={"Amount"} onChange={(e: any) => setAmount(e.target.value)} value={commarize2(sanitize(amount))} />
              </div>

              <div style={{zIndex:-100}} className="fadeInUp animated delay-0-3s">
                <TextField className={`animated faster slideInDown`} placeholder={"Phone Number"} onChange={(e: any) => setPhone(e.target.value)} value={phone} />
              </div>
            
            <div className={`${classes.cta}`}>
              {(ROLES?.UTILITIES_MODULE?.privileges?.PURCHASE_ELECTRICITY ?? false)&&<Button className={`fadeInUp animated delay-0-4s`} style={{ fontWeight: 800, margin: "30px 0" }} text={finishing.text} icon={finishing.icon} loading={finishing.loading} disabled={finishing.disabled} onClick={sendRecharge} />}
              </div>

            </div>)

            }

            
          </>)}

        {switchNum === 1 && <CableTV/>}

        {/* {switchNum === 2 && <Betting/>} */}

      </div>
    </div>
    </>
  );
}
