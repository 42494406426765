import { Theme } from "@mui/material";


import { makeStyles } from 'tss-react/mui';


const useStyles = makeStyles()((theme: Theme) => ({
    ser: {
        display: 'flex',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column'
        }
    },
    title: {
        width: "50%",display: "flex", alignItems:"flex-end",
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            alignItems: "flex-start",
        }
    },
    addSerBtn: {
        display: "flex", justifyContent: "flex-end", alignItems: "center", width: "50%",
        [theme.breakpoints.down('lg')]: {
            width: '100%',
        }
    },
    addSer: {
        display: "flex", alignItems: "center" 
    }
  }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;