import { alpha, Theme } from "@mui/material";

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  main: {
    width: "25px",
    height: "25px",
    display: "flex",
    padding: "8px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    border: `1px solid ${alpha(theme.palette.background.default, 0.7)}`,
    borderRadius: "8px",
    cursor: "pointer",
    boxSizing: "border-box",
    color: "#555",
    transition: "0.3s ease",
    position: "relative",
    fontSize: "0.8em",
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.default,0.7),
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "center",
      position: "absolute",
      top:0,left:0,right:0,bottom:0
    },
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;