import { makeStyles } from 'tss-react/mui';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles =  makeStyles()((theme)=>({
    main:{
        border:'1px solid #00000070', minWidth:200, height:"auto",  borderRadius:5, overflow:'hidden',padding:'10px 12px', transition:'all ease-in 0.2s', cursor:'pointer',
        [theme.breakpoints.down('sm')]:{
            width: 170,
        },
        
        
    },

    title: {
        fontSize:"clamp(0.8em, 1vw, 1em)",fontWeight:700, whiteSpace: "nowrap", marginLeft: 10
    },
    
    text1:{
        fontSize:"clamp(1rem, 2vw, 1.25rem)",fontWeight:600, letterSpacing: "3px", marginTop:-5,marginLeft: 22
    },
    text2:{
        fontSize:"clamp(1rem, 2vw, 1.2rem)", marginTop:-2,marginLeft: 22
    }


}));