import { Typography } from '@mui/material';
import useStyles from './styles';
import image from '../../images/support.svg';
import React from "react";

export interface IWelcomePageProps {
}

export default function WelcomePage (props: IWelcomePageProps) {
  const classes = useStyles().classes;
  return (
    <div className={classes.main}>
      <div className={classes.illus}>
        <img src={image} alt="" />
      </div>
      <Typography className={classes.welcomeText}>
        Let's help you setup just a few things on your account
      </Typography>
      <Typography className={classes.smallText}>
        Click on continue to proceed
      </Typography>
    </div>
  );
}
